import { jsx as _jsx } from "react/jsx-runtime";
// components
import MountDocumentComponent from "components/documentation/MountDocumentComponent";
export const documentDetails = {
    title: "Date picker range",
    component: "leetent-date-picker-range",
    description: "This is a base component, that is used as part of many composed components.",
    attributes: [
        {
            key: "1",
            attr: "label",
            type: "string (optional)",
            notes: [
                "Label placeholder"
            ]
        },
        {
            key: "2",
            attr: "period",
            type: "string (optional)",
            notes: [
                "Period is a string that expects to be one of:",
                `1day | 2days | 7days | 30days, 60days | 90days | byDate`
            ]
        },
        {
            key: "3",
            attr: "start-date",
            type: "string (optional)",
            notes: [
                "start-date is a string describing the beginning of date range in the format YYYYMMDD"
            ]
        },
        {
            key: "4",
            attr: "end-date",
            type: "string (optional)",
            notes: [
                "end-date is a string describing the ending of date range in the format YYYYMMDD"
            ]
        }
    ],
    events: [
        {
            event: "selected-date-range",
            type: "emit",
            data: [
                "The component details object is emitted on date range change.",
                `detail: {
          period: <1day | 2days | 7days | 30days | 60days | 90days | byDate>,
          date: {
            from: string;
            to: string;
          };
          year: {
            from: number;
            to: number;
          };
          month: {
            from: number;
            to: number;
          };
          day: {
            from: number;
            to: number;
          };
          dayOfWeek: {
            from: {
              index: number;
              name: string;
            };
            to: {
              index: number;
              name: string;
            };
          };
          weekOfYear: {
            from: number;
            to: number;
          };
          iso: {
            from: {
              index: number;
              name: string;
            };
            to: {
              index: number;
              name: string;
            };
          };
          timestamps: {
            from: {
              index: number;
              name: string;
            };
            to: {
              index: number;
              name: string;
            };
          };
        }`
            ]
        }
    ]
};
const DatePickerRange = () => {
    documentDetails.example = `<leetent-date-picker-range
      label="By dates"
      period="byDate"
      start-date={20230830}
      end-date={20230904}
    />`;
    documentDetails.tabs = [
        {
            key: "1",
            label: "Default",
            children: _jsx("leetent-date-picker-range", {})
        },
        {
            key: "2",
            label: "By dates",
            children: _jsx("leetent-date-picker-range", { label: "By dates", period: "byDate", "start-date": 20230830, "end-date": 20230904 })
        },
        {
            key: "3",
            label: "30 days",
            children: _jsx("leetent-date-picker-range", { period: "30days" })
        }
    ];
    // render the page based on the provided object
    return _jsx(MountDocumentComponent, { data: documentDetails });
};
export default DatePickerRange;
