var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Row, Col } from "antd";
import { createRoot } from "react-dom/client";
// styles
import styles from "./CountryLeaguesDropdown.module.scss";
const CountryLeaguesDropdown = ({ countries, leagues }) => {
    const [subDataset, setSubDataset] = React.useState(leagues);
    // listen for date changes emitted by the primary component
    React.useEffect(() => {
        const countriesSelectedOptionHandler = (e) => {
            if (Object.keys(e.detail).length === 0) {
                setSubDataset(leagues);
            }
            else {
                setSubDataset(leagues === null || leagues === void 0 ? void 0 : leagues.filter(item => item.category.id === e.detail.id));
            }
        };
        window.addEventListener("countries-selected-option", countriesSelectedOptionHandler);
        return () => {
            window.removeEventListener("countries-selected-option", countriesSelectedOptionHandler);
        };
    }, []);
    React.useEffect(() => {
        setSubDataset(leagues);
    }, [countries]);
    const PrimaryComponent = () => {
        return (_jsx("leetent-dropdown-generic", { name: "countries", "all-option": true, options: JSON.stringify(countries), "label-key": "name", "value-key": "id" }));
    };
    const SecondaryComponent = () => {
        return (_jsx("leetent-dropdown-generic", { name: "leagues", "all-option": true, options: JSON.stringify(subDataset), "label-key": "name", "value-key": "id" }));
    };
    return (_jsx("div", Object.assign({ className: styles.dropdown }, { children: _jsxs(Row, Object.assign({ justify: "start", align: "middle", gutter: [4, 8] }, { children: [_jsx(Col, Object.assign({ xs: 24, sm: 24, md: 12, lg: 12, xl: 12, xxl: 12 }, { children: _jsx(PrimaryComponent, {}) })), _jsx(Col, Object.assign({ xs: 24, sm: 24, md: 12, lg: 12, xl: 12, xxl: 12 }, { children: _jsx(SecondaryComponent, {}) }))] })) })));
};
class Element extends HTMLElement {
    constructor() {
        super();
        this.root = createRoot(this);
        this.countries = [
            {
                label: "No data returned", value: "nodata"
            }
        ];
        this.leagues = [
            {
                label: "No data returned", value: "nodata"
            }
        ];
    }
    static get observedAttributes() {
        return ["countries", "leagues"];
    }
    connectedCallback() {
        var _a;
        (_a = this.root) === null || _a === void 0 ? void 0 : _a.render(_jsx(CountryLeaguesDropdown, { countries: this.countries, leagues: this.leagues }));
    }
    disconnectedCallback() {
        setTimeout(() => { var _a; return (_a = this.root) === null || _a === void 0 ? void 0 : _a.unmount(); }, 0);
    }
    attributeChangedCallback(attrName, _oldValue, newValue) {
        var _a;
        switch (attrName) {
            case "countries":
            case "leagues":
                this[attrName] = JSON.parse(newValue);
                break;
            default:
                break;
        }
        (_a = this.root) === null || _a === void 0 ? void 0 : _a.render(_jsx(CountryLeaguesDropdown, { countries: this.countries, leagues: this.leagues }));
    }
}
(_a = customElements.get("leetent-country-leagues-dropdown")) !== null && _a !== void 0 ? _a : customElements.define("leetent-country-leagues-dropdown", Element);
