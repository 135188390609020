var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { createRoot } from "react-dom/client";
import axios from "axios";
import { LocalStorage } from "helpers/Storage";
import styles from "./SportsNavigation.module.scss";
// those are items that should be embedded to the main array of objects received by the simple-sports endpoint
// please place them in order (not based on the id)
const additionalItems = [
    {
        id: -2,
        imageUrl: "https://d1sg8aqjbar3ur.cloudfront.net/sports/a-to-zed.svg",
        name: "All",
        counter: 0,
        shouldSubscribe: false
    },
    {
        id: -1,
        imageUrl: "https://d1sg8aqjbar3ur.cloudfront.net/sports/live.svg",
        name: "Live",
        counter: 0,
        shouldSubscribe: false
    }
];
const SportsNavigation = ({ defaultSportId }) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [items, setItems] = React.useState([]);
    React.useEffect(() => {
        const fetchSportsNavigation = () => __awaiter(void 0, void 0, void 0, function* () {
            setIsLoading(true);
            yield axios
                .get(`/simple-sports`)
                .then((res) => {
                setItems([...additionalItems, ...res.data]);
            })
                .catch((e) => {
                console.log("Sports Navigation: ", e);
            })
                .finally(() => {
                setIsLoading(false);
            });
        });
        if (defaultSportId) {
            // TODO: rewatch the solution
            // Set as a macrotask by setTimeout to emit it before listening the event
            setTimeout(() => {
                window.dispatchEvent(new CustomEvent("selected-icon-tab-details", {
                    detail: {
                        id: defaultSportId
                    },
                    bubbles: true
                }));
            });
        }
        fetchSportsNavigation();
    }, []);
    // adds the live item to the array of items
    React.useEffect(() => {
        if ((items === null || items === void 0 ? void 0 : items.length) > (additionalItems === null || additionalItems === void 0 ? void 0 : additionalItems.length)) {
            // stores the sports icons in the local storage
            LocalStorage.set("sportsIcons", JSON.stringify(items));
            // TODO: Commented out for testing purposes
            // Emits the defaultSporId so the icons tab gets updated
            if (defaultSportId) {
                // Set as a macrotask by setTimeout to emit it before listening the event
                setTimeout(() => {
                    window.dispatchEvent(new CustomEvent("update-selected-icons-tab-header", {
                        detail: {
                            id: defaultSportId
                        },
                        bubbles: true
                    }));
                });
            }
            // Emit that sports icons have been added to the local storage
            const triggerUpdatedSportsIconsDetails = () => {
                return new CustomEvent("updated-sports-icon", {
                    detail: {
                        icons: items
                    },
                    bubbles: true
                });
            };
            window.dispatchEvent(triggerUpdatedSportsIconsDetails());
        }
    }, [items]);
    if (isLoading)
        return null;
    return (_jsx("div", Object.assign({ className: styles.root }, { children: _jsx("leetent-icons-tab-header", { icons: JSON.stringify(items.map((_a) => {
                var { id, imageUrl, name, counter = 0, shouldSubscribe = false } = _a, rest = __rest(_a, ["id", "imageUrl", "name", "counter", "shouldSubscribe"]);
                return (Object.assign({ id, src: imageUrl, label: name, counter, shouldSubscribe: id !== -1, type: "sports-navigation" }, rest));
            })), "hide-live-no-events": true, classes: JSON.stringify({
                root: styles.iconsTabHeaderRoot,
                active: styles.iconsTabHeaderActive,
                activeSVG: styles.iconsTabHeaderActiveSVG,
                tab: styles.tab
            }) }) })));
};
class Element extends HTMLElement {
    constructor() {
        super();
        this.root = createRoot(this);
    }
    static get observedAttributes() {
        return ["default-sport-id"];
    }
    renderComponent() {
        var _a;
        (_a = this.root) === null || _a === void 0 ? void 0 : _a.render(_jsx(SportsNavigation, { defaultSportId: this["default-sport-id"] }));
    }
    connectedCallback() {
        this.renderComponent();
    }
    disconnectedCallback() {
        setTimeout(() => { var _a; return (_a = this.root) === null || _a === void 0 ? void 0 : _a.unmount(); }, 0);
    }
    attributeChangedCallback(attrName, _oldValue, newValue) {
        switch (attrName) {
            case "default-sport-id":
                this[attrName] = +newValue;
                break;
            default:
                break;
        }
        this.renderComponent();
    }
}
(_a = customElements.get("leetent-sports-navigation")) !== null && _a !== void 0 ? _a : customElements.define("leetent-sports-navigation", Element);
