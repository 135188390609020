import { jsx as _jsx } from "react/jsx-runtime";
// components
import MountDocumentComponent from "components/documentation/MountDocumentComponent";
export const documentDetails = {
    title: "Social bettor",
    component: "leetent-social-bettor",
    description: `Component used to present social information, containing for instance posts,
    users profile details, and some actions like follow/unfollow someone.
    No attributes are required in order to see this component.
    Ids are automatically retrieved from the local/session storage, and interactions are inside the component itself.
  `,
    withSessionComponent: true,
    dependencies: [
        "leetent-nodata-for-market",
        "leetent-tabs-header",
        "leetent-social-profile-card",
        "EditProfileWrapper (internal component)"
    ],
    attributes: [
        {
            key: "1",
            attr: "no-data-options",
            type: "object{} optional",
            notes: [
                "Custom no data options.",
                `{
          message:"No Events Available at the moment",
          src:"https://d1bu7ybn6mtlcz.cloudfront.net/data_alert.svg",
          cta:{JSON.stringify(label: "Go To Sportsbook",url: "/")}
        }`,
                `Default value is:           
          {
            message:"No Events Available at the moment",
            src:"https://d1bu7ybn6mtlcz.cloudfront.net/data_alert.svg",
          }`
            ]
        }
    ],
    events: [
        {
            event: "selected-tab",
            type: "listener",
            data: [
                "Each time a new tab is selected, the state gets updated.",
                "This is transparent to users."
            ]
        },
        {
            event: "go-back-to-social",
            type: "listener",
            data: [
                "Every time user clicks on the go back button from the profile page, this listener updates the selected tab."
            ]
        },
        {
            event: "profile-image-details",
            type: "listener",
            data: [
                "Based on conditionals, the state for the user profile is updated and its related data."
            ]
        },
        // {
        //   event: "edit-profile-image-details",
        //   type: "listener",
        //   data: [
        //     "When profile is edited, data is emitted."
        //   ]
        // },
        {
            event: "updated-bet-details",
            type: "listener",
            data: [
                "When add to betslip is clicked, the component emits its data."
            ]
        },
        {
            event: "nodata-cta-event-details",
            type: "listener",
            data: [
                "When a user clicks on the button, this event gets emitted.",
                `detail : {
          url: <value>
        }`
            ]
        }
    ]
};
const SocialBettor = () => {
    documentDetails.example = `<leetent-social-bettor />`;
    documentDetails.code = _jsx("leetent-social-bettor", { "no-data-options": JSON.stringify({
            message: "No Events Available at the moment",
            src: "https://d1bu7ybn6mtlcz.cloudfront.net/data_alert.svg",
            cta: { label: "Go To Sportbook", url: "/" }
        }) });
    // render the page based on the provided object
    return _jsx(MountDocumentComponent, { data: documentDetails });
};
export default SocialBettor;
