import { jsx as _jsx } from "react/jsx-runtime";
import { overUnderData as data } from "./assets/data";
// components
import MountDocumentComponent from "components/documentation/MountDocumentComponent";
// document details of inner components
import { documentDetails as singleEventInputDocumentDetails } from "pages/documentation/components/OddsSingleEventInput";
import { leagueMatchesBymarketDocumentDetails } from "../../documentDetails";
export const documentDetails = {
    title: "Over/Under (Display Rule)",
    component: "leetent-market-over-under",
    description: "This is a market component, that is used as part of many composed components.",
    version: "1.1.0",
    withDarkMode: false,
    dependencies: [
        "leetent-odds-singleevent-input"
    ],
    attributes: [],
    events: [],
    innerComponents: [
        {
            name: "leetent-odds-singleevent-input",
            documentDetails: singleEventInputDocumentDetails
        },
        {
            name: "leetent-league-matches-bymarket",
            documentDetails: leagueMatchesBymarketDocumentDetails
        }
    ]
};
const { listEventData, singleEventData } = data;
const DoubleChance = () => {
    documentDetails.example = `<leetent-market-over-under />`;
    documentDetails.tabs = [
        {
            key: "1",
            label: "List version",
            children: _jsx("leetent-market-over-under", { mode: "list", "series-id": 123456, data: JSON.stringify(listEventData) })
        },
        {
            key: "2",
            label: "Single event version",
            // TODO: Pending development
            children: _jsx("div", Object.assign({ style: { padding: 5 } }, { children: _jsx("leetent-market-over-under", { mode: "single", "series-id": 123457, data: JSON.stringify(singleEventData) }) }))
        }
    ];
    // render the page based on the provided object
    return _jsx(MountDocumentComponent, { data: documentDetails });
};
export default DoubleChance;
