var _a;
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { createRoot } from "react-dom/client";
import { SUBSCRIBE_EVENT_MARKET_OUTCOMES } from "v2/graphql/subscriptions";
import { useSubscribeByQuery } from "helpers/graphql/useSubscribeByQuery";
import { checkIsOpen } from "helpers/IsOutcomeOpen";
import { MARKET_STATUS } from "enums";
// markets
import DefaultMarket from "v2/components/MarketViews/Single/DefaultMarket";
import BothTeamsToScore from "v2/components/MarketViews/Single/BothTeamsToScore";
import DoubleChance from "v2/components/MarketViews/Single/DoubleChance";
import CorrectScore from "v2/components/MarketViews/Single/CorrectScore";
// handling and events
import { EventUpdatedOddsOutcomes } from "v2/events/EventUpdatedOddsOutcomes";
import { EventUpdatedMarketSingleOutcomes } from "v2/events/EventUpdatedMarketSingleOutcomes";
import handleBetslip from "v2/helpers/handleBetslip";
const MarketSingle = ({ view = "default", labelAttr, inputView, data }) => {
    var _a, _b;
    const [componentData, setComponentData] = React.useState(data);
    React.useEffect(() => {
        setComponentData(structuredClone(data));
    }, [data]);
    // subscribes to multiple event ids
    const valueHandlerCallback = (data, prevData) => {
        var _a, _b;
        const _data = data.sport_domain_market[0];
        const _prevData = (_a = prevData === null || prevData === void 0 ? void 0 : prevData.sport_domain_market) === null || _a === void 0 ? void 0 : _a[0];
        if ((_prevData === null || _prevData === void 0 ? void 0 : _prevData.market_status_id) !== (_data === null || _data === void 0 ? void 0 : _data.market_status_id)) {
            if ((componentData === null || componentData === void 0 ? void 0 : componentData.market) && _data.id === (componentData === null || componentData === void 0 ? void 0 : componentData.market.id)) {
                componentData.market.statusId = _data.market_status_id;
            }
        }
        const outcomesBeingUpdated = [];
        (_b = _data === null || _data === void 0 ? void 0 : _data.market_rl_outcomes) === null || _b === void 0 ? void 0 : _b.forEach((outcome) => {
            const outcomeRef = componentData === null || componentData === void 0 ? void 0 : componentData.outcomes.find((item) => item.id === outcome.id);
            if (outcomeRef) {
                if (outcomeRef.odds !== outcome.odds)
                    outcomeRef.odds = outcome.odds;
                const isOpen = _data.market_status_id !== MARKET_STATUS.OPEN ?
                    false :
                    checkIsOpen((outcomeRef === null || outcomeRef === void 0 ? void 0 : outcomeRef.isOpen) ? outcomeRef.isOpen : false, // original isOpen, generally from the endpoint
                    {
                        id: _data.id,
                        name: _data.name,
                        statusId: _data.market_status_id
                    }, // current market data (received from subscriptions)
                    outcome.outcome_status_id // current outcome status id
                    );
                outcomeRef.isOpen = isOpen;
                outcomesBeingUpdated.push({
                    id: outcome.id,
                    name: outcome.name,
                    odds: outcome.odds,
                    isOpen
                });
            }
        });
        if ((outcomesBeingUpdated === null || outcomesBeingUpdated === void 0 ? void 0 : outcomesBeingUpdated.length) > 0) {
            // broadcast changes to the Outcomes instantiated
            componentData &&
                EventUpdatedOddsOutcomes(componentData.event.id, componentData.market.id, outcomesBeingUpdated);
        }
        // updates the state
        setComponentData(structuredClone(componentData));
    };
    useSubscribeByQuery({
        query: SUBSCRIBE_EVENT_MARKET_OUTCOMES,
        variables: {
            eventId: (componentData === null || componentData === void 0 ? void 0 : componentData.productId) === 1 && ((_a = componentData === null || componentData === void 0 ? void 0 : componentData.event) === null || _a === void 0 ? void 0 : _a.id),
            marketId: (componentData === null || componentData === void 0 ? void 0 : componentData.productId) === 1 && ((_b = componentData === null || componentData === void 0 ? void 0 : componentData.market) === null || _b === void 0 ? void 0 : _b.id)
        },
        valueHandlerCallback
    });
    // listen for the changes to the children component, and emit its changes back via the custom events
    const updateSelectedOutcomes = React.useCallback((selectedOutcomes, updated) => {
        if (componentData) {
            // adds details to the localStorage
            handleBetslip(componentData, selectedOutcomes, updated);
            // event is dispatched, so every piece of work listening for it, can act accordingly
            EventUpdatedMarketSingleOutcomes(componentData.productId, componentData.category, componentData.tournament, componentData.event, componentData.market, selectedOutcomes, updated);
        }
    }, [componentData]);
    const RenderView = React.useMemo(() => {
        if (!componentData)
            return _jsx(_Fragment, {});
        // market views independently of the product should be listed below
        if (view === "bothTeamsToScore") {
            return (_jsx(BothTeamsToScore, { data: componentData, handleOnClick: updateSelectedOutcomes }));
        }
        else if (view === "doubleChance") {
            return (_jsx(DoubleChance, { labelAttr: labelAttr, inputView: inputView, data: componentData, handleOnClick: updateSelectedOutcomes }));
        }
        else if (view === "correctScore") {
            return (_jsx(CorrectScore, { labelAttr: labelAttr, inputView: inputView, data: componentData, handleOnClick: updateSelectedOutcomes }));
        }
        // else if (view === "correctScore") {
        // } else if (view === "overUnder") {
        // } else if (view === "threeWay") {
        // } else if (view === "threeWayHF") {
        // } else if (view === "handicap") {}
        return (_jsxs(_Fragment, { children: [_jsx("h4", Object.assign({ style: { position: "absolute", top: 0 } }, { children: _jsx("slot", Object.assign({ name: "title" }, { children: "Default string" })) })), _jsx(DefaultMarket, { data: componentData, handleOnClick: updateSelectedOutcomes })] }));
    }, [componentData, labelAttr, inputView]);
    return RenderView;
};
class Element extends HTMLElement {
    constructor() {
        super();
        // this.attachShadow({ mode: "open" });
        // console.log(this.attachInternals());
        // let container = document.createElement("div");
        // const shadowRoot = container.attachShadow({ mode: "open" });
        this.root = createRoot(this);
        // this.root = createRoot(shadowRoot);
    }
    static get observedAttributes() {
        return ["view", "label-attr", "input-view", "data"];
    }
    // The _renderReactComponent, our private method for causing a render to occur
    _renderReactComponent() {
        var _a;
        (_a = this.root) === null || _a === void 0 ? void 0 : _a.render(_jsx(MarketSingle, { view: this.view, labelAttr: this["label-attr"], inputView: this["input-view"], data: this.data }));
    }
    connectedCallback() {
        this._renderReactComponent();
    }
    disconnectedCallback() {
        var _a, _b;
        (_a = customElements.get("leetent-market-single")) !== null && _a !== void 0 ? _a : (_b = this.root) === null || _b === void 0 ? void 0 : _b.unmount();
    }
    attributeChangedCallback(attrName, _oldValue, newValue) {
        switch (attrName) {
            case "view":
                this[attrName] = String(newValue);
                break;
            case "label-attr":
                this[attrName] = String(newValue);
                break;
            case "input-view":
                this[attrName] = String(newValue);
                break;
            case "data":
                this[attrName] = JSON.parse(newValue);
                break;
            default:
                break;
        }
        this._renderReactComponent();
    }
}
(_a = customElements.get("leetent-market-single")) !== null && _a !== void 0 ? _a : customElements.define("leetent-market-single", Element);
