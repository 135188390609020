var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
import weekOfYear from "dayjs/plugin/weekOfYear";
import { Row, Col } from "antd";
import Calendar from "./Calendar";
import { createRoot } from "react-dom/client";
import { getDateByPeriod } from "helpers/DayjsFunctions";
import ImageFromCDN from "components/base/imageFromCDN/ImageFromCDN";
import styles from "./DatePickerRange.module.scss";
// extensions
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(weekOfYear);
const inputLabelFormat = "DD/M ddd";
const daysOfTheWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
export const monthYearFormat = "MMM YYYY";
export const dateFormat = "YYYYMMDD";
export const getCalendar = ({ selectedMonth }) => {
    const firstDay = {
        day: 1,
        index: dayjs(selectedMonth, monthYearFormat).startOf("month").day()
    };
    const lastDay = {
        day: dayjs().endOf("month").date(),
        index: dayjs(selectedMonth, monthYearFormat).endOf("month").day()
    };
    const tempCalendar = [];
    let currentDay = firstDay.day;
    // processes the previous days
    let firstDayIndex = firstDay.index;
    let subtractedCounter = 1;
    while (firstDayIndex >= 1) {
        const date = dayjs(selectedMonth, monthYearFormat)
            .startOf("month")
            .subtract(subtractedCounter, "day");
        tempCalendar.unshift({
            label: date.date(),
            date: date.format(dateFormat)
        });
        firstDayIndex--;
        subtractedCounter++;
    }
    // adds all days in the month
    while (currentDay <= lastDay.day) {
        const date = dayjs(selectedMonth, monthYearFormat).date(currentDay);
        tempCalendar.push({
            label: date.date(),
            date: date.format(dateFormat)
        });
        currentDay++;
    }
    let lastDayIndex = lastDay.index;
    let addedCounter = 1;
    while (lastDayIndex < 6) {
        const date = dayjs(selectedMonth, monthYearFormat)
            .endOf("month")
            .add(addedCounter, "day");
        tempCalendar.push({
            label: date.date(),
            date: date.format(dateFormat)
        });
        lastDayIndex++;
        addedCounter++;
    }
    return tempCalendar;
};
export const DatePickerRange = ({ label, exact, startDateRange, endDateRange, period }) => {
    const [selectedMonth, setSelectedMonth] = React.useState(dayjs().format(monthYearFormat));
    const [isDatePickerOpen, setIsDatePickerOpen] = React.useState(false);
    const [calendar, setCalendar] = React.useState([]);
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    // sets the previous or next day information
    const PreviousOrNextDay = React.useCallback((value) => {
        setStartDate((oldValue) => dayjs(oldValue).add(value === "previous" ? -1 : 1, "day"));
        setEndDate((oldValue) => dayjs(oldValue).add(value === "previous" ? -1 : 1, "day"));
    }, []);
    React.useEffect(() => {
        if (period === "byDate" && startDateRange && endDateRange) {
            setStartDate(exact ?
                dayjs(startDateRange, dateFormat).hour(dayjs().hour()).minute(dayjs().minute()).second(dayjs().second()) :
                dayjs(startDateRange, dateFormat).startOf("date"));
            setEndDate(exact ?
                dayjs(endDateRange, dateFormat).hour(dayjs().hour()).minute(dayjs().minute()).second(dayjs().second()) :
                dayjs(endDateRange, dateFormat).endOf("date"));
        }
    }, [period, startDateRange, endDateRange]);
    React.useEffect(() => {
        if (period !== "byDate") {
            const startDate = (period === null || period === void 0 ? void 0 : period.startsWith("next")) ?
                dayjs() :
                period === "live" ?
                    dayjs().subtract(3, "hour") :
                    getDateByPeriod(period, exact);
            const endDate = exact ?
                period === "live" ?
                    getDateByPeriod("next24h", exact) :
                    getDateByPeriod(period, exact) :
                dayjs().endOf("date");
            if (startDate && endDate) {
                setStartDate(startDate);
                setEndDate(endDate);
            }
        }
        else if (!startDateRange && !endDateRange) {
            setStartDate(null);
            setEndDate(null);
        }
    }, [period]);
    // calendar gets processed
    React.useEffect(() => {
        if (selectedMonth) {
            const calendar = getCalendar({ selectedMonth });
            setCalendar(calendar);
        }
    }, [selectedMonth]);
    // emits the selected date information
    React.useEffect(() => {
        if (startDate && endDate) {
            const triggerSelectedDate = new CustomEvent("selected-date-range", {
                detail: {
                    period,
                    date: {
                        from: startDate.format(dateFormat),
                        to: endDate.format(dateFormat)
                    },
                    year: {
                        from: startDate.year(),
                        to: endDate.year()
                    },
                    month: {
                        from: startDate.month() + 1,
                        to: endDate.month() + 1
                    },
                    day: {
                        from: startDate.date(),
                        to: endDate.date()
                    },
                    dayOfWeek: {
                        from: {
                            index: startDate.day(),
                            name: startDate.format("dddd")
                        },
                        to: {
                            index: endDate.day(),
                            name: endDate.format("dddd")
                        }
                    },
                    weekOfYear: {
                        from: startDate.week(),
                        to: endDate.week()
                    },
                    iso: {
                        // Temporary remove Z(UTC)
                        from: {
                            start: exact ?
                                startDate.format("YYYY-MM-DDTHH:mm:ss") :
                                startDate.startOf("date").format("YYYY-MM-DDTHH:mm:ss"),
                            end: startDate.endOf("date").format("YYYY-MM-DDTHH:mm:ss")
                        },
                        to: {
                            start: exact ?
                                endDate.format("YYYY-MM-DDTHH:mm:ss") :
                                endDate.startOf("date").format("YYYY-MM-DDTHH:mm:ss"),
                            end: endDate.endOf("date").format("YYYY-MM-DDTHH:mm:ss")
                        }
                    },
                    timestamps: {
                        from: {
                            start: exact ?
                                startDate.utc(false).valueOf() :
                                startDate.utc(false).startOf("date").valueOf(),
                            end: startDate.utc(false).endOf("date").valueOf()
                        },
                        to: {
                            start: exact ?
                                endDate.utc(false).valueOf() :
                                endDate.utc(false).startOf("date").valueOf(),
                            end: endDate.utc(false).endOf("date").valueOf()
                        }
                    }
                },
                bubbles: true
            });
            window.dispatchEvent(triggerSelectedDate);
        }
    }, [startDate, endDate, exact]);
    const dateRange = `${dayjs(startDate).format(inputLabelFormat)} - ${dayjs(endDate).format(inputLabelFormat)}`;
    return (_jsxs("div", Object.assign({ "data-testid": "DatePickerRange" }, { children: [_jsxs(Row, Object.assign({ justify: "space-between", align: "middle", gutter: [0, 5], className: styles.date }, { children: [_jsx(Col, Object.assign({ flex: "25px", onClick: () => {
                            PreviousOrNextDay("previous");
                            setIsDatePickerOpen(false);
                        } }, { children: _jsx(ImageFromCDN, { id: "angleDownLeftIcon", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/angle-down.png", overrideStyles: styles.leftArrow, size: 10, float: "center" }) })), _jsx(Col, Object.assign({ flex: "auto", className: styles.dateDetails }, { children: _jsxs(Row, Object.assign({ align: "middle", gutter: [5, 5], onClick: () => {
                                setIsDatePickerOpen((isOpen) => !isOpen);
                            }, "data-testid": "DatePickerRange.togglePanel" }, { children: [_jsx(Col, Object.assign({ flex: "auto" }, { children: _jsx(ImageFromCDN, { url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/calendar.svg", float: "right" }) })), _jsx(Col, Object.assign({ flex: "75px", style: {
                                        textAlign: "left",
                                        fontSize: "12px",
                                        flexBasis: "auto"
                                    } }, { children: _jsx("div", Object.assign({ className: styles.calendarLabel, "data-testid": "DatePickerRange.calendarLabel" }, { children: startDate && endDate ?
                                            dateRange :
                                            label !== null && label !== void 0 ? label : "Choose the date range" })) })), _jsx(Col, Object.assign({ flex: "auto" }, { children: _jsx(ImageFromCDN, { id: "angleDownIcon", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/angle-down.png", size: 8, overrideStyles: {
                                            fontWeight: "bold"
                                        } }) }))] })) })), _jsx(Col, Object.assign({ flex: "25px", onClick: () => {
                            PreviousOrNextDay("next");
                            setIsDatePickerOpen(false);
                        } }, { children: _jsx(ImageFromCDN, { id: "angleDownRightIcon", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/angle-down.png", overrideStyles: styles.rightArrow }) }))] })), _jsxs("div", Object.assign({ className: styles.content, style: {
                    display: isDatePickerOpen ? "block" : "none"
                }, "data-testid": "DatePickerRange.content" }, { children: [_jsxs(Row, Object.assign({ justify: "center", align: "middle" }, { children: [_jsx(Col, { flex: "25px", className: styles.leftArrow, onClick: () => {
                                    setSelectedMonth(dayjs(selectedMonth, monthYearFormat)
                                        .subtract(1, "month")
                                        .format(monthYearFormat));
                                } }), _jsx(Col, Object.assign({ flex: "auto", style: { textAlign: "center", fontSize: "13px", fontWeight: "bold" } }, { children: selectedMonth })), _jsx(Col, { flex: "25px", className: styles.rightArrow, onClick: () => {
                                    setSelectedMonth(dayjs(selectedMonth, monthYearFormat)
                                        .add(1, "month")
                                        .format(monthYearFormat));
                                } })] })), _jsx(Row, Object.assign({ justify: "space-around", align: "middle", className: styles.weekDay }, { children: daysOfTheWeek.map((dayOfTheWeek) => {
                            return (_jsx(Col, Object.assign({ className: styles.weekLabel }, { children: dayOfTheWeek }), dayOfTheWeek));
                        }) })), _jsx(Calendar, { calendar: calendar, startDate: startDate, endDate: endDate, setStartDate: setStartDate, setEndDate: setEndDate, selectedMonth: selectedMonth, setIsDatePickerOpen: setIsDatePickerOpen, exact: exact }), _jsxs(Row, Object.assign({ justify: "space-around", align: "middle", className: styles.footer }, { children: [_jsx(Col, Object.assign({ span: 12, className: styles.today, onClick: () => {
                                    setStartDate(dayjs());
                                    setEndDate(dayjs());
                                } }, { children: "Today" })), _jsx(Col, Object.assign({ span: 12, className: styles.close, onClick: () => {
                                    setIsDatePickerOpen((oldValue) => !oldValue);
                                } }, { children: "Close" }))] }))] }))] })));
};
class Element extends HTMLElement {
    static get observedAttributes() {
        return ["label", "exact", "start-date", "end-date", "period"];
    }
    connectedCallback() {
        createRoot(this).render(_jsx(DatePickerRange, { label: this.label, startDateRange: this["start-date"], endDateRange: this["end-date"], period: this.period, exact: this.exact }));
    }
    attributeChangedCallback(attrName, _oldValue, newValue) {
        switch (attrName) {
            case "exact":
                this[attrName] = JSON.parse(newValue);
                break;
            case "label":
            case "start-date":
            case "end-date":
            case "period":
                this[attrName] = String(newValue);
                break;
            default:
                break;
        }
    }
}
(_a = customElements.get("leetent-date-picker-range")) !== null && _a !== void 0 ? _a : customElements.define("leetent-date-picker-range", Element);
