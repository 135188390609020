import { jsx as _jsx } from "react/jsx-runtime";
// components
import MountDocumentComponent from "components/documentation/MountDocumentComponent";
const SportsNavigation = () => {
    const documentDetails = {
        title: "Sports navigation",
        component: "leetent-sports-navigation",
        description: "Sports navigation component.",
        example: "<leetent-sports-navigation />",
        attributes: [
            {
                key: "1",
                attr: "default-sport-id (optional)",
                type: "number",
                notes: [
                    "No need to pass this prop, unless you want to select a different sport by default.",
                    `e.g. <leetent-sports-navigation default-sport-id={3} />`
                ]
            }
        ],
        code: _jsx("leetent-sports-navigation", {})
    };
    // render the page based on the provided object
    return _jsx(MountDocumentComponent, { data: documentDetails });
};
export default SportsNavigation;
