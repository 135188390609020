var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { createRoot } from "react-dom/client";
import { Modal } from "components/base/modal/Modal";
import clsx from "clsx";
// styles
import styles from "../modal/ModalGeneric.module.scss";
const defaultOptions = [
    {
        id: 1,
        label: "Login",
        url: "/sign-in"
    },
    {
        id: 2,
        label: "Register",
        url: "/sign-up"
    }
];
const UnauthenticatedUserModal = ({ open, title = "Please login or register to proceed", destination, overrideOptions }) => {
    const [isModalOpen, setIsModalOpen] = React.useState(open);
    const [options, setOptions] = React.useState(defaultOptions);
    React.useEffect(() => {
        setIsModalOpen(open);
    }, [open]);
    React.useEffect(() => {
        if (overrideOptions) {
            // only applies isSocialEnabled configuration, when override is not provided by client
            const isIdProvided = overrideOptions === null || overrideOptions === void 0 ? void 0 : overrideOptions.every((override) => Object.prototype.hasOwnProperty.call(override, "id"));
            overrideOptions.forEach((override, index) => {
                // check id attribute exists, and try overriding it by id, and if not provided, then do it sequentialy
                let item;
                if (isIdProvided) {
                    item = options.find(item => item.id === override.id);
                }
                else {
                    item = options[index];
                }
                if (item && Object.prototype.hasOwnProperty.call(override, "label"))
                    item.label = override.label;
                if (item && Object.prototype.hasOwnProperty.call(override, "url"))
                    item.url = override.url;
                if (item && Object.prototype.hasOwnProperty.call(override, "Icon"))
                    item.Icon = override.Icon;
            });
            setOptions(options);
        }
    }, []);
    const triggerCloseModal = () => {
        const closeModal = new CustomEvent("close-auth-modal", {
            bubbles: true
        });
        window.dispatchEvent(closeModal);
    };
    const handleButtonClick = (option) => () => {
        setIsModalOpen(false);
        const triggerAuthButton = new CustomEvent("click-auth-button", {
            detail: Object.assign({ origin: window.location.pathname, destination }, option),
            bubbles: true
        });
        window.dispatchEvent(triggerAuthButton);
        triggerCloseModal();
    };
    const closeModalHandler = () => {
        setIsModalOpen(false);
        triggerCloseModal();
    };
    return (_jsx(Modal, Object.assign({ open: isModalOpen, onClose: closeModalHandler }, { children: _jsxs("div", Object.assign({ className: styles.root }, { children: [_jsx("span", Object.assign({ className: styles.title }, { children: title })), _jsx("div", Object.assign({ className: styles.btns }, { children: options.map((option, index) => {
                        const { Icon } = option;
                        return (_jsxs("button", Object.assign({ className: clsx(styles.btn, {
                                [styles.primary]: index === 1 || options.length === 1
                            }), onClick: handleButtonClick(option) }, { children: [(option === null || option === void 0 ? void 0 : option.Icon) && _jsx(Icon, {}), option.label] }), `unauthenticated-user-modal-${option.id}`));
                    }) }))] })) })));
};
class Element extends HTMLElement {
    constructor() {
        super();
        this.root = createRoot(this);
    }
    static get observedAttributes() {
        return ["open", "modal-title", "options", "destination"];
    }
    connectedCallback() {
        var _a;
        (_a = this.root) === null || _a === void 0 ? void 0 : _a.render(_jsx(UnauthenticatedUserModal, { open: this.open, title: this["modal-title"], destination: this.destination, overrideOptions: this.options }));
    }
    disconnectedCallback() {
        setTimeout(() => { var _a; return (_a = this.root) === null || _a === void 0 ? void 0 : _a.unmount(); }, 0);
    }
    attributeChangedCallback(attrName, _oldValue, newValue) {
        var _a;
        switch (attrName) {
            case "modal-title":
            case "destination":
                this[attrName] = String(newValue);
                break;
            case "open":
            case "options":
                this[attrName] = JSON.parse(newValue);
                break;
            default:
                break;
        }
        (_a = this.root) === null || _a === void 0 ? void 0 : _a.render(_jsx(UnauthenticatedUserModal, { open: this.open, title: this["modal-title"], destination: this.destination, overrideOptions: this.options }));
    }
}
(_a = customElements.get("leetent-unauthenticated-user-modal")) !== null && _a !== void 0 ? _a : customElements.define("leetent-unauthenticated-user-modal", Element);
