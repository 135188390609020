var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { createRoot } from "react-dom/client";
import axios from "axios";
import { Row, Col } from "antd";
import clsx from "clsx";
import { LocalStorage } from "helpers/Storage";
import SkeletonSportsEvents from "components/base/skeleton/SportsEvents";
import { SPORT } from "enums";
import { Breadcrumbs } from "components/composed/breadcrumbs/Breadcrumbs";
import { generateEventDetailBreadcrumbData } from "helpers/Sports/Breadcrumbs";
// styles
import styles from "./CountryLeagueMarkets.module.scss";
const CountryLeagueMarkets = ({ leagueId, marketsView = "tags", withAnimation = false, sport }) => {
    var _a, _b, _c, _d, _e, _f;
    const [isLoading, setIsLoading] = React.useState(true);
    const [error, setError] = React.useState();
    const [selectedLeague, setSelectedLeague] = React.useState();
    const [defaultOutcomes, setDefaultOutcomes] = React.useState([]);
    const [marketTypeConfigs, setMarketTypeConfigs] = React.useState([]);
    React.useEffect(() => {
        var _a, _b;
        if (!leagueId) {
            setError("Internal error! Please try again.");
        }
        // read data from the betDetails storage and pass them on to the children components
        const betsDefaultOutcomes = (_b = (_a = LocalStorage.get("betsDetails")) === null || _a === void 0 ? void 0 : _a.filter((bet) => (bet === null || bet === void 0 ? void 0 : bet.productId) === 1)) === null || _b === void 0 ? void 0 : _b.map((bet) => bet === null || bet === void 0 ? void 0 : bet.outcomeId);
        if (betsDefaultOutcomes === null || betsDefaultOutcomes === void 0 ? void 0 : betsDefaultOutcomes.length)
            setDefaultOutcomes(betsDefaultOutcomes);
        const updateSelectedBetsHandler = (e) => {
            var _a, _b, _c;
            !((_a = e.detail) === null || _a === void 0 ? void 0 : _a.isRemoveBet) && setMarketTypeConfigs([]);
            setDefaultOutcomes([...(_c = (_b = e.detail) === null || _b === void 0 ? void 0 : _b.selectedBets) === null || _c === void 0 ? void 0 : _c.map((bet) => bet === null || bet === void 0 ? void 0 : bet.outcomeId)]);
        };
        const matchesBydatesInputHandler = (e) => {
            setDefaultOutcomes(outcomes => {
                var _a;
                if (e.detail.operation === "select") {
                    if ((_a = e.detail) === null || _a === void 0 ? void 0 : _a.isOpen) {
                        return [...outcomes, e.detail.outcomeId];
                    }
                    return outcomes;
                }
                else {
                    return [...outcomes.filter(outcome => outcome !== e.detail.outcomeId)];
                }
            });
        };
        const handleClearBets = () => { setDefaultOutcomes([]); };
        window.addEventListener("matches-bydates-input-details", matchesBydatesInputHandler);
        window.addEventListener("odds-singleevent-input-details", matchesBydatesInputHandler);
        window.addEventListener("update-selected-bets", updateSelectedBetsHandler);
        window.addEventListener("bet-slip-clear-bets", handleClearBets);
        return () => {
            window.removeEventListener("matches-bydates-input-details", matchesBydatesInputHandler);
            window.removeEventListener("odds-singleevent-input-details", matchesBydatesInputHandler);
            window.removeEventListener("update-selected-bets", updateSelectedBetsHandler);
            window.removeEventListener("bet-slip-clear-bets", handleClearBets);
        };
    }, []);
    // fetches the market type configs
    const fetchMarketTypeConfigs = React.useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        var _g, _h;
        if (((_g = process.env) === null || _g === void 0 ? void 0 : _g.REACT_APP_SPORTSBOOK_API_URL) && ((_h = process.env) === null || _h === void 0 ? void 0 : _h.REACT_APP_SPORTSBOOK_API_KEY)) {
            yield axios.get("/market-type-configs")
                .then((res) => {
                setError(undefined);
                setMarketTypeConfigs(res.data);
            }).catch((e) => {
                setError(e);
            });
        }
        else {
            setError("Environment variables are not in place");
        }
        ;
    }), []);
    // fetches league details
    const fetchLeagues = React.useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        var _j, _k;
        !isLoading && setIsLoading(true);
        if (((_j = process.env) === null || _j === void 0 ? void 0 : _j.REACT_APP_SPORTSBOOK_API_URL) && ((_k = process.env) === null || _k === void 0 ? void 0 : _k.REACT_APP_SPORTSBOOK_API_KEY)) {
            yield axios.get(`/tournament/${leagueId}/events?includeMarketData=true`)
                .then((res) => {
                var _a, _b, _c;
                setError(undefined);
                const processingLeagueCategories = [];
                const processingGroupedLeagues = [];
                const league = res.data;
                const isCategoryAdded = processingLeagueCategories.find(category => category.id === league.category.id);
                if (!isCategoryAdded) {
                    processingLeagueCategories.push(league.category);
                    processingGroupedLeagues.push({
                        categoryId: league.category.id,
                        category: league.category.name,
                        leagues: [{
                                id: league.id,
                                name: league.name,
                                country: league.category,
                                events: league.events,
                                availableMarkets: league.events.reduce((total, current) => {
                                    var _a;
                                    total += (_a = current === null || current === void 0 ? void 0 : current.markets) === null || _a === void 0 ? void 0 : _a.length;
                                    return total;
                                }, 0),
                                isOutrightAvailable: league.isOutrightAvailable
                            }]
                    });
                }
                else {
                    processingGroupedLeagues.find(category => category.categoryId === league.category.id).leagues.push({
                        id: league.id,
                        name: league.name,
                        country: league.category,
                        events: league.events,
                        availableMarkets: league.events.reduce((total, current) => {
                            var _a;
                            total += (_a = current === null || current === void 0 ? void 0 : current.markets) === null || _a === void 0 ? void 0 : _a.length;
                            return total;
                        }, 0),
                        isOutrightAvailable: league.isOutrightAvailable
                    });
                }
                if ((processingGroupedLeagues === null || processingGroupedLeagues === void 0 ? void 0 : processingGroupedLeagues.length) === 1) {
                    // replaces market name with the provided short name
                    (_b = (_a = processingGroupedLeagues[0]) === null || _a === void 0 ? void 0 : _a.leagues[0]) === null || _b === void 0 ? void 0 : _b.events.map((event) => {
                        var _a;
                        return (_a = event === null || event === void 0 ? void 0 : event.markets) === null || _a === void 0 ? void 0 : _a.map((market) => {
                            var _a, _b;
                            const marketConfig = marketTypeConfigs.find((config) => config.id === market.marketTypeConfigId);
                            if (marketConfig === null || marketConfig === void 0 ? void 0 : marketConfig.shortName)
                                market.name = marketConfig === null || marketConfig === void 0 ? void 0 : marketConfig.shortName;
                            if ((_a = marketConfig === null || marketConfig === void 0 ? void 0 : marketConfig.marketType) === null || _a === void 0 ? void 0 : _a.displayRule)
                                market.displayRule = (_b = marketConfig === null || marketConfig === void 0 ? void 0 : marketConfig.marketType) === null || _b === void 0 ? void 0 : _b.displayRule;
                            return market;
                        });
                    });
                    setSelectedLeague((_c = processingGroupedLeagues[0]) === null || _c === void 0 ? void 0 : _c.leagues[0]);
                }
                else {
                    setSelectedLeague({});
                }
            }).catch((e) => {
                setError(e);
            }).finally(() => {
                setIsLoading(false);
            });
        }
        else {
            setIsLoading(false);
            setError("Environment variables are not in place");
        }
        ;
    }), [isLoading, marketTypeConfigs]);
    React.useEffect(() => {
        if (leagueId &&
            (marketTypeConfigs === null || marketTypeConfigs === void 0 ? void 0 : marketTypeConfigs.length) === 0)
            fetchMarketTypeConfigs();
        if (leagueId && !error && (marketTypeConfigs === null || marketTypeConfigs === void 0 ? void 0 : marketTypeConfigs.length) > 0)
            fetchLeagues();
    }, [leagueId, error, marketTypeConfigs]);
    const emitEvents = (action, country, league) => {
        var _a, _b;
        const formattedCountry = (_a = country === null || country === void 0 ? void 0 : country.country) === null || _a === void 0 ? void 0 : _a.toLowerCase();
        const formattedLeague = (_b = league === null || league === void 0 ? void 0 : league.league) === null || _b === void 0 ? void 0 : _b.toLowerCase().replaceAll(" ", "-");
        const triggerRoutingDetails = new CustomEvent(action, {
            detail: {
                slug: `/${formattedCountry}/${formattedLeague}`,
                country,
                league
            },
            bubbles: true
        });
        window.dispatchEvent(triggerRoutingDetails);
    };
    if (error) {
        return _jsx("div", { children: JSON.stringify(error) });
    }
    if (isLoading) {
        return _jsx(SkeletonSportsEvents, {});
    }
    const sportName = sport.name;
    const HeaderImage = () => {
        var _a;
        // eslint-disable-next-line
        const headerImage = `https://d1sg8aqjbar3ur.cloudfront.net/headers/sports/single-event-detail-header-${(_a = sportName === null || sportName === void 0 ? void 0 : sportName.toLowerCase()) === null || _a === void 0 ? void 0 : _a.replace(/\s+/g, '-')}.png`;
        return _jsx("img", { className: styles.headerBgImg, src: headerImage, alt: "sport background" });
    };
    let leagueName = "";
    if (sport.id === SPORT.TENNIS && ((_a = selectedLeague === null || selectedLeague === void 0 ? void 0 : selectedLeague.country) === null || _a === void 0 ? void 0 : _a.name)) {
        leagueName = `${selectedLeague.country.name.toUpperCase()} - `;
    }
    leagueName += selectedLeague === null || selectedLeague === void 0 ? void 0 : selectedLeague.name;
    const breadcrumbs = generateEventDetailBreadcrumbData({
        sportName,
        countryName: (_b = selectedLeague === null || selectedLeague === void 0 ? void 0 : selectedLeague.country) === null || _b === void 0 ? void 0 : _b.name,
        leagueName: selectedLeague === null || selectedLeague === void 0 ? void 0 : selectedLeague.name
    });
    return selectedLeague && ((_c = Object.keys(selectedLeague)) === null || _c === void 0 ? void 0 : _c.length) > 0 ?
        _jsxs("div", Object.assign({ className: clsx(styles.leagueDetails, {
                [styles.selected]: withAnimation && selectedLeague,
                [styles.dismiss]: withAnimation && !selectedLeague
            }) }, { children: [_jsxs(Row, Object.assign({ justify: "start", align: "middle", className: styles.leagueDetailsHeader }, { children: [_jsx(HeaderImage, {}), _jsx(Col, Object.assign({ flex: "auto" }, { children: _jsxs(Row, Object.assign({ align: "middle" }, { children: [_jsx(Col, Object.assign({ flex: "22px", onClick: () => {
                                            emitEvents("go-back", {
                                                countryId: -1,
                                                country: "countries"
                                            }, {
                                                leagueId: -1,
                                                league: "all"
                                            });
                                        }, className: styles.backBtn }, { children: _jsx("div", { className: styles.leftArrow }) })), _jsx(Col, Object.assign({ className: styles.countryLeagueName }, { children: _jsx(Breadcrumbs, { items: breadcrumbs.map((breadcrumb, index) => {
                                                if (index !== 1)
                                                    return breadcrumb;
                                                breadcrumb.title = leagueName;
                                                return breadcrumb;
                                            }), from: "country-league-markets", withoutDropdownIcon: true }) })), _jsxs(Col, Object.assign({ span: 24, style: {
                                            marginTop: 5,
                                            display: "flex",
                                            alignItems: "center",
                                            borderTop: "1px solid #D1D9E51A",
                                            padding: 8
                                        } }, { children: [((_d = selectedLeague === null || selectedLeague === void 0 ? void 0 : selectedLeague.country) === null || _d === void 0 ? void 0 : _d.imageUrl) &&
                                                _jsx(Col, Object.assign({ style: { marginRight: 10 } }, { children: _jsx("img", { src: (_e = selectedLeague === null || selectedLeague === void 0 ? void 0 : selectedLeague.country) === null || _e === void 0 ? void 0 : _e.imageUrl, alt: (_f = selectedLeague === null || selectedLeague === void 0 ? void 0 : selectedLeague.country) === null || _f === void 0 ? void 0 : _f.name, style: { borderRadius: 2, width: 22 } }) })), _jsx("div", Object.assign({ className: styles.leagueName }, { children: leagueName }))] }))] })) }))] })), _jsx("leetent-league-multiple-markets", { "product-id": 1, data: JSON.stringify(selectedLeague), "markets-view": marketsView, "default-outcomes": JSON.stringify(defaultOutcomes), sport: JSON.stringify(sport) })] })) :
        _jsx("leetent-nodata-for-market", { src: "https://d1bu7ybn6mtlcz.cloudfront.net/data_alert.png" });
};
class Element extends HTMLElement {
    constructor() {
        super();
        this.root = createRoot(this);
        this["league-id"] = 0;
        this.sport = {
            id: 1,
            name: "soccer"
        };
    }
    static get observedAttributes() {
        return ["league-id", "markets-view", "with-animation", "sport"];
    }
    renderComponent() {
        var _a;
        (_a = this.root) === null || _a === void 0 ? void 0 : _a.render(_jsx(CountryLeagueMarkets, { leagueId: this["league-id"], marketsView: this["markets-view"], withAnimation: this["with-animation"], sport: this.sport }));
    }
    connectedCallback() {
        this.renderComponent();
    }
    disconnectedCallback() {
        setTimeout(() => { var _a; return (_a = this.root) === null || _a === void 0 ? void 0 : _a.unmount(); }, 0);
    }
    attributeChangedCallback(attrName, _oldValue, newValue) {
        switch (attrName) {
            case "league-id":
                this[attrName] = Number(newValue);
                break;
            case "markets-view":
                this[attrName] = String(newValue);
                break;
            case "with-animation":
            case "sport":
                this[attrName] = JSON.parse(newValue);
                break;
            default:
                break;
        }
        this.renderComponent();
    }
}
(_a = customElements.get("leetent-country-league-markets")) !== null && _a !== void 0 ? _a : customElements.define("leetent-country-league-markets", Element);
