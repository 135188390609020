import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Button, Col, Input, Row, Space } from "antd";
// components
import MountDocumentComponent from "components/documentation/MountDocumentComponent";
// document details of inner components
import { tagsListDocumentDetails, tabsHeaderDocumentDetails } from "pages/documentation/documentDetails";
export const documentDetails = {
    title: "Country league markets",
    component: "leetent-country-league-markets",
    description: "This component renders a list of leagues grouped by countries.",
    dependencies: [
        "leetent-league-multiple-markets",
        "leetent-nodata-for-market"
    ],
    attributes: [
        {
            key: "1",
            attr: "league-id",
            type: "number",
            notes: [
                "This is the league-id, and you can decide to inject it, and let the component render the data by itself.",
                `Note: Bear in mind, that if the data prop is expressed, it takes precedent over the league-id,
        which means the component would skip the auto-fetch data.`
            ]
        },
        {
            key: "2",
            attr: "markets-view",
            type: "string  (optional)",
            notes: [
                "You can inform tabs or tags in order to see a different header view on this component.",
                "The following options are available: tabs or tags.",
                "It is an optional prop, so if not informed, you will see it as tags."
            ]
        }
    ],
    events: [
        {
            event: "go-back",
            type: "emit",
            data: ["Each time a user clicks to go back to the countries/leagues page, this information gets emitted."]
        },
        {
            event: "update-selected-bets",
            type: "listener",
            data: ["When add to betslip is clicked, the component emits its data."]
        }
    ],
    innerComponents: [
        {
            name: "leetent-league-multiple-markets",
            documentDetails: {
                events: [
                    {
                        event: "league-markets-options",
                        type: "listener",
                        data: ["-"]
                    },
                    {
                        event: "selected-tag-details",
                        type: "listener",
                        data: ["-"]
                    }
                ],
                innerComponents: [
                    // TODO: create reusable documentDetails obj
                    {
                        name: "leetent-tabs-header",
                        documentDetails: tabsHeaderDocumentDetails
                    },
                    {
                        name: "leetent-tags-list",
                        documentDetails: tagsListDocumentDetails
                    }
                ]
            }
        }
    ]
};
const InteractiveInputs = ({ updateComponent, leagueId }) => {
    return (_jsx(Row, Object.assign({ justify: "start", align: "top", gutter: [30, 10] }, { children: _jsxs(Col, Object.assign({ xs: 20, sm: 20, md: 10, lg: 6 }, { children: [_jsx("label", { children: "Event id for sports" }), _jsxs(Space.Compact, Object.assign({ style: { width: "100%" } }, { children: [_jsx(Input, { id: "leagueIdInput", defaultValue: leagueId, size: "large" }), _jsx(Button, Object.assign({ type: "primary", size: "large", onClick: updateComponent }, { children: "Submit" }))] }))] })) })));
};
const CountryLeagueMarkets = () => {
    const [leagueId, setLeagueId] = React.useState(10170);
    const updateComponent = () => {
        const value = document.getElementById("leagueIdInput").value;
        setLeagueId(value);
    };
    documentDetails.interactiveInputs = _jsx(InteractiveInputs, { updateComponent: updateComponent, leagueId: leagueId });
    documentDetails.example = `<leetent-country-league-markets
      league-id={1}
      markets-view="tags"
    />`;
    documentDetails.code = _jsx("leetent-country-league-markets", { "league-id": leagueId });
    // render the page based on the provided object
    return _jsx(MountDocumentComponent, { data: documentDetails });
};
export default CountryLeagueMarkets;
