import { jsx as _jsx } from "react/jsx-runtime";
// components
import MountDocumentComponent from "components/documentation/MountDocumentComponent";
// document details of inner components
import { dropdownGenericDocumentDetails } from "pages/documentation/documentDetails";
import { documentDetails as datePickerRangeDocumentDetails } from "pages/documentation/components/DatePickerRange";
export const documentDetails = {
    title: "Range filters",
    component: "leetent-range-filters",
    description: "This is a base component, that is used as part of many composed components.",
    attributes: [
        {
            key: "1",
            attr: "start-date",
            type: "string (optional)",
            notes: [
                "start-date is a string describing the beginning of date range in the format YYYYMMDD"
            ]
        },
        {
            key: "2",
            attr: "end-date",
            type: "string (optional)",
            notes: [
                "end-date is a string describing the ending of date range in the format YYYYMMDD"
            ]
        },
        {
            key: "3",
            attr: "exact",
            type: "boolean (optional)",
            notes: [
                "When this prop is informed, start and end dates are based on the current time",
                "If not passed, then you will see dates start @ 00:00:00 and finishing at 23:59:59"
            ]
        }
    ],
    events: [
        {
            event: "selected-date-range",
            type: "listener",
            data: [
                "The component details object is emitted on date range change.",
                `detail: {
          period: <1day | 2days | 7days | 30days | 60days | 90days | byDate>,
          date: {
            from: string;
            to: string;
          };
          year: {
            from: number;
            to: number;
          };
          month: {
            from: number;
            to: number;
          };
          day: {
            from: number;
            to: number;
          };
          dayOfWeek: {
            from: {
              index: number;
              name: string;
            };
            to: {
              index: number;
              name: string;
            };
          };
          weekOfYear: {
            from: number;
            to: number;
          };
          iso: {
            from: {
              index: number;
              name: string;
            };
            to: {
              index: number;
              name: string;
            };
          };
          timestamps: {
            from: {
              index: number;
              name: string;
            };
            to: {
              index: number;
              name: string;
            };
          };
        }`
            ]
        },
        {
            event: "range-filters-selected-option",
            type: "listener",
            data: [
                "The component details object is emitted on range filter option change.",
                `detail: {
          label: string,
          value: "1day" | "2days" | "7days" | "30days" "60days" | "90days" | "byDate"
        }`
            ]
        }
    ],
    innerComponents: [
        {
            name: "leetent-dropdown-generic",
            documentDetails: dropdownGenericDocumentDetails
        },
        {
            name: "leetent-date-picker-range",
            documentDetails: datePickerRangeDocumentDetails
        }
    ]
};
const RangeFilters = () => {
    documentDetails.example = `<leetent-range-filters exact />`;
    documentDetails.code = _jsx("leetent-range-filters", { exact: true });
    // render the page based on the provided object
    return _jsx(MountDocumentComponent, { data: documentDetails });
};
export default RangeFilters;
