import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import clsx from "clsx";
import { Grid, Row, Col } from "antd";
import dayjs from "dayjs";
import Live from "components/base/live/Live";
import ScoresBySport from "components/base/scoresBySport/ScoresBySport";
import { EVENT_STATUS } from "enums";
import { checkIsOpen } from "helpers/IsOutcomeOpen";
import { processedTimeCounter, showClockForSport } from "helpers/ProcessTimeCounter";
import { OddsSuspendedOrVariation } from "components/base/oddsSuspendedOrVariation/OddsSuspendedOrVariation";
import { SUBSCRIBE_EVENTS, SUBSCRIBE_EVENT_MARKET, SUBSCRIBE_OUTCOME } from "v2/graphql/subscriptions";
import { useSubscribeByQuery } from "helpers/graphql/useSubscribeByQuery";
import { mapOutcomeName } from "helpers/OutcomeNameMapper";
import { isInPlayBlacklistedSport } from "helpers/InPlayBlacklistSports";
import { useShowIndicator } from "hooks/useShowIndicator";
import { formatOdds } from "helpers/Bets";
import ImageFromCDN from "components/base/imageFromCDN/ImageFromCDN";
import styles from "./Event.module.scss";
const { useBreakpoint } = Grid;
const getEmptyOdds = (displayRule) => {
    switch (displayRule) {
        case "DEFAULT":
            return Array.from({ length: 2 });
        case "THREE_WAY":
        default:
            return Array.from({ length: 3 });
    }
};
export const EventCardSelection = ({ outcome, match, selectedOutcomes, marketView, onClick, sport, league }) => {
    var _a, _b, _c, _d;
    // data object is coming from different sources into this component, and don't have the same structure
    const marketId = ((_a = match === null || match === void 0 ? void 0 : match.market) === null || _a === void 0 ? void 0 : _a.id) ? match.market.id : match === null || match === void 0 ? void 0 : match.marketId;
    const marketStatusId = ((_b = match === null || match === void 0 ? void 0 : match.market) === null || _b === void 0 ? void 0 : _b.statusId) ? match.market.statusId : match === null || match === void 0 ? void 0 : match.marketStatusId;
    const [prevMarketData, setPrevMarketData] = React.useState({
        id: marketId,
        statusId: marketStatusId,
        eventId: match === null || match === void 0 ? void 0 : match.id
    });
    const [marketData, setMarketData] = React.useState({
        id: marketId,
        statusId: marketStatusId,
        eventId: match === null || match === void 0 ? void 0 : match.id
    });
    const [refOutcome, setRefOutcome] = React.useState(Object.assign(Object.assign({}, outcome), { isOpen: checkIsOpen(outcome === null || outcome === void 0 ? void 0 : outcome.isOpen, marketData, outcome === null || outcome === void 0 ? void 0 : outcome.statusId) }));
    const [refVariation, setRefVariation] = React.useState(0);
    const { showIndicator } = useShowIndicator({ refVariation });
    // subscribe to the the related market
    const valueMarketHandlerCallback = (data, prevData) => {
        var _a;
        const _data = data.sport_domain_market[0];
        const _prevData = (_a = prevData === null || prevData === void 0 ? void 0 : prevData.sport_domain_market) === null || _a === void 0 ? void 0 : _a[0];
        const prevModel = {
            id: _prevData === null || _prevData === void 0 ? void 0 : _prevData.id,
            statusId: _prevData === null || _prevData === void 0 ? void 0 : _prevData.outcome_status_id,
            eventId: match.id // this shoud've come from the subscription
        };
        const model = {
            id: _data === null || _data === void 0 ? void 0 : _data.id,
            statusId: _data === null || _data === void 0 ? void 0 : _data.outcome_status_id,
            eventId: match.id // this shoud've come from the subscription
        };
        if (prevModel.statusId !== model.statusId) {
            setPrevMarketData(prevModel);
            setMarketData(model);
        }
    };
    useSubscribeByQuery({
        query: SUBSCRIBE_EVENT_MARKET,
        variables: {
            eventId: match.id,
            marketId
        },
        valueHandlerCallback: valueMarketHandlerCallback
    });
    // outcome data from GraphQL API
    const [data, setData] = React.useState({
        id: refOutcome === null || refOutcome === void 0 ? void 0 : refOutcome.id,
        key: refOutcome === null || refOutcome === void 0 ? void 0 : refOutcome.key,
        name: refOutcome === null || refOutcome === void 0 ? void 0 : refOutcome.name,
        isOpen: refOutcome === null || refOutcome === void 0 ? void 0 : refOutcome.isOpen,
        statusId: refOutcome === null || refOutcome === void 0 ? void 0 : refOutcome.statusId,
        odds: refOutcome === null || refOutcome === void 0 ? void 0 : refOutcome.odds,
        isBetBuilderEnabled: refOutcome === null || refOutcome === void 0 ? void 0 : refOutcome.isBetBuilderEnabled
    });
    // subscribes to the live counter topic and updates
    const valueHandlerCallback = (data, prevData) => {
        var _a;
        const _data = data.sport_domain_outcome[0];
        const _prevData = (_a = prevData === null || prevData === void 0 ? void 0 : prevData.sport_domain_outcome) === null || _a === void 0 ? void 0 : _a[0];
        if (_data) {
            const model = {
                id: _data.id,
                statusId: _data.outcome_status_id,
                odds: _data.odds,
                marketId: _data.market_id,
                eventId: match.id,
                isOpen: _data.is_active
            };
            setData(model);
            if ((model === null || model === void 0 ? void 0 : model.odds) && (_prevData === null || _prevData === void 0 ? void 0 : _prevData.odds)) {
                setRefVariation(model.odds - _prevData.odds);
            }
        }
        // processes the variation
    };
    useSubscribeByQuery({
        query: SUBSCRIBE_OUTCOME,
        variables: {
            eventId: match.id,
            marketId,
            outcomeId: refOutcome.id
        },
        valueHandlerCallback
    });
    React.useEffect(() => {
        // only updates if market or odds attributes have changed (mainly values or statuses)
        if (prevMarketData.statusId !== marketData.statusId ||
            (data === null || data === void 0 ? void 0 : data.odds) !== (refOutcome === null || refOutcome === void 0 ? void 0 : refOutcome.odds) ||
            (data === null || data === void 0 ? void 0 : data.statusId) !== (refOutcome === null || refOutcome === void 0 ? void 0 : refOutcome.statusId) ||
            (data === null || data === void 0 ? void 0 : data.isOpen) !== (refOutcome === null || refOutcome === void 0 ? void 0 : refOutcome.isOpen)) {
            setRefOutcome((oldRef) => {
                var _a;
                return (Object.assign(Object.assign({}, oldRef), { odds: data === null || data === void 0 ? void 0 : data.odds, statusId: data === null || data === void 0 ? void 0 : data.statusId, isOpen: checkIsOpen((_a = data === null || data === void 0 ? void 0 : data.isOpen) !== null && _a !== void 0 ? _a : outcome === null || outcome === void 0 ? void 0 : outcome.isOpen, marketData, data === null || data === void 0 ? void 0 : data.statusId) }));
            });
        }
    }, [data, prevMarketData, marketData]);
    const screens = useBreakpoint();
    const isEmpty = refOutcome === undefined;
    const odds = (_c = data === null || data === void 0 ? void 0 : data.odds) !== null && _c !== void 0 ? _c : refOutcome === null || refOutcome === void 0 ? void 0 : refOutcome.odds;
    return _jsxs(Col, Object.assign({ flex: "auto", className: clsx(styles.odd, {
            [styles.suspended]: !(refOutcome === null || refOutcome === void 0 ? void 0 : refOutcome.isOpen),
            [styles.empty]: isEmpty,
            [styles.highlight]: (refOutcome === null || refOutcome === void 0 ? void 0 : refOutcome.id) && selectedOutcomes.includes(refOutcome.id),
            [styles.mobile]: screens.xs
        }), onClick: () => {
            var _a, _b, _c, _d;
            onClick({
                matchId: match.id,
                marketName: match.marketName || ((_a = match.market) === null || _a === void 0 ? void 0 : _a.name),
                marketId: match.marketId || ((_b = match.market) === null || _b === void 0 ? void 0 : _b.id),
                event: match.name,
                outcomeId: refOutcome === null || refOutcome === void 0 ? void 0 : refOutcome.id,
                leagueName: (_c = league === null || league === void 0 ? void 0 : league.name) !== null && _c !== void 0 ? _c : match === null || match === void 0 ? void 0 : match.leagueName,
                leagueId: (_d = league === null || league === void 0 ? void 0 : league.id) !== null && _d !== void 0 ? _d : match === null || match === void 0 ? void 0 : match.leagueId,
                name: refOutcome === null || refOutcome === void 0 ? void 0 : refOutcome.name,
                odds,
                startDate: match.startDate,
                isOpen: refOutcome === null || refOutcome === void 0 ? void 0 : refOutcome.isOpen,
                isBetBuilderEnabled: refOutcome === null || refOutcome === void 0 ? void 0 : refOutcome.isBetBuilderEnabled,
                time: match.time,
                sport,
                statusId: data === null || data === void 0 ? void 0 : data.statusId
            });
        } }, { children: [((marketView === "overUnder" && (refOutcome === null || refOutcome === void 0 ? void 0 : refOutcome.name)) || (refOutcome === null || refOutcome === void 0 ? void 0 : refOutcome.label)) && (_jsx("span", Object.assign({ className: clsx(styles.line, {
                    [styles.suspended]: !(refOutcome === null || refOutcome === void 0 ? void 0 : refOutcome.isOpen),
                    [styles.empty]: isEmpty,
                    [styles.highlight]: (refOutcome === null || refOutcome === void 0 ? void 0 : refOutcome.id) && selectedOutcomes.includes(refOutcome.id)
                }) }, { children: (_d = refOutcome === null || refOutcome === void 0 ? void 0 : refOutcome.label) !== null && _d !== void 0 ? _d : mapOutcomeName(refOutcome === null || refOutcome === void 0 ? void 0 : refOutcome.name) }))), !(refOutcome === null || refOutcome === void 0 ? void 0 : refOutcome.isOpen) || !odds ? "-" : formatOdds(odds), OddsSuspendedOrVariation({ suspended: !(refOutcome === null || refOutcome === void 0 ? void 0 : refOutcome.isOpen), refVariation, showIndicator })] }));
};
export const EventCardRow = ({ match, sport, onClick, country, league, displayRule, selectedOutcomes, marketView, isBBEnabled }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29, _30, _31, _32, _33, _34, _35, _36, _37, _38, _39, _40, _41, _42, _43;
    const [liveData, setLiveData] = React.useState(match);
    // we lowercase and remove spaces identified in the sports names
    const sportNameLowerCase = (_a = sport === null || sport === void 0 ? void 0 : sport.name) === null || _a === void 0 ? void 0 : _a.toLowerCase().replaceAll(" ", "");
    // subscribes to multiple event ids
    const valueHandlerCallback = (details) => {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        const nextData = details.sport_domain_event[0];
        const model = {
            id: nextData === null || nextData === void 0 ? void 0 : nextData.id,
            startDate: nextData === null || nextData === void 0 ? void 0 : nextData.start_at,
            statusId: nextData === null || nextData === void 0 ? void 0 : nextData.event_status_id,
            inPlay: {
                clock: {
                    minutes: (_a = nextData === null || nextData === void 0 ? void 0 : nextData.event_clock) === null || _a === void 0 ? void 0 : _a.minutes,
                    period: (_b = nextData === null || nextData === void 0 ? void 0 : nextData.event_clock) === null || _b === void 0 ? void 0 : _b.period_name,
                    seconds: (_c = nextData === null || nextData === void 0 ? void 0 : nextData.event_clock) === null || _c === void 0 ? void 0 : _c.seconds,
                    remainingMinutesInPeriod: (_d = nextData === null || nextData === void 0 ? void 0 : nextData.event_clock) === null || _d === void 0 ? void 0 : _d.minutes_left_in_period,
                    remainingSecondsInPeriod: (_e = nextData === null || nextData === void 0 ? void 0 : nextData.event_clock) === null || _e === void 0 ? void 0 : _e.seconds_left_in_period,
                    statusId: (_f = nextData === null || nextData === void 0 ? void 0 : nextData.event_clock) === null || _f === void 0 ? void 0 : _f.event_clock_status_id,
                    timestamp: (_g = nextData === null || nextData === void 0 ? void 0 : nextData.event_clock) === null || _g === void 0 ? void 0 : _g.timestamp_epoch
                },
                score: {
                    home: nextData === null || nextData === void 0 ? void 0 : nextData.home_score,
                    away: nextData === null || nextData === void 0 ? void 0 : nextData.away_score,
                    periodScores: (_h = nextData === null || nextData === void 0 ? void 0 : nextData.period_scores) === null || _h === void 0 ? void 0 : _h.map((scores) => {
                        return {
                            home: scores.home_score,
                            away: scores.away_score
                        };
                    })
                }
            }
        };
        setLiveData(model);
    };
    useSubscribeByQuery({
        query: SUBSCRIBE_EVENTS,
        variables: {
            eventIds: [match === null || match === void 0 ? void 0 : match.id]
        },
        valueHandlerCallback
    });
    const dispatchEventMatchRow = (match) => {
        const triggerEventMatchDetails = new CustomEvent("match-event-details", {
            detail: {
                sport: {
                    id: sport === null || sport === void 0 ? void 0 : sport.id,
                    name: sport === null || sport === void 0 ? void 0 : sport.name
                },
                country: {
                    id: country === null || country === void 0 ? void 0 : country.id,
                    name: country === null || country === void 0 ? void 0 : country.name
                },
                league: {
                    id: league === null || league === void 0 ? void 0 : league.id,
                    name: league === null || league === void 0 ? void 0 : league.name
                },
                match: {
                    id: match.id,
                    name: match.name,
                    homeTeam: match.homeTeam,
                    awayTeam: match.awayTeam,
                    status: match.status,
                    marketName: match.marketName,
                    startDate: match.startDate,
                    time: match.time,
                    isBetBuilderEnabled: match.isBetBuilderEnabled
                }
            },
            bubbles: true
        });
        window.dispatchEvent(triggerEventMatchDetails);
    };
    const dispatchEventMoreMarkets = (match) => {
        const triggerEventMatchMoreMarkets = new CustomEvent("match-more-markets", {
            detail: {
                sport: {
                    id: sport === null || sport === void 0 ? void 0 : sport.id,
                    name: sport === null || sport === void 0 ? void 0 : sport.name
                },
                country: {
                    id: country === null || country === void 0 ? void 0 : country.id,
                    name: country === null || country === void 0 ? void 0 : country.name
                },
                league: {
                    id: league === null || league === void 0 ? void 0 : league.id,
                    name: league === null || league === void 0 ? void 0 : league.name
                },
                match: {
                    id: match.id,
                    name: match.name,
                    homeTeam: match.homeTeam,
                    awayTeam: match.awayTeam,
                    status: match.status,
                    marketName: match.marketName,
                    startDate: match.startDate,
                    time: match.time,
                    isBetBuilderEnabled: match.isBetBuilderEnabled
                }
            },
            bubbles: true
        });
        window.dispatchEvent(triggerEventMatchMoreMarkets);
    };
    const showBBLogo = ((liveData === null || liveData === void 0 ? void 0 : liveData.isBetBuilderEnabled) || match.isBetBuilderEnabled) &&
        (((liveData === null || liveData === void 0 ? void 0 : liveData.statusId) || match.statusId) !== EVENT_STATUS.LIVE) &&
        isBBEnabled;
    return (_jsxs(_Fragment, { children: [_jsxs(Col, Object.assign({ xs: 11, sm: 12, md: 12, lg: 8, xl: 8, style: { cursor: "pointer" }, onClick: () => dispatchEventMatchRow(match) }, { children: [_jsxs(Row, Object.assign({ align: "middle" }, { children: [_jsx(Col, Object.assign({ span: ((_b = (liveData === null || liveData === void 0 ? void 0 : liveData.statusId) === EVENT_STATUS.LIVE) !== null && _b !== void 0 ? _b : (match === null || match === void 0 ? void 0 : match.statusId) === EVENT_STATUS.LIVE) ? 16 : 24, style: {
                                    fontSize: "11px",
                                    letterSpacing: "-0.2px",
                                    lineHeight: "12.89px"
                                } }, { children: `${match.homeTeam}` })), !isInPlayBlacklistedSport(sport === null || sport === void 0 ? void 0 : sport.id) &&
                                ((_c = (liveData === null || liveData === void 0 ? void 0 : liveData.statusId) === EVENT_STATUS.LIVE) !== null && _c !== void 0 ? _c : (match === null || match === void 0 ? void 0 : match.statusId) === EVENT_STATUS.LIVE) &&
                                ((_e = (_d = liveData === null || liveData === void 0 ? void 0 : liveData.inPlay) === null || _d === void 0 ? void 0 : _d.clock) !== null && _e !== void 0 ? _e : (_f = match === null || match === void 0 ? void 0 : match.inPlay) === null || _f === void 0 ? void 0 : _f.clock) &&
                                ((_h = (_g = liveData === null || liveData === void 0 ? void 0 : liveData.inPlay) === null || _g === void 0 ? void 0 : _g.score) !== null && _h !== void 0 ? _h : (_j = match === null || match === void 0 ? void 0 : match.inPlay) === null || _j === void 0 ? void 0 : _j.score) && (_jsx(Col, Object.assign({ span: 8, style: {
                                    fontSize: "11px",
                                    lineHeight: "12.89px"
                                } }, { children: _jsx(ScoresBySport, { sportName: sportNameLowerCase, type: "home", clock: (_l = (_k = liveData === null || liveData === void 0 ? void 0 : liveData.inPlay) === null || _k === void 0 ? void 0 : _k.clock) !== null && _l !== void 0 ? _l : (_m = match === null || match === void 0 ? void 0 : match.inPlay) === null || _m === void 0 ? void 0 : _m.clock, score: (_p = (_o = liveData === null || liveData === void 0 ? void 0 : liveData.inPlay) === null || _o === void 0 ? void 0 : _o.score) !== null && _p !== void 0 ? _p : (_q = match === null || match === void 0 ? void 0 : match.inPlay) === null || _q === void 0 ? void 0 : _q.score, periodScores: (_t = (_s = (_r = liveData === null || liveData === void 0 ? void 0 : liveData.inPlay) === null || _r === void 0 ? void 0 : _r.score) === null || _s === void 0 ? void 0 : _s.periodScores) !== null && _t !== void 0 ? _t : (_v = (_u = match === null || match === void 0 ? void 0 : match.inPlay) === null || _u === void 0 ? void 0 : _u.score) === null || _v === void 0 ? void 0 : _v.periodScores }) })))] })), _jsxs(Row, { children: [_jsx(Col, Object.assign({ span: ((_w = (liveData === null || liveData === void 0 ? void 0 : liveData.statusId) === EVENT_STATUS.LIVE) !== null && _w !== void 0 ? _w : (match === null || match === void 0 ? void 0 : match.statusId) === EVENT_STATUS.LIVE) ? 16 : 24, style: {
                                    fontSize: "11px",
                                    letterSpacing: "-0.2px",
                                    lineHeight: "12.89px",
                                    marginTop: 5
                                } }, { children: `${match.awayTeam}` })), !isInPlayBlacklistedSport(sport === null || sport === void 0 ? void 0 : sport.id) &&
                                ((_x = (liveData === null || liveData === void 0 ? void 0 : liveData.statusId) === EVENT_STATUS.LIVE) !== null && _x !== void 0 ? _x : (match === null || match === void 0 ? void 0 : match.statusId) === EVENT_STATUS.LIVE) &&
                                ((_z = (_y = liveData === null || liveData === void 0 ? void 0 : liveData.inPlay) === null || _y === void 0 ? void 0 : _y.clock) !== null && _z !== void 0 ? _z : (_0 = match === null || match === void 0 ? void 0 : match.inPlay) === null || _0 === void 0 ? void 0 : _0.clock) &&
                                ((_2 = (_1 = liveData === null || liveData === void 0 ? void 0 : liveData.inPlay) === null || _1 === void 0 ? void 0 : _1.score) !== null && _2 !== void 0 ? _2 : (_3 = match === null || match === void 0 ? void 0 : match.inPlay) === null || _3 === void 0 ? void 0 : _3.score) && (_jsx(Col, Object.assign({ span: 8, style: {
                                    fontSize: "11px",
                                    lineHeight: "12.89px"
                                } }, { children: _jsx(ScoresBySport, { sportName: sportNameLowerCase, type: "away", clock: (_5 = (_4 = liveData === null || liveData === void 0 ? void 0 : liveData.inPlay) === null || _4 === void 0 ? void 0 : _4.clock) !== null && _5 !== void 0 ? _5 : (_6 = match === null || match === void 0 ? void 0 : match.inPlay) === null || _6 === void 0 ? void 0 : _6.clock, score: (_8 = (_7 = liveData === null || liveData === void 0 ? void 0 : liveData.inPlay) === null || _7 === void 0 ? void 0 : _7.score) !== null && _8 !== void 0 ? _8 : (_9 = match === null || match === void 0 ? void 0 : match.inPlay) === null || _9 === void 0 ? void 0 : _9.score, periodScores: (_12 = (_11 = (_10 = liveData === null || liveData === void 0 ? void 0 : liveData.inPlay) === null || _10 === void 0 ? void 0 : _10.score) === null || _11 === void 0 ? void 0 : _11.periodScores) !== null && _12 !== void 0 ? _12 : (_14 = (_13 = match === null || match === void 0 ? void 0 : match.inPlay) === null || _13 === void 0 ? void 0 : _13.score) === null || _14 === void 0 ? void 0 : _14.periodScores }) }))), _jsx(Col, Object.assign({ span: 24 }, { children: _jsxs(Row, Object.assign({ style: { marginTop: "5px" } }, { children: [!isInPlayBlacklistedSport(sport === null || sport === void 0 ? void 0 : sport.id) &&
                                            ((_15 = (liveData === null || liveData === void 0 ? void 0 : liveData.statusId) === EVENT_STATUS.LIVE) !== null && _15 !== void 0 ? _15 : (match === null || match === void 0 ? void 0 : match.statusId) === EVENT_STATUS.LIVE) ?
                                            (_jsxs(Col, Object.assign({ span: sportNameLowerCase !== "tennis" &&
                                                    sportNameLowerCase !== "volleyball" &&
                                                    sportNameLowerCase !== "tabletennis" ?
                                                    24 :
                                                    16, style: {
                                                    fontSize: "9px",
                                                    color: "#707070",
                                                    marginTop: 3,
                                                    marginBottom: 8,
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: 5
                                                } }, { children: [_jsx(Live, { sport: sport, period: (_18 = (_17 = (_16 = liveData === null || liveData === void 0 ? void 0 : liveData.inPlay) === null || _16 === void 0 ? void 0 : _16.clock) === null || _17 === void 0 ? void 0 : _17.period) !== null && _18 !== void 0 ? _18 : (_20 = (_19 = match === null || match === void 0 ? void 0 : match.inPlay) === null || _19 === void 0 ? void 0 : _19.clock) === null || _20 === void 0 ? void 0 : _20.period, timeCounter: showClockForSport(sport === null || sport === void 0 ? void 0 : sport.id) ?
                                                            processedTimeCounter((_22 = (_21 = liveData === null || liveData === void 0 ? void 0 : liveData.inPlay) === null || _21 === void 0 ? void 0 : _21.clock) !== null && _22 !== void 0 ? _22 : (_23 = match === null || match === void 0 ? void 0 : match.inPlay) === null || _23 === void 0 ? void 0 : _23.clock, sportNameLowerCase === "basketball") :
                                                            "", isCountdown: sportNameLowerCase === "basketball", statusId: (_26 = (_25 = (_24 = liveData === null || liveData === void 0 ? void 0 : liveData.inPlay) === null || _24 === void 0 ? void 0 : _24.clock) === null || _25 === void 0 ? void 0 : _25.statusId) !== null && _26 !== void 0 ? _26 : (_28 = (_27 = match === null || match === void 0 ? void 0 : match.inPlay) === null || _27 === void 0 ? void 0 : _27.clock) === null || _28 === void 0 ? void 0 : _28.statusId, startDate: (_29 = liveData === null || liveData === void 0 ? void 0 : liveData.startDate) !== null && _29 !== void 0 ? _29 : match === null || match === void 0 ? void 0 : match.startDate, minutes: (_32 = (_31 = (_30 = liveData === null || liveData === void 0 ? void 0 : liveData.inPlay) === null || _30 === void 0 ? void 0 : _30.clock) === null || _31 === void 0 ? void 0 : _31.minutes) !== null && _32 !== void 0 ? _32 : (_34 = (_33 = match === null || match === void 0 ? void 0 : match.inPlay) === null || _33 === void 0 ? void 0 : _33.clock) === null || _34 === void 0 ? void 0 : _34.minutes, seconds: (_37 = (_36 = (_35 = liveData === null || liveData === void 0 ? void 0 : liveData.inPlay) === null || _35 === void 0 ? void 0 : _35.clock) === null || _36 === void 0 ? void 0 : _36.seconds) !== null && _37 !== void 0 ? _37 : (_39 = (_38 = match === null || match === void 0 ? void 0 : match.inPlay) === null || _38 === void 0 ? void 0 : _38.clock) === null || _39 === void 0 ? void 0 : _39.seconds }), showBBLogo && (_jsx(ImageFromCDN, { alt: "Bet Builder", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/bet-builder.svg", overrideStyles: styles.betBuilder, width: 30 }))] }))) :
                                            (_jsxs(Col, Object.assign({ span: sportNameLowerCase !== "tennis" &&
                                                    sportNameLowerCase !== "volleyball" &&
                                                    sportNameLowerCase !== "tabletennis" ?
                                                    24 :
                                                    16, style: {
                                                    fontSize: "9px",
                                                    color: "#707070",
                                                    marginTop: 3,
                                                    marginBottom: 8,
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: 5
                                                } }, { children: [dayjs(match.startDate).format("DD MMMM | HH:mm").toUpperCase(), showBBLogo && (_jsx(ImageFromCDN, { alt: "Bet Builder", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/bet-builder.svg", overrideStyles: styles.betBuilder, width: 30 }))] }))), !isInPlayBlacklistedSport(sport === null || sport === void 0 ? void 0 : sport.id) &&
                                            ((_40 = (liveData === null || liveData === void 0 ? void 0 : liveData.statusId) === EVENT_STATUS.LIVE) !== null && _40 !== void 0 ? _40 : (match === null || match === void 0 ? void 0 : match.statusId) === EVENT_STATUS.LIVE) &&
                                            sportNameLowerCase === "tennis" && (_jsx(Col, Object.assign({ span: 8 }, { children: _jsxs(_Fragment, { children: [_jsx("span", Object.assign({ className: clsx(styles.tennisLabels) }, { children: "S" })), _jsx("span", Object.assign({ className: clsx(styles.tennisLabels), style: {
                                                            borderRight: "1px solid #cccccc",
                                                            paddingRight: "10%"
                                                        } }, { children: "G" })), _jsx("span", Object.assign({ className: clsx(styles.tennisLabels) }, { children: "P" }))] }) }))), !isInPlayBlacklistedSport(sport === null || sport === void 0 ? void 0 : sport.id) &&
                                            ((_41 = (liveData === null || liveData === void 0 ? void 0 : liveData.statusId) === EVENT_STATUS.LIVE) !== null && _41 !== void 0 ? _41 : (match === null || match === void 0 ? void 0 : match.statusId) === EVENT_STATUS.LIVE) &&
                                            sportNameLowerCase === "volleyball" && (_jsx(Col, Object.assign({ span: 8 }, { children: _jsxs(_Fragment, { children: [_jsx("span", Object.assign({ className: clsx(styles.volleyballLabels) }, { children: "S" })), _jsx("span", Object.assign({ className: clsx(styles.volleyballLabels), style: {
                                                            borderRight: "1px solid #cccccc",
                                                            paddingRight: "10%"
                                                        } }, { children: "P" }))] }) }))), !isInPlayBlacklistedSport(sport === null || sport === void 0 ? void 0 : sport.id) &&
                                            ((_42 = (liveData === null || liveData === void 0 ? void 0 : liveData.statusId) === EVENT_STATUS.LIVE) !== null && _42 !== void 0 ? _42 : (match === null || match === void 0 ? void 0 : match.statusId) === EVENT_STATUS.LIVE) &&
                                            sportNameLowerCase === "tabletennis" && (_jsx(Col, Object.assign({ span: 8 }, { children: _jsxs(_Fragment, { children: [_jsx("span", Object.assign({ className: clsx(styles.tabletennisLabels) }, { children: "S" })), _jsx("span", Object.assign({ className: clsx(styles.tabletennisLabels), style: {
                                                            borderRight: "1px solid #cccccc",
                                                            paddingRight: "10%"
                                                        } }, { children: "P" }))] }) })))] })) }))] })] })), _jsxs(Col, Object.assign({ xs: 13, sm: 12, md: 12, lg: 16, xl: 16 }, { children: [_jsx(Row, Object.assign({ justify: "space-between", align: "middle" }, { children: (((_43 = match === null || match === void 0 ? void 0 : match.market) === null || _43 === void 0 ? void 0 : _43.outcomes) || getEmptyOdds(displayRule)).map((outcome, index) => {
                            var _a, _b;
                            if ((_a = match === null || match === void 0 ? void 0 : match.market) === null || _a === void 0 ? void 0 : _a.line) {
                                const line = Number.parseFloat(match.market.line);
                                if (outcome.key === "home") {
                                    outcome.label = line > 0 ? `+${line}` : `${line}`;
                                }
                                else if (outcome.key === "away") {
                                    const inverseLine = line * -1;
                                    outcome.label = inverseLine > 0 ? `+${inverseLine}` : `${inverseLine}`;
                                }
                            }
                            return (_jsx(EventCardSelection, { outcome: outcome, match: match, sport: sport, selectedOutcomes: selectedOutcomes, marketView: marketView, league: league, onClick: onClick }, `match-${match.id}-outcome-${(_b = outcome === null || outcome === void 0 ? void 0 : outcome.id) !== null && _b !== void 0 ? _b : `empty-${index}`}`));
                        }) })), _jsx(Row, Object.assign({ justify: "end", align: "middle", style: { marginBottom: 10 } }, { children: _jsxs(Col, Object.assign({ flex: "auto", className: clsx(styles.marketCount), onClick: () => dispatchEventMoreMarkets(match) }, { children: ["More +", match.marketCount, " ", _jsx("span", { children: "\u203A" })] })) }))] }))] }));
};
