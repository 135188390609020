import { jsx as _jsx } from "react/jsx-runtime";
import { Nx2Data, Nx2YesNo, Nx2Yes } from "./assets/data";
// components
import MountDocumentComponent from "components/documentation/MountDocumentComponent";
// document details of inner components
import { documentDetails as singleEventInputDocumentDetails } from "pages/documentation/components/OddsSingleEventInput";
export const documentDetails = {
    title: "Nx2 (Display Rule)",
    component: "leetent-market-n2",
    description: "This is a market component, that is used as part of many composed components.",
    withDarkMode: false,
    innerComponents: [
        {
            name: "leetent-odds-singleevent-input",
            documentDetails: singleEventInputDocumentDetails
        }
    ]
};
const CorrectScore = () => {
    documentDetails.example = `<leetent-market-n2 />`;
    documentDetails.tabs = [
        {
            key: "1",
            label: "Over Under",
            children: _jsx("leetent-market-n2", { "series-id": 123, data: JSON.stringify(Nx2Data) })
        },
        {
            key: "2",
            label: "Yes No",
            children: _jsx("leetent-market-n2", { "series-id": 123, data: JSON.stringify(Nx2YesNo), "yes-no": true })
        },
        {
            key: "3",
            label: "Yes",
            children: _jsx("leetent-market-n2", { "series-id": 123, data: JSON.stringify(Nx2Yes), "yes-no": true })
        }
    ];
    // render the page based on the provided object
    return _jsx(MountDocumentComponent, { data: documentDetails });
};
export default CorrectScore;
