var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var _a;
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { createRoot } from "react-dom/client";
import clsx from "clsx";
import { Row, Col } from "antd";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import isTomorrow from "dayjs/plugin/isTomorrow";
import { LocalStorage } from "helpers/Storage";
import { isInPlayBlacklistedSport } from "helpers/InPlayBlacklistSports";
import { BETS_LIMIT, EVENT_STATUS } from "enums";
import Live from "components/base/live/Live";
import ScoresBySport from "components/base/scoresBySport/ScoresBySport";
import { SUBSCRIBE_EVENTS } from "v2/graphql/subscriptions";
import { useSubscribeByQuery } from "helpers/graphql/useSubscribeByQuery";
import { processedTimeCounter, showClockForSport } from "helpers/ProcessTimeCounter";
import { EventCardSelection } from "../eventCard/EventCardSelection";
import ImageFromCDN from "components/base/imageFromCDN/ImageFromCDN";
import axios from "axios";
import styles from "./Matches.module.scss";
dayjs.extend(isToday);
dayjs.extend(isTomorrow);
const LeagueMatches = ({ sport, country, league, data, singleDay, defaultOutcomes, headerKeys, productId }) => {
    var _a, _b, _c, _d;
    const [refData, setRefData] = React.useState(data);
    const [selectedOutcomes, setSelectedOutcome] = React.useState(defaultOutcomes !== null && defaultOutcomes !== void 0 ? defaultOutcomes : []);
    const [isBBEnabled, setIsBBEnabled] = React.useState(false);
    const [eventsData, setEventsData] = React.useState([...new Set((_a = data === null || data === void 0 ? void 0 : data.matchesByDate) === null || _a === void 0 ? void 0 : _a.map((byDate) => {
            return byDate === null || byDate === void 0 ? void 0 : byDate.times.map((time) => {
                return time === null || time === void 0 ? void 0 : time.matches.map((match) => {
                    const event = {
                        id: match.id,
                        startDate: match === null || match === void 0 ? void 0 : match.startDate,
                        statusId: match === null || match === void 0 ? void 0 : match.statusId
                    };
                    return event;
                });
            });
        }).flat(Infinity))]);
    // when the prop data changes, we should update the state
    React.useEffect(() => {
        setRefData(data);
    }, [data]);
    React.useEffect(() => {
        const fetchClientData = () => {
            var _a;
            if (!((_a = process.env) === null || _a === void 0 ? void 0 : _a.REACT_APP_GATEWAY_API_URL))
                return;
            axios
                .get(`${process.env.REACT_APP_GATEWAY_API_URL}/client/config`)
                .then((res) => {
                var _a;
                const { isBetBuilderEnabled } = (_a = res.data) !== null && _a !== void 0 ? _a : {};
                setIsBBEnabled(isBetBuilderEnabled);
            })
                .catch((e) => {
                console.log(e);
            });
        };
        fetchClientData();
    }, []);
    const sportNameLowerCase = (_b = sport === null || sport === void 0 ? void 0 : sport.name) === null || _b === void 0 ? void 0 : _b.toLowerCase().replaceAll(" ", "");
    const sportId = sport === null || sport === void 0 ? void 0 : sport.id;
    // subscribes to multiple event ids
    const valueHandlerCallback = (details) => {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        const nextData = details.sport_domain_event[0];
        const model = {
            id: nextData === null || nextData === void 0 ? void 0 : nextData.id,
            startDate: nextData === null || nextData === void 0 ? void 0 : nextData.start_at,
            statusId: nextData === null || nextData === void 0 ? void 0 : nextData.event_status_id,
            inPlay: {
                clock: {
                    minutes: (_a = nextData === null || nextData === void 0 ? void 0 : nextData.event_clock) === null || _a === void 0 ? void 0 : _a.minutes,
                    period: (_b = nextData === null || nextData === void 0 ? void 0 : nextData.event_clock) === null || _b === void 0 ? void 0 : _b.period_name,
                    seconds: (_c = nextData === null || nextData === void 0 ? void 0 : nextData.event_clock) === null || _c === void 0 ? void 0 : _c.seconds,
                    remainingMinutesInPeriod: (_d = nextData === null || nextData === void 0 ? void 0 : nextData.event_clock) === null || _d === void 0 ? void 0 : _d.minutes_left_in_period,
                    remainingSecondsInPeriod: (_e = nextData === null || nextData === void 0 ? void 0 : nextData.event_clock) === null || _e === void 0 ? void 0 : _e.seconds_left_in_period,
                    statusId: (_f = nextData === null || nextData === void 0 ? void 0 : nextData.event_clock) === null || _f === void 0 ? void 0 : _f.event_clock_status_id,
                    timestamp: (_g = nextData === null || nextData === void 0 ? void 0 : nextData.event_clock) === null || _g === void 0 ? void 0 : _g.timestamp_epoch
                },
                score: {
                    home: nextData === null || nextData === void 0 ? void 0 : nextData.home_score,
                    away: nextData === null || nextData === void 0 ? void 0 : nextData.away_score,
                    periodScores: (_h = nextData === null || nextData === void 0 ? void 0 : nextData.period_scores) === null || _h === void 0 ? void 0 : _h.map((scores) => {
                        return {
                            home: scores.home_score,
                            away: scores.away_score
                        };
                    })
                }
            }
        };
        setEventsData(oldArray => oldArray.map((event) => {
            if (event.id === (model === null || model === void 0 ? void 0 : model.id)) {
                return data;
            }
            else {
                return event;
            }
        }));
    };
    useSubscribeByQuery({
        query: SUBSCRIBE_EVENTS,
        variables: {
            eventIds: [
                (_c = eventsData === null || eventsData === void 0 ? void 0 : eventsData.filter((item) => item === null || item === void 0 ? void 0 : item.id)) === null || _c === void 0 ? void 0 : _c.map((item) => item.id)
            ]
        },
        valueHandlerCallback
    });
    // injects default outcomes in the selectedOutcomes state, so it is highlighted
    React.useEffect(() => {
        if (defaultOutcomes && (defaultOutcomes === null || defaultOutcomes === void 0 ? void 0 : defaultOutcomes.length) > 0) {
            setSelectedOutcome((oldArray) => [...oldArray, ...defaultOutcomes]);
        }
        const handleRemoveBet = (e) => {
            const { outcomeId } = e.detail;
            if (outcomeId) {
                setSelectedOutcome((oldArray) => [
                    ...oldArray === null || oldArray === void 0 ? void 0 : oldArray.filter((outcome) => outcome !== outcomeId)
                ]);
            }
        };
        const handleClearBets = () => {
            setSelectedOutcome([]);
        };
        const handleReuseBets = (e) => {
            var _a;
            ((_a = e === null || e === void 0 ? void 0 : e.detail) === null || _a === void 0 ? void 0 : _a.bets) && setSelectedOutcome(e.detail.bets);
        };
        window.addEventListener("bet-slip-reuse-bets", handleReuseBets);
        window.addEventListener("bet-slip-bet-remove", handleRemoveBet);
        window.addEventListener("bet-slip-clear-bets", handleClearBets);
        return () => {
            window.removeEventListener("bet-slip-reuse-bets", handleReuseBets);
            window.removeEventListener("bet-slip-bet-remove", handleRemoveBet);
            window.removeEventListener("bet-slip-clear-bets", handleClearBets);
        };
    }, []);
    const onClick = (obj) => {
        var _a;
        const limited = ((_a = LocalStorage.get("betsDetails")) === null || _a === void 0 ? void 0 : _a.length) >= BETS_LIMIT;
        // add or remove odd id to the selected state
        let operation = "select";
        if (selectedOutcomes.includes(obj === null || obj === void 0 ? void 0 : obj.outcomeId)) {
            setSelectedOutcome((oldArray) => [
                ...oldArray === null || oldArray === void 0 ? void 0 : oldArray.filter((outcome) => outcome !== (obj === null || obj === void 0 ? void 0 : obj.outcomeId))
            ]);
            operation = "unselect";
        }
        else {
            (obj === null || obj === void 0 ? void 0 : obj.isOpen) &&
                !limited &&
                setSelectedOutcome((oldArray) => [...oldArray, obj === null || obj === void 0 ? void 0 : obj.outcomeId]);
        }
        const { isOpen } = obj, rest = __rest(obj, ["isOpen"]);
        const triggerMatchesByMarketInputDetails = new CustomEvent("matches-bydates-input-details", {
            detail: Object.assign(Object.assign({}, rest), { operation, isOpen: isOpen && !limited }),
            bubbles: true
        });
        window.dispatchEvent(triggerMatchesByMarketInputDetails);
        // emits a data layer when input is selected
        if (operation === "select") {
            const triggerBetDataLayerEvent = (action) => {
                window.dispatchEvent(new CustomEvent("bet-data-layer", {
                    detail: {
                        event: action,
                        event_count: 1,
                        events: [{
                                id: `${String(obj === null || obj === void 0 ? void 0 : obj.outcomeId)}`,
                                product_category: "Sports",
                                sport_category: sport === null || sport === void 0 ? void 0 : sport.name,
                                name: obj === null || obj === void 0 ? void 0 : obj.event,
                                market_name: obj === null || obj === void 0 ? void 0 : obj.marketName,
                                add_to_betslip_place: "",
                                league_name: obj === null || obj === void 0 ? void 0 : obj.leagueName,
                                outcome_name: obj === null || obj === void 0 ? void 0 : obj.name,
                                odd_type: dayjs(obj === null || obj === void 0 ? void 0 : obj.startDate) >= dayjs() ? "pregame" : "inplay",
                                odd: obj === null || obj === void 0 ? void 0 : obj.odds
                            }]
                    },
                    bubbles: true
                }));
            };
            triggerBetDataLayerEvent("add_bet");
        }
        ;
    };
    const dispatchEventMatchRow = (startDate, time, match) => {
        const triggerEventMatchDetails = new CustomEvent("match-event-details", {
            detail: {
                sport: {
                    id: sport === null || sport === void 0 ? void 0 : sport.id,
                    name: sport === null || sport === void 0 ? void 0 : sport.name
                },
                country: {
                    id: country === null || country === void 0 ? void 0 : country.id,
                    name: country === null || country === void 0 ? void 0 : country.name
                },
                league: {
                    id: league === null || league === void 0 ? void 0 : league.id,
                    name: league === null || league === void 0 ? void 0 : league.name
                },
                match: {
                    id: match.id,
                    name: match.name,
                    homeTeam: match.homeTeam,
                    awayTeam: match.awayTeam,
                    status: match.status,
                    marketName: match.marketName,
                    startDate,
                    time
                }
            },
            bubbles: true
        });
        window.dispatchEvent(triggerEventMatchDetails);
    };
    const dispatchEventMoreMarkets = (startDate, time, match) => {
        const triggerEventMatchMoreMarkets = new CustomEvent("match-more-markets", {
            detail: {
                sport: {
                    id: sport === null || sport === void 0 ? void 0 : sport.id,
                    name: sport === null || sport === void 0 ? void 0 : sport.name
                },
                country: {
                    id: country === null || country === void 0 ? void 0 : country.id,
                    name: country === null || country === void 0 ? void 0 : country.name
                },
                league: {
                    id: league === null || league === void 0 ? void 0 : league.id,
                    name: league === null || league === void 0 ? void 0 : league.name
                },
                match: {
                    id: match.id,
                    name: match.name,
                    homeTeam: match.homeTeam,
                    awayTeam: match.awayTeam,
                    status: match === null || match === void 0 ? void 0 : match.status,
                    marketName: match.marketName,
                    startDate,
                    time
                }
            },
            bubbles: true
        });
        window.dispatchEvent(triggerEventMatchMoreMarkets);
    };
    const DateHeader = (obj) => {
        if (!obj.isPresent || !obj.value)
            return _jsx(_Fragment, {});
        return _jsx("div", Object.assign({ className: styles.dateWrapper }, { children: obj.value }));
    };
    const OutcomeKeysRow = (keys) => {
        let defaultKeys = ["HOME", "DRAW", "AWAY"];
        if (sport.id === 3 ||
            sport.id === 12) {
            if ((keys === null || keys === void 0 ? void 0 : keys.length) === 2) {
                defaultKeys = ["W1", "W2"];
            }
            else {
                defaultKeys = ["W1", "DRAW", "W2"];
            }
        }
        else {
            if ((refData === null || refData === void 0 ? void 0 : refData.displayRule) === "DEFAULT") {
                defaultKeys = ["HOME", "AWAY"];
            }
            else {
                defaultKeys = ["HOME", "DRAW", "AWAY"];
            }
        }
        let columns;
        // when following the expected path, we just read the keys
        if ((keys === null || keys === void 0 ? void 0 : keys.length) && sport.id !== 3 && sport.id !== 12) {
            columns = keys.map((k) => {
                return _jsx(Col, Object.assign({ span: 8, className: styles.oddLabel }, { children: k }), `column_${k}`);
            });
        }
        else { // when dealing with exceptions. eg. tennis and boxing
            columns = defaultKeys.map((k) => {
                return _jsx(Col, Object.assign({ span: 8, className: styles.oddLabel }, { children: k }), `column_${k}`);
            });
        }
        return (_jsx(Row, Object.assign({ justify: "center", align: "middle" }, { children: columns })));
    };
    if ((_d = Object.keys(refData)) === null || _d === void 0 ? void 0 : _d.length) {
        let formattedDate = null;
        let previousLeague = null;
        let shouldPresentDateHeader = true;
        let shouldGroupEvents = false;
        const EventDetails = ({ match }) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26;
            if (!match)
                return _jsx(_Fragment, {});
            const event = eventsData.find((event) => event.id === match.id);
            const showBBLogo = ((event === null || event === void 0 ? void 0 : event.statusId) || (match === null || match === void 0 ? void 0 : match.statusId)) !== EVENT_STATUS.LIVE &&
                ((_a = match === null || match === void 0 ? void 0 : match.outcomes) === null || _a === void 0 ? void 0 : _a[0].isBetBuilderEnabled) &&
                isBBEnabled;
            return (_jsxs(_Fragment, { children: [_jsxs(Row, Object.assign({ align: "middle" }, { children: [_jsx(Col, Object.assign({ span: (match === null || match === void 0 ? void 0 : match.statusId) === EVENT_STATUS.LIVE ? 16 : 24, style: {
                                    fontSize: "11px",
                                    letterSpacing: "-0.2px",
                                    lineHeight: "12.89px"
                                } }, { children: `${match.homeTeam}` })), !isInPlayBlacklistedSport(sport === null || sport === void 0 ? void 0 : sport.id) &&
                                (match === null || match === void 0 ? void 0 : match.statusId) === EVENT_STATUS.LIVE &&
                                ((_b = match === null || match === void 0 ? void 0 : match.inPlay) === null || _b === void 0 ? void 0 : _b.clock) && ((_c = match === null || match === void 0 ? void 0 : match.inPlay) === null || _c === void 0 ? void 0 : _c.score) &&
                                (_jsx(Col, Object.assign({ span: 8, style: {
                                        fontSize: "11px",
                                        lineHeight: "12.89px"
                                    } }, { children: _jsx(ScoresBySport, { sportName: sportNameLowerCase, type: "home", clock: (_e = (_d = event === null || event === void 0 ? void 0 : event.inPlay) === null || _d === void 0 ? void 0 : _d.clock) !== null && _e !== void 0 ? _e : (_f = match === null || match === void 0 ? void 0 : match.inPlay) === null || _f === void 0 ? void 0 : _f.clock, score: (_h = (_g = event === null || event === void 0 ? void 0 : event.inPlay) === null || _g === void 0 ? void 0 : _g.score) !== null && _h !== void 0 ? _h : (_j = match === null || match === void 0 ? void 0 : match.inPlay) === null || _j === void 0 ? void 0 : _j.score, periodScores: (_m = (_l = (_k = event === null || event === void 0 ? void 0 : event.inPlay) === null || _k === void 0 ? void 0 : _k.score) === null || _l === void 0 ? void 0 : _l.periodScores) !== null && _m !== void 0 ? _m : (_p = (_o = match === null || match === void 0 ? void 0 : match.inPlay) === null || _o === void 0 ? void 0 : _o.score) === null || _p === void 0 ? void 0 : _p.periodScores }) })))] })), _jsxs(Row, { children: [_jsx(Col, Object.assign({ span: (match === null || match === void 0 ? void 0 : match.statusId) === EVENT_STATUS.LIVE ? 16 : 24, style: {
                                    fontSize: "11px",
                                    letterSpacing: "-0.2px",
                                    lineHeight: "12.89px",
                                    marginTop: 5
                                } }, { children: `${match.awayTeam}` })), !isInPlayBlacklistedSport(sport === null || sport === void 0 ? void 0 : sport.id) &&
                                (match === null || match === void 0 ? void 0 : match.statusId) === EVENT_STATUS.LIVE &&
                                ((_q = match === null || match === void 0 ? void 0 : match.inPlay) === null || _q === void 0 ? void 0 : _q.clock) && ((_r = match === null || match === void 0 ? void 0 : match.inPlay) === null || _r === void 0 ? void 0 : _r.score) &&
                                (_jsx(Col, Object.assign({ span: 8, style: {
                                        fontSize: "11px",
                                        lineHeight: "12.89px"
                                    } }, { children: _jsx(ScoresBySport, { sportName: sportNameLowerCase, type: "away", clock: (_t = (_s = event === null || event === void 0 ? void 0 : event.inPlay) === null || _s === void 0 ? void 0 : _s.clock) !== null && _t !== void 0 ? _t : (_u = match === null || match === void 0 ? void 0 : match.inPlay) === null || _u === void 0 ? void 0 : _u.clock, score: (_w = (_v = event === null || event === void 0 ? void 0 : event.inPlay) === null || _v === void 0 ? void 0 : _v.score) !== null && _w !== void 0 ? _w : (_x = match === null || match === void 0 ? void 0 : match.inPlay) === null || _x === void 0 ? void 0 : _x.score, periodScores: (_0 = (_z = (_y = event === null || event === void 0 ? void 0 : event.inPlay) === null || _y === void 0 ? void 0 : _y.score) === null || _z === void 0 ? void 0 : _z.periodScores) !== null && _0 !== void 0 ? _0 : (_2 = (_1 = match === null || match === void 0 ? void 0 : match.inPlay) === null || _1 === void 0 ? void 0 : _1.score) === null || _2 === void 0 ? void 0 : _2.periodScores }) }))), _jsx(Col, Object.assign({ span: 24 }, { children: _jsxs(Row, Object.assign({ style: { marginTop: "5px" } }, { children: [!isInPlayBlacklistedSport(sport === null || sport === void 0 ? void 0 : sport.id) &&
                                            ((event === null || event === void 0 ? void 0 : event.statusId) === EVENT_STATUS.LIVE || (match === null || match === void 0 ? void 0 : match.statusId) === EVENT_STATUS.LIVE) ?
                                            _jsxs(Col, Object.assign({ span: sportNameLowerCase !== "tennis" &&
                                                    sportNameLowerCase !== "volleyball" &&
                                                    sportNameLowerCase !== "tabletennis" ?
                                                    24 :
                                                    16, style: {
                                                    display: "flex",
                                                    gap: 5,
                                                    alignItems: "center"
                                                } }, { children: [_jsx(Live, { sport: sport, period: (_5 = (_4 = (_3 = event === null || event === void 0 ? void 0 : event.inPlay) === null || _3 === void 0 ? void 0 : _3.clock) === null || _4 === void 0 ? void 0 : _4.period) !== null && _5 !== void 0 ? _5 : (_7 = (_6 = match === null || match === void 0 ? void 0 : match.inPlay) === null || _6 === void 0 ? void 0 : _6.clock) === null || _7 === void 0 ? void 0 : _7.period, timeCounter: showClockForSport(sportId) ?
                                                            processedTimeCounter((_9 = (_8 = event === null || event === void 0 ? void 0 : event.inPlay) === null || _8 === void 0 ? void 0 : _8.clock) !== null && _9 !== void 0 ? _9 : (_10 = match === null || match === void 0 ? void 0 : match.inPlay) === null || _10 === void 0 ? void 0 : _10.clock, sportNameLowerCase === "basketball") :
                                                            "", isCountdown: sportNameLowerCase === "basketball", statusId: (_13 = (_12 = (_11 = event === null || event === void 0 ? void 0 : event.inPlay) === null || _11 === void 0 ? void 0 : _11.clock) === null || _12 === void 0 ? void 0 : _12.statusId) !== null && _13 !== void 0 ? _13 : (_15 = (_14 = match === null || match === void 0 ? void 0 : match.inPlay) === null || _14 === void 0 ? void 0 : _14.clock) === null || _15 === void 0 ? void 0 : _15.statusId, minutes: (_18 = (_17 = (_16 = event === null || event === void 0 ? void 0 : event.inPlay) === null || _16 === void 0 ? void 0 : _16.clock) === null || _17 === void 0 ? void 0 : _17.minutes) !== null && _18 !== void 0 ? _18 : (_20 = (_19 = match === null || match === void 0 ? void 0 : match.inPlay) === null || _19 === void 0 ? void 0 : _19.clock) === null || _20 === void 0 ? void 0 : _20.minutes, seconds: (_23 = (_22 = (_21 = event === null || event === void 0 ? void 0 : event.inPlay) === null || _21 === void 0 ? void 0 : _21.clock) === null || _22 === void 0 ? void 0 : _22.seconds) !== null && _23 !== void 0 ? _23 : (_25 = (_24 = match === null || match === void 0 ? void 0 : match.inPlay) === null || _24 === void 0 ? void 0 : _24.clock) === null || _25 === void 0 ? void 0 : _25.seconds, startDate: (_26 = event === null || event === void 0 ? void 0 : event.startDate) !== null && _26 !== void 0 ? _26 : match === null || match === void 0 ? void 0 : match.startDate }), showBBLogo && (_jsx(ImageFromCDN, { alt: "Bet Builder", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/bet-builder.svg", overrideStyles: styles.betBuilder, width: 30 }))] })) :
                                            _jsxs(Col, Object.assign({ span: sportNameLowerCase !== "tennis" &&
                                                    sportNameLowerCase !== "volleyball" &&
                                                    sportNameLowerCase !== "tabletennis" ?
                                                    24 :
                                                    16, style: {
                                                    fontSize: "9px",
                                                    color: "#707070",
                                                    display: "flex",
                                                    gap: 5,
                                                    alignItems: "center"
                                                } }, { children: [(dayjs(match.startDate).format("DD MMMM | HH:mm")).toUpperCase(), showBBLogo && (_jsx(ImageFromCDN, { alt: "Bet Builder", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/bet-builder.svg", overrideStyles: styles.betBuilder, width: 30 }))] })), !isInPlayBlacklistedSport(sport === null || sport === void 0 ? void 0 : sport.id) &&
                                            ((event === null || event === void 0 ? void 0 : event.statusId) === EVENT_STATUS.LIVE ||
                                                (match === null || match === void 0 ? void 0 : match.statusId) === EVENT_STATUS.LIVE) && sportNameLowerCase === "tennis" && (_jsx(Col, Object.assign({ span: 8 }, { children: _jsxs(_Fragment, { children: [_jsx("span", Object.assign({ className: clsx(styles.tennisLabels) }, { children: "S" })), _jsx("span", Object.assign({ className: clsx(styles.tennisLabels), style: { borderRight: "1px solid #cccccc", paddingRight: "10%" } }, { children: "G" })), _jsx("span", Object.assign({ className: clsx(styles.tennisLabels) }, { children: "P" }))] }) }))), !isInPlayBlacklistedSport(sport === null || sport === void 0 ? void 0 : sport.id) &&
                                            ((event === null || event === void 0 ? void 0 : event.statusId) === EVENT_STATUS.LIVE ||
                                                (match === null || match === void 0 ? void 0 : match.statusId) === EVENT_STATUS.LIVE) && sportNameLowerCase === "volleyball" && (_jsx(Col, Object.assign({ span: 8 }, { children: _jsxs(_Fragment, { children: [_jsx("span", Object.assign({ className: clsx(styles.volleyballLabels) }, { children: "S" })), _jsx("span", Object.assign({ className: clsx(styles.volleyballLabels), style: {
                                                            borderRight: "1px solid #cccccc",
                                                            paddingRight: "10%"
                                                        } }, { children: "P" }))] }) }))), !isInPlayBlacklistedSport(sport === null || sport === void 0 ? void 0 : sport.id) &&
                                            ((event === null || event === void 0 ? void 0 : event.statusId) === EVENT_STATUS.LIVE ||
                                                (match === null || match === void 0 ? void 0 : match.statusId) === EVENT_STATUS.LIVE) && sportNameLowerCase === "tabletennis" && (_jsx(Col, Object.assign({ span: 8 }, { children: _jsxs(_Fragment, { children: [_jsx("span", Object.assign({ className: clsx(styles.tabletennisLabels) }, { children: "S" })), _jsx("span", Object.assign({ className: clsx(styles.tabletennisLabels), style: {
                                                            borderRight: "1px solid #cccccc",
                                                            paddingRight: "10%"
                                                        } }, { children: "P" }))] }) })))] })) }))] })] }));
        };
        return (_jsx(_Fragment, { children: _jsx("div", Object.assign({ style: { border: "1px solid #f2f2f2" } }, { children: refData.matchesByDate.map((item, index) => {
                    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
                    let tempFormattedDate = null;
                    let dateHasChanged = false;
                    let leagueDetails = null;
                    if ((_a = league === null || league === void 0 ? void 0 : league.tournament) === null || _a === void 0 ? void 0 : _a.id) {
                        leagueDetails = { id: (_b = league === null || league === void 0 ? void 0 : league.tournament) === null || _b === void 0 ? void 0 : _b.id, name: (_c = league === null || league === void 0 ? void 0 : league.tournament) === null || _c === void 0 ? void 0 : _c.name };
                    }
                    else {
                        leagueDetails = { id: league.id, name: league.name };
                    }
                    // identify the label for the dates
                    if (dayjs(item.startDate).isToday()) {
                        tempFormattedDate = "TODAY";
                    }
                    else if (dayjs(item.startDate).isTomorrow()) {
                        tempFormattedDate = "TOMORROW";
                    }
                    else {
                        tempFormattedDate = dayjs(item.startDate).format("dddd, DD MMMM").toUpperCase();
                    }
                    // when the date is different
                    if (formattedDate && formattedDate !== tempFormattedDate) {
                        dateHasChanged = true;
                        formattedDate = tempFormattedDate;
                        shouldPresentDateHeader = true;
                        shouldGroupEvents = false;
                    }
                    else {
                        if (formattedDate)
                            shouldPresentDateHeader = false;
                        formattedDate = tempFormattedDate;
                    }
                    // when the league is the same
                    if (previousLeague === (leagueDetails === null || leagueDetails === void 0 ? void 0 : leagueDetails.id) && !dateHasChanged) {
                        shouldGroupEvents = true;
                    }
                    else {
                        previousLeague = leagueDetails === null || leagueDetails === void 0 ? void 0 : leagueDetails.id;
                        shouldGroupEvents = false;
                    }
                    if (!((_f = (_e = (_d = item === null || item === void 0 ? void 0 : item.times) === null || _d === void 0 ? void 0 : _d[0]) === null || _e === void 0 ? void 0 : _e.matches) === null || _f === void 0 ? void 0 : _f.length)) {
                        return null;
                    }
                    return (_jsxs("div", Object.assign({ style: {
                            marginTop: (shouldPresentDateHeader && index > 0) ||
                                (!shouldGroupEvents && index > 0) ?
                                10 :
                                1,
                            marginLeft: 5,
                            marginRight: 5,
                            background: "#fff"
                        } }, { children: [_jsx("div", Object.assign({ className: styles.dayLabel }, { children: _jsx(DateHeader, { value: formattedDate, isPresent: shouldPresentDateHeader }) })), !shouldGroupEvents &&
                                _jsxs(Row, Object.assign({ style: {
                                        borderBottom: "1px solid #f2f2f2",
                                        borderTopWidth: 0,
                                        backgroundColor: "#fff"
                                    } }, { children: [_jsx(Col, { xs: 11, sm: 12, md: 12, lg: 8, xl: 8, style: { borderBottom: "1px solid #f2f2f2" } }), _jsx(Col, Object.assign({ xs: 13, sm: 12, md: 12, lg: 16, xl: 16, className: styles.oddLabelWrapper }, { children: OutcomeKeysRow((_l = (_k = (_j = (_h = (_g = item === null || item === void 0 ? void 0 : item.times) === null || _g === void 0 ? void 0 : _g[0]) === null || _h === void 0 ? void 0 : _h.matches) === null || _j === void 0 ? void 0 : _j[0]) === null || _k === void 0 ? void 0 : _k.outcomes) === null || _l === void 0 ? void 0 : _l.map((obj) => obj === null || obj === void 0 ? void 0 : obj.key)) }))] }), `league_${league.id}_${index}`), item.times.map((time, index) => {
                                const uniqueEventIds = new Set();
                                const uniqueMarketTypeConfigIds = new Set();
                                const filteredMatches = time.matches.filter((match) => {
                                    if (uniqueEventIds.has(match.id) &&
                                        uniqueMarketTypeConfigIds.has(match.marketTypeConfigId)) {
                                        return false;
                                    }
                                    else {
                                        uniqueEventIds.add(match.id);
                                        if (!uniqueMarketTypeConfigIds.has(match.marketTypeConfigId)) {
                                            uniqueMarketTypeConfigIds.add(match.marketTypeConfigId);
                                        }
                                        return true;
                                    }
                                });
                                return (_jsx("div", { children: filteredMatches.map((match, idx) => {
                                        return _jsxs(Row, Object.assign({ style: {
                                                paddingTop: 15,
                                                paddingBottom: 10,
                                                borderBottom: "1px solid #f2f2f2",
                                                marginLeft: 8,
                                                marginRight: 8
                                            } }, { children: [_jsx(Col, Object.assign({ xs: 11, sm: 12, md: 12, lg: 8, xl: 8, style: { cursor: "pointer" }, onClick: () => dispatchEventMatchRow(item.startDate, time.time, match) }, { children: _jsx(EventDetails, { match: match }) })), _jsxs(Col, Object.assign({ xs: 13, sm: 12, md: 12, lg: 16, xl: 16 }, { children: [_jsx(Row, Object.assign({ justify: "space-between", align: "middle" }, { children: match.outcomes.map((outcome) => {
                                                                if (match === null || match === void 0 ? void 0 : match.marketLine) {
                                                                    const line = Number.parseFloat(match.marketLine);
                                                                    if (outcome.key === "home") {
                                                                        outcome.label = line > 0 ? `+${line}` : `${line}`;
                                                                    }
                                                                    else if (outcome.key === "away") {
                                                                        const inverseLine = line * -1;
                                                                        outcome.label = inverseLine > 0 ? `+${inverseLine}` : `${inverseLine}`;
                                                                    }
                                                                }
                                                                return (_jsx(React.Fragment, { children: _jsx(EventCardSelection, { onClick: onClick, selectedOutcomes: selectedOutcomes, outcome: outcome, match: match, marketView: (refData === null || refData === void 0 ? void 0 : refData.displayRule) === "OVER_UNDER" ? "overUnder" : "" }) }, `match-${match.id}-outcome-${outcome.id}`));
                                                            }) })), _jsx(Row, Object.assign({ justify: "end", align: "middle" }, { children: _jsxs(Col, Object.assign({ flex: "auto", className: clsx(styles.marketCount), onClick: () => dispatchEventMoreMarkets(item.startDate, time.time, match) }, { children: ["More +", (match === null || match === void 0 ? void 0 : match.marketCount) || 0, " ", _jsx("span", { children: "\u203A" })] })) }))] }))] }), match.id);
                                    }) }, `league-matches-time-${time.time}-${index}`));
                            })] }), `matches-by-date-${index}`));
                }) })) }));
    }
    // TODO: Add skeleton and error messages - WIP
    return _jsx("div", {});
};
class Element extends HTMLElement {
    constructor() {
        super();
        this.root = createRoot(this);
        this.sport = {};
        this.country = {};
        this.league = {};
        this.data = {};
        this["default-outcomes"] = [];
        this["header-keys"] = ["HOME", "DRAW", "AWAY"];
    }
    static get observedAttributes() {
        return [
            "sport",
            "country",
            "league",
            "data",
            "single-day",
            "default-outcomes",
            "header-keys",
            "product-id"
        ];
    }
    connectedCallback() {
        var _a;
        (_a = this.root) === null || _a === void 0 ? void 0 : _a.render(_jsx(LeagueMatches, { sport: this.sport, country: this.country, league: this.league, data: this.data, singleDay: this["single-day"], defaultOutcomes: this["default-outcomes"], headerKeys: this["header-keys"], productId: this["product-id"] }));
    }
    disconnectedCallback() {
        setTimeout(() => { var _a; return (_a = this.root) === null || _a === void 0 ? void 0 : _a.unmount(); }, 0);
    }
    attributeChangedCallback(attrName, _oldValue, newValue) {
        var _a;
        switch (attrName) {
            case "sport":
            case "country":
            case "league":
            case "data":
            case "single-day":
            case "default-outcomes":
            case "header-keys":
                this[attrName] = JSON.parse(newValue);
                break;
            case "product-id":
                this[attrName] = Number(newValue);
                break;
            default:
                break;
        }
        (_a = this.root) === null || _a === void 0 ? void 0 : _a.render(_jsx(LeagueMatches, { sport: this.sport, country: this.country, league: this.league, data: this.data, singleDay: this["single-day"], defaultOutcomes: this["default-outcomes"], headerKeys: this["header-keys"], productId: this["product-id"] }));
    }
}
(_a = customElements.get("leetent-league-matches-bydates")) !== null && _a !== void 0 ? _a : customElements.define("leetent-league-matches-bydates", Element);
