var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import axios from "axios";
import { Row, Col } from "antd";
import dayjs from "dayjs";
import { createRoot } from "react-dom/client";
import { SPORTS_SERIES_DEFAULT, ESPORTS_SERIES_DEFAULT } from "enums";
import { SessionStorage } from "helpers/Storage";
import { SUBSCRIBE_LIVE_EVENT_COUNTS } from "v2/graphql/subscriptions";
import { useSubscribeByQuery } from "helpers/graphql/useSubscribeByQuery";
import { FILTERS } from "helpers/Sports/Filters";
// styles
import styles from "./NavigationFeatured.module.scss";
const tagsSource = "tags-list-navigation-featured";
const timeRangeTagsSource = "time-range-tags-list-navigation-featured";
const tournamentsTagsSource = "tournaments-tags-list-navigation-featured";
const marketsTagsSource = "markets-tags-list-navigation-featured";
const defaultCategoryFilters = [
    { id: FILTERS.FEATURED, name: "Home", disabled: false },
    { id: FILTERS.UPCOMING, name: "Upcoming", disabled: false },
    { id: FILTERS.COUPONS, name: "Coupons", disabled: false }
];
const timeRangeFilters = [
    { id: FILTERS.TODAY, name: "Today", disabled: false },
    { id: FILTERS.HOURS_4, name: "in 4 hours", disabled: false },
    { id: FILTERS.HOURS_12, name: "in 12 hours", disabled: false },
    { id: FILTERS.TOMORROW, name: "Tomorrow", disabled: false },
    { id: FILTERS.WEEKEND, name: "Weekend", disabled: false }
];
const isMarketDropdown = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    return (((_b = (_a = SessionStorage.get("leetent")) === null || _a === void 0 ? void 0 : _a.navigationFeatured) === null || _b === void 0 ? void 0 : _b.tag) &&
        (((_e = (_d = (_c = SessionStorage.get("leetent")) === null || _c === void 0 ? void 0 : _c.navigationFeatured) === null || _d === void 0 ? void 0 : _d.tag) === null || _e === void 0 ? void 0 : _e.id) === FILTERS.FEATURED ||
            ((_h = (_g = (_f = SessionStorage.get("leetent")) === null || _f === void 0 ? void 0 : _f.navigationFeatured) === null || _g === void 0 ? void 0 : _g.tag) === null || _h === void 0 ? void 0 : _h.id) === FILTERS.LIVE ||
            ((_l = (_k = (_j = SessionStorage.get("leetent")) === null || _j === void 0 ? void 0 : _j.navigationFeatured) === null || _k === void 0 ? void 0 : _k.tag) === null || _l === void 0 ? void 0 : _l.id) === FILTERS.UPCOMING ||
            ((_p = (_o = (_m = SessionStorage.get("leetent")) === null || _m === void 0 ? void 0 : _m.navigationFeatured) === null || _o === void 0 ? void 0 : _o.tag) === null || _p === void 0 ? void 0 : _p.id) === FILTERS.COUPONS));
};
const NavigationFeatured = ({ productId, defaultSportId, defaultEventState, exact }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19;
    const [isLoading, setIsLoading] = React.useState(true);
    const [isTournamentsLoading, setIsTournamentsLoading] = React.useState(false);
    const [isEventsLoading, setIsEventsLoading] = React.useState(false);
    const [sportId, setSportId] = React.useState(1);
    const [withMarketDropdown, setWithMarketDropdown] = React.useState(isMarketDropdown());
    const [liveEvents, setLiveEvents] = React.useState({
        all: 0,
        sports: {}
    });
    // market types
    const [marketTypes, setMarketTypes] = React.useState([]);
    const [defaultMarket, setDefaultMarket] = React.useState(productId === 1 ?
        ((_b = (_a = SessionStorage.get("leetent")) === null || _a === void 0 ? void 0 : _a.navigationFeatured) === null || _b === void 0 ? void 0 : _b.serie) ?
            (_d = (_c = SessionStorage.get("leetent")) === null || _c === void 0 ? void 0 : _c.navigationFeatured) === null || _d === void 0 ? void 0 : _d.serie :
            SPORTS_SERIES_DEFAULT :
        productId === 2 && ((_f = (_e = SessionStorage.get("leetent")) === null || _e === void 0 ? void 0 : _e.navigationFeatured) === null || _f === void 0 ? void 0 : _f.serie) ?
            (_h = (_g = SessionStorage.get("leetent")) === null || _g === void 0 ? void 0 : _g.navigationFeatured) === null || _h === void 0 ? void 0 : _h.serie :
            ESPORTS_SERIES_DEFAULT);
    // mounts the model that is emitted to its parent
    const [model, setModel] = React.useState({
        lastUpdated: dayjs().toISOString(),
        productId,
        game: productId === 1 ? 1 : -1,
        country: { id: -1, name: "all" },
        serie: ((_k = (_j = SessionStorage.get("leetent")) === null || _j === void 0 ? void 0 : _j.navigationFeatured) === null || _k === void 0 ? void 0 : _k.serie) ?
            (_m = (_l = SessionStorage.get("leetent")) === null || _l === void 0 ? void 0 : _l.navigationFeatured) === null || _m === void 0 ? void 0 : _m.serie :
            defaultMarket,
        tag: ((_p = (_o = SessionStorage.get("leetent")) === null || _o === void 0 ? void 0 : _o.navigationFeatured) === null || _p === void 0 ? void 0 : _p.tag) ? Object.assign(Object.assign({}, (_r = (_q = SessionStorage.get("leetent")) === null || _q === void 0 ? void 0 : _q.navigationFeatured) === null || _r === void 0 ? void 0 : _r.tag), { source: null }) :
            { source: null, id: FILTERS.FEATURED, name: "Featured" },
        timeRangeTag: ((_t = (_s = SessionStorage.get("leetent")) === null || _s === void 0 ? void 0 : _s.navigationFeatured) === null || _t === void 0 ? void 0 : _t.timeRangeTag) ? Object.assign(Object.assign({}, (_v = (_u = SessionStorage.get("leetent")) === null || _u === void 0 ? void 0 : _u.navigationFeatured) === null || _v === void 0 ? void 0 : _v.timeRangeTag), { source: null }) :
            { source: null, id: FILTERS.TODAY, name: "Today" },
        tournamentTag: ((_x = (_w = SessionStorage.get("leetent")) === null || _w === void 0 ? void 0 : _w.navigationFeatured) === null || _x === void 0 ? void 0 : _x.tournamentTag) ? Object.assign(Object.assign({}, (_z = (_y = SessionStorage.get("leetent")) === null || _y === void 0 ? void 0 : _y.navigationFeatured) === null || _z === void 0 ? void 0 : _z.tournamentTag), { source: null }) :
            undefined
    });
    const [selectedCategoryTag, setSelectedCategoryTag] = React.useState(((_1 = (_0 = SessionStorage.get("leetent")) === null || _0 === void 0 ? void 0 : _0.navigationFeatured) === null || _1 === void 0 ? void 0 : _1.tag) ?
        (_4 = (_3 = (_2 = SessionStorage.get("leetent")) === null || _2 === void 0 ? void 0 : _2.navigationFeatured) === null || _3 === void 0 ? void 0 : _3.tag) === null || _4 === void 0 ? void 0 : _4.id :
        FILTERS.FEATURED);
    const [selectedTimeRangeTag, setSelectedTimeRangeTag] = React.useState(((_6 = (_5 = SessionStorage.get("leetent")) === null || _5 === void 0 ? void 0 : _5.navigationFeatured) === null || _6 === void 0 ? void 0 : _6.timeRangeTag) ?
        (_9 = (_8 = (_7 = SessionStorage.get("leetent")) === null || _7 === void 0 ? void 0 : _7.navigationFeatured) === null || _8 === void 0 ? void 0 : _8.timeRangeTag) === null || _9 === void 0 ? void 0 : _9.id :
        FILTERS.TODAY);
    const [selectedTournamentTag, setSelectedTournamentTag] = React.useState(((_11 = (_10 = SessionStorage.get("leetent")) === null || _10 === void 0 ? void 0 : _10.navigationFeatured) === null || _11 === void 0 ? void 0 : _11.tournamentTag) ?
        (_14 = (_13 = (_12 = SessionStorage.get("leetent")) === null || _12 === void 0 ? void 0 : _12.navigationFeatured) === null || _13 === void 0 ? void 0 : _13.tournamentTag) === null || _14 === void 0 ? void 0 : _14.id :
        undefined);
    // subscribes to the live counter topic and updates
    const valueHandlerCallback = (details) => {
        const nextData = details.getLiveEventCounts;
        const processing = {
            all: 0,
            sports: {}
        };
        // processes the results by sports
        nextData.forEach((item) => {
            processing.all = Number(processing.all) + Number(item.TotalEvents);
            if (Object.prototype.hasOwnProperty.call(processing.sports, item.SportId)) {
                processing.sports[item.SportId] = Number(processing.sports[item.SportId]) + Number(item.TotalEvents);
            }
            else {
                processing.sports[item.SportId] = item.TotalEvents;
            }
        });
        setLiveEvents(processing);
    };
    useSubscribeByQuery({
        query: SUBSCRIBE_LIVE_EVENT_COUNTS,
        valueHandlerCallback
    });
    const isTagDisabled = (id) => model.tag.id !== id && isEventsLoading;
    const [filters, setFilters] = React.useState(defaultCategoryFilters);
    const [tournamentsFilters, setTournamentsFilters] = React.useState([]);
    const [filteredFilters, setFilteredFilters] = React.useState([]);
    React.useEffect(() => {
        if (filteredFilters.length) {
            setFilters(filters => {
                const updatedFilters = [...filters];
                filters.forEach((filter, index) => {
                    filteredFilters.forEach(filteredFilter => {
                        const isFilterExist = filters.find(filter => filter.id === filteredFilter.id);
                        if (!isFilterExist) {
                            updatedFilters.push(filteredFilter);
                        }
                        else {
                            if (filter.id === filteredFilter.id) {
                                updatedFilters[index] = filteredFilter;
                            }
                        }
                    });
                });
                return updatedFilters;
            });
        }
    }, [filteredFilters]);
    React.useEffect(() => {
        defaultSportId && setSportId(defaultSportId);
    }, [defaultSportId]);
    React.useEffect(() => {
        setFilters(filters => [...filters.map(filter => {
                filter.disabled = isTagDisabled(filter.id);
                return filter;
            })]);
    }, [model.tag.id, isEventsLoading]);
    const liveEventsAmount = (_15 = liveEvents === null || liveEvents === void 0 ? void 0 : liveEvents.sports) === null || _15 === void 0 ? void 0 : _15[sportId];
    React.useEffect(() => {
        if (liveEvents && (sportId > 0 || sportId === -1)) {
            setFilters(filters => {
                const liveFilterIndex = filters.findIndex(filter => filter.id === "live");
                if (liveEventsAmount) {
                    if (liveFilterIndex !== -1) {
                        // If "live" filter already exists, update it with actual DD
                        return filters.map((filter, index) => {
                            if (index === liveFilterIndex) {
                                return Object.assign(Object.assign({}, filter), { name: `Live (${liveEventsAmount})`, disabled: (liveEvents === null || liveEvents === void 0 ? void 0 : liveEvents.all) === 0 });
                            }
                            return filter;
                        });
                    }
                    else {
                        // If "live" filter doesn't exist, add it as second ite,m
                        return [
                            filters[0],
                            // Add the new "live" filter
                            { id: "live", name: `Live (${liveEventsAmount})`, disabled: (liveEvents === null || liveEvents === void 0 ? void 0 : liveEvents.all) === 0 },
                            ...filters.slice(1)
                        ];
                    }
                }
                else {
                    //  If there is no liveEventsAmount
                    if (liveFilterIndex !== -1) {
                        return filters.filter(filter => filter.id !== "live");
                    }
                }
                return filters;
            });
        }
    }, [liveEvents, sportId]);
    React.useEffect(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2;
        setModel({
            lastUpdated: dayjs().toISOString(),
            productId,
            game: productId === 1 ? 1 : -1,
            country: { id: -1, name: "all" },
            serie: defaultMarket,
            tag: ((_b = (_a = SessionStorage.get("leetent")) === null || _a === void 0 ? void 0 : _a.navigationFeatured) === null || _b === void 0 ? void 0 : _b.tag) ? Object.assign(Object.assign({}, (_d = (_c = SessionStorage.get("leetent")) === null || _c === void 0 ? void 0 : _c.navigationFeatured) === null || _d === void 0 ? void 0 : _d.tag), { source: null }) :
                { source: null, id: FILTERS.FEATURED, name: "Featured" },
            timeRangeTag: ((_f = (_e = SessionStorage.get("leetent")) === null || _e === void 0 ? void 0 : _e.navigationFeatured) === null || _f === void 0 ? void 0 : _f.timeRangeTag) ? Object.assign(Object.assign({}, (_h = (_g = SessionStorage.get("leetent")) === null || _g === void 0 ? void 0 : _g.navigationFeatured) === null || _h === void 0 ? void 0 : _h.timeRangeTag), { source: null }) :
                { source: null, id: FILTERS.TODAY, name: "Today" },
            tournamentTag: ((_k = (_j = SessionStorage.get("leetent")) === null || _j === void 0 ? void 0 : _j.navigationFeatured) === null || _k === void 0 ? void 0 : _k.tournamentTag) ? Object.assign(Object.assign({}, (_m = (_l = SessionStorage.get("leetent")) === null || _l === void 0 ? void 0 : _l.navigationFeatured) === null || _m === void 0 ? void 0 : _m.tournamentTag), { source: null }) :
                undefined
        });
        setSelectedCategoryTag(((_p = (_o = SessionStorage.get("leetent")) === null || _o === void 0 ? void 0 : _o.navigationFeatured) === null || _p === void 0 ? void 0 : _p.tag) ?
            (_s = (_r = (_q = SessionStorage.get("leetent")) === null || _q === void 0 ? void 0 : _q.navigationFeatured) === null || _r === void 0 ? void 0 : _r.tag) === null || _s === void 0 ? void 0 : _s.id :
            FILTERS.FEATURED);
        setSelectedTimeRangeTag(((_u = (_t = SessionStorage.get("leetent")) === null || _t === void 0 ? void 0 : _t.navigationFeatured) === null || _u === void 0 ? void 0 : _u.timeRangeTag) ?
            (_x = (_w = (_v = SessionStorage.get("leetent")) === null || _v === void 0 ? void 0 : _v.navigationFeatured) === null || _w === void 0 ? void 0 : _w.timeRangeTag) === null || _x === void 0 ? void 0 : _x.id :
            FILTERS.TODAY);
        setSelectedTournamentTag(((_z = (_y = SessionStorage.get("leetent")) === null || _y === void 0 ? void 0 : _y.navigationFeatured) === null || _z === void 0 ? void 0 : _z.tournamentTag) ?
            (_2 = (_1 = (_0 = SessionStorage.get("leetent")) === null || _0 === void 0 ? void 0 : _0.navigationFeatured) === null || _1 === void 0 ? void 0 : _1.tournamentTag) === null || _2 === void 0 ? void 0 : _2.id :
            undefined);
    }, [defaultMarket]);
    React.useEffect(() => {
        if (productId === 2 || !productId) {
            axios
                .get("/esports/market-types")
                .then((res) => {
                var _a;
                setMarketTypes(res.data);
                // when the default market is not provided, then assume the first market in the array
                setDefaultMarket((_a = res.data[0]) === null || _a === void 0 ? void 0 : _a.id);
            })
                .catch((e) => {
                console.log(e);
            })
                .finally(() => {
                setIsLoading(false);
            });
        }
        else if (productId === 1) {
            axios
                .get("/market-type-configs")
                .then((res) => {
                var _a;
                const data = res.data.filter((config) => (config === null || config === void 0 ? void 0 : config.isDefault) === true);
                setMarketTypes(data);
                const defaultMarketId = (_a = data.find((market) => market.sportId === sportId && market.isDefault)) === null || _a === void 0 ? void 0 : _a.id;
                if (defaultMarketId) {
                    setDefaultMarket(defaultMarketId);
                }
            })
                .catch((e) => {
                console.log(e);
            })
                .finally(() => {
                setIsLoading(false);
            });
        }
    }, [productId, sportId]);
    React.useEffect(() => {
        var _a, _b;
        if (productId === 1 && selectedCategoryTag === FILTERS.COUPONS && ((_a = process.env) === null || _a === void 0 ? void 0 : _a.REACT_APP_GATEWAY_API_URL)) {
            setIsTournamentsLoading(true);
            axios
                .get(`${(_b = process.env) === null || _b === void 0 ? void 0 : _b.REACT_APP_GATEWAY_API_URL}/sports/tournament-groups`)
                .then((res) => {
                setTournamentsFilters(res.data.filter((tournament) => {
                    return tournament.sportId === sportId;
                }).map((tournament) => ({ id: tournament === null || tournament === void 0 ? void 0 : tournament.id, name: tournament === null || tournament === void 0 ? void 0 : tournament.name, disabled: false })));
            })
                .catch((e) => {
                console.log(e);
            })
                .finally(() => {
                setIsTournamentsLoading(false);
            });
        }
    }, [productId, selectedCategoryTag, sportId]);
    // listen for date changes emitted by the date picker component
    const listeners = () => {
        const selectedTagDetailsHandler = (e) => {
            if (e.detail.source === marketsTagsSource) {
                if (e.detail.id !== model.serie) {
                    setModel(Object.assign(Object.assign({}, model), { serie: e.detail.id, lastUpdated: dayjs().toISOString() }));
                    setDefaultMarket(e.detail.id);
                }
            }
            if (e.detail.source === tagsSource ||
                e.detail.source === timeRangeTagsSource ||
                e.detail.source === tournamentsTagsSource) {
                if (e.detail.id !== model.tag.id ||
                    e.detail.id !== model.timeRangeTag.id ||
                    e.detail.id !== model.tournamentTag.id
                // model.tag.id === SessionStorage.get("leetent")?.navigation?.tag?.id
                ) {
                    const updatedModel = structuredClone(model);
                    if (e.detail.source === tagsSource) {
                        updatedModel.tag = e.detail;
                        setSelectedCategoryTag(e.detail.id);
                        if (e.detail.id === FILTERS.FEATURED ||
                            e.detail.id === FILTERS.LIVE ||
                            e.detail.id === FILTERS.UPCOMING ||
                            e.detail.id === FILTERS.COUPONS) {
                            setWithMarketDropdown(true);
                        }
                        else {
                            setWithMarketDropdown(false);
                        }
                    }
                    if (e.detail.source === timeRangeTagsSource) {
                        updatedModel.timeRangeTag = e.detail;
                        setSelectedTimeRangeTag(e.detail.id);
                    }
                    if (e.detail.source === tournamentsTagsSource) {
                        updatedModel.tournamentTag = e.detail;
                        setSelectedTournamentTag(e.detail.id);
                    }
                    setModel(Object.assign(Object.assign({}, updatedModel), { lastUpdated: dayjs().toISOString() }));
                }
                else if (!model.tag.source) {
                    const updatedModel = structuredClone(model);
                    updatedModel.tag.source = e.detail.source;
                    setModel(Object.assign(Object.assign({}, updatedModel), { lastUpdated: dayjs().toISOString() }));
                }
            }
        };
        const eventsLoadingHandler = (e) => {
            setIsEventsLoading(e.detail.isLoading);
        };
        const selectedIconTabHandler = (e) => {
            const { id, isFeatureTabEnabled, isTodaysPopularTabEnabled
            // isUpcomingTabEnabled
             } = e.detail;
            !defaultSportId && setSportId(id);
            if (id <= 0)
                return;
            const filteredFilters = defaultCategoryFilters.filter(item => {
                if (item.id === "featured") {
                    return isFeatureTabEnabled;
                    // } else if (item.id === "upcoming") {
                    //   return isUpcomingTabEnabled;
                }
                else if (item.id === FILTERS.COUPONS || item.id === FILTERS.UPCOMING) {
                    return isTodaysPopularTabEnabled;
                }
                return true;
            });
            setFilteredFilters(filteredFilters);
        };
        const addListeners = () => {
            window.addEventListener("selected-icon-tab-details", selectedIconTabHandler);
            window.addEventListener("selected-tag-details", selectedTagDetailsHandler);
            window.addEventListener("events-loading", eventsLoadingHandler);
        };
        const removeListeners = () => {
            window.removeEventListener("selected-icon-tab-details", selectedIconTabHandler);
            window.removeEventListener("selected-tag-details", selectedTagDetailsHandler);
            window.removeEventListener("events-loading", eventsLoadingHandler);
        };
        return { addListeners, removeListeners };
    };
    const triggerNavigationEventDetails = React.useMemo(() => {
        if (model) {
            // updates the sessionStorage -> navigation attribute
            const leetentStorage = SessionStorage.get("leetent");
            // when the leetent object is not created, then creates it, if not updates the navigation attribute
            if (!leetentStorage) {
                const obj = {
                    navigationFeatured: model
                };
                SessionStorage.set("leetent", obj);
            }
            else {
                leetentStorage.navigationFeatured = model;
                SessionStorage.set("leetent", leetentStorage);
            }
            // The purpose of the setTimeout is to wait a little bit before re-fetching the data from the storage
            // The line above sets the data to the storage, so that's why we wait a little bit
            // to guarantee the data is in place
            setTimeout(function () {
                setWithMarketDropdown(isMarketDropdown());
            }, 200);
            const emitModel = Object.assign({}, model);
            // Removes unnecessary key/values before emitting the data
            if (productId === 2) {
                delete emitModel.country;
                delete emitModel.league;
            }
            return new CustomEvent("navigation-featured-details", {
                detail: {
                    emitModel
                },
                bubbles: true
            });
        }
    }, [model, filters]);
    React.useEffect(() => {
        if (model) {
            const { addListeners, removeListeners } = listeners();
            addListeners();
            window.dispatchEvent(triggerNavigationEventDetails);
            return () => {
                removeListeners();
            };
        }
    }, [model]);
    // once tags source is provided, we start listening to changes
    // basically, this listener is just to sync labels, nothing more
    React.useEffect(() => {
        if (filters === null || filters === void 0 ? void 0 : filters.length) {
            const tagsListDetailsHandler = (e) => {
                e.preventDefault();
                if (e.detail.source === tagsSource) {
                    if (e.detail.tag.id === model.tag.id)
                        return;
                    const updatedModel = structuredClone(model);
                    let id = e.detail.tag.id;
                    if (e.detail.tag.id === "all_live")
                        id = "live";
                    const filteredItem = filters.find((filter) => filter.id === id);
                    if (filteredItem) {
                        updatedModel.tag.id = filteredItem.id;
                        updatedModel.tag.name = filteredItem.name;
                        setSelectedCategoryTag(filteredItem.id);
                    }
                    else {
                        updatedModel.tag.id = id;
                        updatedModel.tag.name = e.detail.name;
                        setSelectedCategoryTag(id);
                    }
                }
            };
            window.addEventListener("update-selected-tags-list", tagsListDetailsHandler);
            return () => {
                window.removeEventListener("update-selected-tags-list", tagsListDetailsHandler);
            };
        }
    }, [filters]);
    const filteredMarkets = marketTypes.filter(marketType => marketType.sportId === sportId) || marketTypes;
    const marketsTags = filteredMarkets.map(market => ({
        id: market.id,
        name: market.shortName,
        disabled: false
    }));
    if (sportId <= 0) {
        return null;
    }
    const isTournamentFilters = selectedCategoryTag === FILTERS.COUPONS && !isTournamentsLoading && tournamentsFilters.length > 0;
    return (_jsx("div", Object.assign({ className: styles.navigationWrapper }, { children: _jsx("div", Object.assign({ className: styles.tagsWrapper }, { children: _jsxs(Row, { children: [_jsx(Col, Object.assign({ xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24, className: styles.tags, style: { paddingBottom: 0 } }, { children: liveEvents && (_jsx("leetent-tags-list", { "custom-style": JSON.stringify({ paddingBottom: 0 }), source: tagsSource, selected: filters.find((filter) => (filter === null || filter === void 0 ? void 0 : filter.id) === selectedCategoryTag) ?
                                selectedCategoryTag :
                                (_16 = filters[0]) === null || _16 === void 0 ? void 0 : _16.id, tags: JSON.stringify(filters) })) })), selectedCategoryTag === FILTERS.UPCOMING &&
                        _jsx(Col, Object.assign({ xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24, className: styles.tags, style: { paddingBottom: 0 } }, { children: _jsx("leetent-tags-list", { "custom-style": JSON.stringify({ paddingTop: 0, paddingBottom: 0 }), source: timeRangeTagsSource, selected: timeRangeFilters.find((filter) => (filter === null || filter === void 0 ? void 0 : filter.id) === selectedTimeRangeTag) ?
                                    selectedTimeRangeTag :
                                    (_17 = timeRangeFilters[0]) === null || _17 === void 0 ? void 0 : _17.id, tags: JSON.stringify(timeRangeFilters) }) })), isTournamentFilters &&
                        _jsx(Col, Object.assign({ xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24, className: styles.tags, style: { paddingBottom: 0 } }, { children: _jsx("leetent-tags-list", { "custom-style": JSON.stringify({ paddingTop: 0, paddingBottom: 0 }), source: tournamentsTagsSource, selected: tournamentsFilters.find((filter) => (filter === null || filter === void 0 ? void 0 : filter.id) === selectedTournamentTag) ?
                                    selectedTimeRangeTag :
                                    (_18 = tournamentsFilters[0]) === null || _18 === void 0 ? void 0 : _18.id, tags: JSON.stringify(tournamentsFilters) }) })), withMarketDropdown && !isLoading && (isTournamentFilters || selectedCategoryTag !== FILTERS.COUPONS) &&
                        _jsx(Col, Object.assign({ xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24, className: styles.tags }, { children: (marketsTags === null || marketsTags === void 0 ? void 0 : marketsTags.length) > 0 && (_jsx("leetent-tags-list", { "custom-style": JSON.stringify({ paddingTop: 0 }), source: marketsTagsSource, selected: productId === 1 ?
                                    defaultMarket !== undefined ?
                                        defaultMarket :
                                        (_19 = marketsTags.find((market) => market === null || market === void 0 ? void 0 : market.isDefault)) === null || _19 === void 0 ? void 0 : _19.id :
                                    undefined, tags: JSON.stringify(marketsTags) })) }))] }) })) })));
};
class Element extends HTMLElement {
    constructor() {
        super();
        this.root = createRoot(this);
    }
    static get observedAttributes() {
        return ["product-id", "default-event-state", "exact", "default-sport-id"];
    }
    renderComponent() {
        var _a;
        (_a = this.root) === null || _a === void 0 ? void 0 : _a.render(_jsx(NavigationFeatured, { productId: this["product-id"], defaultEventState: this["default-event-state"], withoutTags: this["without-tags"], exact: this.exact, defaultSportId: this["default-sport-id"] }));
    }
    connectedCallback() {
        this.renderComponent();
    }
    disconnectedCallback() {
        setTimeout(() => { var _a; return (_a = this.root) === null || _a === void 0 ? void 0 : _a.unmount(); }, 0);
    }
    attributeChangedCallback(attrName, _oldValue, newValue) {
        switch (attrName) {
            case "product-id":
            case "default-sport-id":
                this[attrName] = +newValue;
                break;
            case "default-event-state":
                this[attrName] = String(newValue);
                break;
            case "without-tags":
            case "exact":
                this[attrName] = JSON.parse(newValue);
                break;
            default:
                break;
        }
        this.renderComponent();
    }
}
(_a = customElements.get("leetent-navigation-featured")) !== null && _a !== void 0 ? _a : customElements.define("leetent-navigation-featured", Element);
