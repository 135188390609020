import { jsx as _jsx } from "react/jsx-runtime";
import OutcomeGenericV2 from "../../../../v2/components/OutcomeGeneric/OutcomeGeneric";
// components
import MountDocumentComponent from "components/documentation/MountDocumentComponent";
const componentObj = {
    data: {
        id: 120649,
        name: "Brazil To Win",
        odds: 9.94,
        isOpen: true
    }
};
export const documentDetails = {
    title: "Odds Generic",
    component: "OutcomeGeneric",
    description: "This is a base component, that is used as part of many composed components.",
    attributes: [
    // {
    //   key: "1",
    //   attr: "data",
    //   type: "object",
    //   notes: [
    //     "Data object expects the following information.",
    //     `{
    //       seriesid: number,
    //       marketid: number,
    //       selectionid: number,
    //       label: string,
    //       value: number
    //     }`
    //   ]
    // }
    ],
    events: [
    // {
    //   event: "odds-multievent-input-details",
    //   type: "emit",
    //   data: [
    //     "The component details object is emitted when input is clicked, containing the following information.",
    //     `detail: {
    //       seriesid: <value>,
    //       marketid: <value>,
    //       selectionid: <value>,
    //       label: <value>,
    //       value: <value>
    //    }`
    //   ]
    // }
    ]
};
const OutcomeGeneric = () => {
    documentDetails.example = `<OutcomeGeneric
    data={
      id: 120649,
      name: "Brazil To Win",
      odds: 9.94,
      isOpen: true
    }
  />`;
    documentDetails.code = _jsx(OutcomeGenericV2, { data: componentObj.data });
    // render the page based on the provided object
    return _jsx(MountDocumentComponent, { data: documentDetails });
};
export default OutcomeGeneric;
