// this event is fired when one or more odds (outcomes) for a specific market changes
// it gets broadcasted, and should be listened by the right outcomes
const customEventName = "updated-odds-outcomes";
const EventUpdatedOddsOutcomes = (eventId, marketId, updatedOutcomes) => {
    const customEvent = new CustomEvent(customEventName, {
        detail: {
            eventId,
            marketId,
            updatedOutcomes
        },
        bubbles: true
    });
    window.dispatchEvent(customEvent);
};
// function
const updatedOdds = (e) => {
    return e.detail;
};
const EventUpdatedOddsOutcomesListener = () => {
    window.addEventListener(customEventName, updatedOdds);
};
const EventUpdatedOddsOutcomesListenerRemoval = () => {
    window.removeEventListener(customEventName, updatedOdds);
};
export { EventUpdatedOddsOutcomes, EventUpdatedOddsOutcomesListener, EventUpdatedOddsOutcomesListenerRemoval };
