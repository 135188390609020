import dayjs from "dayjs";
const handleBetslip = (data, selectedOutcomes, updated) => {
    const productLabel = data.productId === 1 ? "sports" : data.productId === 2 ? "esports" : "other";
    const leetent = JSON.parse(localStorage.getItem("leetent"));
    const betslip = leetent === null || leetent === void 0 ? void 0 : leetent.betslip;
    const productBetslip = betslip ? betslip[productLabel] : undefined;
    const details = {
        createdAt: dayjs().toISOString(),
        updatedAt: null,
        status: "open",
        event: data.event,
        market: data.market,
        outcome: selectedOutcomes
    };
    if (!leetent) {
        if ((updated === null || updated === void 0 ? void 0 : updated.operation) === "select") {
            localStorage.setItem("leetent", JSON.stringify({
                betslip: { [productLabel]: [details] }
            }));
        }
    }
    else if (!betslip && (updated === null || updated === void 0 ? void 0 : updated.operation) === "select") {
        const newObject = structuredClone(leetent);
        newObject.betslip = { [productLabel]: [details] };
        localStorage.setItem("leetent", JSON.stringify(newObject));
    }
    else if (!productBetslip && (updated === null || updated === void 0 ? void 0 : updated.operation) === "select") {
        leetent.betslip = Object.assign(Object.assign({}, betslip), { [productLabel]: [details] });
        localStorage.setItem("leetent", JSON.stringify(leetent));
    }
    else {
        // TODO: Matches the market information
        // TODO: Checks if market exists, and if not add a new entry to the productBetslip
        const isMarketInPlace = productBetslip.find((item) => item.market.id === data.market.id);
        if (!isMarketInPlace && (updated === null || updated === void 0 ? void 0 : updated.operation) === "select") {
            productBetslip.push(details);
        }
        else {
            if ((updated === null || updated === void 0 ? void 0 : updated.operation) === "select") {
                // iterates though the product object, in order to update its outcome arrays
                productBetslip === null || productBetslip === void 0 ? void 0 : productBetslip.map((item) => {
                    var _a, _b;
                    const isOutcomeInPlace = (_a = item === null || item === void 0 ? void 0 : item.outcome) === null || _a === void 0 ? void 0 : _a.filter((outcome) => outcome.id === updated.outcome.id);
                    // adds the missing outcome to the product vs market array, so it is grouped together
                    if (!(isOutcomeInPlace === null || isOutcomeInPlace === void 0 ? void 0 : isOutcomeInPlace.length)) {
                        const outcome = item === null || item === void 0 ? void 0 : item.outcome;
                        if (!outcome)
                            item.outcome = [selectedOutcomes.find((item) => item.id === updated.outcome.id)];
                        if (outcome)
                            (_b = item === null || item === void 0 ? void 0 : item.outcome) === null || _b === void 0 ? void 0 : _b.push(selectedOutcomes.find((item) => item.id === updated.outcome.id));
                    }
                    return item;
                });
            }
            else if ((updated === null || updated === void 0 ? void 0 : updated.operation) === "unselect") {
                productBetslip === null || productBetslip === void 0 ? void 0 : productBetslip.map((item) => {
                    var _a;
                    const filteredOutcomes = (_a = item === null || item === void 0 ? void 0 : item.outcome) === null || _a === void 0 ? void 0 : _a.filter((outcome) => outcome.id !== updated.outcome.id);
                    // adds the missing outcome to the product vs market array, so it is grouped together
                    if (!(filteredOutcomes === null || filteredOutcomes === void 0 ? void 0 : filteredOutcomes.length)) {
                        item.outcome = [];
                    }
                    else {
                        item.outcome = filteredOutcomes;
                    }
                    return item;
                });
            }
        }
        localStorage.setItem("leetent", JSON.stringify(leetent));
    }
};
export default handleBetslip;
