// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/images/angle-down.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".xJbYGw8zaxKw7S0dLyAH{overflow:hidden}.N9e5Pl95bGw4loagIFnc{display:flex;align-items:center;justify-content:space-between;cursor:pointer;position:relative}.XMxdXODbhpf5V19Acj04{margin:0}.MyaswGcZ3A6o6cSylRzP{position:absolute;top:20px;right:10px;width:10px;height:10px;background-position:center;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:10px;background-repeat:no-repeat;transition:.5s cubic-bezier(0, 1, 0, 1)}.wrNZIR95sENtOZlhXzlx{max-height:0;overflow:hidden;transition:.5s cubic-bezier(0, 1, 0, 1)}.W3yBw9FcFpirCjFplwVg{max-height:max-content;transition:1s ease-in-out}", "",{"version":3,"sources":["webpack://./src/components/base/Accordion/Accordion.module.scss"],"names":[],"mappings":"AAAA,sBACC,eAAA,CAGD,sBACC,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,cAAA,CACA,iBAAA,CAGD,sBACC,QAAA,CAGD,sBACC,iBAAA,CACA,QAAA,CACA,UAAA,CACA,UAAA,CACC,WAAA,CACD,0BAAA,CACA,wDAAA,CACA,oBAAA,CACA,2BAAA,CACA,uCAAA,CAGD,sBACC,YAAA,CACA,eAAA,CACA,uCAAA,CAGD,sBACC,sBAAA,CACA,yBAAA","sourcesContent":[".root {\n\toverflow: hidden;\n}\n\n.header {\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: space-between;\n\tcursor: pointer;\n\tposition: relative;\n}\n\n.title {\n\tmargin: 0;\n}\n\n.dropdownIcon{\n\tposition: absolute;\n\ttop: 20px;\n\tright: 10px;\n\twidth: 10px;\n  height: 10px;\n\tbackground-position: center;\n\tbackground-image: url(\"../../../assets/images/angle-down.png\");\n\tbackground-size: 10px;\n\tbackground-repeat: no-repeat;\n\ttransition: .5s cubic-bezier(0, 1, 0, 1);\n}\n\n.contentContainer {\n\tmax-height: 0;\n\toverflow: hidden;\n\ttransition: .5s cubic-bezier(0, 1, 0, 1);\n}\n\n.open {\n\tmax-height: max-content;\n\ttransition: 1s ease-in-out;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "xJbYGw8zaxKw7S0dLyAH",
	"header": "N9e5Pl95bGw4loagIFnc",
	"title": "XMxdXODbhpf5V19Acj04",
	"dropdownIcon": "MyaswGcZ3A6o6cSylRzP",
	"contentContainer": "wrNZIR95sENtOZlhXzlx",
	"open": "W3yBw9FcFpirCjFplwVg"
};
export default ___CSS_LOADER_EXPORT___;
