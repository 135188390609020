import { jsx as _jsx } from "react/jsx-runtime";
import { bothTeamsToScoreData as data } from "./assets/data";
// components
import MountDocumentComponent from "components/documentation/MountDocumentComponent";
// document details of inner components
import { documentDetails as singleEventInputDocumentDetails } from "pages/documentation/components/OddsSingleEventInput";
export const documentDetails = {
    title: "Both teams to score (Display Rule)",
    component: "leetent-market-both-teams-score",
    description: "This is a market component, that is used as part of many composed components.",
    withDarkMode: false,
    innerComponents: [
        {
            name: "leetent-odds-singleevent-input",
            documentDetails: singleEventInputDocumentDetails
        }
    ]
};
const BothTeamsToScore = () => {
    documentDetails.example = `<leetent-market-both-teams-score />`;
    documentDetails.tabs = [
        {
            key: "2",
            label: "Single event version",
            children: (_jsx("leetent-market-both-teams-score", { "series-id": 123, data: JSON.stringify(data) }))
        }
    ];
    // render the page based on the provided object
    return _jsx(MountDocumentComponent, { data: documentDetails });
};
export default BothTeamsToScore;
