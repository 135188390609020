import { jsx as _jsx } from "react/jsx-runtime";
import { correctScoreNoDrawData as data } from "./assets/data";
// components
import MountDocumentComponent from "components/documentation/MountDocumentComponent";
// document details of inner components
import { documentDetails as singleEventInputDocumentDetails } from "pages/documentation/components/OddsSingleEventInput";
export const documentDetails = {
    title: "Correct score no draw(Display Rule)",
    component: "leetent-market-correct-score-no-draw",
    description: "This is a market component, that is used as part of many composed components.",
    withDarkMode: false,
    innerComponents: [
        {
            name: "leetent-odds-singleevent-input",
            documentDetails: singleEventInputDocumentDetails
        }
    ]
};
const CorrectScoreNoDraw = () => {
    documentDetails.example = `<leetent-market-correct-score-no-draw />`;
    documentDetails.tabs = [
        {
            key: "1",
            label: "List version",
            children: (_jsx("leetent-market-correct-score-no-draw", { "series-id": 123, data: JSON.stringify(data), "home-team": "Rafael Nadal", "away-team": "Andy Murray" }))
        },
        {
            key: "2",
            label: "Single event version",
            // TODO: Pending development
            children: (_jsx("leetent-market-correct-score-no-draw", { "series-id": 123, data: JSON.stringify(data), "home-team": "Rafael Nadal", "away-team": "Andy Murray" }))
        }
    ];
    // render the page based on the provided object
    return _jsx(MountDocumentComponent, { data: documentDetails });
};
export default CorrectScoreNoDraw;
