// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._GUZrJGmTicIeb7e2zhA{display:flex;align-items:center;flex-direction:column;overflow-y:auto;max-height:20vh;background:#f7f7f7}html[data-theme=dark] ._GUZrJGmTicIeb7e2zhA{background-color:var(--darkBackground);color:#f7f7f7}._GUZrJGmTicIeb7e2zhA .DUJVOkiJDRUnunslAuOz{border-bottom:.5px solid #e5e3e3;width:100%}._GUZrJGmTicIeb7e2zhA .DUJVOkiJDRUnunslAuOz:last-child{border-bottom:none}._GUZrJGmTicIeb7e2zhA .DUJVOkiJDRUnunslAuOz.KAXudRCMTX8ArnNqU1aV .UePQUOKB5Btn1Pb2GpXN{color:#b3b3b3}._GUZrJGmTicIeb7e2zhA .UePQUOKB5Btn1Pb2GpXN{width:100%;display:flex;justify-content:space-between;align-items:center;padding:10px 12px}._GUZrJGmTicIeb7e2zhA .fLM1H_bxCPsYVkumewVq{font-weight:bold}._GUZrJGmTicIeb7e2zhA .AderG_InbnoxL3ZP8au8{display:flex;align-items:center;gap:8px}", "",{"version":3,"sources":["webpack://./src/components/composed/betSlip/systemBets/SystemBets.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,qBAAA,CACA,eAAA,CACA,eAAA,CACA,kBAAA,CACA,4CACE,sCAAA,CACA,aAAA,CAGF,4CACE,gCAAA,CACA,UAAA,CAEA,uDACE,kBAAA,CAIA,uFACE,aAAA,CAKN,4CACE,UAAA,CACA,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,iBAAA,CAGF,4CACE,gBAAA,CAGF,4CACE,YAAA,CACA,kBAAA,CACA,OAAA","sourcesContent":[".root {\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  overflow-y: auto;\n  max-height: 20vh;\n  background: #f7f7f7;\n  html[data-theme=\"dark\"] & {\n    background-color: var(--darkBackground);\n    color: #f7f7f7;\n  }\n\n  .betRow {\n    border-bottom: 0.5px solid #e5e3e3;\n    width: 100%;\n\n    &:last-child {\n      border-bottom: none;\n    }\n\n    &.disabled {\n      .bet {\n        color: #b3b3b3;\n      }\n    }\n  }\n\n  .bet {\n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 10px 12px;\n  }\n\n  .type {\n    font-weight: bold;\n  }\n\n  .stake {\n    display: flex;\n    align-items: center;\n    gap: 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "_GUZrJGmTicIeb7e2zhA",
	"betRow": "DUJVOkiJDRUnunslAuOz",
	"disabled": "KAXudRCMTX8ArnNqU1aV",
	"bet": "UePQUOKB5Btn1Pb2GpXN",
	"type": "fLM1H_bxCPsYVkumewVq",
	"stake": "AderG_InbnoxL3ZP8au8"
};
export default ___CSS_LOADER_EXPORT___;
