var _a;
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { createRoot } from "react-dom/client";
import { Row, Col } from "antd";
import clsx from "clsx";
import { SUBSCRIBE_EVENT_MARKETS } from "v2/graphql/subscriptions";
import { useSubscribeByQuery } from "helpers/graphql/useSubscribeByQuery";
import { DEFAULT_VISIBLE_MARKET_ROWS } from "config/constants";
import { ShowMoreLess } from "components/base/markets/ShowMoreLess";
// styles
import styles from "./Markets.module.scss";
const Outcomes = ({ productId, data, defaultOutcomes, seriesId, sport, yesNoLine }) => {
    var _a, _b, _c, _d, _e, _f;
    const [fullOutcomes, setFullOutcomes] = React.useState(false);
    const isYesOnly = ((_b = (_a = data === null || data === void 0 ? void 0 : data[0]) === null || _a === void 0 ? void 0 : _a.outcomes) === null || _b === void 0 ? void 0 : _b.length) === 1;
    const onShowMoreLessClick = () => { setFullOutcomes(fullOutcomes => !fullOutcomes); };
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: styles.header }, { children: [_jsx("div", Object.assign({ className: clsx(styles.player, { [styles.yesOnly]: isYesOnly }), style: { justifyContent: "flex-start" } }, { children: "Player" })), _jsx("div", Object.assign({ className: styles.pick }, { children: yesNoLine ? "Yes" : (_d = (_c = data === null || data === void 0 ? void 0 : data[0].outcomes) === null || _c === void 0 ? void 0 : _c[0].key) !== null && _d !== void 0 ? _d : "Over" })), !isYesOnly && _jsx("div", Object.assign({ className: styles.pick }, { children: yesNoLine ? "No" : (_f = (_e = data === null || data === void 0 ? void 0 : data[0].outcomes) === null || _e === void 0 ? void 0 : _e[1].key) !== null && _f !== void 0 ? _f : "Under" }))] })), data === null || data === void 0 ? void 0 : data.map((marketLine, index) => {
                return _jsxs(Row, Object.assign({ justify: "space-between", align: "middle", className: styles.contentRow, style: { display: !fullOutcomes && index >= DEFAULT_VISIBLE_MARKET_ROWS ? "none" : "" }, gutter: [5, 8] }, { children: [_jsx(Col, Object.assign({ span: isYesOnly ? 16 : 8, style: { color: "#808594", paddingLeft: 7.5 } }, { children: marketLine.playerName })), _jsx(Col, Object.assign({ span: isYesOnly ? 8 : 16 }, { children: _jsx(Row, Object.assign({ justify: "space-between", align: "middle", gutter: [5, 8] }, { children: marketLine === null || marketLine === void 0 ? void 0 : marketLine.outcomes.map((selection) => {
                                    var _a, _b;
                                    const label = parseFloat((_b = (_a = selection.name.match(/\d+(\.\d+)?/)) === null || _a === void 0 ? void 0 : _a[0]) !== null && _b !== void 0 ? _b : "");
                                    return (_jsx(Col, Object.assign({ span: isYesOnly ? 24 : 12 }, { children: _jsx("leetent-odds-singleevent-input", { id: `odds-singlevent-input-${selection.id}`, "product-id": productId, "default-outcomes": JSON.stringify(defaultOutcomes), data: JSON.stringify({
                                                market: {
                                                    id: marketLine.marketId,
                                                    name: marketLine.name,
                                                    statusId: marketLine.statusId,
                                                    type: null
                                                },
                                                match: {
                                                // TODO: Is it necessary for sports?
                                                // id: match.abId
                                                },
                                                series: {
                                                    id: seriesId,
                                                    name: marketLine === null || marketLine === void 0 ? void 0 : marketLine.eventName,
                                                    startDate: marketLine === null || marketLine === void 0 ? void 0 : marketLine.startDate,
                                                    leagueName: marketLine === null || marketLine === void 0 ? void 0 : marketLine.leagueName,
                                                    leagueId: data === null || data === void 0 ? void 0 : data.leagueId,
                                                    sportName: marketLine === null || marketLine === void 0 ? void 0 : marketLine.sportName
                                                },
                                                selection: {
                                                    id: selection.id,
                                                    name: `${marketLine === null || marketLine === void 0 ? void 0 : marketLine.playerName} ${selection.name}`,
                                                    label: yesNoLine ? null : label || selection.name,
                                                    value: selection.odds,
                                                    isOpen: selection.isOpen,
                                                    statusId: selection.statusId,
                                                    isBetBuilderEnabled: selection === null || selection === void 0 ? void 0 : selection.isBetBuilderEnabled
                                                }
                                            }), view: "center", sport: JSON.stringify(sport) }) }), `outcome-${selection.id}`));
                                }) })) }))] }), `market-${String(marketLine.id)}-${String(marketLine.marketId)}`);
            }), data.length > DEFAULT_VISIBLE_MARKET_ROWS && (_jsx(ShowMoreLess, { onClick: onShowMoreLessClick, fullOutcomes: fullOutcomes }))] }));
};
const Nx2 = ({ productId, mode, asItem, seriesId, homeTeam, awayTeam, defaultOutcomes, data, sport, yesNoLine }) => {
    var _a;
    const [refData, setRefData] = React.useState(data);
    // Keep actual data from props
    React.useEffect(() => {
        if ((data === null || data === void 0 ? void 0 : data.length) > 0) {
            setRefData(data);
        }
    }, [data]);
    // subscribes to multiple event ids
    const valueHandlerCallback = (data, prevData) => {
        var _a;
        const _data = data.sport_domain_market[0];
        const _prevData = (_a = prevData === null || prevData === void 0 ? void 0 : prevData.sport_domain_market) === null || _a === void 0 ? void 0 : _a[0];
        if ((_prevData === null || _prevData === void 0 ? void 0 : _prevData.market_status_id) !== (_data === null || _data === void 0 ? void 0 : _data.market_status_id)) {
            setRefData((oldArray) => oldArray.map((item) => {
                // updates the market statusId
                if (item.marketId === _data.id)
                    item.statusId = _data.market_status_id;
                return item;
            }));
        }
    };
    useSubscribeByQuery({
        query: SUBSCRIBE_EVENT_MARKETS,
        variables: {
            eventId: seriesId,
            marketIds: (_a = refData === null || refData === void 0 ? void 0 : refData.filter((item) => item === null || item === void 0 ? void 0 : item.id)) === null || _a === void 0 ? void 0 : _a.map((item) => item === null || item === void 0 ? void 0 : item.marketId)
        },
        valueHandlerCallback
    });
    const onClick = (event) => {
        const content = event.target.nextElementSibling;
        const isActive = event.target.classList.value.includes(styles.active);
        if (isActive) {
            event.target.classList.remove(styles.active);
            content.style.maxHeight = 0;
            content.style.padding = 0;
        }
        else {
            event.target.classList.add(styles.active);
            content.style.transition = "max-height 0.2s ease-out";
            content.style.maxHeight = String(Number(content.scrollHeight) + 8) + "px";
            content.style.padding = "4px";
        }
    };
    if (!seriesId || !refData) {
        return _jsx(_Fragment, { children: "Loading..." });
    }
    return asItem ?
        _jsx("div", Object.assign({ className: styles.content }, { children: _jsx(Outcomes, { productId: productId, data: refData, defaultOutcomes: defaultOutcomes, seriesId: seriesId, homeTeam: homeTeam, awayTeam: awayTeam, sport: sport, yesNoLine: yesNoLine }) })) :
        _jsxs("div", Object.assign({ className: styles.marketGroup }, { children: [_jsx("button", Object.assign({ className: clsx(styles.collapsible, styles.active), onClick: (e) => onClick(e) }, { children: refData === null || refData === void 0 ? void 0 : refData.name })), _jsx("div", Object.assign({ className: styles.content }, { children: _jsx(Outcomes, { productId: productId, data: refData, defaultOutcomes: defaultOutcomes, seriesId: seriesId, homeTeam: homeTeam, awayTeam: awayTeam, sport: sport, yesNoLine: yesNoLine }) }))] }), `market-${refData === null || refData === void 0 ? void 0 : refData.marketTypeConfigId}`);
};
class Element extends HTMLElement {
    constructor() {
        super();
        this.root = createRoot(this);
        this.mode = "list";
        this["series-id"] = 0;
        this["default-outcomes"] = [];
        this.data = undefined;
    }
    static get observedAttributes() {
        return [
            "mode",
            "as-item",
            "series-id",
            "default-outcomes",
            "data",
            "home-team",
            "away-team",
            "product-id",
            "sport",
            "yes-no"
        ];
    }
    connectedCallback() {
        var _a;
        (_a = this.root) === null || _a === void 0 ? void 0 : _a.render(_jsx(Nx2, { mode: this.mode, productId: this["product-id"], asItem: this["as-item"], seriesId: this["series-id"], homeTeam: this["home-team"], awayTeam: this["away-team"], defaultOutcomes: this["default-outcomes"], data: this.data, sport: this.sport, yesNoLine: this["yes-no"] }));
    }
    disconnectedCallback() {
        setTimeout(() => { var _a; return (_a = this.root) === null || _a === void 0 ? void 0 : _a.unmount(); }, 0);
    }
    attributeChangedCallback(attrName, _oldValue, newValue) {
        var _a;
        switch (attrName) {
            case "series-id":
            case "product-id":
                this[attrName] = Number(newValue);
                break;
            case "mode":
            case "home-team":
            case "away-team":
                this[attrName] = newValue;
                break;
            case "as-item":
            case "default-outcomes":
            case "data":
            case "sport":
            case "yes-no":
                this[attrName] = JSON.parse(newValue);
                break;
            default:
                break;
        }
        (_a = this.root) === null || _a === void 0 ? void 0 : _a.render(_jsx(Nx2, { mode: this.mode, productId: this["product-id"], asItem: this["as-item"], seriesId: this["series-id"], homeTeam: this["home-team"], awayTeam: this["away-team"], defaultOutcomes: this["default-outcomes"], data: this.data, sport: this.sport, yesNoLine: this["yes-no"] }));
    }
}
(_a = customElements.get("leetent-market-n2")) !== null && _a !== void 0 ? _a : customElements.define("leetent-market-n2", Element);
