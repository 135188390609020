import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { mockEventDetailData, mockEventDetailBasketballData, mockEventDetailVolleyballData, mockEventDetailTableTennisData, mockEventDetailTennisData, mockBreadcrumbData, mockBreadcrumbBasketballData, mockBreadcrumbTennisData, mockBreadcrumbVolleyballData, mockBreadcrumbTableTennisData } from "./assets/SingleEventDetailHeader";
// components
import MountDocumentComponent from "components/documentation/MountDocumentComponent";
const SingleEventDetailHeader = () => {
    const [documentDetails, setDocumentDetails] = React.useState({});
    const volleyballBreadcrumbs = structuredClone(mockBreadcrumbVolleyballData);
    volleyballBreadcrumbs[0].title = "Volleyball";
    const iceHockeyBreadcrumbs = structuredClone(mockBreadcrumbBasketballData);
    iceHockeyBreadcrumbs[0].title = "Ice Hockey";
    const tableTennisBreadcrumbs = structuredClone(mockBreadcrumbTableTennisData);
    tableTennisBreadcrumbs[0].title = "Table Tennis";
    React.useEffect(() => {
        setDocumentDetails({
            title: "Single event detail header",
            component: "leetent-singleEventDetail-header",
            description: "This component will be used to present event information and navigate between events",
            withDarkMode: false,
            attributes: [
                {
                    key: "1",
                    attr: "data",
                    type: "object",
                    notes: "This attribute contains data for event details and other related events." +
                        "Such as team names, league details, date and sport." +
                        "There is no default value."
                },
                {
                    key: "2",
                    attr: "breadcrumbs",
                    type: "object",
                    notes: `This attribute needs data is for the breadcrumb on the top left. It is an array with
            the labels and links. There is no default value.`
                }
            ],
            events: [
                {
                    event: "single-event-detail-back-button-click",
                    type: "emit",
                    data: [
                        `When back button on top left clicked, this event gets emitted with details`,
                        `detail: {
              isBackClicked: true
            }`
                    ]
                },
                {
                    event: "single-event-detail-breadcrumb-item-click",
                    type: "emit",
                    data: [
                        `When user clicks on breadcrumb item such as 'Football' or 'UEFA Qualifiers'
            this event gets emitted`,
                        `detail: {
              clickedItemHref: '/soccer'
            }`
                    ]
                },
                {
                    event: "single-event-detail-event-row-clicked",
                    type: "emit",
                    data: [
                        `This emit, gives the event information when user clicks different event on other events modal`,
                        `detail: {
                "clickedEvent":{
                  "id":19477,
                  "name":"Arsenal v Liverpool",
                  "homeTeam":"Arsenal",
                  "awayTeam":"Liverpool",
                  "leagueName":"UEFA Champions League",
                  "countryName":"Euro Qualifiers",
                  "sportName":"Soccer",
                  "startDate":"2023-10-04T22:23:45+00:00"
                }
            }`
                    ]
                },
                {
                    event: "single-event-detail-event-row-clicked",
                    type: "emit",
                    data: [
                        `This emit, gives the event information when user clicks different event on other events modal`,
                        `detail: {
                "clickedEvent":{
                  "id":19477,
                  "name":"Arsenal v Liverpool",
                  "homeTeam":"Arsenal",
                  "awayTeam":"Liverpool",
                  "leagueName":"UEFA Champions League",
                  "countryName":"Euro Qualifiers",
                  "sportName":"Soccer",
                  "startDate":"2023-10-04T22:23:45+00:00"
                }
            }`
                    ]
                }
            ],
            example: `<leetent-single-event-detail-header
        data={
          eventDetail: {
            id: 19475,
            name: "Galatasaray Spor Kulubu v Borussia Monchengladbach",
            homeTeam: "Galatasaray",
            awayTeam: "Borussia Monchengladbach",
            leagueName: "UEFA Champions League",
            countryName: "Euro Qualifiers",
            sportName: "Soccer",
            startDate: "2023-09-26T18:45:00+00:00",
            statusId: 18,
            inPlay: {
              clock: {
                period: "2H",
                statusId: 10,
                minutes: 1,
                seconds: 140,
                remainingMinutesInPeriod: 1,
                remainingSecondsInPeriod: 20,
                timestamp: 1708442098
              },
              score: {
                home: 2,
                away: 1,
                periodScores: [
                  {
                    period: "1H",
                    home: 1,
                    away: 0
                  },
                  {
                    period: "2H",
                    home: 2,
                    away: 1
                  }
                ]
              },
              stats: {
                homecorners: 4,
                awaycorners: 1,
                homeyellows: 0,
                awayyellows: 0,
                homepossession: 50,
                awaypossession: 50,
                homereds: 0,
                awayreds: 0
              }
            }
          },
          otherEvents: [
            {
              key: "0",
              name: "Galatasaray Spor Kulubu v Borussia Monchengladbach",
              id: 19475,
              homeTeam: "Galatasaray",
              awayTeam: "Borussia Monchengladbach",
              leagueName: "UEFA Champions League",
              countryName: "Euro Qualifiers",
              sportName: "Soccer",
              startDate: "2023-09-26T18:45:00+00:00",
              href: "http://www.oyawin.com/soccer/euro-qualifiers/champions-league/galatasaray-vs-kopenhag",
              statusId: 18,
              inPlay: {
                clock: {
                  period: "2H",
                  statusId: 10,
                  minutes: 1,
                  seconds: 140,
                  remainingMinutesInPeriod: 1,
                  remainingSecondsInPeriod: 20,
                  timestamp: 1708442098
                },
                score: {
                  home: 2,
                  away: 1,
                  periodScores: [
                    {
                      period: "1H",
                      home: 1,
                      away: 0
                    },
                    {
                      period: "2H",
                      home: 2,
                      away: 1
                    }
                  ]
                },
                stats: {
                  homecorners: 4,
                  awaycorners: 1,
                  homeyellows: 0,
                  awayyellows: 0,
                  homepossession: 50,
                  awaypossession: 50,
                  homereds: 0,
                  awayreds: 0
                }
              }
            },
            {
              key: "1",
              name: "Fulham v Chelsea",
              id: 19475,
              homeTeam: "Fulham",
              awayTeam: "Chelsea",
              leagueName: "UEFA Champions League",
              countryName: "Euro Qualifiers",
              sportName: "Soccer",
              startDate: "2023-09-29T19:45:00+00:00",
              href: "http://www.oyawin.com/soccer/england/premier-league/fulham-vs-chelsea",
              status: "notstarted"
            },
            {
              key: "2",
              name: "Arsenal v Liverpool",
              id: 19475,
              homeTeam: "Arsenal",
              awayTeam: "Liverpool",
              leagueName: "UEFA Champions League",
              countryName: "Euro Qualifiers",
              sportName: "Soccer",
              startDate: "2023-10-04T22:00:00+00:00",
              href: "http://www.oyawin.com/soccer/england/premier-league/arsenal-vs-liverpool",
              statusId: 18,
              inPlay: {
                clock: {
                  period: "2H",
                  statusId: 10,
                  minutes: 1,
                  seconds: 140,
                  remainingMinutesInPeriod: 1,
                  remainingSecondsInPeriod: 20,
                  timestamp: 1708442098
                },
                score: {
                  home: 0,
                  away: 5,
                  periodScores: [
                    {
                      period: "1H",
                      home: 0,
                      away: 3
                    },
                    {
                      period: "2H",
                      home: 0,
                      away: 5
                    }
                  ]
                },
                stats: {
                  homecorners: 4,
                  awaycorners: 1,
                  homeyellows: 0,
                  awayyellows: 0,
                  homepossession: 50,
                  awaypossession: 50,
                  homereds: 0,
                  awayreds: 0
                }
              }
            },
            {
              key: "3",
              name: "Wolves v Brentford",
              id: 19475,
              homeTeam: "Wolves",
              awayTeam: "Brentford",
              leagueName: "UEFA Champions League",
              countryName: "Euro Qualifiers",
              sportName: "Soccer",
              startDate: "2023-09-20T20:00:00+00:00",
              href: "http://www.oyawin.com/soccer/england/premier-league/wolves-vs-brentford",
              statusId: 18,
              inPlay: {
                clock: {
                  period: "1H",
                  statusId: 10,
                  minutes: 1,
                  seconds: 140,
                  remainingMinutesInPeriod: 1,
                  remainingSecondsInPeriod: 20,
                  timestamp: 1708442098
                },
                score: {
                  home: 1,
                  away: 3,
                  periodScores: [
                    {
                      period: "1H",
                      home: 1,
                      away: 3
                    }
                  ]
                },
                stats: {
                  homecorners: 4,
                  awaycorners: 1,
                  homeyellows: 0,
                  awayyellows: 0,
                  homepossession: 50,
                  awaypossession: 50,
                  homereds: 0,
                  awayreds: 0
                }
              }
            },
          ];
        }
        breadcrumbs=[
          {
            title: "Soccer",
            href: "http://www.oyawin.com/soccer"
          },
          {
            title: "EURO Qualifiers",
            href: "http://www.oyawin.com/soccer/england/premier-league"
          },
          {
            title: "Galatasaray v Kopenhag",
          }
        ];
      />`,
            tabs: [
                {
                    key: "1",
                    label: "Soccer",
                    children: _jsx("leetent-single-event-detail-header", { data: JSON.stringify(mockEventDetailData), breadcrumbs: JSON.stringify(mockBreadcrumbData) })
                },
                {
                    key: "2",
                    label: "Tennis",
                    children: _jsx("leetent-single-event-detail-header", { data: JSON.stringify(mockEventDetailTennisData), breadcrumbs: JSON.stringify(mockBreadcrumbTennisData) })
                },
                {
                    key: "3",
                    label: "Basketball",
                    children: _jsx("leetent-single-event-detail-header", { data: JSON.stringify(mockEventDetailBasketballData), breadcrumbs: JSON.stringify(mockBreadcrumbBasketballData) })
                },
                {
                    key: "4",
                    label: "Volleyball",
                    children: _jsx("leetent-single-event-detail-header", { data: JSON.stringify(mockEventDetailVolleyballData), breadcrumbs: JSON.stringify(volleyballBreadcrumbs) })
                },
                {
                    key: "5",
                    label: "Ice Hockey",
                    children: _jsx("leetent-single-event-detail-header", { data: JSON.stringify(mockEventDetailBasketballData), breadcrumbs: JSON.stringify(iceHockeyBreadcrumbs) })
                },
                {
                    key: "6",
                    label: "Table Tennis",
                    children: _jsx("leetent-single-event-detail-header", { data: JSON.stringify(mockEventDetailTableTennisData), breadcrumbs: JSON.stringify(tableTennisBreadcrumbs) })
                }
            ]
        });
    }, []);
    // render the page based on the provided object
    const SingleEventDetailHeaderComponent = React.useCallback(() => {
        if (Object.keys(documentDetails).length)
            return _jsx(MountDocumentComponent, { data: documentDetails });
        return _jsx("div", { children: "Loading page..." });
    }, [documentDetails]);
    return _jsx(SingleEventDetailHeaderComponent, {});
};
export default SingleEventDetailHeader;
