var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { createRoot } from "react-dom/client";
import clsx from "clsx";
import axios from "axios";
import { Skeleton, Row, Col } from "antd";
import EditProfileWrapper from "pages/profile/editProfile/EditProfileWrapper";
import { LocalStorage } from "helpers/Storage";
import { fetchProfile } from "../../../helpers/SessionProfileId";
import { v4 as uuidv4 } from "uuid";
// styles
import styles from "./SocialBettor.module.scss";
import flexStyle from "assets/styles/flex.module.scss";
// tabs options
const tabsOptions = [
    { name: "popular", value: "popular" },
    { name: "new", value: "latest" },
    { name: "following", value: "followers" }
];
const SocialBettor = ({ noDataOptions }) => {
    const [isLoading, setIsloading] = React.useState(true);
    const [error, setError] = React.useState();
    const [posts, setPosts] = React.useState([]);
    const [tab, setTab] = React.useState("latest");
    const [showUserProfile, setShowUserProfile] = React.useState(false);
    const [userProfileData, setShowUserProfileData] = React.useState("");
    const [totalItemCount, setTotalItemCount] = React.useState(0);
    const [lastBookMark, setLastBookMark] = React.useState("");
    const [profileIdFromSession, setProfileIdFromSession] = React.useState(sessionStorage.getItem("profileId"));
    const playerId = LocalStorage.get("widget-playerId");
    const token = localStorage.getItem("widget-token");
    const userProfileJSON = sessionStorage.getItem("userProfile");
    const bettorIdFromSessionUserProfile = JSON.parse(userProfileJSON !== null && userProfileJSON !== void 0 ? userProfileJSON : "{}");
    React.useEffect(() => {
        if (playerId && token && bettorIdFromSessionUserProfile.id) {
            fetchProfile(setProfileIdFromSession, bettorIdFromSessionUserProfile.id);
        }
    }, [playerId, token, bettorIdFromSessionUserProfile]);
    React.useEffect(() => {
        window.addEventListener("selected-tab", (e) => {
            e.preventDefault();
            setTab(e.detail.value);
        });
        window.addEventListener("go-back-to-social", (e) => {
            e.preventDefault();
            setTab(e.detail.value);
        });
        window.addEventListener("profile-image-details", (e) => {
            e.preventDefault();
            e.detail.showUserProfile !== showUserProfile && setShowUserProfile(e.detail.showUserProfile);
            e.detail.bettorId !== userProfileData && setShowUserProfileData(e.detail.bettorId);
        });
        // TODO: Event was added, but it is not in use. What should we do with it?
        // window.addEventListener("edit-profile-image-details", (e: any) => {
        //   e.preventDefault();
        //   e.detail.showUserProfile !== showUserProfile && setShowUserProfile(e.detail.showUserProfile);
        //   e.detail.bettorId !== userProfileData && setShowUserProfileData(e.detail.bettorId);
        // });
    }, []);
    // fetches the user profile information when component is rendered
    React.useEffect(() => {
        const profileData = sessionStorage.getItem("userProfile");
        if (playerId) {
            if (profileData) {
                try {
                    const parsedProfileData = JSON.parse(profileData);
                    if (playerId !== (parsedProfileData === null || parsedProfileData === void 0 ? void 0 : parsedProfileData.providerBettorId)) {
                        sessionStorage.removeItem("userProfile");
                        getBettorDetails(playerId);
                    }
                }
                catch (error) {
                    setError(error);
                }
            }
            else {
                getBettorDetails(playerId);
            }
        }
        else {
            profileData && sessionStorage.removeItem("userProfile");
        }
        // listeners
        const tabHandler = (e) => {
            setTab(e.detail.value);
        };
        const profileImageDetailsHandler = (e) => {
            e.preventDefault();
            e.detail.showUserProfile !== showUserProfile && setShowUserProfile(e.detail.showUserProfile);
            e.detail.bettorId !== userProfileData && setShowUserProfileData(e.detail.bettorId);
        };
        window.addEventListener("selected-tab", tabHandler);
        window.addEventListener("go-back-to-social", tabHandler);
        window.addEventListener("profile-image-details", profileImageDetailsHandler);
        // window.addEventListener("edit-profile-image-details", (e: any) => {
        //   e.preventDefault();
        //   console.log(e.detail);
        //   e.detail.showUserProfile !== showUserProfile && setShowUserProfile(e.detail.showUserProfile);
        //   e.detail.bettorId !== userProfileData && setShowUserProfileData(e.detail.bettorId);
        // });
        return () => {
            window.removeEventListener("selected-tab", tabHandler);
            window.removeEventListener("go-back-to-social", tabHandler);
            window.removeEventListener("profile-image-details", profileImageDetailsHandler);
        };
    }, []);
    const getBettorDetails = React.useCallback((platformBettorId) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        if (((_a = process.env) === null || _a === void 0 ? void 0 : _a.REACT_APP_GATEWAY_API_URL) && platformBettorId) {
            try {
                const response = yield axios.get(`${(_b = process.env) === null || _b === void 0 ? void 0 : _b.REACT_APP_GATEWAY_API_URL}/bets/bettor/${platformBettorId}`);
                const bettorProfile = response.data;
                sessionStorage.setItem("userProfile", JSON.stringify(bettorProfile));
                setProfileId(bettorProfile === null || bettorProfile === void 0 ? void 0 : bettorProfile.id);
            }
            catch (e) {
                setError(e);
            }
        }
    }), []);
    const setProfileId = (id = "") => __awaiter(void 0, void 0, void 0, function* () {
        var _c, _d, _e, _f;
        try {
            const response = yield axios.get(`${(_e = (_d = (_c = process.env) === null || _c === void 0 ? void 0 : _c.REACT_APP_GATEWAY_API_URL) === null || _d === void 0 ? void 0 : _d.replace("api/", "")) !== null && _e !== void 0 ? _e : ""}/sourceProfile?source-player-id=${id}`);
            const profileId = (_f = response.data) === null || _f === void 0 ? void 0 : _f.profileId;
            if (profileId !== undefined) {
                sessionStorage.setItem("profileId", profileId);
                setShowUserProfileData(profileId);
            }
        }
        catch (e) {
            console.log(e);
        }
    });
    const fetchPostsData = React.useCallback((fromTabChange = false, lastBookMark) => __awaiter(void 0, void 0, void 0, function* () {
        var _g, _h, _j;
        !isLoading && setIsloading(true);
        // TODO: Create an axios handler, capable of handling all necessary requests
        if (((_g = process.env) === null || _g === void 0 ? void 0 : _g.REACT_APP_GATEWAY_API_URL) &&
            ((_h = process.env) === null || _h === void 0 ? void 0 : _h.REACT_APP_GATEWAY_API_KEY)) {
            const urlToPostsScope = `${(_j = process.env) === null || _j === void 0 ? void 0 : _j.REACT_APP_GATEWAY_API_URL}/posts?scope=${tab}`;
            const followersProfileIdString = tab === "followers" && sessionStorage.getItem("profileId") ?
                `&profile-id=${profileIdFromSession !== null && profileIdFromSession !== void 0 ? profileIdFromSession : ""}` :
                "";
            yield axios
                .get(`${urlToPostsScope}&bookmark=${lastBookMark || ""}${followersProfileIdString}&limit=20`, {
                headers: {
                    "x-api-key": process.env.REACT_APP_GATEWAY_API_KEY
                }
            })
                .then((res) => {
                let data = res.data.items;
                const esportsPostsData = data === null || data === void 0 ? void 0 : data.filter((el) => {
                    var _a, _b, _c, _d, _e, _f;
                    return ((_f = (_e = (_d = (_c = (_b = (_a = el.detail) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.Bets) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.Legs) === null || _e === void 0 ? void 0 : _e[0]) === null || _f === void 0 ? void 0 : _f.ProductId) !== 1;
                });
                data = esportsPostsData;
                setError(undefined);
                setTotalItemCount(res.data.totalItemCount);
                if (fromTabChange) {
                    setPosts(data);
                    setLastBookMark("");
                }
                else {
                    setPosts((prevPostData) => [...prevPostData, ...data]);
                }
                setLastBookMark(res.data.lastBookmark);
            })
                .catch((e) => {
                setError(e);
            })
                .finally(() => {
                setIsloading(false);
            });
        }
        else {
            setError("Environment variables are not in place");
        }
    }), [isLoading, tab]);
    React.useEffect(() => {
        if (tab)
            fetchPostsData(true, ""); // only makes a call to the method, when tab is provided
    }, [tab]);
    const handleLoadMore = (event) => {
        event.preventDefault();
        fetchPostsData(false, lastBookMark);
    };
    if (isLoading && !error) {
        return (_jsxs("div", Object.assign({ className: styles.root }, { children: [_jsxs(Row, Object.assign({ justify: "space-around", align: "middle", gutter: 5, className: styles.skeletonRow }, { children: [_jsx(Col, Object.assign({ span: 6 }, { children: _jsx(Skeleton.Input, { active: true, size: "small", block: true, className: styles.skeletonMaxHeight }) })), _jsx(Col, Object.assign({ span: 6 }, { children: _jsx(Skeleton.Input, { active: true, size: "small", block: true, className: styles.skeletonMaxHeight }) }))] })), [1, 2, 3].map(n => {
                    return (_jsxs("div", Object.assign({ className: styles.skeletonOdds }, { children: [_jsx(Row, Object.assign({ gutter: 5, style: { marginTop: "1%" } }, { children: _jsx(Col, Object.assign({ span: 24 }, { children: _jsx(Skeleton.Input, { active: true, size: "default", block: true, className: styles.skeletonMaxHeight }) })) })), _jsx(Row, Object.assign({ gutter: 5, style: { marginTop: "1%" } }, { children: _jsx(Col, Object.assign({ span: 24 }, { children: _jsx(Skeleton.Input, { active: true, size: "default", block: true, className: styles.skeletonMaxHeight }) })) })), _jsx(Row, Object.assign({ gutter: 5, style: { marginTop: "1%" } }, { children: _jsx(Col, Object.assign({ span: 24 }, { children: _jsx(Skeleton.Input, { active: true, size: "default", block: true, className: styles.skeletonMaxHeight }) })) }))] }), `skeleton-odds-${uuidv4()}`));
                })] })));
    }
    else if (error) {
        return (_jsx("leetent-nodata-for-market", { message: noDataOptions === null || noDataOptions === void 0 ? void 0 : noDataOptions.message, src: noDataOptions === null || noDataOptions === void 0 ? void 0 : noDataOptions.src, cta: JSON.stringify(noDataOptions === null || noDataOptions === void 0 ? void 0 : noDataOptions.cta) }));
    }
    else if (!showUserProfile) {
        return (_jsxs("div", { children: [_jsx("leetent-tabs-header", { tabs: JSON.stringify(playerId ? tabsOptions : tabsOptions === null || tabsOptions === void 0 ? void 0 : tabsOptions.filter(option => option.value !== "followers")), selected: tab }), _jsx("leetent-social-profile-card", { posts: JSON.stringify(posts), "user-post-required": false, loading: isLoading }), _jsx("div", Object.assign({ className: clsx(flexStyle.dflex, flexStyle.justifyCenter) }, { children: totalItemCount > (posts === null || posts === void 0 ? void 0 : posts.length) && !isLoading && (_jsx("div", Object.assign({ className: clsx(flexStyle.dflex, flexStyle.justifyCenter) }, { children: _jsx("button", Object.assign({ onClick: handleLoadMore, className: "button_social_widget load" }, { children: "Load More" })) }))) }))] }));
    }
    else {
        return (_jsx(EditProfileWrapper, { profileId: userProfileData, setShowUserProfile: setShowUserProfile, showUserProfile: showUserProfile }));
    }
};
class Element extends HTMLElement {
    static get observedAttributes() {
        return ["no-data-options"];
    }
    connectedCallback() {
        createRoot(this).render(_jsx(SocialBettor, { noDataOptions: this["no-data-options"] }));
    }
    attributeChangedCallback(attrName, _oldValue, newValue) {
        switch (attrName) {
            case "no-data-options":
                this[attrName] = JSON.parse(newValue);
                break;
            default:
                break;
        }
    }
}
(_a = customElements.get("leetent-social-bettor")) !== null && _a !== void 0 ? _a : customElements.define("leetent-social-bettor", Element);
