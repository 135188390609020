import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { LocalStorage } from "helpers/Storage";
// components
import MountDocumentComponent from "components/documentation/MountDocumentComponent";
// document details of inner components
import { tabsHeaderDocumentDetails } from "pages/documentation/documentDetails";
import { documentDetails as unauthenticatedUserModalDocumentDetails } from "pages/documentation/components/UnauthenticatedUserModal";
import { documentDetails as betSlipConfirmationDocumentDetails } from "pages/documentation/components/BetSlipConfirmation";
import { documentDetails as modalGenericDocumentDetails } from "pages/documentation/components/ModalGeneric";
const emptyBetslipMessage = "Betslip is empty";
export const documentDetails = {
    title: "Bet slip",
    component: "leetent-bet-slip",
    description: "This is a base component, that is used as part of many composed components.",
    withDarkMode: true,
    withSessionComponent: true,
    dependencies: [
        "leetent-tabs-header",
        "leetent-unauthenticated-user-modal",
        "leetent-bet-slip-confirmation",
        "StakeSelection (internal)",
        "BetSummary (internal)",
        "BetSlipFooter (internal)"
    ],
    attributes: [
        {
            key: "1",
            attr: "bets",
            type: "object[] (optional)",
            notes: [
                "Object array of bets which is used to set custom bets",
                `Default bets get from localstorage by "betsDetails" key.`
            ]
        },
        {
            key: "2",
            attr: "min-stake",
            type: "Number",
            notes: ["Minimum stake value to place bet(s).", `Default is 100.`]
        },
        {
            key: "3",
            attr: "relative",
            type: "boolean (optional)",
            notes: ["If value is true, bet slip will be relative.", "Fixed by default."]
        },
        {
            key: "4",
            attr: "coupon",
            type: "boolean (optional)",
            notes: ["If coupon is true, bet slip will be minified"]
        },
        {
            key: "5",
            attr: "open",
            type: "boolean (optional)",
            notes: ["Default is false"]
        },
        {
            key: "5",
            attr: "max-width",
            type: "string (optional)",
            notes: [
                "By default the max-width is defined as inherit",
                `If necessary, you can define a max-width. e.g. max-width="600px"`,
                "Note: The minimum value acceptable would be 380px"
            ]
        }
    ],
    events: [
        {
            event: "close-auth-modal",
            type: "listener",
            data: [
                "The component details object is emitted on closing the unauthenticated modal."
            ]
        },
        {
            event: "click-modal-button",
            type: "listener",
            data: [
                "The component details object is emitted on click auth buttons in the modal.",
                `detail: {
          id: number
          label: string
          url: string
        }`
            ]
        },
        {
            event: "bet-slip-selected-tab",
            type: "listener",
            data: [
                "Each time the betslip tab is selected, the state gets updated.",
                "This is transparent to users."
            ]
        },
        {
            event: "bet-slip-confirmation-close",
            type: "listener",
            data: [
                "The component details object is emitted on close bet slip confirmation.",
                `detail: { 
          reuseSelections?: boolean
        }`
            ]
        },
        {
            event: `bet-slip-bet-remove`,
            type: "listener",
            data: [
                "This event is emitted when user removes bet from the betslip."
            ]
        },
        {
            event: `bet-slip-bet-stake`,
            type: "listener",
            data: [
                "The component details object is emitted on stake change.",
                `detail: { 
          stake: string; 
          legs: { 
            outcomeId: number; 
            productId: number; 
            odds: number; 
          }[]; 
        }`
            ]
        },
        {
            event: "max-stake-exceeded",
            type: "emit",
            data: [
                "This event is emitted when a bet max stake exceeded."
            ]
        },
        {
            event: "close-bet-slip",
            type: "emit",
            data: [
                "This event is emitted when the betslip is closed/minimised."
            ]
        },
        {
            event: "update-selected-bets",
            type: "emit",
            data: [
                "This event is emitted when the betslip gets updated."
            ]
        },
        {
            event: "bet-slip-clear-bets",
            type: "emit",
            data: [
                "This event is emitted when user clears betslip."
            ]
        },
        {
            event: "triggerDepositFlow",
            type: "emit",
            data: [
                "This event is emitted on click deposit modal button."
            ]
        },
        {
            event: "prev-change-odds",
            type: "emit",
            data: [
                "This event is emitted on odds change with data of the previous one."
            ]
        },
        {
            event: "change-max-stake",
            type: "emit",
            data: [
                "This event is emitted on error response of bet placement for bet max stake change."
            ]
        },
        {
            event: "balance-update",
            type: "emit",
            data: [
                "This event is emitted when a bet is successfully placed"
            ]
        }
    ],
    innerComponents: [
        {
            name: "leetent-tabs-header",
            documentDetails: tabsHeaderDocumentDetails
        },
        {
            name: "leetent-bet-slip-confirmation",
            documentDetails: betSlipConfirmationDocumentDetails
        },
        {
            name: "leetent-unauthenticated-user-modal",
            documentDetails: unauthenticatedUserModalDocumentDetails
        },
        {
            name: "leetent-modal-generic",
            documentDetails: modalGenericDocumentDetails
        }
    ]
};
const BetSlip = () => {
    const bets = [
        {
            market: "Series Winner",
            pick: "Team Spirit",
            event: "Team Spirit VS Sprout",
            odds: 1.32,
            time: "20:00",
            outcomeId: 111,
            eventId: 111,
            productId: 2
        },
        {
            market: "Match Winner",
            pick: "TSM",
            event: "Team Falcons VS TSM",
            odds: 2.06,
            time: "21:00",
            outcomeId: 112,
            eventId: 112,
            productId: 2
        },
        {
            market: "Match Winner",
            pick: "10Nine",
            event: "10INE VS Looking4Something",
            odds: 2.56,
            time: "19:53",
            outcomeId: 113,
            eventId: 113,
            productId: 2
        }
    ];
    const [betsChanged, setBetsChanged] = React.useState([
        {
            market: "Series Winner",
            pick: "Team Spirit",
            event: "Team Spirit VS Sprout",
            odds: 1.32,
            time: "20:00",
            outcomeId: 111,
            eventId: 111,
            productId: 2
        },
        {
            market: "Match Winner",
            pick: "TSM",
            event: "Team Falcons VS TSM",
            odds: 2.06,
            time: "21:00",
            outcomeId: 112,
            eventId: 112,
            productId: 2
        },
        {
            market: "Match Winner",
            pick: "10Nine",
            event: "10INE VS Looking4Something",
            odds: 2.56,
            time: "19:53",
            outcomeId: 113,
            eventId: 113,
            productId: 2
        }
    ]);
    React.useEffect(() => {
        const updateSelectedBetsHandler = (e) => {
            e.preventDefault();
            const updatedBets = [...e.detail.selectedBets];
            setBetsChanged(updatedBets);
            LocalStorage.set("betsDetails", updatedBets);
        };
        window.addEventListener("update-selected-bets", updateSelectedBetsHandler);
        return () => {
            window.removeEventListener("update-selected-bets", updateSelectedBetsHandler);
        };
    }, []);
    documentDetails.example = `<leetent-bet-slip open relative />`;
    documentDetails.tabs = [
        {
            key: "1",
            label: "Default",
            children: _jsxs(_Fragment, { children: [bets.length < 1 && emptyBetslipMessage, _jsx("leetent-bet-slip", { bets: JSON.stringify(bets), open: true, relative: true, "product-id": 2 })] })
        },
        {
            key: "2",
            label: "Limited width",
            children: _jsxs(_Fragment, { children: [bets.length < 1 && emptyBetslipMessage, _jsx("leetent-bet-slip", { bets: JSON.stringify(bets), open: true, relative: true, "product-id": 2, "max-width": "640px" })] })
        },
        {
            key: "3",
            label: "Coupon",
            children: _jsxs(_Fragment, { children: [bets.length < 1 && emptyBetslipMessage, _jsx("leetent-bet-slip", { bets: JSON.stringify(bets), open: true, relative: true, "product-id": 2, coupon: true })] })
        },
        {
            key: "4",
            label: "Changed odds",
            children: _jsxs(_Fragment, { children: [betsChanged.length < 1 && emptyBetslipMessage, _jsx("leetent-bet-slip", { bets: JSON.stringify(betsChanged.map(bet => {
                            bet.outcomeId *= 2;
                            return bet;
                        })), open: true, relative: true, "product-id": 2 })] })
        }
    ];
    // render the page based on the provided object
    return _jsx(MountDocumentComponent, { data: documentDetails });
};
export default BetSlip;
