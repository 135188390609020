var _a;
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { createRoot } from "react-dom/client";
import { Row, Col, Grid } from "antd";
import clsx from "clsx";
import { SUBSCRIBE_EVENT_MARKETS } from "v2/graphql/subscriptions";
import { useSubscribeByQuery } from "helpers/graphql/useSubscribeByQuery";
import { DEFAULT_VISIBLE_MARKET_ROWS } from "config/constants";
import { ShowMoreLess } from "components/base/markets/ShowMoreLess";
// styles
import styles from "./Markets.module.scss";
const { useBreakpoint } = Grid;
const OutcomesSingleObject = ({ productId, data, mode, onlyOutcomes, defaultOutcomes, seriesId, sport }) => {
    return (_jsx(Row
    // key={`OutcomesSingleObject-${Number(data?.outcomes?.[0]?.id)}`}
    , Object.assign({ 
        // key={`OutcomesSingleObject-${Number(data?.outcomes?.[0]?.id)}`}
        justify: "space-between", align: "middle", className: styles.contentRow, gutter: [5, 8] }, { children: data === null || data === void 0 ? void 0 : data.outcomes.map((selection) => {
            return (_jsx(Col, Object.assign({ span: data.outcomes.length > 3 ? 8 : 24 / data.outcomes.length }, { children: _jsx("leetent-odds-singleevent-input", { id: `odds-singlevent-input-${selection.id}`, "product-id": productId, view: mode === "single" ? "top" : "default", "only-outcomes": onlyOutcomes, "default-outcomes": JSON.stringify(defaultOutcomes), data: JSON.stringify({
                        market: {
                            id: data.marketId,
                            name: data.name,
                            statusId: data.statusId,
                            type: null
                        },
                        match: {
                            id: data === null || data === void 0 ? void 0 : data.id
                        },
                        series: {
                            id: seriesId,
                            name: data === null || data === void 0 ? void 0 : data.eventName,
                            startDate: data === null || data === void 0 ? void 0 : data.startDate,
                            leagueName: data === null || data === void 0 ? void 0 : data.leagueName,
                            leagueId: data === null || data === void 0 ? void 0 : data.leagueId,
                            sportName: data === null || data === void 0 ? void 0 : data.sportName
                        },
                        selection: {
                            id: selection.id,
                            name: selection.name,
                            label: selection.name,
                            value: selection.odds,
                            isOpen: selection.isOpen,
                            statusId: selection.statusId,
                            isBetBuilderEnabled: selection === null || selection === void 0 ? void 0 : selection.isBetBuilderEnabled
                        }
                    }), sport: JSON.stringify(sport) }) }), `outcome-${selection.id}`));
        }) })));
};
const OutcomesList = ({ productId, onlyOutcomes, data, defaultOutcomes, sport }) => {
    var _a, _b, _c, _d;
    if (onlyOutcomes) {
        return (data.map((event, index) => {
            var _a, _b, _c;
            return (_jsx(Col, Object.assign({ span: 24, style: { borderBottom: index < (data === null || data === void 0 ? void 0 : data.length) - 1 ? "1px solid #f2f2f2" : "0px solid #f2f2f2" } }, { children: _jsx("leetent-league-matches-bymarket", { "product-id": productId, sport: JSON.stringify(sport), events: JSON.stringify([event]), country: JSON.stringify(event.country), league: JSON.stringify({ id: (_a = event === null || event === void 0 ? void 0 : event.league) === null || _a === void 0 ? void 0 : _a.id, name: (_b = event === null || event === void 0 ? void 0 : event.league) === null || _b === void 0 ? void 0 : _b.name }), "market-count": JSON.stringify(0), "market-view": "overUnder", "default-outcomes": JSON.stringify(defaultOutcomes), "only-outcomes": JSON.stringify(onlyOutcomes) }) }), `league_${(_c = event === null || event === void 0 ? void 0 : event.league) === null || _c === void 0 ? void 0 : _c.id}_event_${event === null || event === void 0 ? void 0 : event.id}`));
        }));
    }
    return (_jsxs("div", { children: [_jsxs(Row, Object.assign({ className: styles.headerWrapper }, { children: [_jsx(Col, Object.assign({ xs: 11, sm: 11, md: 11, lg: 8, xl: 8, style: { fontSize: 9 } }, { children: "ALL GAMES" })), _jsx(Col, Object.assign({ className: styles.headerLabelWrapper, xs: 13, sm: 13, md: 13, lg: 16, xl: 16 }, { children: _jsxs(Row, Object.assign({ justify: "center", align: "middle" }, { children: [_jsxs(Col, Object.assign({ span: 8, className: styles.oddLabel }, { children: [(_b = (_a = data[0]) === null || _a === void 0 ? void 0 : _a.outcomes[0]) === null || _b === void 0 ? void 0 : _b.key.toUpperCase(), " GOALS"] })), _jsxs(Col, Object.assign({ span: 8, className: styles.oddLabel }, { children: [(_d = (_c = data[0]) === null || _c === void 0 ? void 0 : _c.outcomes[1]) === null || _d === void 0 ? void 0 : _d.key.toUpperCase(), " GOALS"] }))] })) }))] })), _jsx(Row, Object.assign({ style: {
                    border: "1px solid #cccccc",
                    borderTopWidth: 0,
                    borderBottomLeftRadius: 3,
                    borderBottomRightRadius: 3
                } }, { children: data.map((event, index) => {
                    var _a, _b, _c;
                    return (_jsx(Col, Object.assign({ span: 24, style: { borderBottom: index < (data === null || data === void 0 ? void 0 : data.length) - 1 ? "1px solid #f2f2f2" : "0px solid #f2f2f2" } }, { children: _jsx("leetent-league-matches-bymarket", { "product-id": productId, sport: JSON.stringify({ id: sport.id, name: sport.label }), events: JSON.stringify([event]), country: JSON.stringify(event.country), league: JSON.stringify({ id: (_a = event === null || event === void 0 ? void 0 : event.league) === null || _a === void 0 ? void 0 : _a.id, name: (_b = event === null || event === void 0 ? void 0 : event.league) === null || _b === void 0 ? void 0 : _b.name }), "market-count": JSON.stringify(0), "market-view": "overUnder", "default-outcomes": JSON.stringify(defaultOutcomes) }) }), `league_${(_c = event === null || event === void 0 ? void 0 : event.league) === null || _c === void 0 ? void 0 : _c.id}_event_${event === null || event === void 0 ? void 0 : event.id}`));
                }) }))] }));
};
const OutcomesSingleArray = ({ productId, data, labelTop, onlyOutcomes, defaultOutcomes, seriesId, sport }) => {
    const screens = useBreakpoint();
    const [fullOutcomes, setFullOutcomes] = React.useState(false);
    const onShowMoreLessClick = () => { setFullOutcomes(fullOutcomes => !fullOutcomes); };
    return (_jsxs(_Fragment, { children: [data === null || data === void 0 ? void 0 : data.map((item, index) => {
                return (_jsx(Row, Object.assign({ justify: "space-between", align: "middle", className: styles.contentRow, style: { display: !fullOutcomes && index >= DEFAULT_VISIBLE_MARKET_ROWS ? "none" : "" }, gutter: [5, 8] }, { children: item === null || item === void 0 ? void 0 : item.outcomes.map((selection) => {
                        var _a;
                        return (_jsx(Col, Object.assign({ span: item.outcomes.length > 3 ? 8 : 24 / item.outcomes.length }, { children: _jsx("leetent-odds-singleevent-input", { id: `odds-singlevent-input-${selection.id}`, "product-id": productId, 
                                // view={mode === "single" || labelTop ? "top" : "default"}
                                view: (labelTop !== null && labelTop !== void 0 ? labelTop : (!labelTop && screens.xs)) ? "top" : "default", "only-outcomes": onlyOutcomes, "default-outcomes": JSON.stringify(defaultOutcomes), data: JSON.stringify({
                                    market: {
                                        id: item.marketId,
                                        name: item.name,
                                        status: (_a = item === null || item === void 0 ? void 0 : item.marketStatusId) !== null && _a !== void 0 ? _a : item === null || item === void 0 ? void 0 : item.statusId,
                                        type: null
                                    },
                                    match: {
                                        id: item === null || item === void 0 ? void 0 : item.id
                                    },
                                    series: {
                                        id: seriesId,
                                        name: item === null || item === void 0 ? void 0 : item.eventName,
                                        startDate: item === null || item === void 0 ? void 0 : item.startDate,
                                        leagueName: data === null || data === void 0 ? void 0 : data.leagueName,
                                        leagueId: data === null || data === void 0 ? void 0 : data.leagueId,
                                        sportName: data === null || data === void 0 ? void 0 : data.sportName
                                    },
                                    selection: {
                                        id: selection.id,
                                        label: selection.name,
                                        value: selection.odds,
                                        isOpen: selection.isOpen,
                                        statusId: selection.statusId,
                                        isBetBuilderEnabled: selection === null || selection === void 0 ? void 0 : selection.isBetBuilderEnabled
                                    }
                                }), sport: JSON.stringify(sport) }) }), `outcome-${selection.id}`));
                    }) }), `outcomes-row-${index}`));
            }), data.length > DEFAULT_VISIBLE_MARKET_ROWS && (_jsx(ShowMoreLess, { onClick: onShowMoreLessClick, fullOutcomes: fullOutcomes }))] }));
};
const OverUnder = ({ productId, mode, asItem, seriesId, defaultOutcomes, onlyOutcomes, labelTop, data, sport }) => {
    var _a, _b;
    const [refData, setRefData] = React.useState(data);
    // Keep actual data from props
    React.useEffect(() => {
        if ((data === null || data === void 0 ? void 0 : data.length) > 0) {
            setRefData(data);
        }
    }, [data]);
    // subscribes to multiple event ids
    const valueHandlerCallback = (data, prevData) => {
        var _a;
        const _data = data.sport_domain_market[0];
        const _prevData = (_a = prevData === null || prevData === void 0 ? void 0 : prevData.sport_domain_market) === null || _a === void 0 ? void 0 : _a[0];
        if ((_prevData === null || _prevData === void 0 ? void 0 : _prevData.market_status_id) !== (_data === null || _data === void 0 ? void 0 : _data.market_status_id)) {
            setRefData((oldArray) => oldArray.map((item) => {
                // updates the market statusId
                if (item.marketId === _data.id)
                    item.statusId = _data.market_status_id;
                return item;
            }));
        }
    };
    useSubscribeByQuery({
        query: SUBSCRIBE_EVENT_MARKETS,
        variables: {
            eventId: seriesId,
            marketIds: (_a = refData === null || refData === void 0 ? void 0 : refData.filter((item) => item === null || item === void 0 ? void 0 : item.id)) === null || _a === void 0 ? void 0 : _a.map((item) => item === null || item === void 0 ? void 0 : item.marketId)
        },
        valueHandlerCallback
    });
    const isArray = refData instanceof Array;
    const onClick = (event) => {
        const content = event.target.nextElementSibling;
        const isActive = event.target.classList.value.includes(styles.active);
        if (isActive === true) {
            event.target.classList.remove(styles.active);
            content.style.maxHeight = 0;
            content.style.padding = 0;
        }
        else {
            event.target.classList.add(styles.active);
            content.style.transition = "max-height 0.2s ease-out";
            content.style.maxHeight = String(Number(content.scrollHeight) + 8) + "px";
            content.style.padding = "4px";
        }
    };
    return ((seriesId && refData) ?
        (mode === "list" ?
            _jsx(OutcomesList, { onlyOutcomes: onlyOutcomes, data: refData, productId: productId, defaultOutcomes: defaultOutcomes, sport: sport }) :
            !asItem ?
                _jsxs("div", Object.assign({ className: styles.marketGroup }, { children: [_jsx("button", Object.assign({ className: clsx(styles.collapsible, styles.active), onClick: (e) => onClick(e) }, { children: isArray ? (_b = refData[0]) === null || _b === void 0 ? void 0 : _b.name : refData === null || refData === void 0 ? void 0 : refData.name })), _jsx("div", Object.assign({ className: styles.content }, { children: isArray ?
                                _jsx(OutcomesSingleArray, { productId: productId, data: refData, labelTop: labelTop, onlyOutcomes: onlyOutcomes, defaultOutcomes: defaultOutcomes, seriesId: seriesId, sport: sport }) :
                                _jsx(OutcomesSingleObject, { productId: productId, data: refData, mode: mode, onlyOutcomes: onlyOutcomes, defaultOutcomes: defaultOutcomes, seriesId: seriesId, sport: sport }) }))] }), `market-${refData === null || refData === void 0 ? void 0 : refData.marketTypeConfigId}`) :
                _jsx("div", Object.assign({ className: styles.content }, { children: isArray ?
                        _jsx(OutcomesSingleArray, { productId: productId, data: refData, labelTop: labelTop, onlyOutcomes: onlyOutcomes, defaultOutcomes: defaultOutcomes, seriesId: seriesId, sport: sport }) :
                        _jsx(OutcomesSingleObject, { productId: productId, data: refData, mode: mode, onlyOutcomes: onlyOutcomes, defaultOutcomes: defaultOutcomes, seriesId: seriesId, sport: sport }) }))) :
        _jsx(_Fragment, { children: "Loading..." }));
};
class Element extends HTMLElement {
    constructor() {
        super();
        this.root = createRoot(this);
        this.mode = "list";
        this["series-id"] = 0;
        this["default-outcomes"] = [];
        this.data = undefined;
    }
    static get observedAttributes() {
        return [
            "mode",
            "as-item",
            "series-id",
            "default-outcomes",
            "only-outcomes",
            "label-top",
            "data",
            "sport",
            "product-id"
        ];
    }
    connectedCallback() {
        var _a;
        (_a = this.root) === null || _a === void 0 ? void 0 : _a.render(_jsx(OverUnder, { mode: this.mode, productId: this["product-id"], asItem: this["as-item"], seriesId: this["series-id"], defaultOutcomes: this["default-outcomes"], onlyOutcomes: this["only-outcomes"], labelTop: this["label-top"], data: this.data, sport: this.sport }));
    }
    disconnectedCallback() {
        setTimeout(() => { var _a; return (_a = this.root) === null || _a === void 0 ? void 0 : _a.unmount(); }, 0);
    }
    attributeChangedCallback(attrName, _oldValue, newValue) {
        var _a;
        switch (attrName) {
            case "series-id":
            case "product-id":
                this[attrName] = Number(newValue);
                break;
            case "mode":
                this[attrName] = String(newValue);
                break;
            case "as-item":
            case "default-outcomes":
            case "only-outcomes":
            case "label-top":
            case "data":
            case "sport":
                this[attrName] = JSON.parse(newValue);
                break;
            default:
                break;
        }
        (_a = this.root) === null || _a === void 0 ? void 0 : _a.render(_jsx(OverUnder, { mode: this.mode, productId: this["product-id"], asItem: this["as-item"], seriesId: this["series-id"], defaultOutcomes: this["default-outcomes"], onlyOutcomes: this["only-outcomes"], labelTop: this["label-top"], data: this.data, sport: this.sport }));
    }
}
(_a = customElements.get("leetent-market-over-under")) !== null && _a !== void 0 ? _a : customElements.define("leetent-market-over-under", Element);
