import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
// components
import MountDocumentComponent from "components/documentation/MountDocumentComponent";
export const documentDetails = {
    title: "Unauthenticated user modal",
    component: "leetent-unauthenticated-user-modal",
    description: "This component displays a modal that contains buttons for logging in and registering.",
    attributes: [
        {
            key: "1",
            attr: "open",
            type: "boolean (optional)",
            notes: ["Define this property to control open state"]
        },
        {
            key: "2",
            attr: "modal-title",
            type: "string (optional)",
            notes: [
                "Custom unauthenticated modal title.",
                `Default value is: Please Proceed to Place a Bet`
            ]
        },
        {
            key: "3",
            attr: "destination",
            type: "string (optional)",
            notes: [
                "Captures the page the user wanted to access"
            ]
        },
        {
            key: "4",
            attr: "options",
            type: "object[] (optional)",
            notes: [
                "Optional attribute to override buttons options",
                `Default options: [
          {
            id: 1,
            label: "Login",
            url: "/sign-in"
          },
        
          {
            id: 2,
            label: "Register",
            url: "/sign-up"
          }
        ]`
            ]
        }
    ],
    overrideEvents: [
        {
            event: "click-auth-button",
            type: "emit",
            data: [
                "The component details object is emitted on click auth buttons in the modal.",
                `detail: {
          id: number
          label: string
          url: string
        }`
            ]
        },
        {
            event: "close-auth-modal",
            type: "emit",
            data: ["The component details object is emitted on closing the modal."]
        }
    ]
};
const UnauthenticatedUserModal = () => {
    const [open, setOpen] = React.useState(false);
    const [docDetails, setDocDetails] = React.useState(documentDetails);
    const [closeModal, setCloseModal] = React.useState();
    const [clickAuthButton, setClickAuthButton] = React.useState();
    React.useEffect(() => {
        const closeModalHandler = (e) => {
            if (!open)
                setOpen(false);
            setCloseModal(e.detail);
        };
        const clickAuthButtonHandler = (e) => {
            if (!open)
                setOpen(false);
            setClickAuthButton(e.detail);
        };
        window.addEventListener("close-auth-modal", closeModalHandler);
        window.addEventListener("click-auth-button", clickAuthButtonHandler);
        return () => {
            window.removeEventListener("close-auth-modal", closeModalHandler);
            window.removeEventListener("click-auth-button", clickAuthButtonHandler);
        };
    }, []);
    React.useEffect(() => {
        setDocDetails((docDetails) => (Object.assign(Object.assign({}, docDetails), { overrideEvents: [
                {
                    event: "click-auth-button",
                    type: "emit",
                    data: [
                        "The component details object is emitted on click auth buttons in the modal.",
                        `detail: {
              id: number
              label: string
              url: string
            }`
                    ],
                    detail: {
                        emitModel: clickAuthButton
                    }
                },
                {
                    event: "close-auth-modal",
                    type: "emit",
                    data: ["The component details object is emitted on closing the modal."],
                    detail: {
                        emitModel: closeModal
                    }
                }
            ], example: `leetent-unauthenticated-user-modal open={true}`, code: _jsxs(_Fragment, { children: [_jsx("button", Object.assign({ style: {
                            cursor: "pointer",
                            fontSize: "14px",
                            fontWeight: 600,
                            padding: "10px 20px",
                            borderRadius: "4px",
                            textTransform: "uppercase",
                            backgroundColor: "#f3f5f8",
                            border: "solid 1px #d6dbe3",
                            color: "#000"
                        }, onClick: () => { setOpen(true); } }, { children: "Open modal" })), _jsx("leetent-unauthenticated-user-modal", { open: open, destination: "/that-is-the-secret-of-success" })] }) })));
    }, [open, clickAuthButton, closeModal]);
    // render the page based on the provided object
    const ModalComponent = React.useCallback(() => {
        if (Object.keys(docDetails).length)
            return _jsx(MountDocumentComponent, { data: docDetails });
        return _jsx("div", { children: "Loading page..." });
    }, [docDetails]);
    return _jsx(ModalComponent, {});
};
export default UnauthenticatedUserModal;
