import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
// import clsx from "clsx";
import { Row, Col } from "antd";
import OutcomeGeneric from "v2/components/OutcomeGeneric/OutcomeGeneric";
import CardWrapper from "./CardWrapper";
const DoubleChance = ({ data, labelAttr = "name", inputView = "leftRight", handleOnClick = () => null, noWrapper }) => {
    let selectedOutcomes = []; // TODO: read selected options when mounting this component
    // listen for the changes to the children component, and emit its changes back to the parent component
    const updateSelectedOutcomes = React.useCallback((operation, outcome) => {
        if (operation === "select") {
            selectedOutcomes.push(outcome);
        }
        else {
            selectedOutcomes = [...selectedOutcomes.filter(selected => selected.id !== outcome.id)];
        }
        ;
        const updated = { operation, outcome };
        handleOnClick(selectedOutcomes, updated);
    }, []);
    const Outcomes = React.useCallback(() => {
        return (_jsx(Row, Object.assign({ gutter: [5, 5] }, { children: data.outcomes.map(outcome => {
                return (_jsx(Col, Object.assign({ span: 8 }, { children: _jsx(OutcomeGeneric, { view: inputView, handleOnClick: updateSelectedOutcomes, data: {
                            id: outcome.id,
                            name: outcome[labelAttr],
                            odds: outcome.odds,
                            isOpen: outcome.isOpen
                        } }, outcome.id) }), `doublechance-market-outcomes-col-${outcome.id}`));
            }) }), `doublechance-market-outcomes-row-${data.market.id}`));
    }, [data]);
    return (_jsx(_Fragment, { children: !noWrapper ?
            _jsx(CardWrapper, Object.assign({ data: data.market }, { children: _jsx(Outcomes, {}) })) :
            _jsx(Outcomes, {}) }));
};
export default DoubleChance;
