import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import dayjs from "dayjs";
import { dateFormat, monthYearFormat } from "./DatePickerRange";
import { Col, Row } from "antd";
import clsx from "clsx";
import styles from "./DatePickerRange.module.scss";
const yearMonthFormat = "YYYYMM";
const Calendar = ({ calendar, startDate, endDate, setStartDate, setEndDate, selectedMonth, setIsDatePickerOpen, exact = false }) => {
    const [hoverDate, setHoverDate] = React.useState();
    const rows = [];
    let columns = [];
    if (calendar) {
        let counter = 1;
        calendar.forEach((day, index) => {
            const currentDay = dayjs(day.date, dateFormat);
            let isActive = false;
            // if first date selected
            if (startDate && currentDay.isSame(startDate)) {
                isActive = true;
            }
            // if date between startDate and endDate
            if (startDate &&
                endDate &&
                Number(currentDay.format("YYYYMMDD")) >= Number(startDate.format("YYYYMMDD")) &&
                Number(currentDay.format("YYYYMMDD")) <= Number(endDate.format("YYYYMMDD"))) {
                isActive = true;
            }
            // if first date hovered
            if (!startDate && currentDay.isSame(hoverDate)) {
                isActive = true;
            }
            // if date between startDate and hoverDate
            if (startDate &&
                !endDate &&
                hoverDate &&
                Number(currentDay.format("YYYYMMDD")) >= Number(startDate.format("YYYYMMDD")) &&
                currentDay.isBefore(hoverDate.add(1, "day"))) {
                isActive = true;
            }
            columns.push(_jsx(Col, Object.assign({ "data-testid": `Calendar-${day.date}${isActive ? "-active" : ""}`, className: clsx(styles.day, { [styles.active]: isActive }), style: {
                    color: dayjs(selectedMonth, monthYearFormat).format(yearMonthFormat) !==
                        day.date.substring(0, 6) ?
                        "hsl(0, 0%, 75%)" :
                        undefined,
                    fontWeight: day.date === dayjs().format(dateFormat) ? "bold" : undefined
                }, onClick: () => {
                    if (!startDate || currentDay.isBefore(startDate) || endDate) {
                        setStartDate(exact ?
                            currentDay.hour(dayjs().hour()).minute(dayjs().minute()).second(dayjs().second()) :
                            currentDay);
                        endDate && setEndDate(null);
                        return;
                    }
                    setEndDate(exact ?
                        currentDay.hour(dayjs().hour()).minute(dayjs().minute()).second(dayjs().second()) :
                        currentDay);
                    setIsDatePickerOpen(oldValue => !oldValue);
                }, onMouseEnter: () => {
                    setHoverDate(currentDay);
                }, onMouseLeave: () => {
                    setHoverDate(undefined);
                } }, { children: day.label }), day.date));
            if (counter === 7) {
                rows.push(_jsx(Row, Object.assign({ justify: "space-around", align: "middle", className: styles.week, gutter: [10, 10] }, { children: columns }), `row-${index}`));
                columns = [];
            }
            counter === 7 ? (counter = 1) : counter++;
        });
    }
    return rows;
};
export default React.memo(Calendar);
