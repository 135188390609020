var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { createRoot } from "react-dom/client";
import { defaultRangeFiltersSelectedOption } from "helpers/DayjsFunctions";
const calendarOptions = [
    {
        label: "Last 24 hours",
        value: "1day"
    },
    {
        label: "Last 48 hours",
        value: "2days"
    },
    {
        label: "Last 7 days",
        value: "7days"
    },
    {
        label: "Last 30 days",
        value: "30days"
    },
    {
        label: "Last 60 days",
        value: "60days"
    },
    {
        label: "Last 90 days",
        value: "90days"
    },
    {
        label: "All",
        value: "all"
    }
];
export const RangeFilters = ({ startDateRange, endDateRange, exact }) => {
    const [dropdownValue, setDropdownValue] = React.useState(defaultRangeFiltersSelectedOption);
    React.useEffect(() => {
        const rangeFiltersSelectedOptionHandler = (e) => {
            setDropdownValue(e.detail.value);
        };
        window.addEventListener("range-filters-selected-option", rangeFiltersSelectedOptionHandler);
        return () => {
            window.removeEventListener("range-filters-selected-option", rangeFiltersSelectedOptionHandler);
        };
    }, []);
    return (_jsx("leetent-dropdown-generic", { name: "range-filters", options: JSON.stringify(calendarOptions), selected: dropdownValue }));
};
class Element extends HTMLElement {
    static get observedAttributes() {
        return ["start-date", "end-date", "exact"];
    }
    connectedCallback() {
        createRoot(this).render(_jsx(RangeFilters, { startDateRange: this["start-date"], endDateRange: this["end-date"], exact: this.exact }));
    }
    attributeChangedCallback(attrName, _oldValue, newValue) {
        switch (attrName) {
            case "exact":
                this[attrName] = JSON.parse(newValue);
                break;
            case "start-date":
            case "end-date":
                this[attrName] = newValue;
                break;
            default:
                break;
        }
    }
}
(_a = customElements.get("leetent-range-filters")) !== null && _a !== void 0 ? _a : customElements.define("leetent-range-filters", Element);
