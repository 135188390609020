var _a;
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { createRoot } from "react-dom/client";
import { Row, Col } from "antd";
import clsx from "clsx";
import { SUBSCRIBE_EVENT_MARKETS } from "v2/graphql/subscriptions";
import { useSubscribeByQuery } from "helpers/graphql/useSubscribeByQuery";
import { DEFAULT_VISIBLE_MARKET_ROWS } from "config/constants";
import { ShowMoreLess } from "components/base/markets/ShowMoreLess";
// styles
import styles from "./Markets.module.scss";
const ColOutcome = ({ productId, selection, defaultOutcomes, data, seriesId, sport, isOutrights }) => {
    return _jsx("div", Object.assign({ className: styles.oddsInput }, { children: _jsx("leetent-odds-singleevent-input", { id: `odds-singlevent-input-${selection.id}`, "product-id": productId, "default-outcomes": JSON.stringify(defaultOutcomes), data: JSON.stringify({
                market: {
                    id: data.marketId,
                    name: data.name,
                    statusId: data.statusId,
                    type: null
                },
                series: {
                    id: seriesId,
                    name: data === null || data === void 0 ? void 0 : data.eventName,
                    startDate: data === null || data === void 0 ? void 0 : data.startDate,
                    leagueName: data === null || data === void 0 ? void 0 : data.leagueName,
                    leagueId: data === null || data === void 0 ? void 0 : data.leagueId,
                    sportName: data === null || data === void 0 ? void 0 : data.sportName
                },
                selection: {
                    id: selection.id,
                    name: selection.name,
                    value: selection.odds,
                    isOpen: selection.isOpen,
                    statusId: selection.statusId,
                    isBetBuilderEnabled: selection === null || selection === void 0 ? void 0 : selection.isBetBuilderEnabled,
                    isOutrights
                }
            }), sport: JSON.stringify(sport) }) }));
};
const Outcomes = ({ productId, data, defaultOutcomes, seriesId, sport, isOutrights }) => {
    var _a, _b;
    const [fullOutcomes, setFullOutcomes] = React.useState(false);
    const onShowMoreLessClick = () => { setFullOutcomes(fullOutcomes => !fullOutcomes); };
    return (_jsxs(_Fragment, { children: [data === null || data === void 0 ? void 0 : data.map((market) => {
                return market === null || market === void 0 ? void 0 : market.outcomes.map((outcome, index) => {
                    const isLastItem = index === market.outcomes.length - 1;
                    return _jsxs(Row, Object.assign({ justify: "space-between", className: styles.contentRow, style: {
                            display: !fullOutcomes && index >= DEFAULT_VISIBLE_MARKET_ROWS ? "none" : "",
                            borderTop: `1px solid ${index === 0 ? "#EEF1F6" : "rgb(245, 245, 245)"}`,
                            borderBottom: (fullOutcomes && isLastItem) ||
                                (!fullOutcomes && (index + 1) === DEFAULT_VISIBLE_MARKET_ROWS) ?
                                "1px solid rgb(245, 245, 245)" :
                                "",
                            padding: "5px 14px"
                        }, gutter: [5, 0] }, { children: [_jsx(Col, Object.assign({ span: 16 }, { children: _jsx("div", Object.assign({ className: styles.outcomeLabel }, { children: outcome.name || outcome.key })) })), _jsx(Col, Object.assign({ span: 8 }, { children: _jsx(ColOutcome, { productId: productId, selection: outcome, defaultOutcomes: defaultOutcomes, data: market, seriesId: seriesId, sport: sport, isOutrights: isOutrights }) }))] }), `market-${market === null || market === void 0 ? void 0 : market.id}-outcome-${outcome.id}`);
                });
            }), ((_b = (_a = data === null || data === void 0 ? void 0 : data[0]) === null || _a === void 0 ? void 0 : _a.outcomes) === null || _b === void 0 ? void 0 : _b.length) > DEFAULT_VISIBLE_MARKET_ROWS && (_jsx(ShowMoreLess, { onClick: onShowMoreLessClick, fullOutcomes: fullOutcomes, style: { margin: "10px 15px", width: "calc(100% - 30px)", fontSize: "12px" } }))] }));
};
const Nx1 = ({ productId, mode, asItem, seriesId, homeTeam, awayTeam, defaultOutcomes, data, sport, isOutrights }) => {
    var _a;
    const [refData, setRefData] = React.useState(data);
    // Keep actual data from props
    React.useEffect(() => {
        if ((data === null || data === void 0 ? void 0 : data.length) > 0) {
            setRefData(data);
        }
    }, [data]);
    // subscribes to multiple event ids
    const valueHandlerCallback = (data, prevData) => {
        var _a;
        const _data = data.sport_domain_market[0];
        const _prevData = (_a = prevData === null || prevData === void 0 ? void 0 : prevData.sport_domain_market) === null || _a === void 0 ? void 0 : _a[0];
        if ((_prevData === null || _prevData === void 0 ? void 0 : _prevData.market_status_id) !== (_data === null || _data === void 0 ? void 0 : _data.market_status_id)) {
            setRefData((oldArray) => oldArray.map((item) => {
                // updates the market statusId
                if (item.marketId === _data.id)
                    item.statusId = _data.market_status_id;
                return item;
            }));
        }
    };
    useSubscribeByQuery({
        query: SUBSCRIBE_EVENT_MARKETS,
        variables: {
            eventId: seriesId,
            marketIds: (_a = refData === null || refData === void 0 ? void 0 : refData.filter((item) => item === null || item === void 0 ? void 0 : item.id)) === null || _a === void 0 ? void 0 : _a.map((item) => item === null || item === void 0 ? void 0 : item.marketId)
        },
        valueHandlerCallback
    });
    const onClick = (event) => {
        const content = event.target.nextElementSibling;
        const isActive = event.target.classList.value.includes(styles.active);
        if (isActive) {
            event.target.classList.remove(styles.active);
            content.style.maxHeight = 0;
            content.style.padding = 0;
        }
        else {
            event.target.classList.add(styles.active);
            content.style.transition = "max-height 0.2s ease-out";
            content.style.maxHeight = String(Number(content.scrollHeight) + 8) + "px";
            content.style.padding = "4px";
        }
    };
    if (!seriesId || !refData) {
        return _jsx(_Fragment, { children: "Loading..." });
    }
    return asItem ?
        _jsx("div", Object.assign({ className: styles.content }, { children: _jsx(Outcomes, { productId: productId, data: refData, defaultOutcomes: defaultOutcomes, seriesId: seriesId, homeTeam: homeTeam, awayTeam: awayTeam, sport: sport, isOutrights: isOutrights }) })) :
        _jsxs("div", Object.assign({ className: styles.marketGroup }, { children: [_jsx("button", Object.assign({ className: clsx(styles.collapsible, styles.active), onClick: (e) => onClick(e) }, { children: refData === null || refData === void 0 ? void 0 : refData.name })), _jsx("div", Object.assign({ className: styles.content }, { children: _jsx(Outcomes, { productId: productId, data: refData, defaultOutcomes: defaultOutcomes, seriesId: seriesId, homeTeam: homeTeam, awayTeam: awayTeam, sport: sport }) }))] }), `market-${refData === null || refData === void 0 ? void 0 : refData.marketTypeConfigId}`);
};
class Element extends HTMLElement {
    constructor() {
        super();
        this.root = createRoot(this);
        this.mode = "list";
        this["series-id"] = 0;
        this["default-outcomes"] = [];
        this.data = undefined;
    }
    static get observedAttributes() {
        return [
            "mode",
            "as-item",
            "series-id",
            "default-outcomes",
            "data",
            "home-team",
            "away-team",
            "product-id",
            "outrights",
            "sport"
        ];
    }
    connectedCallback() {
        var _a;
        (_a = this.root) === null || _a === void 0 ? void 0 : _a.render(_jsx(Nx1, { mode: this.mode, productId: this["product-id"], asItem: this["as-item"], seriesId: this["series-id"], homeTeam: this["home-team"], awayTeam: this["away-team"], defaultOutcomes: this["default-outcomes"], data: this.data, sport: this.sport, isOutrights: this.outrights }));
    }
    disconnectedCallback() {
        setTimeout(() => { var _a; return (_a = this.root) === null || _a === void 0 ? void 0 : _a.unmount(); }, 0);
    }
    attributeChangedCallback(attrName, _oldValue, newValue) {
        var _a;
        switch (attrName) {
            case "series-id":
            case "product-id":
                this[attrName] = Number(newValue);
                break;
            case "mode":
            case "home-team":
            case "away-team":
                this[attrName] = newValue;
                break;
            case "as-item":
            case "default-outcomes":
            case "data":
            case "sport":
            case "outrights":
                this[attrName] = JSON.parse(newValue);
                break;
            default:
                break;
        }
        (_a = this.root) === null || _a === void 0 ? void 0 : _a.render(_jsx(Nx1, { mode: this.mode, productId: this["product-id"], asItem: this["as-item"], seriesId: this["series-id"], homeTeam: this["home-team"], awayTeam: this["away-team"], defaultOutcomes: this["default-outcomes"], data: this.data, sport: this.sport, isOutrights: this.outrights }));
    }
}
(_a = customElements.get("leetent-market-n1")) !== null && _a !== void 0 ? _a : customElements.define("leetent-market-n1", Element);
