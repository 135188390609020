import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
// import clsx from "clsx";
// import getSession from "config/diffusionClient";
import { Row, Col } from "antd";
import OutcomeGeneric from "v2/components/OutcomeGeneric/OutcomeGeneric";
import CardWrapper from "./CardWrapper";
const CorrectScore = ({ data, labelAttr = "name", inputView = "leftRight", handleOnClick = () => null, noWrapper }) => {
    let selectedOutcomes = []; // TODO: read selected options when mounting this component
    // TODO: read selected options when mounting this component
    // const [selectedOutcomes, setSelectedOutcomes] = React.useState<OutcomeOrSelection[]>([]);
    const [outcomesHeader, setOutcomesHeader] = React.useState([]);
    // processes the data and classify the outcomes based on the keys (scores in this case)
    const [componentData, setComponentData] = React.useState(data);
    const [homeOutcomes, setHomeOutcomes] = React.useState([]);
    const [awayOutcomes, setAwayOutcomes] = React.useState([]);
    const [drawOutcomes, setDrawOutcomes] = React.useState([]);
    React.useEffect(() => {
        setComponentData(data);
    }, [data]);
    React.useEffect(() => {
        setHomeOutcomes(componentData.outcomes.filter(outcome => {
            var _a;
            const keys = (_a = outcome === null || outcome === void 0 ? void 0 : outcome.key) === null || _a === void 0 ? void 0 : _a.split("-");
            if ((keys === null || keys === void 0 ? void 0 : keys.length) === 2 && keys[0] > keys[1])
                return true;
            return false;
        }));
        setAwayOutcomes(componentData.outcomes.filter(outcome => {
            var _a;
            const keys = (_a = outcome === null || outcome === void 0 ? void 0 : outcome.key) === null || _a === void 0 ? void 0 : _a.split("-");
            if ((keys === null || keys === void 0 ? void 0 : keys.length) === 2 && keys[0] < keys[1])
                return true;
            return false;
        }));
        setDrawOutcomes(componentData.outcomes.filter(outcome => {
            var _a;
            const keys = (_a = outcome === null || outcome === void 0 ? void 0 : outcome.key) === null || _a === void 0 ? void 0 : _a.split("-");
            if ((keys === null || keys === void 0 ? void 0 : keys.length) === 2 && keys[0] === keys[1])
                return true;
            return false;
        }));
        if (componentData.event.homeTeam && componentData.event.awayTeam) {
            setOutcomesHeader([componentData.event.homeTeam, "Draw", componentData.event.awayTeam]);
        }
    }, [componentData]);
    //
    // const subscribeToMarketOutcomes = (): void => {
    //   const session = getSession();
    //   console.log(session);
    // };
    // listen for the changes to the children component, and emit its changes back to the parent component
    const updateSelectedOutcomes = React.useCallback((operation, outcome) => {
        if (operation === "select") {
            selectedOutcomes.push(outcome);
        }
        else {
            selectedOutcomes = [...selectedOutcomes.filter(selected => selected.id !== outcome.id)];
        }
        ;
        const updated = { operation, outcome };
        handleOnClick(selectedOutcomes, updated);
    }, []);
    const Outcomes = React.useCallback(() => {
        if (!componentData)
            return _jsx(_Fragment, {});
        return (_jsxs(Row, Object.assign({ gutter: [5, 5] }, { children: [(outcomesHeader === null || outcomesHeader === void 0 ? void 0 : outcomesHeader.length) > 0 && (outcomesHeader.map((item, index) => {
                    return (_jsx(Col, Object.assign({ span: 8, style: {
                            fontSize: 10,
                            textAlign: "center",
                            padding: "8px 0px",
                            borderTop: "1px solid #f2f2f2",
                            borderBottom: "1px solid #f2f2f2",
                            marginBottom: 2,
                            textTransform: "uppercase"
                        } }, { children: item }), `correctscore-market-outcomes-col-header-${index}`));
                })), _jsx(Col, Object.assign({ span: 8 }, { children: _jsx(Row, Object.assign({ gutter: [5, 5] }, { children: homeOutcomes.map(outcome => {
                            return (_jsx(Col, Object.assign({ span: 24 }, { children: _jsx(OutcomeGeneric, { view: inputView, handleOnClick: updateSelectedOutcomes, data: {
                                        id: outcome.id,
                                        name: outcome[labelAttr],
                                        odds: outcome.odds,
                                        isOpen: outcome.isOpen
                                    } }, outcome.id) }), `correctscore-market-outcomes-col-${outcome.id}`));
                        }) })) }), `correctscore-market-outcomes-col-home`), _jsx(Col, Object.assign({ span: 8 }, { children: _jsx(Row, Object.assign({ gutter: [5, 5] }, { children: drawOutcomes.map(outcome => {
                            return (_jsx(Col, Object.assign({ span: 24 }, { children: _jsx(OutcomeGeneric, { view: inputView, handleOnClick: updateSelectedOutcomes, data: {
                                        id: outcome.id,
                                        name: outcome[labelAttr],
                                        odds: outcome.odds,
                                        isOpen: outcome.isOpen
                                    } }, outcome.id) }), `correctscore-market-outcomes-col-${outcome.id}`));
                        }) })) }), `correctscore-market-outcomes-col-draw`), _jsx(Col, Object.assign({ span: 8 }, { children: _jsx(Row, Object.assign({ gutter: [5, 5] }, { children: awayOutcomes.map(outcome => {
                            return (_jsx(Col, Object.assign({ span: 24 }, { children: _jsx(OutcomeGeneric, { view: inputView, handleOnClick: updateSelectedOutcomes, data: {
                                        id: outcome.id,
                                        name: outcome[labelAttr],
                                        odds: outcome.odds,
                                        isOpen: outcome.isOpen
                                    } }, outcome.id) }), `correctscore-market-outcomes-col-${outcome.id}`));
                        }) })) }), `correctscore-market-outcomes-col-away`)] }), `correctscore-market-outcomes-row-${componentData.market.id}`));
    }, [componentData, outcomesHeader]);
    // React.useEffect(() => {
    //   subscribeToMarketOutcomes();
    // }, []);
    return (_jsx(_Fragment, { children: !noWrapper ?
            _jsx(CardWrapper, Object.assign({ data: componentData === null || componentData === void 0 ? void 0 : componentData.market }, { children: _jsx(Outcomes, {}) })) :
            _jsx(Outcomes, {}) }));
};
export default CorrectScore;
