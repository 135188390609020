var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import axios from "axios";
// components
import MountDocumentComponent from "components/documentation/MountDocumentComponent";
export const documentDetails = {
    title: "Country leagues dropdown",
    component: "leetent-country-leagues-dropdown",
    description: `Component that takes countries and leagues list, and presents two dropdown generics,
    that are synchronized and ready for interaction.`,
    attributes: [
        {
            key: "1",
            attr: "all-option",
            type: "boolean",
            notes: `Currently not in use? It might be required at some point to override internal dropdowns prop.`
        },
        {
            key: "2",
            attr: "countries",
            type: "object[]",
            notes: "Data passed into the primary leetent-dropdown-generic component."
        },
        {
            key: "3",
            attr: "leagues",
            type: "object[]",
            notes: `Data passed into the SecondaryComponent (leetent-dropdown-generic)
        in order to present the leagues list.`
        }
    ],
    events: [
        {
            event: "countries-selected-option",
            type: "listener",
            data: [
                "Listens for changes on the primary component, and then filters the secondary one accordingly."
            ]
        },
        {
            event: "leagues-selected-option",
            type: "listener",
            data: [
                "Listens for changes on the secondary component (emitted data)"
            ]
        }
    ]
};
const CountryLeaguesDropdown = () => {
    const [isLoading, setIsLoading] = React.useState(true);
    // const [error, setError] = React.useState<any>();
    const [docDetails, setDocDetails] = React.useState(documentDetails);
    const [categories, setCategories] = React.useState([]);
    const [leagues, setLeagues] = React.useState([]);
    // fetches league details
    const fetchLeagues = React.useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        !isLoading && setIsLoading(true);
        if (((_a = process.env) === null || _a === void 0 ? void 0 : _a.REACT_APP_GATEWAY_API_URL) && ((_b = process.env) === null || _b === void 0 ? void 0 : _b.REACT_APP_SPORTSBOOK_API_KEY)) {
            yield axios.get("/tournament/events?includeMarketData=false&skip=0&take=500").then((res) => {
                // setError(undefined);
                setLeagues(res.data);
                const processingLeagueCategories = [];
                const processingGroupedLeagues = [];
                res.data.forEach((league) => {
                    const isCategoryAdded = processingLeagueCategories.find(category => category.id === league.category.id);
                    if (!isCategoryAdded) {
                        processingLeagueCategories.push(league.category);
                        processingGroupedLeagues.push({
                            categoryId: league.category.id,
                            category: league.category.name,
                            leagues: [{
                                    id: league.id,
                                    name: league.name,
                                    country: league.category
                                }]
                        });
                    }
                    else {
                        processingGroupedLeagues.find(category => category.categoryId === league.category.id).leagues.push({
                            id: league.id,
                            name: league.name,
                            country: league.category
                        });
                    }
                });
                setCategories(processingLeagueCategories);
            }).catch((e) => {
                // setError(e);
                console.log(e);
            }).finally(() => {
                setIsLoading(false);
            });
        }
        else {
            setIsLoading(false);
            // setError("Environment variables are not in place");
            console.log("Environment variables are not in place");
        }
        ;
    }), [isLoading]);
    React.useEffect(() => {
        fetchLeagues();
    }, []);
    React.useEffect(() => {
        setDocDetails((docDetails) => (Object.assign(Object.assign({}, docDetails), { example: `<leetent-country-leagues-dropdown
        id="categories_leagues"
        all-option
        countries={[objects]}
        leagues={[objects]}
      />`, code: _jsx("leetent-country-leagues-dropdown", { id: "categories_leagues", "all-option": true, countries: categories ? JSON.stringify(categories) : [], leagues: leagues ? JSON.stringify(leagues) : [] }) })));
    }, [categories, leagues]);
    // render the page based on the provided object
    const CountryLeaguesDropdownComponent = React.useCallback(() => {
        if (Object.keys(docDetails).length)
            return _jsx(MountDocumentComponent, { data: docDetails });
        return _jsx("div", { children: "Loading page..." });
    }, [docDetails]);
    return _jsx(CountryLeaguesDropdownComponent, {});
};
export default CountryLeaguesDropdown;
