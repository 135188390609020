import { jsx as _jsx } from "react/jsx-runtime";
// components
import MountDocumentComponent from "components/documentation/MountDocumentComponent";
const eSportsIcons = [
    {
        id: -1,
        label: "ALL",
        src: "https://d1bu7ybn6mtlcz.cloudfront.net/images/rabbit.png",
        alt: "Settings"
    },
    {
        id: 1,
        label: "ESOCCER",
        src: "https://img.abiosgaming.com/games/esoccer-square.png"
    },
    {
        id: 2,
        label: "LOL",
        src: "https://img.abiosgaming.com/games/lol-square-logo.png"
    },
    {
        id: 3,
        label: "CS:GO",
        src: "https://img.abiosgaming.com/games/cs-square-logo.png"
    },
    {
        id: 4,
        label: "DOTA 2",
        src: "https://img.abiosgaming.com/games/dota-square-logo.png"
    },
    {
        id: 5,
        label: "VALORANT",
        src: "https://img.abiosgaming.com/games/valorant-square-logo.png"
    }
];
export const documentDetails = {
    title: "Icons tab header",
    component: "leetent-icons-tab-header",
    description: "This is a base component, that is used as part of many composed components.",
    withDarkMode: true,
    attributes: [
        {
            key: "1",
            attr: "icons",
            type: "object[]",
            notes: [
                "Icons objects array expects the following information:",
                `[
          {
            id: number,
            label: string,
            src: string
          }
        ]`
            ]
        }
    ],
    events: [
        {
            event: "selected-icon-tab-details",
            type: "emit",
            data: [
                "The component details object is emitted on change selected icon.",
                `detail: {
          id: <value>,
          label: <value>,
       }`
            ]
        }
    ]
};
const IconsTabHeader = () => {
    documentDetails.example = `<leetent-icons-tab-header
      icons={JSON.stringify([
        {
          id: 1,
          label: "SOCCER",
          src: SoccerIcon,
          alt: "img"
        }
      ])}
    />`;
    documentDetails.tabs = [
        {
            key: "1",
            label: "Sports",
            children: _jsx("leetent-icons-tab-header", { icons: JSON.stringify([
                    {
                        id: 1,
                        label: "SOCCER",
                        src: "https://d1bu7ybn6mtlcz.cloudfront.net/images/soccer.png",
                        alt: "Settings"
                    }
                ]) })
        },
        {
            key: "2",
            label: "eSports",
            children: _jsx("leetent-icons-tab-header", { icons: JSON.stringify(eSportsIcons) })
        }
    ];
    // render the page based on the provided object
    return _jsx(MountDocumentComponent, { data: documentDetails });
};
export default IconsTabHeader;
