// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".T2PQoBG_AAjoeLkXaR13{min-height:100dvh;z-index:1;padding-bottom:70px}.T2PQoBG_AAjoeLkXaR13.eC7tO893YNq9SnLnrIVR{position:fixed !important;height:100%;overflow-y:scroll;z-index:1000}.Rb4D4LrtAIsCzGaK8h7V{height:70px}.eCwB_qygYohpQBn3mYX0{padding:3px 3%;height:70px;border-bottom:1px solid #f2f2f2}.sqNLt9PTJCFMPhhJlVmB{background-color:#fff;max-width:100dvw}.ugNygp8IY83GMwnHylst{position:absolute;background-color:#001529;min-width:100%;min-height:100%;opacity:.85}", "",{"version":3,"sources":["webpack://./src/layouts/Playground.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,SAAA,CACA,mBAAA,CAEA,2CACE,yBAAA,CACA,WAAA,CACA,iBAAA,CACA,YAAA,CAIJ,sBACE,WAAA,CAGF,sBACE,cAAA,CACA,WAAA,CACA,+BAAA,CAGF,sBACE,qBAAA,CACA,gBAAA,CAGF,sBACE,iBAAA,CACA,wBAAA,CACA,cAAA,CACA,eAAA,CACA,WAAA","sourcesContent":[".sider {\n  min-height: 100dvh;\n  z-index: 1;\n  padding-bottom: 70px;\n\n  &.mobile{\n    position: fixed !important;\n    height: 100%;\n    overflow-y: scroll;\n    z-index: 1000;\n  }\n}\n\n.siderLogo {\n  height: 70px;\n}\n\n.header {\n  padding: 3px 3%;\n  height: 70px;\n  border-bottom: 1px solid #f2f2f2;\n}\n\n.content {\n  background-color: #fff;\n  max-width: 100dvw;\n}\n\n.environmentBackground {\n  position: absolute;\n  background-color: rgb(0, 21, 41);\n  min-width: 100%;\n  min-height: 100%;\n  opacity: 0.85\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sider": "T2PQoBG_AAjoeLkXaR13",
	"mobile": "eC7tO893YNq9SnLnrIVR",
	"siderLogo": "Rb4D4LrtAIsCzGaK8h7V",
	"header": "eCwB_qygYohpQBn3mYX0",
	"content": "sqNLt9PTJCFMPhhJlVmB",
	"environmentBackground": "ugNygp8IY83GMwnHylst"
};
export default ___CSS_LOADER_EXPORT___;
