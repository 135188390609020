import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Accordion } from "components/base/Accordion/Accordion";
import dayjs from "dayjs";
import styles from "./Outrights.module.scss";
export const Outrights = ({ data, productId, defaultOutcomes, sport }) => {
    var _a;
    const tournament = data === null || data === void 0 ? void 0 : data[0];
    const getAccordionHeader = ({ title, startDate }) => {
        const AccordionHeader = () => {
            var _a, _b;
            return _jsxs("div", Object.assign({ className: styles.header }, { children: [_jsx("div", Object.assign({ className: styles.imgWrapper }, { children: _jsx("img", { src: (_a = tournament === null || tournament === void 0 ? void 0 : tournament.category) === null || _a === void 0 ? void 0 : _a.imageUrl, alt: (_b = tournament === null || tournament === void 0 ? void 0 : tournament.category) === null || _b === void 0 ? void 0 : _b.name, style: { borderRadius: 2, width: 22 } }) })), _jsxs("div", Object.assign({ className: styles.info }, { children: [_jsx("div", Object.assign({ className: styles.title }, { children: title })), _jsx("div", Object.assign({ className: styles.startDate }, { children: (dayjs(startDate).format("DD MMMM | HH:mm")).toUpperCase() }))] }))] }));
        };
        return AccordionHeader;
    };
    if (!((_a = tournament === null || tournament === void 0 ? void 0 : tournament.events) === null || _a === void 0 ? void 0 : _a.length)) {
        return _jsx("leetent-nodata-for-market", { src: "https://d1bu7ybn6mtlcz.cloudfront.net/data_alert.png" });
    }
    return tournament.events.map((event) => {
        return event.markets.map((market) => {
            var _a, _b, _c, _d;
            const title = _jsxs(_Fragment, { children: [_jsx("strong", { children: market.name }), " - ", event.name] });
            const AccordionHeader = getAccordionHeader({ title, startDate: event.startDate });
            const formattedData = {
                displayRule: market === null || market === void 0 ? void 0 : market.displayRule,
                id: event.id,
                marketId: market.id,
                marketTypeConfig: market.marketTypeConfigId,
                isBetBuilderEnabled: market === null || market === void 0 ? void 0 : market.isBetBuilderEnabled,
                name: market.name,
                line: (_a = market === null || market === void 0 ? void 0 : market.line) !== null && _a !== void 0 ? _a : "",
                outcomes: market === null || market === void 0 ? void 0 : market.outcomes,
                eventName: event.name,
                startDate: event.startDate,
                leagueName: tournament === null || tournament === void 0 ? void 0 : tournament.name,
                leagueId: tournament === null || tournament === void 0 ? void 0 : tournament.id,
                sportName: (_d = (_c = (_b = event === null || event === void 0 ? void 0 : event.tournament) === null || _b === void 0 ? void 0 : _b.category) === null || _c === void 0 ? void 0 : _c.sport) === null || _d === void 0 ? void 0 : _d.name,
                playerName: market === null || market === void 0 ? void 0 : market.playerName
            };
            return _jsx(Accordion, Object.assign({ Header: AccordionHeader, classes: { root: styles.root, dropdownIcon: styles.dropdownIcon } }, { children: _jsx("leetent-market-n1", { "product-id": productId, mode: "single", "as-item": true, "series-id": event.id, "default-outcomes": JSON.stringify(defaultOutcomes), data: JSON.stringify([formattedData]), sport: JSON.stringify(sport), outrights: true }) }), `${event.id}-${market.id}`);
        });
    });
};
