var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import clsx from "clsx";
import { v4 as uuidv4 } from "uuid";
import { createRoot } from "react-dom/client";
import ImageFromCDN from "components/base/imageFromCDN/ImageFromCDN";
import { STAKE_SELECTIONS_TABS } from "enums";
// styles
import styles from "./TabsHeader.module.scss";
import flexStyle from "assets/styles/flex.module.scss";
const uuid = uuidv4();
const TabsHeader = ({ tabs, selected, eventName, classes }) => {
    const [selectedOption, setSelectedOption] = React.useState(selected);
    React.useEffect(() => {
        setSelectedOption(selected);
    }, [selected]);
    React.useEffect(() => {
        // if there are no selected tabs, then read it from the first item from the tabs array
        if (tabs.length > 0 && !selectedOption)
            setSelectedOption(tabs[0].value);
    }, [selectedOption, tabs]);
    const emitSelectedTabDetails = React.useCallback((newValue) => {
        var _a;
        if (selectedOption && newValue !== selectedOption) {
            const triggerSelectedTabDetails = new CustomEvent(eventName !== null && eventName !== void 0 ? eventName : "selected-tab", {
                detail: {
                    name: (_a = tabs === null || tabs === void 0 ? void 0 : tabs.filter((tab) => tab.value === newValue)[0]) === null || _a === void 0 ? void 0 : _a.name,
                    value: newValue
                },
                bubbles: true
            });
            window.dispatchEvent(triggerSelectedTabDetails);
        }
    }, [selectedOption, tabs, eventName]);
    return (_jsx("div", Object.assign({ className: clsx(flexStyle.flex, flexStyle.alignCenter, styles.tab, classes === null || classes === void 0 ? void 0 : classes.root) }, { children: _jsx("ul", Object.assign({ className: clsx(flexStyle.flex, styles.tab__inner) }, { children: tabs.map((tab, index) => {
                var _a;
                return (_jsxs("li", Object.assign({ className: clsx(classes === null || classes === void 0 ? void 0 : classes.tab, {
                        [styles.active]: tab.value === selectedOption,
                        [styles.disabled]: tab.disabled
                    }), onClick: () => !tab.disabled && emitSelectedTabDetails(tab.value), "data-testid": tab.value }, { children: [(tab === null || tab === void 0 ? void 0 : tab.isBB) && (tab === null || tab === void 0 ? void 0 : tab.name) === STAKE_SELECTIONS_TABS.BET_BUILDER &&
                            _jsx(ImageFromCDN, { alt: "Bet Builder", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/bet-builder.svg", overrideStyles: styles.betBuilder }), tab.name] }), `li-${(_a = tab.value) !== null && _a !== void 0 ? _a : index}-${uuidv4()}`));
            }) }), `tab-header-${uuid}`) })));
};
class Element extends HTMLElement {
    constructor() {
        super();
        this.tabs = [];
        this.root = createRoot(this);
    }
    static get observedAttributes() {
        return ["tabs", "selected", "event-name", "classes"];
    }
    renderComponent() {
        var _a;
        (_a = this.root) === null || _a === void 0 ? void 0 : _a.render(_jsx(TabsHeader, { tabs: this.tabs, selected: this.selected, eventName: this["event-name"], classes: this.classes }));
    }
    connectedCallback() {
        this.renderComponent();
    }
    disconnectedCallback() {
        setTimeout(() => { var _a; return (_a = this.root) === null || _a === void 0 ? void 0 : _a.unmount(); }, 0);
    }
    attributeChangedCallback(attrName, oldValue, newValue) {
        switch (attrName) {
            case "tabs":
            case "classes":
                this[attrName] = JSON.parse(newValue);
                break;
            case "selected":
            case "event-name":
                this[attrName] = String(newValue);
                break;
            default:
                break;
        }
        this.renderComponent();
    }
}
(_a = customElements.get("leetent-tabs-header")) !== null && _a !== void 0 ? _a : customElements.define("leetent-tabs-header", Element);
