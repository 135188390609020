import { jsx as _jsx } from "react/jsx-runtime";
import { HandicapData as data } from "./assets/data";
// components
import MountDocumentComponent from "components/documentation/MountDocumentComponent";
// document details of inner components
import { documentDetails as singleEventInputDocumentDetails } from "pages/documentation/components/OddsSingleEventInput";
export const documentDetails = {
    title: "TwoWayHandicap (Display Rule)",
    component: "leetent-market-two-way-handicap",
    description: "This is a market component, that is used as part of many composed components.",
    withDarkMode: false,
    innerComponents: [
        {
            name: "leetent-odds-singleevent-input",
            documentDetails: singleEventInputDocumentDetails
        }
    ]
};
const TwoWayHandicap = () => {
    documentDetails.example = `<leetent-market-two-way-handicap />`;
    documentDetails.code = _jsx("leetent-market-two-way-handicap", { "series-id": 123, data: JSON.stringify(data) });
    // render the page based on the provided object
    return _jsx(MountDocumentComponent, { data: documentDetails });
};
export default TwoWayHandicap;
