import { jsx as _jsx } from "react/jsx-runtime";
// components
import MountDocumentComponent from "components/documentation/MountDocumentComponent";
const ctaObj = {
    label: "Go To Sportsbook",
    url: "/"
};
export const documentDetails = {
    title: "No data for market",
    component: "leetent-nodata-for-market",
    description: "This component is used to present a no data information related to the market.",
    withDarkMode: true,
    attributes: [
        {
            key: "1",
            attr: "message",
            type: "string (optional)",
            notes: `The default message is Sorry! We don’t have any odds available to bring you,
        so it is not necessary to express this attribute, unless you want to override the default message`
            // tags: ["nice", "developer"]
        },
        {
            key: "2",
            attr: "src",
            type: "string (optional)",
            notes: "By default, an image is not rendered, but you can inform a url to the image that is to be rendered"
            // tags: ["loser"]
        },
        {
            key: "3",
            attr: "cta",
            type: "object(optional)",
            notes: `{ label : "Go To Sportsbook" url:"homepage" }
      By default, an image is not rendered, but you can inform a url to the image that is to be rendered`
            // tags: ["loser"]
        }
    ],
    events: [
        {
            event: "nodata-cta-event-details",
            type: "listener",
            data: [
                `The component details object is emitted when the Go To Sportsbook button is clicked,
          containing the following information.`,
                `detail: {
          url: <value>
       }`
            ]
        }
    ]
};
const NoDataForMarket = () => {
    documentDetails.example = `<leetent-nodata-for-market
      message="You can override the default message"
      src="https://d1bu7ybn6mtlcz.cloudfront.net/b184fc1e99834ec1ab45.gif"
      cta={JSON.stringify(label: "Go To Sportsbook",url: "/"))}
    />`;
    documentDetails.tabs = [
        {
            key: "1",
            label: "Default",
            children: _jsx("leetent-nodata-for-market", {})
        },
        {
            key: "2",
            label: "With Icon",
            children: _jsx("leetent-nodata-for-market", { src: "https://d1bu7ybn6mtlcz.cloudfront.net/b184fc1e99834ec1ab45.gif" })
        },
        {
            key: "3",
            label: "Override message",
            children: _jsx("leetent-nodata-for-market", { message: "You can override the default message" })
        },
        {
            key: "4",
            label: "With Icon and CTA",
            children: _jsx("leetent-nodata-for-market", { message: "No Events Available at the moment", src: "https://d1bu7ybn6mtlcz.cloudfront.net/data_alert.svg", cta: JSON.stringify(ctaObj) })
        }
    ];
    // render the page based on the provided object
    return _jsx(MountDocumentComponent, { data: documentDetails });
};
export default NoDataForMarket;
