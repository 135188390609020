var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import axios from "axios";
import { Row, Col, Skeleton } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
import weekOfYear from "dayjs/plugin/weekOfYear";
import { createRoot } from "react-dom/client";
import { SPORTS_SERIES_DEFAULT, ESPORTS_SERIES_DEFAULT } from "enums";
import { isRangeInPeriods, getDateByPeriod } from "helpers/DayjsFunctions";
import { SessionStorage } from "helpers/Storage";
// styles
import styles from "./NavigationGeneric.module.scss";
// extensions
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(weekOfYear);
export const dateFormat = "YYYYMMDD";
const tagsSource = "tags-list-navigation-generic";
const marketsTagsSource = "markets-tags-list-navigation-featured";
const calendarOptions = [
    {
        label: "Next 24h",
        value: "next24h"
    },
    {
        label: "Next 48h",
        value: "next48h"
    },
    {
        label: "Next 72h",
        value: "next72h"
    },
    {
        label: "Live",
        value: "live"
    },
    {
        label: "Highlights",
        value: "highlights"
    },
    {
        label: "Upcoming",
        value: "upcoming"
    },
    {
        label: "By date",
        value: "byDate"
    }
];
const NavigationGeneric = ({ productId, defaultEventState, withoutDatePicker = false, withDateRange, withoutTags, exact }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y;
    const [isLoading, setIsLoading] = React.useState(true);
    const [error, setError] = React.useState();
    // This is the countries list, in case we need to enable it
    // const [categories, setCategories] = React.useState([]);
    const [leagues, setLeagues] = React.useState([]);
    const [previousRange, setPreviousRange] = React.useState();
    const [sportId, setSportId] = React.useState(1);
    // market types
    const [marketTypes, setMarketTypes] = React.useState([]);
    const [defaultMarket, setDefaultMarket] = React.useState(productId === 1 ?
        ((_b = (_a = SessionStorage.get("leetent")) === null || _a === void 0 ? void 0 : _a.navigation) === null || _b === void 0 ? void 0 : _b.serie) ?
            (_d = (_c = SessionStorage.get("leetent")) === null || _c === void 0 ? void 0 : _c.navigation) === null || _d === void 0 ? void 0 : _d.serie :
            SPORTS_SERIES_DEFAULT :
        productId === 2 && ((_f = (_e = SessionStorage.get("leetent")) === null || _e === void 0 ? void 0 : _e.navigation) === null || _f === void 0 ? void 0 : _f.serie) ?
            (_h = (_g = SessionStorage.get("leetent")) === null || _g === void 0 ? void 0 : _g.navigation) === null || _h === void 0 ? void 0 : _h.serie :
            ESPORTS_SERIES_DEFAULT);
    // mounts the model that is emitted to its parent
    const [model, setModel] = React.useState({
        lastUpdated: "",
        productId,
        game: productId === 1 ? 1 : -1,
        date: {
            period: (withDateRange !== null && withDateRange !== void 0 ? withDateRange : withoutDatePicker) ? "next24h" : "upcoming",
            date: (withDateRange !== null && withDateRange !== void 0 ? withDateRange : withoutDatePicker) ?
                {
                    from: dayjs().format("YYYYMMDD"),
                    to: dayjs().add(24, "hour").format("YYYYMMDD")
                } :
                dayjs().format("YYYYMMDD"),
            year: (withDateRange !== null && withDateRange !== void 0 ? withDateRange : withoutDatePicker) ?
                {
                    from: dayjs().year(),
                    to: dayjs().add(24, "hour").year()
                } :
                dayjs().year(),
            month: (withDateRange !== null && withDateRange !== void 0 ? withDateRange : withoutDatePicker) ?
                {
                    from: dayjs().month(),
                    to: dayjs().add(24, "hour").month()
                } :
                dayjs().month(),
            day: (withDateRange !== null && withDateRange !== void 0 ? withDateRange : withoutDatePicker) ?
                {
                    from: dayjs().date(),
                    to: dayjs().add(24, "hour").date()
                } :
                dayjs().date(),
            dayOfWeek: (withDateRange !== null && withDateRange !== void 0 ? withDateRange : withoutDatePicker) ?
                {
                    from: {
                        index: dayjs().day(),
                        name: dayjs().add(24, "hour").format("dddd")
                    },
                    to: {
                        index: dayjs().day(),
                        name: dayjs().add(24, "hour").format("dddd")
                    }
                } :
                {
                    index: dayjs().day(),
                    name: dayjs().format("dddd")
                },
            weekOfYear: (withDateRange !== null && withDateRange !== void 0 ? withDateRange : withoutDatePicker) ?
                {
                    from: dayjs().week(),
                    to: dayjs().add(24, "hour").week()
                } :
                dayjs().week(),
            iso: (withDateRange !== null && withDateRange !== void 0 ? withDateRange : withoutDatePicker) ?
                {
                    from: {
                        start: exact ?
                            dayjs().format("YYYY-MM-DDTHH:mm:ssZ") :
                            dayjs().startOf("date").format("YYYY-MM-DDTHH:mm:ssZ"),
                        end: dayjs().endOf("date").format("YYYY-MM-DDTHH:mm:ssZ")
                    },
                    to: {
                        start: exact ?
                            dayjs().add(24, "hour").format("YYYY-MM-DDTHH:mm:ssZ") :
                            dayjs().startOf("date").format("YYYY-MM-DDTHH:mm:ssZ"),
                        end: dayjs().add(24, "hour").endOf("date").format("YYYY-MM-DDTHH:mm:ssZ")
                    }
                } :
                {
                    start: dayjs().startOf("date").format("YYYY-MM-DDTHH:mm:ssZ"),
                    end: dayjs().endOf("date").format("YYYY-MM-DDTHH:mm:ssZ")
                },
            timestamps: (withDateRange !== null && withDateRange !== void 0 ? withDateRange : withoutDatePicker) ?
                {
                    from: {
                        start: dayjs().utc(false).startOf("date").valueOf(),
                        end: dayjs().add(24, "hour").utc(false).endOf("date").valueOf()
                    },
                    to: {
                        start: dayjs().utc(false).startOf("date").valueOf(),
                        end: dayjs().add(24, "hour").utc(false).endOf("date").valueOf()
                    }
                } :
                {
                    start: dayjs().utc(false).startOf("date").valueOf(),
                    end: dayjs().utc(false).endOf("date").valueOf()
                }
        },
        country: { id: -1, name: "all" },
        league: ((_k = (_j = SessionStorage.get("leetent")) === null || _j === void 0 ? void 0 : _j.navigation) === null || _k === void 0 ? void 0 : _k.league) ?
            (_m = (_l = SessionStorage.get("leetent")) === null || _l === void 0 ? void 0 : _l.navigation) === null || _m === void 0 ? void 0 : _m.league :
            { id: -1, name: "all", country: { id: -1, name: "all" } },
        serie: ((_p = (_o = SessionStorage.get("leetent")) === null || _o === void 0 ? void 0 : _o.navigation) === null || _p === void 0 ? void 0 : _p.serie) ?
            (_r = (_q = SessionStorage.get("leetent")) === null || _q === void 0 ? void 0 : _q.navigation) === null || _r === void 0 ? void 0 : _r.serie :
            defaultMarket,
        tag: withoutTags ?
            { id: "highlights", name: "Highlights" } :
            ((_t = (_s = SessionStorage.get("leetent")) === null || _s === void 0 ? void 0 : _s.navigation) === null || _t === void 0 ? void 0 : _t.tag) ?
                (_v = (_u = SessionStorage.get("leetent")) === null || _u === void 0 ? void 0 : _u.navigation) === null || _v === void 0 ? void 0 : _v.tag :
                !withDateRange && !withoutDatePicker ?
                    {
                        id: "all",
                        name: "All"
                    } :
                    defaultEventState ?
                        {
                            id: (_w = calendarOptions === null || calendarOptions === void 0 ? void 0 : calendarOptions.find(option => option.value === defaultEventState)) === null || _w === void 0 ? void 0 : _w.value,
                            name: (_x = calendarOptions === null || calendarOptions === void 0 ? void 0 : calendarOptions.find(option => option.value === defaultEventState)) === null || _x === void 0 ? void 0 : _x.label
                        } :
                        { id: "next24h", name: "Next 24h" }
    });
    React.useEffect(() => {
        // it should trigger the filter, which is captured by other components,
        // when dealing with without date picker scenario
        if (withoutDatePicker && !withDateRange) {
            setModel(Object.assign(Object.assign({}, model), { lastUpdated: dayjs().toISOString() }));
        }
    }, []);
    React.useEffect(() => {
        if (productId === 2 || !productId) {
            axios
                .get("/esports/market-types")
                .then((res) => {
                var _a;
                setMarketTypes(res.data);
                // when the default market is not provided, then assume the first market in the array
                setDefaultMarket((_a = res.data[0]) === null || _a === void 0 ? void 0 : _a.id);
            })
                .catch((e) => {
                console.log(e);
            })
                .finally(() => {
                setIsLoading(false);
            });
        }
        else if (productId === 1) {
            axios
                .get("/market-type-configs")
                .then((res) => {
                var _a, _b;
                const data = (_a = res.data) === null || _a === void 0 ? void 0 : _a.filter((config) => (config === null || config === void 0 ? void 0 : config.isDefault) === true);
                setMarketTypes(data);
                // when the default market is not provided, then assume the first market in the array
                if (!data.find((market) => market.id === SPORTS_SERIES_DEFAULT)) {
                    setDefaultMarket((_b = data[0]) === null || _b === void 0 ? void 0 : _b.id);
                }
            })
                .catch((e) => {
                console.log(e);
            })
                .finally(() => {
                setIsLoading(false);
            });
        }
    }, [productId]);
    React.useEffect(() => {
        if (defaultMarket !== SPORTS_SERIES_DEFAULT) {
            setModel(Object.assign(Object.assign({}, model), { serie: defaultMarket, lastUpdated: dayjs().toISOString() }));
        }
    }, [defaultMarket]);
    let iconsData = [];
    if (productId === 2 || !productId) {
        iconsData = [
            {
                id: -1,
                label: "ALL",
                src: "https://d1bu7ybn6mtlcz.cloudfront.net/images/rabbit.png",
                alt: "Settings"
            },
            {
                id: 1,
                label: "ESOCCER",
                src: "https://img.abiosgaming.com/games/esoccer-square.png"
            },
            {
                id: 2,
                label: "LOL",
                src: "https://img.abiosgaming.com/games/lol-square-logo.png"
            },
            {
                id: 3,
                label: "CS:GO",
                src: "https://img.abiosgaming.com/games/cs-square-logo.png"
            },
            {
                id: 4,
                label: "DOTA 2",
                src: "https://img.abiosgaming.com/games/dota-square-logo.png"
            },
            {
                id: 5,
                label: "VALORANT",
                src: "https://img.abiosgaming.com/games/valorant-square-logo.png"
            }
        ];
    }
    else if (productId === 1) {
        iconsData = [
            {
                id: 1,
                label: "SOCCER",
                src: "https://d1bu7ybn6mtlcz.cloudfront.net/images/soccer.png",
                alt: "Settings"
            }
        ];
    }
    // listen for date changes emitted by the date picker component
    const listeners = () => {
        const selectedIconTabDetailsHandler = (e) => {
            const { type, id, label } = e.detail;
            if (type === "sports-navigation") {
                setSportId(id);
            }
            else {
                if (id !== model.game) {
                    setModel(Object.assign(Object.assign({}, model), { game: {
                            id,
                            label
                        }, lastUpdated: dayjs().toISOString() }));
                }
            }
        };
        const selectedDateHandler = (e) => {
            var _a, _b, _c, _d, _e, _f, _g;
            // The following attributes should be returned
            // date (eg YYYYYMM), month, year, day and dayOfTheWeek (it is an index 0 to 6)
            const isDateRange = (((_b = (_a = e.detail) === null || _a === void 0 ? void 0 : _a.date) === null || _b === void 0 ? void 0 : _b.from) && ((_d = (_c = e.detail) === null || _c === void 0 ? void 0 : _c.date) === null || _d === void 0 ? void 0 : _d.to));
            if (!isDateRange && e.detail.date !== model.date) {
                setModel(Object.assign(Object.assign({}, model), { date: e.detail, lastUpdated: dayjs().toISOString() }));
            }
            ;
            if (isDateRange &&
                (e.detail.date.from !== (previousRange === null || previousRange === void 0 ? void 0 : previousRange.from) ||
                    e.detail.date.to !== (previousRange === null || previousRange === void 0 ? void 0 : previousRange.to))) {
                const updatedModel = structuredClone(model);
                let matchedPeriod = isRangeInPeriods((_e = calendarOptions === null || calendarOptions === void 0 ? void 0 : calendarOptions.filter(option => option.value !== "byDate")) === null || _e === void 0 ? void 0 : _e.map(option => option.value), {
                    from: Number(e.detail.date.from),
                    to: Number(e.detail.date.to)
                }, true);
                if (model.date.period === "highlights" && matchedPeriod !== "byDate")
                    matchedPeriod = "highlights";
                if (model.date.period === "live" && matchedPeriod !== "byDate")
                    matchedPeriod = "live";
                if (model.date.period === "upcoming" && matchedPeriod !== "byDate")
                    matchedPeriod = "upcoming";
                setPreviousRange({ from: e.detail.date.from, to: e.detail.date.to });
                updatedModel.date = e.detail;
                const newOption = calendarOptions.find(option => option.value === matchedPeriod);
                updatedModel.tag = { id: (_f = newOption === null || newOption === void 0 ? void 0 : newOption.value) !== null && _f !== void 0 ? _f : "all", name: (_g = newOption === null || newOption === void 0 ? void 0 : newOption.label) !== null && _g !== void 0 ? _g : "All" };
                updatedModel.date.period = matchedPeriod;
                if (e.detail.date !== model.date) {
                    setModel(Object.assign(Object.assign({}, updatedModel), { lastUpdated: dayjs().toISOString() }));
                }
                ;
            }
        };
        const countriesSelectedOptionHandler = (e) => {
            if (e.detail.name !== model.country) {
                setModel(Object.assign(Object.assign({}, model), { country: Object.keys(e.detail).length === 0 ?
                        { id: -1, name: "all" } :
                        { id: e.detail.id, name: e.detail.name }, league: { id: -1, name: "all", country: { id: -1, name: "all" } }, lastUpdated: dayjs().toISOString() }));
            }
            ;
        };
        const leaguesSelectedOptionHandler = (e) => {
            if (e.detail.name !== model.league) {
                setModel(Object.assign(Object.assign({}, model), { league: Object.keys(e.detail).length === 0 ?
                        { id: -1, name: "all", country: { id: -1, name: "all" } } :
                        {
                            id: e.detail.id,
                            name: e.detail.name,
                            country: {
                                id: e.detail.category.id,
                                name: e.detail.category.name
                            }
                        }, lastUpdated: dayjs().toISOString() }));
            }
            ;
        };
        const selectedTagDetailsHandler = (e) => {
            var _a, _b, _c, _d;
            if (e.detail.source === marketsTagsSource) {
                if (e.detail.id !== model.serie) {
                    setModel(Object.assign(Object.assign({}, model), { serie: e.detail.id, lastUpdated: dayjs().toISOString() }));
                }
            }
            if (e.detail.source === tagsSource &&
                (e.detail.id !== model.tag.id ||
                    model.tag.id === ((_c = (_b = (_a = SessionStorage.get("leetent")) === null || _a === void 0 ? void 0 : _a.navigation) === null || _b === void 0 ? void 0 : _b.tag) === null || _c === void 0 ? void 0 : _c.id))) {
                const updatedModel = structuredClone(model);
                updatedModel.tag = e.detail;
                updatedModel.date.period = e.detail.id;
                // verify if dealing with presets, and process "next" dates where possible
                if (withoutDatePicker && ((_d = e.detail) === null || _d === void 0 ? void 0 : _d.id) && String(e.detail.id).toLowerCase().startsWith("next")) {
                    const startDate = exact ? dayjs() : dayjs().startOf("date");
                    const endDate = getDateByPeriod(e.detail.id, exact);
                    // updates the model with the new date
                    if (startDate && endDate) {
                        updatedModel.date = {
                            period: e.detail.id,
                            date: {
                                from: startDate.format(dateFormat),
                                to: endDate.format(dateFormat)
                            },
                            year: {
                                from: startDate.year(),
                                to: endDate.year()
                            },
                            month: {
                                from: startDate.month() + 1,
                                to: endDate.month() + 1
                            },
                            day: {
                                from: startDate.date(),
                                to: endDate.date()
                            },
                            dayOfWeek: {
                                from: {
                                    index: startDate.day(),
                                    name: startDate.format("dddd")
                                },
                                to: {
                                    index: endDate.day(),
                                    name: endDate.format("dddd")
                                }
                            },
                            weekOfYear: {
                                from: startDate.week(),
                                to: endDate.week()
                            },
                            iso: {
                                // Temporary remove Z(UTC)
                                from: {
                                    start: exact ?
                                        startDate.format("YYYY-MM-DDTHH:mm:ss") :
                                        startDate.startOf("date").format("YYYY-MM-DDTHH:mm:ss"),
                                    end: startDate.endOf("date").format("YYYY-MM-DDTHH:mm:ss")
                                },
                                to: {
                                    start: exact ?
                                        endDate.format("YYYY-MM-DDTHH:mm:ss") :
                                        endDate.startOf("date").format("YYYY-MM-DDTHH:mm:ss"),
                                    end: endDate.endOf("date").format("YYYY-MM-DDTHH:mm:ss")
                                }
                            },
                            timestamps: {
                                from: {
                                    start: exact ?
                                        startDate.utc(false).valueOf() :
                                        startDate.utc(false).startOf("date").valueOf(),
                                    end: startDate.utc(false).endOf("date").valueOf()
                                },
                                to: {
                                    start: exact ?
                                        endDate.utc(false).valueOf() :
                                        endDate.utc(false).startOf("date").valueOf(),
                                    end: endDate.utc(false).endOf("date").valueOf()
                                }
                            }
                        };
                    }
                }
                setModel(Object.assign(Object.assign({}, updatedModel), { lastUpdated: dayjs().toISOString() }));
            }
            ;
        };
        const addListeners = () => {
            window.addEventListener("selected-icon-tab-details", selectedIconTabDetailsHandler);
            !withDateRange && !withoutDatePicker ?
                window.addEventListener("selected-date", selectedDateHandler) :
                window.addEventListener("selected-date-range", selectedDateHandler);
            if (productId === 1) {
                window.addEventListener("countries-selected-option", countriesSelectedOptionHandler);
                window.addEventListener("leagues-selected-option", leaguesSelectedOptionHandler);
            }
            // TODO: implement this section when endpoint is in place
            !withoutTags && window.addEventListener("selected-tag-details", selectedTagDetailsHandler);
        };
        const removeListeners = () => {
            window.removeEventListener("selected-icon-tab-details", selectedIconTabDetailsHandler);
            window.removeEventListener("countries-selected-option", countriesSelectedOptionHandler);
            window.removeEventListener("leagues-selected-option", leaguesSelectedOptionHandler);
            !withoutTags && window.removeEventListener("selected-tag-details", selectedTagDetailsHandler);
            !withDateRange && !withoutDatePicker ?
                window.removeEventListener("selected-date", selectedDateHandler) :
                window.removeEventListener("selected-date-range", selectedDateHandler);
        };
        return { addListeners, removeListeners };
    };
    const triggerNavigationEventDetails = React.useMemo(() => {
        if (model) {
            // updates the sessionStorage -> navigation attribute
            const leetentStorage = SessionStorage.get("leetent");
            // when the leetent object is not created, then creates it, if not updates the navigation attribute
            if (!leetentStorage) {
                const obj = {
                    navigation: model
                };
                SessionStorage.set("leetent", obj);
            }
            else {
                leetentStorage.navigation = model;
                SessionStorage.set("leetent", leetentStorage);
            }
            const emitModel = Object.assign({}, model);
            // Removes unnecessary key/values before emitting the data
            if (productId === 2) {
                delete emitModel.country;
                delete emitModel.league;
            }
            // if (product === "sports") delete emitModel.serie;
            return new CustomEvent("navigation-details", {
                detail: {
                    emitModel
                },
                bubbles: true
            });
        }
    }, [model]);
    React.useEffect(() => {
        if (model) {
            const { addListeners, removeListeners } = listeners();
            addListeners();
            window.dispatchEvent(triggerNavigationEventDetails);
            return () => {
                removeListeners();
            };
        }
    }, [model]);
    // fetches league details
    const fetchLeagues = React.useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        var _z, _0;
        !isLoading && setIsLoading(true);
        if (((_z = process.env) === null || _z === void 0 ? void 0 : _z.REACT_APP_GATEWAY_API_URL) && ((_0 = process.env) === null || _0 === void 0 ? void 0 : _0.REACT_APP_SPORTSBOOK_API_KEY)) {
            // TODO: Extend it to listen to the icons list clicked event, and then pass it in here
            // As the icons list is hidden at this moment, we are always passing soccer (id: 1)
            yield axios.get(`/sports?sportId=${sportId}`).then((res) => {
                setError(undefined);
                // processes the results
                const processed = [];
                res.data.forEach((sport) => {
                    // when sport has not yet been processed
                    if (!processed.find(item => item.id === sport.id)) {
                        const obj = {
                            id: sport.id,
                            name: sport.name,
                            eventCount: sport.eventCount,
                            tournamentCount: sport.tournamentCount,
                            tournaments: []
                        };
                        sport.categories.forEach((category) => {
                            const categoryId = category.id;
                            const categoryName = category.name;
                            category.tournaments.forEach((tournament) => {
                                obj.tournaments.push({
                                    category: {
                                        id: categoryId,
                                        name: categoryName
                                    },
                                    id: tournament.id,
                                    name: tournament.name,
                                    priorityOrder: tournament.priorityOrder,
                                    eventCount: tournament.eventCount
                                });
                            });
                            processed.push(obj);
                        });
                    }
                });
                setLeagues(processed.find(sport => sport.id === sportId).tournaments);
            }).catch((e) => {
                setError(e);
            }).finally(() => {
                setIsLoading(false);
            });
        }
        else {
            setIsLoading(false);
            setError("Environment variables are not in place");
        }
        ;
    }), [isLoading]);
    React.useEffect(() => {
        productId === 1 && fetchLeagues();
    }, []);
    const DatePickerRangeComponent = React.useCallback(() => {
        var _a, _b, _c, _d, _e, _f, _g;
        if (withDateRange !== null && withDateRange !== void 0 ? withDateRange : withoutDatePicker) {
            if (((_a = model === null || model === void 0 ? void 0 : model.tag) === null || _a === void 0 ? void 0 : _a.id) === "all" || ((_b = model === null || model === void 0 ? void 0 : model.tag) === null || _b === void 0 ? void 0 : _b.id) === "byDate") {
                return _jsx("leetent-date-picker-range", { period: "byDate", "start-date": (_d = (_c = model === null || model === void 0 ? void 0 : model.date) === null || _c === void 0 ? void 0 : _c.date) === null || _d === void 0 ? void 0 : _d.from, "end-date": (_f = (_e = model === null || model === void 0 ? void 0 : model.date) === null || _e === void 0 ? void 0 : _e.date) === null || _f === void 0 ? void 0 : _f.to, exact: exact });
            }
            else if (((_g = model === null || model === void 0 ? void 0 : model.tag) === null || _g === void 0 ? void 0 : _g.id) === "live") {
                return (_jsx("leetent-date-picker-range", { period: "live", exact: true }));
            }
            return (_jsx("leetent-date-picker-range", { period: model.date.period, exact: true }));
        }
        return _jsx(_Fragment, {});
    }, [model.date.period, (_y = model === null || model === void 0 ? void 0 : model.tag) === null || _y === void 0 ? void 0 : _y.id]);
    const TagsListComponent = React.useCallback(() => {
        if (withoutDatePicker) {
            return (_jsx("leetent-tags-list", { source: tagsSource, selected: model.tag.id, tags: JSON.stringify([
                    { id: "highlights", name: "Highlights" },
                    { id: "next24h", name: "Next 24h" },
                    { id: "next48h", name: "Next 48h" },
                    { id: "next72h", name: "Next 72h" }
                ]) }));
        }
        else if (withDateRange) {
            return (_jsx("leetent-tags-list", { source: tagsSource, selected: model.tag.id, tags: JSON.stringify([
                    { id: "live", name: "Live" },
                    { id: "next24h", name: "Next 24h" },
                    { id: "next48h", name: "Next 48h" },
                    { id: "next72h", name: "Next 72h" }
                ]) }));
        }
        return (_jsx("leetent-tags-list", { source: tagsSource, selected: model.tag.id, tags: JSON.stringify([
                { id: "all", name: "All" },
                { id: "live", name: "Live" },
                { id: "upcoming", name: "Upcoming" }
                // TODO: Temporarily commented
                // { id: "favourites", name: "Favourites" }
            ]) }));
    }, [model.tag.id]);
    const LeagueDropdown = React.useCallback(() => {
        return (_jsx("leetent-dropdown-generic", { name: "leagues", "all-option": true, options: JSON.stringify(leagues), selected: model.league.id, "label-key": "name", "value-key": "id" }));
    }, [leagues, model.league.id]);
    const SeriesDropdown = React.useCallback(() => {
        var _a;
        if ((marketTypes === null || marketTypes === void 0 ? void 0 : marketTypes.length) > 0) {
            return (_jsx("leetent-dropdown-generic", { name: "series", options: JSON.stringify(marketTypes), "label-key": productId === 1 ? "shortName" : "name", "value-key": "id", selected: productId === 1 ?
                    (_a = marketTypes.find((market) => (market === null || market === void 0 ? void 0 : market.id) === defaultMarket)) === null || _a === void 0 ? void 0 : _a.id :
                    null }));
        }
        return _jsx(_Fragment, {});
    }, [marketTypes, defaultMarket]);
    return (_jsxs("div", Object.assign({ className: styles.navigationWrapper }, { children: [productId === 2 &&
                _jsx("leetent-icons-tab-header", { icons: JSON.stringify(iconsData) }), _jsx("div", Object.assign({ className: styles.filtersWrapper }, { children: _jsxs(Row, Object.assign({ justify: "start", align: "middle", gutter: [4, 8] }, { children: [!withoutDatePicker && (_jsx(Col, Object.assign({ xs: 24, sm: 24, md: 24, lg: 8, xl: 8, xxl: 8 }, { children: !withDateRange ?
                                _jsx("leetent-date-picker", {}) :
                                _jsx(DatePickerRangeComponent, {}) }))), productId === 1 && (_jsx(_Fragment, { children: _jsx(Col, Object.assign({ xs: 12, sm: 12, md: 12, lg: withoutDatePicker ? 12 : 8, xl: withoutDatePicker ? 12 : 8, xxl: withoutDatePicker ? 12 : 8 }, { children: isLoading && !error ?
                                    (_jsxs(Row, Object.assign({ justify: "space-around", align: "middle", gutter: [5, 5] }, { children: [_jsx(Col, Object.assign({ xs: 24, sm: 24, md: 12, lg: 12, xl: 12, xxl: 12 }, { children: _jsx(Skeleton.Input, { active: true, size: "default", block: true, className: styles.skeleton }) })), _jsx(Col, Object.assign({ xs: 24, sm: 24, md: 12, lg: 12, xl: 12, xxl: 12 }, { children: _jsx(Skeleton.Input, { active: true, size: "default", block: true, className: styles.skeleton }) }))] }))) :
                                    error ?
                                        null :
                                        _jsx(LeagueDropdown, {}) })) })), _jsx(Col, Object.assign({ xs: 12, sm: 12, md: 12, lg: withoutDatePicker ? 12 : 8, xl: withoutDatePicker ? 12 : 8, xxl: withoutDatePicker ? 12 : 8 }, { children: _jsx(SeriesDropdown, {}) }))] })) })), !withoutTags && (_jsx("div", Object.assign({ className: styles.tagsWrapper }, { children: _jsx(Row, { children: _jsx(Col, Object.assign({ xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24, className: styles.tags }, { children: _jsx(TagsListComponent, {}) })) }) })))] })));
};
class Element extends HTMLElement {
    constructor() {
        super();
        this.root = createRoot(this);
    }
    static get observedAttributes() {
        return ["product-id", "default-event-state", "without-date-picker", "with-date-range", "without-tags", "exact"];
    }
    connectedCallback() {
        var _a;
        (_a = this.root) === null || _a === void 0 ? void 0 : _a.render(_jsx(NavigationGeneric, { productId: this["product-id"], defaultEventState: this["default-event-state"], withDateRange: this["with-date-range"], withoutDatePicker: this["without-date-picker"], withoutTags: this["without-tags"], exact: this.exact }));
    }
    disconnectedCallback() {
        setTimeout(() => { var _a; return (_a = this.root) === null || _a === void 0 ? void 0 : _a.unmount(); }, 0);
    }
    attributeChangedCallback(attrName, _oldValue, newValue) {
        var _a;
        switch (attrName) {
            case "product-id":
                this[attrName] = +newValue;
                break;
            case "default-event-state":
                this[attrName] = String(newValue);
                break;
            case "with-date-range":
            case "without-date-picker":
            case "without-tags":
            case "exact":
                this[attrName] = JSON.parse(newValue);
                break;
            default:
                break;
        }
        (_a = this.root) === null || _a === void 0 ? void 0 : _a.render(_jsx(NavigationGeneric, { productId: this["product-id"], defaultEventState: this["default-event-state"], withDateRange: this["with-date-range"], withoutDatePicker: this["without-date-picker"], withoutTags: this["without-tags"], exact: this.exact }));
    }
}
(_a = customElements.get("leetent-navigation-generic")) !== null && _a !== void 0 ? _a : customElements.define("leetent-navigation-generic", Element);
