var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { createRoot } from "react-dom/client";
import { Grid, Row, Badge } from "antd";
import { SUBSCRIBE_LIVE_EVENT_COUNTS } from "v2/graphql/subscriptions";
import { useSubscribeByQuery } from "helpers/graphql/useSubscribeByQuery";
import ImageFromCDN from "components/base/imageFromCDN/ImageFromCDN";
import clsx from "clsx";
// styles
import styles from "./IconsTabHeader.module.scss";
const { useBreakpoint } = Grid;
let sortedIcons;
const IconNavigation = ({ direction }) => {
    const scrollLeftOrRight = (direction) => {
        const IconTabElement = document.getElementById("icons-tab-header");
        if (IconTabElement) {
            if (direction === "left")
                IconTabElement.scrollLeft -= 20;
            if (direction === "right")
                IconTabElement.scrollLeft += 20;
        }
    };
    return (_jsx("div", Object.assign({ id: `scrollToThe${direction}`, className: styles.icon, style: {
            left: direction === "left" ? 0 : "inherit",
            right: direction === "right" ? 0 : "inherit"
        }, onClick: () => { scrollLeftOrRight(direction); } }, { children: _jsx(ImageFromCDN, { alt: "angle-down-icon", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/angle-down.png", size: 20, overrideStyles: {
                width: "4px",
                height: "4px",
                margin: "30px 4px 0px 4px",
                rotate: direction === "left" ? "90deg" : "-90deg"
            } }) })));
};
const IconsTabHeader = ({ icons, classes, hideLiveNoEvents }) => {
    const screens = useBreakpoint();
    const [selectedIcon, setSelectedIcon] = React.useState((icons === null || icons === void 0 ? void 0 : icons.length) ? icons === null || icons === void 0 ? void 0 : icons.find((icon) => icon.id === 1) : undefined);
    const [liveEvents, setLiveEvents] = React.useState({
        all: 0,
        sports: {}
    });
    // subscribes to the live counter topic and updates
    const valueHandlerCallback = (details) => {
        const nextData = details.getLiveEventCounts;
        const processing = {
            all: 0,
            sports: {}
        };
        // processes the results by sports
        nextData.forEach((item) => {
            processing.all = Number(processing.all) + Number(item.TotalEvents);
            if (Object.prototype.hasOwnProperty.call(processing.sports, item.SportId)) {
                processing.sports[item.SportId] = Number(processing.sports[item.SportId]) + Number(item.TotalEvents);
            }
            else {
                processing.sports[item.SportId] = item.TotalEvents;
            }
        });
        setLiveEvents(processing);
    };
    useSubscribeByQuery({
        query: SUBSCRIBE_LIVE_EVENT_COUNTS,
        valueHandlerCallback
    });
    const triggerSelectedIconEventDetails = () => {
        return new CustomEvent("selected-icon-tab-details", {
            detail: Object.assign({}, selectedIcon),
            bubbles: true
        });
    };
    React.useEffect(() => {
        if (selectedIcon) {
            window.dispatchEvent(triggerSelectedIconEventDetails());
        }
    }, [selectedIcon]);
    if (icons === null || icons === void 0 ? void 0 : icons.some(icon => icon.order)) {
        sortedIcons = icons.sort((a, b) => Number(a === null || a === void 0 ? void 0 : a.order) - Number(b === null || b === void 0 ? void 0 : b.order));
    }
    // once items are provided, we start listening to changes
    React.useEffect(() => {
        if (icons === null || icons === void 0 ? void 0 : icons.length) {
            const navigationDetailsHandler = (e) => {
                e.preventDefault();
                const updatedIcon = icons.find((icon) => icon.id === e.detail.id);
                updatedIcon && setSelectedIcon(updatedIcon);
            };
            window.addEventListener("update-selected-icons-tab-header", navigationDetailsHandler);
            return () => {
                window.removeEventListener("update-selected-icons-tab-header", navigationDetailsHandler);
            };
        }
    }, [icons]);
    return (_jsx("div", Object.assign({ className: clsx(styles.tabHeader, "flex", "alignCenter", classes === null || classes === void 0 ? void 0 : classes.root) }, { children: _jsxs(Row, Object.assign({ justify: "start", align: "middle", className: clsx(styles.tabHeader__inner, "flex", classes === null || classes === void 0 ? void 0 : classes.tabs), id: "icons-tab-header" }, { children: [!screens.xs && !screens.xl && !screens.xxl && icons.length > 8 && _jsx(IconNavigation, { direction: "left" }), (sortedIcons !== null && sortedIcons !== void 0 ? sortedIcons : icons).map((_a) => {
                    var _b, _c, _d, _e;
                    var { label, id, src, alt, counter, shouldSubscribe, SVGImage, type } = _a, rest = __rest(_a, ["label", "id", "src", "alt", "counter", "shouldSubscribe", "SVGImage", "type"]);
                    const isActive = (selectedIcon === null || selectedIcon === void 0 ? void 0 : selectedIcon.id) === id;
                    return (_jsxs("a", Object.assign({ onClick: () => {
                            setSelectedIcon(Object.assign(Object.assign({ id, label, type }, rest), { action: "onClick" }));
                        }, className: clsx(classes === null || classes === void 0 ? void 0 : classes.tab, {
                            [styles.hidden]: hideLiveNoEvents && id === -1 && liveEvents.all === 0
                        }) }, { children: [_jsx(Badge, Object.assign({ count: shouldSubscribe && id !== -1 ?
                                    (_b = liveEvents === null || liveEvents === void 0 ? void 0 : liveEvents.sports) === null || _b === void 0 ? void 0 : _b[id] :
                                    id === -1 ?
                                        liveEvents === null || liveEvents === void 0 ? void 0 : liveEvents.all :
                                        0, size: "small", offset: [3, 7], style: {
                                    borderRadius: 3,
                                    borderColor: "transparent",
                                    fontSize: 9,
                                    fontWeight: "bold",
                                    padding: "0px 3px 2px 3px"
                                } }, { children: SVGImage ?
                                    _jsx("div", { className: clsx(styles.svg, {
                                            [(_c = classes === null || classes === void 0 ? void 0 : classes.activeSVG) !== null && _c !== void 0 ? _c : ""]: isActive
                                        }), dangerouslySetInnerHTML: { __html: SVGImage } }) :
                                    _jsx("div", Object.assign({ className: clsx({ [styles.activeSVG]: isActive }) }, { children: _jsx(ImageFromCDN, { alt: alt !== null && alt !== void 0 ? alt : "img", url: src, size: 25 }) })) })), _jsx("div", Object.assign({ className: clsx(styles.label, {
                                    [styles.active]: !(classes === null || classes === void 0 ? void 0 : classes.active) && isActive,
                                    [(_d = classes === null || classes === void 0 ? void 0 : classes.active) !== null && _d !== void 0 ? _d : ""]: (classes === null || classes === void 0 ? void 0 : classes.active) && isActive
                                }) }, { children: label })), _jsx("div", { className: clsx({
                                    [styles.highlight]: true,
                                    [(_e = classes === null || classes === void 0 ? void 0 : classes.active) !== null && _e !== void 0 ? _e : ""]: (classes === null || classes === void 0 ? void 0 : classes.active) && isActive,
                                    [styles.selected]: !(classes === null || classes === void 0 ? void 0 : classes.active) && isActive
                                }), style: {
                                    borderBottom: isActive ? "3px solid var(--primaryBrandColor, $color-pink)" : ""
                                } })] }), `${id} + ${label}`));
                }), !screens.xs && !screens.xl && !screens.xxl && icons.length > 8 && _jsx(IconNavigation, { direction: "right" })] })) })));
};
class Element extends HTMLElement {
    constructor() {
        super();
        this.root = createRoot(this);
        this.icons = [];
    }
    static get observedAttributes() {
        return ["icons", "classes", "hide-live-no-events"];
    }
    disconnectedCallback() {
        setTimeout(() => { var _a; return (_a = this.root) === null || _a === void 0 ? void 0 : _a.unmount(); }, 0);
    }
    connectedCallback() {
        var _a;
        (_a = this.root) === null || _a === void 0 ? void 0 : _a.render(_jsx(IconsTabHeader, { icons: this.icons, classes: this.classes, hideLiveNoEvents: this["hide-live-no-events"] }));
    }
    attributeChangedCallback(attrName, _oldValue, newValue) {
        var _a;
        switch (attrName) {
            case "icons":
            case "classes":
            case "hide-live-no-events":
                this[attrName] = JSON.parse(newValue);
                break;
            default:
                break;
        }
        (_a = this.root) === null || _a === void 0 ? void 0 : _a.render(_jsx(IconsTabHeader, { icons: this.icons, classes: this.classes, hideLiveNoEvents: this["hide-live-no-events"] }));
    }
}
(_a = customElements.get("leetent-icons-tab-header")) !== null && _a !== void 0 ? _a : customElements.define("leetent-icons-tab-header", Element);
