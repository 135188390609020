var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Badge, Skeleton } from "antd";
import clsx from "clsx";
import { SUBSCRIBE_LIVE_EVENT_COUNTS } from "v2/graphql/subscriptions";
import { useSubscribeByQuery } from "helpers/graphql/useSubscribeByQuery";
import { Accordion } from "components/base/Accordion/Accordion";
import axios from "axios";
import ImageFromCDN from "components/base/imageFromCDN/ImageFromCDN";
import styles from "./AZ.module.scss";
const getItem = ({ imageUrl, name, withPadding, isRegion, isLeague }) => {
    const Item = ({ open, onClick }) => {
        return _jsxs("div", Object.assign({ className: clsx(styles.item, {
                [styles.withPadding]: withPadding,
                [styles.region]: isRegion,
                [styles.league]: isLeague
            }), onClick: onClick }, { children: [_jsxs("div", Object.assign({ className: clsx(styles.content, { [styles.active]: open }) }, { children: [imageUrl &&
                            _jsx(ImageFromCDN, { url: imageUrl, size: 16 }), name] })), _jsx(ImageFromCDN, { alt: "Arrow right", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/keyboard_arrow_right.svg", color: open ? "#3DAF7F" : "#1C1B1F", size: 10, overrideStyles: { transform: open ? "rotate(90deg)" : "rotate(0deg)" } })] }));
    };
    return Item;
};
export const AZ = ({ noDataOptions, showFeatured = true }) => {
    var _a, _b, _c, _d;
    const [isLoading, setIsLoading] = React.useState(false);
    const [data, setData] = React.useState();
    const [liveEvents, setLiveEvents] = React.useState({
        all: 0,
        sports: {}
    });
    // subscribes to the live counter topic and updates
    const valueHandlerCallback = (details) => {
        const nextData = details.getLiveEventCounts;
        const processing = {
            all: 0,
            sports: {}
        };
        // processes the results by sports
        nextData.forEach((item) => {
            processing.all = Number(processing.all) + Number(item.TotalEvents);
            if (Object.prototype.hasOwnProperty.call(processing.sports, item.SportId)) {
                processing.sports[item.SportId] = Number(processing.sports[item.SportId]) + Number(item.TotalEvents);
            }
            else {
                processing.sports[item.SportId] = item.TotalEvents;
            }
        });
        setLiveEvents(processing);
    };
    useSubscribeByQuery({
        query: SUBSCRIBE_LIVE_EVENT_COUNTS,
        valueHandlerCallback
    });
    React.useEffect(() => {
        const fetchData = () => __awaiter(void 0, void 0, void 0, function* () {
            var _a, _b, _c;
            if (((_a = process.env) === null || _a === void 0 ? void 0 : _a.REACT_APP_GATEWAY_API_URL) && ((_b = process.env) === null || _b === void 0 ? void 0 : _b.REACT_APP_GATEWAY_API_KEY)) {
                setIsLoading(true);
                yield axios.get(`${(_c = process.env) === null || _c === void 0 ? void 0 : _c.REACT_APP_GATEWAY_API_URL}/sports/a-z-featured`).then((res) => {
                    setData(res.data);
                }).catch((e) => {
                    console.error(e);
                }).finally(() => {
                    setIsLoading(false);
                });
            }
            else {
                setIsLoading(false);
            }
            ;
        });
        fetchData();
    }, []);
    if (isLoading) {
        return _jsx(Skeleton.Input, { active: true, size: "small", block: true, className: styles.skeleton });
    }
    if (!((_a = data === null || data === void 0 ? void 0 : data.featured) === null || _a === void 0 ? void 0 : _a.length) && !((_b = data === null || data === void 0 ? void 0 : data.sportList) === null || _b === void 0 ? void 0 : _b.length)) {
        return _jsx("leetent-nodata-for-market", { message: noDataOptions === null || noDataOptions === void 0 ? void 0 : noDataOptions.message, src: noDataOptions === null || noDataOptions === void 0 ? void 0 : noDataOptions.src, cta: JSON.stringify(noDataOptions === null || noDataOptions === void 0 ? void 0 : noDataOptions.cta) });
    }
    const featuredLiveFilteredData = (_c = data === null || data === void 0 ? void 0 : data.featured) === null || _c === void 0 ? void 0 : _c.filter((item) => {
        var _a;
        const isLive = ((_a = item === null || item === void 0 ? void 0 : item.name) === null || _a === void 0 ? void 0 : _a.toLocaleLowerCase()) === "live";
        if (isLive && liveEvents.all < 1)
            return false;
        return true;
    });
    return (_jsxs("div", Object.assign({ className: styles.root }, { children: [showFeatured && _jsxs("div", Object.assign({ className: styles.featured }, { children: [_jsx("div", Object.assign({ className: clsx(styles.item, styles.title) }, { children: _jsx("div", Object.assign({ className: styles.content }, { children: "Featured" })) })), featuredLiveFilteredData === null || featuredLiveFilteredData === void 0 ? void 0 : featuredLiveFilteredData.map(({ name, url, id }) => {
                        const isLive = (name === null || name === void 0 ? void 0 : name.toLocaleLowerCase()) === "live";
                        return _jsxs("a", Object.assign({ href: url, className: styles.item }, { children: [_jsxs("div", Object.assign({ className: styles.content }, { children: [name, isLive &&
                                            _jsx(Badge, { count: liveEvents.all, size: "small", style: {
                                                    borderRadius: 3,
                                                    borderColor: "transparent",
                                                    fontSize: 9,
                                                    fontWeight: "bold",
                                                    padding: "0px 3px 2px 3px",
                                                    marginLeft: 4
                                                } })] })), _jsx(ImageFromCDN, { alt: "Arrow right", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/keyboard_arrow_right.svg", color: "#1C1B1F", size: 10 })] }), `featured-${name}-${id}`);
                    })] })), _jsxs("div", Object.assign({ className: styles.AZ }, { children: [_jsx("div", Object.assign({ className: clsx(styles.item, styles.title) }, { children: _jsx("div", Object.assign({ className: styles.content }, { children: "A-Z List" })) })), (_d = data === null || data === void 0 ? void 0 : data.sportList) === null || _d === void 0 ? void 0 : _d.map((sport) => {
                        const { imageUrl, sportName, id: sportId, categories } = sport;
                        const SportHeader = getItem({ imageUrl, name: sportName });
                        return _jsx(Accordion, Object.assign({ classes: { root: styles.rootAccordion }, Header: SportHeader, withArrow: false, open: false }, { children: categories.map((category) => {
                                const { name: categoryName, leagues, id: categoryId } = category;
                                const CategoryHeader = getItem({ name: categoryName, withPadding: true, isRegion: true });
                                return _jsx(Accordion, Object.assign({ classes: { root: styles.rootAccordion }, Header: CategoryHeader, withArrow: false, open: false }, { children: leagues.map((league) => {
                                        const { name: leagueName, id: leagueId } = league;
                                        const LeagueItem = getItem({ name: leagueName, withPadding: true, isLeague: true });
                                        const itemClickHandler = () => {
                                            const triggerEventLeagueDetails = new CustomEvent("league-event-details", {
                                                detail: {
                                                    sport: { id: sportId, name: sportName },
                                                    country: {
                                                        id: categoryId,
                                                        name: categoryName
                                                    },
                                                    league: {
                                                        id: leagueId,
                                                        name: leagueName
                                                    }
                                                },
                                                bubbles: true
                                            });
                                            window.dispatchEvent(triggerEventLeagueDetails);
                                        };
                                        return _jsx(LeagueItem, { onClick: itemClickHandler }, `league-${leagueId}`);
                                    }) }), `category-${categoryId}`);
                            }) }), `sport-list-${sportId}`);
                    })] }))] })));
};
