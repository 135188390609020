// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "div.DRrE7NIaIiSKPEs4btGn{background:#080b1e;color:#6c7086;fill:#6c7086;padding:14px 0 0}div.DRrE7NIaIiSKPEs4btGn svg{width:auto !important}div.DRrE7NIaIiSKPEs4btGn .xTZ3hgbzJU9OgGk_W0GD{color:#ffb800;border-color:#ffb800 !important;border-top-left-radius:8px;border-top-right-radius:8px;font-weight:400}div.DRrE7NIaIiSKPEs4btGn .QwFa6HekaLlod2Rmhlec path{fill:#ffb800 !important}div.DRrE7NIaIiSKPEs4btGn .GpmeGIY4eH9mtudsmk6B{max-width:53px}", "",{"version":3,"sources":["webpack://./src/components/composed/sportsNavigation/SportsNavigation.module.scss"],"names":[],"mappings":"AAAA,yBACE,kBAAA,CACA,aAAA,CACA,YAAA,CACA,gBAAA,CAEA,6BACE,qBAAA,CAGF,+CACE,aAAA,CACA,+BAAA,CACA,0BAAA,CACA,2BAAA,CACA,eAAA,CAIF,oDACE,uBAAA,CAGF,+CACE,cAAA","sourcesContent":["div.iconsTabHeaderRoot{\n  background: #080B1E;\n  color: #6C7086;\n  fill: #6C7086;\n  padding: 14px 0 0;\n\n  svg{\n    width: auto !important;\n  }\n\n  .iconsTabHeaderActive{\n    color: #FFB800;\n    border-color: #FFB800 !important;\n    border-top-left-radius: 8px;\n    border-top-right-radius: 8px;\n    font-weight: 400;\n\n  }\n\n  .iconsTabHeaderActiveSVG path{\n    fill: #FFB800 !important;\n  }\n\n  .tab {\n    max-width: 53px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconsTabHeaderRoot": "DRrE7NIaIiSKPEs4btGn",
	"iconsTabHeaderActive": "xTZ3hgbzJU9OgGk_W0GD",
	"iconsTabHeaderActiveSVG": "QwFa6HekaLlod2Rmhlec",
	"tab": "GpmeGIY4eH9mtudsmk6B"
};
export default ___CSS_LOADER_EXPORT___;
