import { jsx as _jsx } from "react/jsx-runtime";
// components
import MountDocumentComponent from "components/documentation/MountDocumentComponent";
export const documentDetails = {
    title: "Footer navigation",
    component: "leetent-footer-navigation",
    description: "This is a base component, that is used as part of many composed components.",
    withSessionComponent: true,
    attributes: [
        {
            key: "1",
            attr: "product-id",
            type: "number",
            notes: [
                "1 - sports",
                "2 - eSports"
            ]
        },
        {
            key: "2",
            attr: "current-route",
            type: "string (optional)",
            notes: [
                "Default value is window.location.pathname"
            ]
        },
        {
            key: "3",
            attr: "betslip-items",
            type: "number",
            notes: [
                "Amount of bets in bet slip"
            ]
        },
        {
            key: "4",
            attr: "total-odds",
            type: "number",
            notes: [
                "Total odds of bet slip bets"
            ]
        },
        {
            key: "5",
            attr: "relative",
            type: "boolean (optional)",
            notes: [
                "If value is true, Footer Navigation will be relative.",
                "Fixed by default."
            ]
        },
        {
            key: "6",
            attr: "menu-options",
            type: "object[] (optional)",
            notes: [
                "Custom menu options.",
                `{
          id?: number
          label: string
          url: string
          Icon?: React.FC<React.SVGProps<SVGSVGElement>>
          isAuthenticationRequired?: boolean
        }`,
                "By default My Bets requires authentication (isAuthenticationRequired: true)"
            ]
        },
        {
            key: "7",
            attr: "max-width",
            type: "string (optional)",
            notes: [
                "By default the max-width is defined as inherit",
                `If necessary, you can define a max-width. e.g. max-width="600px"`,
                "Note: The minimum value acceptable would be 380px"
            ]
        }
    ],
    events: [
        {
            event: "switch-footer-route",
            type: "emit",
            data: [
                "The component details object is emitted on footer navigation tab change.",
                `detail: {
          id: number,
          route: string,
          isAuthenticationRequired: boolean
        }`
            ]
        },
        {
            event: "open-bet-slip",
            type: "emit",
            data: [
                "The component details object is emitted on toggle bet slip.",
                `detail: {
          productId: number
        }`
            ]
        }
    ]
};
const FooterNavigation = () => {
    documentDetails.example = `<leetent-footer-navigation
      product-id={2}
      current-route="/"
      betslip-items={3}
      total-odds={18.82}
      relative
    />`;
    documentDetails.tabs = [
        {
            key: "1",
            label: "Sports",
            children: _jsx("leetent-footer-navigation", { "product-id": 1, "current-route": "/", "betslip-items": 3, "total-odds": 18.82, relative: true })
        },
        {
            key: "2",
            label: "Esports",
            children: _jsx("leetent-footer-navigation", { "product-id": 2, "current-route": "/", "betslip-items": 3, "total-odds": 18.82, relative: true })
        }
    ];
    return _jsx(MountDocumentComponent, { data: documentDetails });
};
export default FooterNavigation;
