import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import clsx from "clsx";
import { Row, Col } from "antd";
import { useShowIndicator } from "hooks/useShowIndicator";
import styles from "./OutcomeGeneric.module.scss";
import ImageFromCDN from "components/base/imageFromCDN/ImageFromCDN";
// Description:
// This is the outcome generic, which is the most granular component.
// It is widely used inside other components, such as markets and/or lists.
// Responsability: How does this supposed to work?
// This component receives a data prop, which is passed on to the state (controlled onMount)
// It gets updated only via event handlers, which is generally triggered by the subscriptions
const OutcomeGeneric = ({ view = "leftRight", handleOnClick = () => null, data, decimals = 2 }) => {
    // define properties based on the view prop
    let rowJustify = "start";
    const rowAlign = "middle";
    let labelSpan = 24;
    let valueSpan = 24;
    switch (view) {
        case "leftRight":
            labelSpan = 17;
            valueSpan = 6;
            break;
        case "leftCenter":
            labelSpan = 11;
            valueSpan = 12;
            break;
        case "topCenter":
            rowJustify = "center";
            break;
        case "center":
            labelSpan = 12;
            valueSpan = 10;
            rowJustify = "space-between";
            break;
        case "noLabel":
            labelSpan = 0;
            valueSpan = 23;
            rowJustify = "space-around";
            break;
        default:
            break;
    }
    ;
    // set value and prevValue when data.value changes, so the variation gets processed
    const [outcome, setOutcome] = React.useState(data);
    const [prevValue, setPrevValue] = React.useState(null);
    const [isSelected, setIsSelected] = React.useState(false);
    const variation = outcome.odds && prevValue ? outcome.odds - prevValue.odds : 0;
    const { showIndicator } = useShowIndicator({ refVariation: variation });
    React.useEffect(() => {
        const broadcastedOddsChanges = (e) => {
            var _a, _b;
            const updatedOutcome = (_b = (_a = e.detail) === null || _a === void 0 ? void 0 : _a.updatedOutcomes) === null || _b === void 0 ? void 0 : _b.find((updatedOutcome) => updatedOutcome.id === outcome.id);
            if (updatedOutcome) {
                setPrevValue(outcome);
                setOutcome(oldObj => (Object.assign(Object.assign({}, oldObj), { odds: updatedOutcome.odds, isOpen: updatedOutcome.isOpen })));
            }
        };
        window.addEventListener("updated-odds-outcomes", broadcastedOddsChanges);
        return () => {
            window.removeEventListener("updated-odds-outcomes", broadcastedOddsChanges);
        };
    }, []);
    // this component is responsible for present the flags related to the outcome
    const LockOrFlag = () => {
        if (!(outcome === null || outcome === void 0 ? void 0 : outcome.isOpen)) {
            return (_jsx(ImageFromCDN, { "data-testid": "input-generic-lock", alt: "Suspended selection", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/lock.svg", size: 8, overrideStyles: {
                    position: "absolute",
                    top: "4px",
                    right: "5px"
                } }));
        }
        // renders related flags when applicable
        const arrowSvgStyles = {
            position: "absolute",
            top: 5,
            right: 8
        };
        return (_jsx(Col, Object.assign({ span: 1, className: styles.variation }, { children: variation === 0 || !showIndicator ?
                _jsx(_Fragment, {}) :
                variation > 0 ? // going up
                    (_jsx(ImageFromCDN, { alt: "Arrow Up", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/arrow_up.svg", size: 8, overrideStyles: arrowSvgStyles })) :
                    (variation < 0 && // going down
                        (_jsx(ImageFromCDN, { alt: "Arrow Down", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/arrow_down.svg", size: 8, overrideStyles: arrowSvgStyles }))) })));
    };
    // this component is responsible for labeling the outcome
    const LabelValue = () => {
        if (view === "topCenter") { // this defines a vertical layout
            return (_jsx(_Fragment, { children: _jsx(Col, Object.assign({ span: 23 }, { children: _jsxs(Row, Object.assign({ style: { textAlign: "center" } }, { children: [_jsx(Col, Object.assign({ span: labelSpan, className: clsx(styles.label, {
                                    [styles.labelLeft]: view.startsWith("left"),
                                    [styles.labelTop]: view === "topCenter",
                                    [styles.spacing]: view !== "topCenter"
                                }) }, { children: _jsx("small", Object.assign({ "data-testid": "input-generic-label" }, { children: outcome === null || outcome === void 0 ? void 0 : outcome.name })) })), _jsx(Col, Object.assign({ span: valueSpan, className: clsx(styles.value) }, { children: _jsx("small", Object.assign({ "data-testid": "input-generic-value" }, { children: outcome.isOpen ? outcome.odds.toFixed(decimals) : "-" })) }))] })) })) }));
        }
        return (_jsxs(_Fragment, { children: [view !== "noLabel" &&
                    _jsx(Col, Object.assign({ span: labelSpan, className: clsx(styles.label, {
                            [styles.labelLeft]: view.startsWith("left"),
                            [styles.labelRight]: view === "center"
                        }) }, { children: _jsx("small", Object.assign({ "data-testid": "input-generic-label" }, { children: outcome === null || outcome === void 0 ? void 0 : outcome.name })) })), _jsx(Col, Object.assign({ span: valueSpan, className: clsx(styles.value, {
                        [styles.valueLeft]: view === "leftCenter" || view === "center",
                        [styles.valueCenter]: view === "noLabel",
                        [styles.alignToEnd]: view.startsWith("left")
                    }) }, { children: _jsx("small", Object.assign({ "data-testid": "input-generic-value" }, { children: outcome.isOpen ? outcome.odds.toFixed(decimals) : "-" })) }))] }));
    };
    // renders the component
    return (_jsx(_Fragment, { children: _jsxs(Row, Object.assign({ justify: rowJustify, align: rowAlign, className: clsx(styles.root, {
                [styles.suspended]: !(outcome === null || outcome === void 0 ? void 0 : outcome.isOpen),
                [styles.selected]: isSelected
            }), onClick: () => {
                if (outcome === null || outcome === void 0 ? void 0 : outcome.isOpen) {
                    setIsSelected(!isSelected);
                    handleOnClick(!isSelected ? "select" : "unselect", {
                        id: outcome === null || outcome === void 0 ? void 0 : outcome.id,
                        name: outcome === null || outcome === void 0 ? void 0 : outcome.name,
                        odds: outcome === null || outcome === void 0 ? void 0 : outcome.odds,
                        isOpen: outcome === null || outcome === void 0 ? void 0 : outcome.isOpen
                    });
                }
            } }, { children: [_jsx(LabelValue, {}), _jsx(LockOrFlag, {})] }), `input-generic-${outcome.id}`) }));
};
export default OutcomeGeneric;
