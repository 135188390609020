import { jsx as _jsx } from "react/jsx-runtime";
import BothTeamsToScore from "../../../../v2/components/MarketViews/Single/BothTeamsToScore";
// components
import MountDocumentComponent from "components/documentation/MountDocumentComponent";
const componentObj = {
    productId: 1,
    category: {
        id: 1,
        name: "soccer",
        imageUrl: ""
    },
    tournament: {
        id: 1,
        name: "Brasileirão",
        isPopular: true,
        priorityOrder: 1,
        country: {
            id: 1,
            name: "Brazil",
            imageUrl: ""
        }
    },
    event: {
        id: 1,
        name: "",
        homeTeam: "",
        awayTeam: "",
        marketCount: 10,
        date: "",
        time: "",
        statusId: 18
    },
    market: {
        id: 1,
        marketTypeConfigId: 30,
        name: "Both Teams to Score",
        // line: null,
        // playerName: null,
        statusId: 10
    },
    outcomes: [{
            id: 120649,
            name: "Yes",
            // key: null,
            odds: 9.31,
            isOpen: true,
            statusId: 10
        },
        {
            id: 120650,
            name: "No",
            // key: null,
            odds: 6.22,
            isOpen: true,
            statusId: 10
        }]
};
export const documentDetails = {
    title: "Market Views",
    component: "Different Market View components",
    description: "This is a base component, that is used to compose a market and its related outcomes.",
    attributes: [],
    events: []
};
const MarketViews = () => {
    documentDetails.example = `<BothTeamsToScore
    data=${JSON.stringify(componentObj)}
  />`;
    documentDetails.code = _jsx(BothTeamsToScore, { data: componentObj });
    // render the page based on the provided object
    return _jsx(MountDocumentComponent, { data: documentDetails });
};
export default MarketViews;
