var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var _a;
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { createRoot } from "react-dom/client";
import axios from "axios";
import { LocalStorage } from "helpers/Storage";
import { generateEventDetailBreadcrumbData } from "helpers/Sports/Breadcrumbs";
import SkeletonEventDetailHeader from "components/base/skeleton/EventDetailHeader";
import { triggerUserUnauthenticatedEvent } from "helpers/events/triggerUserUnauthenticatedEvent";
const productId = 1;
const SingleEventPage = ({ defaultSportId, seriesId, isRelative, menuOptions, unauthenticatedModalTitle, unauthenticatedModalOptions, footerNavigationMaxWidth, betSlipMaxWidth }) => {
    const eventId = seriesId; // TODO: Read it also from the URL
    const [sportId, setSportId] = React.useState(defaultSportId);
    const [event, setEvent] = React.useState();
    const [sports, setSports] = React.useState([]);
    const [headerData, setHeaderData] = React.useState();
    const [headerIsLoading, setHeaderIsLoading] = React.useState(false);
    const [bets, setBets] = React.useState(Array.isArray(LocalStorage.get("betsDetails")) ? LocalStorage.get("betsDetails") : []);
    const [betslipOpen, setBetslipOpen] = React.useState(false);
    const [modalOpen, setModalOpen] = React.useState(false);
    const [unauthenticatedTitle, setUnauthenticatedTitle] = React.useState(unauthenticatedModalTitle);
    const [tryingToAccess, setTryingToAccess] = React.useState();
    React.useEffect(() => {
        if (eventId) {
            // fetches the event details
            const fetchEvent = () => {
                var _a, _b;
                if (!((_a = process.env) === null || _a === void 0 ? void 0 : _a.REACT_APP_SPORTSBOOK_API_URL) || !((_b = process.env) === null || _b === void 0 ? void 0 : _b.REACT_APP_SPORTSBOOK_API_KEY))
                    return;
                setHeaderIsLoading(true); // mark header as being loaded
                axios
                    .get(`/events/${String(eventId)}`)
                    .then((res) => {
                    setEvent(res.data);
                })
                    .catch((e) => {
                    setEvent(e);
                });
            };
            fetchEvent();
            // fetches the sports list
            const fetchSportsList = () => {
                var _a, _b;
                if (!((_a = process.env) === null || _a === void 0 ? void 0 : _a.REACT_APP_SPORTSBOOK_API_URL) || !((_b = process.env) === null || _b === void 0 ? void 0 : _b.REACT_APP_SPORTSBOOK_API_KEY))
                    return;
                axios
                    .get(`/sports`)
                    .then((res) => {
                    setSports(res.data);
                })
                    .catch((e) => {
                    setSports(e);
                });
            };
            fetchSportsList();
        }
    }, []);
    // Events listeners
    React.useEffect(() => {
        const footerDetailsHandler = (e) => {
            var _a, _b, _c, _d;
            e.preventDefault();
            const optionBeingOverriden = menuOptions === null || menuOptions === void 0 ? void 0 : menuOptions.find(option => { var _a; return (option === null || option === void 0 ? void 0 : option.id) === ((_a = e.detail) === null || _a === void 0 ? void 0 : _a.id); });
            // It checks the user is unauthenticated, and if so, it emits an event and stop processing it
            if ((_a = e.detail) === null || _a === void 0 ? void 0 : _a.isAuthenticationRequired) {
                if (!LocalStorage.get("widget-playerId")) {
                    triggerUserUnauthenticatedEvent((_b = e.detail) === null || _b === void 0 ? void 0 : _b.route);
                    return;
                }
            }
            // Redirect to the customers URL, in case url override is provided
            if (optionBeingOverriden === null || optionBeingOverriden === void 0 ? void 0 : optionBeingOverriden.url) {
                window.location.href = e.detail.route;
            }
            else if (((_c = e.detail) === null || _c === void 0 ? void 0 : _c.route) === "/profile" || ((_d = e.detail) === null || _d === void 0 ? void 0 : _d.route) === "/social") {
                window.location.href = e.detail.route;
            }
        };
        const toggleBetSlipHandler = (e) => {
            e.preventDefault();
            setBetslipOpen(true);
        };
        const closeBetSlipHandler = (e) => {
            e.preventDefault();
            setBetslipOpen(false);
        };
        const updateSelectedBetsHandler = (e) => {
            e.preventDefault();
            setBets([...e.detail.selectedBets]);
        };
        const matchesByMarketHandler = (e) => {
            console.log(e);
            e.preventDefault();
            const bets = Array.isArray(LocalStorage.get("betsDetails")) ? LocalStorage.get("betsDetails") : [];
            const isBetAlreadyInPlace = bets.find((bet) => bet.outcomeId === e.detail.outcomeId);
            let updatedBets = [...bets];
            if (!isBetAlreadyInPlace && e.detail.operation === "select") {
                const _a = e.detail, { marketName, name, matchId } = _a, rest = __rest(_a, ["marketName", "name", "matchId"]);
                if (e.detail.isOpen) {
                    const newBet = Object.assign(Object.assign({}, rest), { market: marketName, pick: name, eventId: matchId, productId });
                    updatedBets.push(newBet);
                }
            }
            else if (isBetAlreadyInPlace && e.detail.operation === "unselect") {
                updatedBets = updatedBets.filter((bet) => !(bet.outcomeId === e.detail.outcomeId));
            }
            LocalStorage.set("betsDetails", JSON.stringify(updatedBets));
            setBets(updatedBets);
        };
        const betslipConfirmationCloseHandler = () => {
            setBets(LocalStorage.get("betsDetails"));
        };
        const closeUnauthenticatedModal = (e) => {
            e.preventDefault();
            setModalOpen(false);
        };
        const userUnauthenticatedModal = (e) => {
            var _a;
            e.preventDefault();
            if (e.detail.destination.includes("mybets")) {
                setUnauthenticatedTitle("Please Proceed to See My Bets");
            }
            setModalOpen(true);
            setTryingToAccess((_a = e.detail) === null || _a === void 0 ? void 0 : _a.destination);
        };
        const selectedExternalOutcomeDetails = (e) => {
            var _a;
            e.preventDefault();
            const bets = Array.isArray(LocalStorage.get("betsDetails")) ? LocalStorage.get("betsDetails") : [];
            const isBetAlreadyInPlace = bets.find((bet) => bet.outcomeId === e.detail.outcomeId);
            let updatedBets = [...bets];
            if (!isBetAlreadyInPlace && e.detail.operation === "select") {
                if (e.detail.isOpen) {
                    const newBet = {
                        market: e.detail.marketName,
                        pick: e.detail.name,
                        event: e.detail.event,
                        odds: e.detail.odds,
                        time: e.detail.time,
                        outcomeId: e.detail.outcomeId,
                        eventId: e.detail.matchId,
                        productId,
                        isBetBuilderEnabled: (_a = e.detail) === null || _a === void 0 ? void 0 : _a.isBetBuilderEnabled
                    };
                    updatedBets.push(newBet);
                }
            }
            else if (isBetAlreadyInPlace && e.detail.operation === "unselect") {
                updatedBets = updatedBets.filter((bet) => !(bet.outcomeId === e.detail.outcomeId));
            }
            LocalStorage.set("betsDetails", JSON.stringify(updatedBets));
            setBets(updatedBets);
        };
        window.addEventListener("selected-external-outcome-details", selectedExternalOutcomeDetails);
        window.addEventListener("bet-slip-confirmation-close", betslipConfirmationCloseHandler);
        window.addEventListener("switch-footer-route", footerDetailsHandler);
        window.addEventListener("open-bet-slip", toggleBetSlipHandler);
        window.addEventListener("close-bet-slip", closeBetSlipHandler);
        window.addEventListener("update-selected-bets", updateSelectedBetsHandler);
        window.addEventListener("odds-singleevent-input-details", matchesByMarketHandler);
        window.addEventListener("close-auth-modal", closeUnauthenticatedModal);
        window.addEventListener("user-unauthenticated", userUnauthenticatedModal);
        return () => {
            window.removeEventListener("selected-external-outcome-details", selectedExternalOutcomeDetails);
            window.removeEventListener("bet-slip-confirmation-close", betslipConfirmationCloseHandler);
            window.removeEventListener("switch-footer-route", footerDetailsHandler);
            window.removeEventListener("open-bet-slip", toggleBetSlipHandler);
            window.removeEventListener("close-bet-slip", closeBetSlipHandler);
            window.removeEventListener("update-selected-bets", updateSelectedBetsHandler);
            window.removeEventListener("odds-singleevent-input-details", matchesByMarketHandler);
            window.removeEventListener("close-auth-modal", closeUnauthenticatedModal);
            window.removeEventListener("user-unauthenticated", userUnauthenticatedModal);
        };
    }, []);
    // after finding the sport and tournament details associated with the event
    // we process the information, normalising the data that is to be used
    const ProcessResponse = (data) => {
        var _a, _b, _c;
        // check sport, country and league details are in place
        if ((data === null || data === void 0 ? void 0 : data.id) && ((_a = data === null || data === void 0 ? void 0 : data.categories) === null || _a === void 0 ? void 0 : _a.id) && ((_c = (_b = data === null || data === void 0 ? void 0 : data.categories) === null || _b === void 0 ? void 0 : _b.tournaments) === null || _c === void 0 ? void 0 : _c.id)) {
            return {
                sportId: data.id,
                sportName: data.name,
                countryId: data.categories.id,
                countryName: data.categories.name,
                leagueId: data.categories.tournaments.id,
                leagueName: data.categories.tournaments.name,
                eventDetails: event
                // TODO: for the meta information, we might want to retrieve it via custom event or
                // ask clients to store them to the localStorage, so it can be fetched and embeded
                // ...getSportBookMeta(
                //   {
                //     pageType: SportsPageType.SingleEventPage,
                //     sport: data.name,
                //     country: data.categories.name,
                //     league: categories.categories.tournaments.name,
                //   },
                //   event,
                // ),
            };
        }
    };
    //
    const getSameLeagueEvents = (tournamentId) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        if (!((_a = process.env) === null || _a === void 0 ? void 0 : _a.REACT_APP_SPORTSBOOK_API_URL) || !((_b = process.env) === null || _b === void 0 ? void 0 : _b.REACT_APP_SPORTSBOOK_API_KEY))
            return;
        const response = yield axios
            .get(`tournament/events?tournamentId=${tournamentId}`)
            .then((res) => {
            return res.data;
        })
            .catch((e) => {
            return e;
        });
        return response;
    });
    //
    const generateEventDetailHeader = (eventDetails, leagueName, leagueId, sportId, sportName, countryName) => __awaiter(void 0, void 0, void 0, function* () {
        const eventName = eventDetails.name;
        const eventId = eventDetails.id;
        const eventDetail = {
            id: eventId,
            name: eventName,
            homeTeam: eventDetails.homeTeam,
            awayTeam: eventDetails.awayTeam,
            inPlay: eventDetails.inPlay,
            leagueName,
            countryName,
            sportId,
            sportName,
            startDate: eventDetails.startDate,
            statusId: eventDetails.statusId
        };
        // const sameLeagueEvents: League[] = getSameLeagueEvents(leagueId);
        const sameLeagueEvents = yield getSameLeagueEvents(leagueId);
        const breadcrumbsData = generateEventDetailBreadcrumbData({
            sportName,
            countryName,
            leagueName,
            eventName,
            eventId
        });
        const eventDetailHeader = {
            data: {
                eventDetail,
                otherEvents: sameLeagueEvents[0].events
            },
            breadcrumbs: breadcrumbsData
        };
        return eventDetailHeader;
    });
    // considering the sport id has not been provided and that the sports list
    // is in place, then we can find the sport related information based on the event category and tournament
    React.useEffect(() => {
        var _a, _b, _c, _d;
        if (!sportId && (sports === null || sports === void 0 ? void 0 : sports.length) > 0 && (event === null || event === void 0 ? void 0 : event.id)) {
            const country = (_a = event === null || event === void 0 ? void 0 : event.tournament) === null || _a === void 0 ? void 0 : _a.category;
            const tournament = { id: (_b = event === null || event === void 0 ? void 0 : event.tournament) === null || _b === void 0 ? void 0 : _b.id, name: (_c = event === null || event === void 0 ? void 0 : event.tournament) === null || _c === void 0 ? void 0 : _c.name };
            // TODO: I can obtain the league and country details from this iteraction
            const sportsByCountryTournamentId = (_d = structuredClone(sports)) === null || _d === void 0 ? void 0 : _d.find((sport) => {
                const newCategories = sport.categories.filter((category) => {
                    let newTournamentList;
                    if (category.id === country.id) {
                        newTournamentList = category.tournaments.find((t) => t.id === tournament.id);
                    }
                    category.tournaments = newTournamentList;
                    return category.tournaments !== undefined;
                });
                sport.categories = newCategories[0];
                return sport.categories !== undefined;
            });
            const response = ProcessResponse(sportsByCountryTournamentId);
            generateEventDetailHeader(response.eventDetails, response.leagueName, response.leagueId, response.sportId, response.sportName, response.countryName).then((res) => {
                setHeaderData(res);
            }).finally(() => {
                // once the header is generated, independently of the error, then we marke it as loaded
                setHeaderIsLoading(false);
            });
            (sportsByCountryTournamentId === null || sportsByCountryTournamentId === void 0 ? void 0 : sportsByCountryTournamentId.id) && setSportId(sportsByCountryTournamentId.id);
        }
    }, [event, sports]);
    if (headerIsLoading) {
        return (_jsx(SkeletonEventDetailHeader, {}));
    }
    ;
    return (_jsxs(_Fragment, { children: [headerData && _jsx("leetent-single-event-detail-header", { data: JSON.stringify(headerData === null || headerData === void 0 ? void 0 : headerData.data), breadcrumbs: JSON.stringify(headerData === null || headerData === void 0 ? void 0 : headerData.breadcrumbs) }), eventId && _jsx("leetent-odds-outcomes", { "product-id": productId, "series-id": eventId }), _jsx("leetent-unauthenticated-user-modal", { open: modalOpen, "modal-title": unauthenticatedTitle, options: unauthenticatedModalOptions && JSON.stringify(unauthenticatedModalOptions), destination: tryingToAccess }), _jsx("leetent-footer-navigation", { "product-id": productId, bets: JSON.stringify(bets), relative: isRelative, "menu-options": JSON.stringify(menuOptions), "max-width": footerNavigationMaxWidth }), _jsx("leetent-bet-slip", { open: betslipOpen, "product-id": productId, bets: JSON.stringify(bets.filter((bet) => (bet === null || bet === void 0 ? void 0 : bet.productId) === productId)), "unauthenticated-modal-title": unauthenticatedTitle, "unauthenticated-modal-options": JSON.stringify(unauthenticatedModalOptions), "max-width": betSlipMaxWidth })] }));
};
class Element extends HTMLElement {
    constructor() {
        super();
        this.root = createRoot(this);
    }
    renderComponent() {
        var _a;
        (_a = this.root) === null || _a === void 0 ? void 0 : _a.render(_jsx(SingleEventPage, { defaultSportId: this["default-sport-id"], seriesId: this["series-id"], isRelative: this.relative, menuOptions: this["menu-options"], unauthenticatedModalTitle: this["unauthenticated-modal-title"], unauthenticatedModalOptions: this["unauthenticated-modal-options"], footerNavigationMaxWidth: this["footer-navigation-max-width"], betSlipMaxWidth: this["betslip-max-width"] }));
    }
    connectedCallback() {
        this.renderComponent();
    }
    disconnectedCallback() {
        setTimeout(() => { var _a; return (_a = this.root) === null || _a === void 0 ? void 0 : _a.unmount(); }, 0);
    }
    static get observedAttributes() {
        return [
            "default-sport-id",
            "series-id",
            "relative",
            "menu-options",
            "unauthenticated-modal-title",
            "unauthenticated-modal-options",
            "footer-navigation-max-width",
            "betslip-max-width"
        ];
    }
    attributeChangedCallback(attrName, _oldValue, newValue) {
        switch (attrName) {
            case "default-sport-id":
            case "series-id":
                this[attrName] = +newValue;
                break;
            case "unauthenticated-modal-title":
            case "footer-navigation-max-width":
            case "betslip-max-width":
                this[attrName] = String(newValue);
                break;
            case "relative":
            case "menu-options":
            case "unauthenticated-modal-options":
                this[attrName] = JSON.parse(newValue);
                break;
            default:
                break;
        }
        this.renderComponent();
    }
}
(_a = customElements.get("leetent-sports-single-event-page")) !== null && _a !== void 0 ? _a : customElements.define("leetent-sports-single-event-page", Element);
