// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".q4XnaXeluLWL3X8kTLAf{min-width:100%;padding:5% 5% 7% 5%;background-color:#f2f2f2;display:flex;justify-content:center;text-align:center}html[data-theme=dark] .q4XnaXeluLWL3X8kTLAf{color:var(--darkColor);background-color:var(--darkLighterBackground)}.faFzd7tkA5fkJ2kkkYaV{display:flex;justify-content:center}.r1K1UCoDBKJTf5EOReI4{margin-top:15px;padding:0px 30px;color:#000}", "",{"version":3,"sources":["webpack://./src/components/base/noDataForMarket/NoDataForMarket.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,mBAAA,CACA,wBAAA,CACA,YAAA,CACA,sBAAA,CACA,iBAAA,CACA,4CACE,sBAAA,CACA,6CAAA,CAIJ,sBACE,YAAA,CACA,sBAAA,CAGF,sBACE,eAAA,CACA,gBAAA,CACA,UAAA","sourcesContent":[".noDataMarket {\n  min-width: 100%;\n  padding: 5% 5% 7% 5%;\n  background-color: #f2f2f2;\n  display: flex;\n  justify-content: center;\n  text-align: center;\n  html[data-theme=\"dark\"] & {\n    color: var(--darkColor);\n    background-color: var(--darkLighterBackground);\n  }\n}\n\n.noOddsImage {\n  display: flex;\n  justify-content: center;\n}\n\n.noOddsMessage {\n  margin-top: 15px;\n  padding: 0px 30px;\n  color: black;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noDataMarket": "q4XnaXeluLWL3X8kTLAf",
	"noOddsImage": "faFzd7tkA5fkJ2kkkYaV",
	"noOddsMessage": "r1K1UCoDBKJTf5EOReI4"
};
export default ___CSS_LOADER_EXPORT___;
