var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var _a;
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { createRoot } from "react-dom/client";
import { LocalStorage } from "helpers/Storage";
import { triggerUserUnauthenticatedEvent } from "helpers/events/triggerUserUnauthenticatedEvent";
import { getSportLabelById } from "helpers/Sports/LeagueFunctions";
const productId = 1;
const SportsWrapperFeatured = ({ isRelative, menuOptions, unauthenticatedModalTitle, unauthenticatedModalOptions, footerNavigationMaxWidth, noDataOptions, defaultEventState, betSlipMaxWidth, defaultSportId }) => {
    const [listeners, setListeners] = React.useState([{
            event: "navigation-featured-details",
            detail: null
        }]);
    const [bets, setBets] = React.useState(Array.isArray(LocalStorage.get("betsDetails")) ? LocalStorage.get("betsDetails") : []);
    const [betslipOpen, setBetslipOpen] = React.useState(false);
    const [modalOpen, setModalOpen] = React.useState(false);
    const [unauthenticatedTitle, setUnauthenticatedTitle] = React.useState(unauthenticatedModalTitle);
    const [tryingToAccess, setTryingToAccess] = React.useState();
    const [shouldRedirectToComponent, setShouldRedirectToComponent] = React.useState();
    // If defaultSportId provided emit the same event as in SportsNavigation
    React.useEffect(() => {
        if (defaultSportId) {
            // TODO: rewatch the solution
            // Set as a macrotask by setTimeout to emit it before listening the event
            setTimeout(() => {
                window.dispatchEvent(new CustomEvent("selected-icon-tab-details", {
                    detail: {
                        id: defaultSportId,
                        label: getSportLabelById(defaultSportId),
                        type: "sports-navigation"
                    },
                    bubbles: true
                }));
            });
        }
    }, [defaultSportId]);
    React.useEffect(() => {
        const footerDetailsHandler = (e) => {
            var _a, _b, _c, _d, _e, _f, _g;
            e.preventDefault();
            const optionBeingOverriden = menuOptions === null || menuOptions === void 0 ? void 0 : menuOptions.find(option => { var _a; return (option === null || option === void 0 ? void 0 : option.id) === ((_a = e.detail) === null || _a === void 0 ? void 0 : _a.id); });
            const listener = listeners.find(listener => listener.event === "switch-footer-route");
            // It checks the user is unauthenticated, and if so, it emits an event and stop processing it
            if (((_a = e.detail) === null || _a === void 0 ? void 0 : _a.isAuthenticationRequired) === true) {
                if (!LocalStorage.get("widget-playerId")) {
                    triggerUserUnauthenticatedEvent((_b = e.detail) === null || _b === void 0 ? void 0 : _b.route);
                    return;
                }
            }
            if (listener) {
                listener.detail = e.detail;
            }
            else {
                listeners.push({
                    event: "switch-footer-route",
                    detail: e.detail
                });
            }
            // Redirect to the customers URL, in case url override is provided
            if (optionBeingOverriden === null || optionBeingOverriden === void 0 ? void 0 : optionBeingOverriden.url) {
                window.location.href = e.detail.route;
            }
            else if (((_c = e.detail) === null || _c === void 0 ? void 0 : _c.route) === "/profile" || ((_d = e.detail) === null || _d === void 0 ? void 0 : _d.route) === "/social") {
                window.location.href = e.detail.route;
            }
            else {
                if (((_e = e.detail) === null || _e === void 0 ? void 0 : _e.route) === "/") {
                    setShouldRedirectToComponent("leetent-league-single-market-featured");
                }
                else if (((_f = e.detail) === null || _f === void 0 ? void 0 : _f.route) === "/redirect-to-component-mybets") {
                    setShouldRedirectToComponent("leetent-my-bets");
                }
                else if (((_g = e.detail) === null || _g === void 0 ? void 0 : _g.route) === "/redirect-to-component-search") {
                    setShouldRedirectToComponent("leetent-country-leagues-search");
                }
            }
            setListeners([...listeners]);
        };
        const toggleBetSlipHandler = (e) => {
            e.preventDefault();
            const listener = listeners.find(listener => listener.event === "open-bet-slip");
            if (listener) {
                listener.detail = e.detail;
            }
            else {
                listeners.push({
                    event: "open-bet-slip",
                    detail: e.detail
                });
            }
            // TODO: Only adds if it is not already in place
            setListeners([...listeners]);
            setBetslipOpen(true);
        };
        const closeBetSlipHandler = (e) => {
            e.preventDefault();
            setBetslipOpen(false);
        };
        const updateSelectedBetsHandler = (e) => {
            e.preventDefault();
            setBets([...e.detail.selectedBets]);
        };
        const matchesByMarketHandler = (e) => {
            e.preventDefault();
            const listener = listeners.find(listener => listener.event === "matches-bymarket-input-details");
            if (listener) {
                listener.detail = e.detail;
            }
            else {
                listeners.push({
                    event: "matches-bymarket-input-details",
                    detail: e.detail
                });
            }
            setListeners([...listeners]);
            // add selection to the betslip
            // add to local storage in every case
            const bets = Array.isArray(LocalStorage.get("betsDetails")) ? LocalStorage.get("betsDetails") : [];
            const isBetAlreadyInPlace = bets.find((bet) => bet.outcomeId === e.detail.outcomeId);
            let updatedBets = [...bets];
            if (!isBetAlreadyInPlace && e.detail.operation === "select") {
                const _a = e.detail, { marketName, name, matchId } = _a, rest = __rest(_a, ["marketName", "name", "matchId"]);
                if (e.detail.isOpen) {
                    const newBet = Object.assign(Object.assign({}, rest), { market: marketName, pick: name, eventId: matchId, productId });
                    updatedBets.push(newBet);
                }
            }
            else if (isBetAlreadyInPlace && e.detail.operation === "unselect") {
                updatedBets = updatedBets.filter((bet) => !(bet.outcomeId === e.detail.outcomeId));
            }
            LocalStorage.set("betsDetails", JSON.stringify(updatedBets));
            setBets(updatedBets);
        };
        const betslipConfirmationCloseHandler = () => {
            setBets(LocalStorage.get("betsDetails"));
        };
        const closeUnauthenticatedModal = (e) => {
            e.preventDefault();
            setModalOpen(false);
        };
        const clickAuthButton = (e) => {
            e.preventDefault();
            // TODO: This might be required in the future
            console.log(e.detail);
        };
        const userUnauthenticatedModal = (e) => {
            var _a;
            e.preventDefault();
            if (e.detail.destination.includes("mybets")) {
                setUnauthenticatedTitle("Please Proceed to See My Bets");
            }
            setModalOpen(true);
            setTryingToAccess((_a = e.detail) === null || _a === void 0 ? void 0 : _a.destination);
        };
        const selectedExternalOutcomeDetails = (e) => {
            var _a;
            e.preventDefault();
            const bets = Array.isArray(LocalStorage.get("betsDetails")) ? LocalStorage.get("betsDetails") : [];
            const isBetAlreadyInPlace = bets.find((bet) => bet.outcomeId === e.detail.outcomeId);
            let updatedBets = [...bets];
            if (!isBetAlreadyInPlace && e.detail.operation === "select") {
                if (e.detail.isOpen) {
                    const newBet = {
                        market: e.detail.marketName,
                        pick: e.detail.name,
                        event: e.detail.event,
                        odds: e.detail.odds,
                        time: e.detail.time,
                        outcomeId: e.detail.outcomeId,
                        eventId: e.detail.matchId,
                        productId,
                        isBetBuilderEnabled: (_a = e.detail) === null || _a === void 0 ? void 0 : _a.isBetBuilderEnabled
                    };
                    updatedBets.push(newBet);
                }
            }
            else if (isBetAlreadyInPlace && e.detail.operation === "unselect") {
                updatedBets = updatedBets.filter((bet) => !(bet.outcomeId === e.detail.outcomeId));
            }
            LocalStorage.set("betsDetails", JSON.stringify(updatedBets));
            setBets(updatedBets);
        };
        window.addEventListener("selected-external-outcome-details", selectedExternalOutcomeDetails);
        window.addEventListener("bet-slip-confirmation-close", betslipConfirmationCloseHandler);
        window.addEventListener("switch-footer-route", footerDetailsHandler);
        window.addEventListener("open-bet-slip", toggleBetSlipHandler);
        window.addEventListener("close-bet-slip", closeBetSlipHandler);
        window.addEventListener("update-selected-bets", updateSelectedBetsHandler);
        window.addEventListener("odds-singleevent-input-details", matchesByMarketHandler);
        window.addEventListener("matches-bymarket-input-details", matchesByMarketHandler);
        window.addEventListener("close-auth-modal", closeUnauthenticatedModal);
        window.addEventListener("click-auth-button", clickAuthButton);
        window.addEventListener("user-unauthenticated", userUnauthenticatedModal);
        return () => {
            window.removeEventListener("selected-external-outcome-details", selectedExternalOutcomeDetails);
            window.removeEventListener("bet-slip-confirmation-close", betslipConfirmationCloseHandler);
            window.removeEventListener("switch-footer-route", footerDetailsHandler);
            window.removeEventListener("open-bet-slip", toggleBetSlipHandler);
            window.removeEventListener("close-bet-slip", closeBetSlipHandler);
            window.removeEventListener("update-selected-bets", updateSelectedBetsHandler);
            window.removeEventListener("odds-singleevent-input-details", matchesByMarketHandler);
            window.removeEventListener("matches-bymarket-input-details", matchesByMarketHandler);
            window.removeEventListener("close-auth-modal", closeUnauthenticatedModal);
            window.removeEventListener("click-auth-button", clickAuthButton);
            window.removeEventListener("user-unauthenticated", userUnauthenticatedModal);
        };
    }, []);
    return (_jsxs(_Fragment, { children: [!shouldRedirectToComponent || shouldRedirectToComponent === "leetent-league-single-market-featured" ?
                (_jsxs(_Fragment, { children: [_jsx("leetent-navigation-featured", { "product-id": productId, "default-sport-id": defaultSportId, "default-event-state": defaultEventState, exact: true }), _jsx("leetent-league-single-market-featured", { "product-id": productId, "no-data-options": JSON.stringify(noDataOptions), "default-sport-id": defaultSportId })] })) :
                shouldRedirectToComponent === "leetent-my-bets" ?
                    _jsx("leetent-my-bets", { "product-id": productId, "no-data-options": JSON.stringify(noDataOptions) }) :
                    shouldRedirectToComponent === "leetent-country-leagues-search" &&
                        _jsx("leetent-country-leagues-search", { "with-header": true, "with-search": true }), _jsx("leetent-unauthenticated-user-modal", { open: modalOpen, "modal-title": unauthenticatedTitle, options: unauthenticatedModalOptions && JSON.stringify(unauthenticatedModalOptions), destination: tryingToAccess }), _jsx("leetent-footer-navigation", { "product-id": productId, bets: JSON.stringify(bets), relative: isRelative, "menu-options": JSON.stringify(menuOptions), "max-width": footerNavigationMaxWidth }), _jsx("leetent-bet-slip", { open: betslipOpen, "product-id": productId, bets: JSON.stringify(bets.filter((bet) => (bet === null || bet === void 0 ? void 0 : bet.productId) === productId)), "unauthenticated-modal-title": unauthenticatedTitle, "unauthenticated-modal-options": JSON.stringify(unauthenticatedModalOptions), "max-width": betSlipMaxWidth })] }));
};
class Element extends HTMLElement {
    constructor() {
        super();
        this.root = createRoot(this);
    }
    renderComponent() {
        var _a;
        (_a = this.root) === null || _a === void 0 ? void 0 : _a.render(_jsx(SportsWrapperFeatured, { isRelative: this.relative, menuOptions: this["menu-options"], unauthenticatedModalTitle: this["unauthenticated-modal-title"], unauthenticatedModalOptions: this["unauthenticated-modal-options"], footerNavigationMaxWidth: this["footer-navigation-max-width"], noDataOptions: this["no-data-options"], defaultEventState: this["default-event-state"], betSlipMaxWidth: this["betslip-max-width"], defaultSportId: this["default-sport-id"] }));
    }
    connectedCallback() {
        this.renderComponent();
    }
    disconnectedCallback() {
        setTimeout(() => { var _a; return (_a = this.root) === null || _a === void 0 ? void 0 : _a.unmount(); }, 0);
    }
    static get observedAttributes() {
        return [
            "relative",
            "menu-options",
            "unauthenticated-modal-title",
            "unauthenticated-modal-options",
            "footer-navigation-max-width",
            "no-data-options",
            "default-event-state",
            "betslip-max-width",
            "default-sport-id"
        ];
    }
    attributeChangedCallback(attrName, _oldValue, newValue) {
        switch (attrName) {
            case "unauthenticated-modal-title":
            case "footer-navigation-max-width":
            case "betslip-max-width":
                this[attrName] = String(newValue);
                break;
            case "relative":
            case "menu-options":
            case "unauthenticated-modal-options":
            case "no-data-options":
                this[attrName] = JSON.parse(newValue);
                break;
            case "default-event-state":
                this[attrName] = String(newValue);
                break;
            case "default-sport-id":
                this[attrName] = +newValue;
                break;
            default:
                break;
        }
        this.renderComponent();
    }
}
(_a = customElements.get("leetent-sports-wrapper-featured")) !== null && _a !== void 0 ? _a : customElements.define("leetent-sports-wrapper-featured", Element);
