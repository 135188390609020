// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/images/angle-down.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".oNkj77ZZW77XrySSK1r7{display:flex;align-items:center;justify-content:center;background:#f3f5f8;width:calc(100% - 10px);margin:5px;border:.5px solid #c3c3c3;border-radius:3px;color:#0d1335;font-family:Roboto;font-size:14px;padding:10px}.Xs8pMHLbEvvmutub64gT{width:10px;height:10px;background-position:center;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:10px;background-repeat:no-repeat;margin-left:6px}", "",{"version":3,"sources":["webpack://./src/components/base/markets/ShowMoreLess.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,kBAAA,CACA,uBAAA,CACA,UAAA,CACA,yBAAA,CACA,iBAAA,CACA,aAAA,CACA,kBAAA,CACA,cAAA,CACA,YAAA,CAGF,sBACC,UAAA,CACC,WAAA,CACD,0BAAA,CACA,wDAAA,CACA,oBAAA,CACA,2BAAA,CACC,eAAA","sourcesContent":[".showMore{\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background: #F3F5F8;\n  width: calc(100% - 10px);\n  margin: 5px;\n  border: 0.5px solid #C3C3C3;\n  border-radius: 3px;\n  color: #0D1335;\n  font-family: Roboto;\n  font-size: 14px;\n  padding: 10px;\n}\n\n.dropdownIcon{\n\twidth: 10px;\n  height: 10px;\n\tbackground-position: center;\n\tbackground-image: url(\"../../../assets/images/angle-down.png\");\n\tbackground-size: 10px;\n\tbackground-repeat: no-repeat;\n  margin-left: 6px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"showMore": "oNkj77ZZW77XrySSK1r7",
	"dropdownIcon": "Xs8pMHLbEvvmutub64gT"
};
export default ___CSS_LOADER_EXPORT___;
