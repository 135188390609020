import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import clsx from "clsx";
import { Row, Col } from "antd";
import styles from "./SingleMarketViews.module.scss";
const CardWrapper = ({ children, data }) => {
    const [isCollapsed, setIsCollapsed] = React.useState(false);
    const handleCardClick = (event) => {
        const content = event.target.nextElementSibling;
        const isActive = event.target.classList.value.includes(styles.active);
        if (isActive === true) {
            event.target.classList.remove(styles.active);
            content.style.maxHeight = 0;
            content.style.padding = 0;
            setIsCollapsed(true);
        }
        else {
            event.target.classList.add(styles.active);
            content.style.transition = "max-height 0.2s ease-out";
            content.style.maxHeight = String(Number(content.scrollHeight) + 8) + "px";
            content.style.padding = "4px";
            setIsCollapsed(false);
        }
    };
    const RenderChildren = () => {
        return (_jsx(Row, { children: _jsx(Col, Object.assign({ span: 24 }, { children: !isCollapsed && children })) }));
    };
    return (_jsx(Row, Object.assign({ gutter: [5, 5] }, { children: _jsxs(Col, Object.assign({ span: 24, className: styles.marketGroup }, { children: [_jsx("button", Object.assign({ className: clsx(styles.collapsible, styles.active), onClick: (e) => handleCardClick(e) }, { children: data.name })), _jsx(RenderChildren, {})] }), `market-single-card-wrapper-${data.marketTypeConfigId}`) })));
};
export default CardWrapper;
