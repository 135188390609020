import dayjs from "dayjs";
export const EventUpdatedMarketSingleOutcomes = (productId, category, tournament, event, market, selectedOutcomes, updated) => {
    const customEvent = new CustomEvent(`updated-market-single-outcomes`, {
        detail: {
            updatedAt: dayjs().toISOString(),
            productId,
            category,
            tournament,
            event,
            market,
            outcomes: selectedOutcomes,
            updated
        },
        bubbles: true
    });
    window.dispatchEvent(customEvent);
};
