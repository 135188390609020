var _a;
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { createRoot } from "react-dom/client";
import { Row, Col } from "antd";
import clsx from "clsx";
import { SUBSCRIBE_EVENT_MARKETS } from "v2/graphql/subscriptions";
import { useSubscribeByQuery } from "helpers/graphql/useSubscribeByQuery";
import { DEFAULT_VISIBLE_MARKET_ROWS } from "config/constants";
import { ShowMoreLess } from "components/base/markets/ShowMoreLess";
// styles
import styles from "./Markets.module.scss";
const OutcomesSingleObject = ({ productId, data, mode, defaultOutcomes, seriesId, sport }) => {
    return (_jsx(Row, Object.assign({ justify: "space-between", align: "middle", className: styles.contentRow, gutter: [5, 8] }, { children: data === null || data === void 0 ? void 0 : data.map((market) => market.outcomes.map((selection) => {
            return (_jsx(Col, Object.assign({ 
                // 2 or 3 outcomes - display in a single row
                // 4+ display in a single column
                span: market.outcomes.length > 3 ? 24 : 24 / market.outcomes.length }, { children: _jsx("leetent-odds-singleevent-input", { id: `odds-singlevent-input-${selection.id}`, "product-id": productId, view: mode === "single" && market.outcomes.length <= 3 ? "top" : "default", "default-outcomes": JSON.stringify(defaultOutcomes), data: JSON.stringify({
                        market: {
                            id: market.marketId,
                            name: market.name,
                            statusId: market.statusId,
                            type: null
                        },
                        match: {
                        // TODO: Is it necessary for sports?
                        // id: match.abId
                        },
                        series: {
                            id: seriesId,
                            name: market === null || market === void 0 ? void 0 : market.eventName,
                            startDate: market === null || market === void 0 ? void 0 : market.startDate,
                            leagueName: market === null || market === void 0 ? void 0 : market.leagueName,
                            leagueId: data === null || data === void 0 ? void 0 : data.leagueId,
                            sportName: market === null || market === void 0 ? void 0 : market.sportName
                        },
                        selection: {
                            id: selection.id,
                            name: selection.name,
                            label: (selection === null || selection === void 0 ? void 0 : selection.key) ? selection.key : selection.name,
                            value: selection.odds,
                            isOpen: selection.isOpen,
                            statusId: selection.statusId,
                            isBetBuilderEnabled: selection === null || selection === void 0 ? void 0 : selection.isBetBuilderEnabled
                        }
                    }), sport: JSON.stringify(sport) }) }), `outcome-${market === null || market === void 0 ? void 0 : market.id}-${selection.id}`));
        })) })));
};
const OutcomesSingleArray = ({ productId, data, mode, defaultOutcomes, seriesId, sport }) => {
    const [fullOutcomes, setFullOutcomes] = React.useState(false);
    const onShowMoreLessClick = () => {
        setFullOutcomes((fullOutcomes) => !fullOutcomes);
    };
    return (_jsxs(_Fragment, { children: [data === null || data === void 0 ? void 0 : data.map((market, index) => {
                return (_jsx(Row, Object.assign({ justify: "space-between", align: "middle", className: styles.contentRow, gutter: [5, 8], style: {
                        display: !fullOutcomes && index >= DEFAULT_VISIBLE_MARKET_ROWS ? "none" : ""
                    } }, { children: market === null || market === void 0 ? void 0 : market.outcomes.map((selection) => {
                        return (_jsx(Col, Object.assign({ 
                            // 2 or 3 outcomes - display in a single row
                            // 4+ display in a single column
                            span: market.outcomes.length > 3 ? 24 : 24 / market.outcomes.length }, { children: _jsx("leetent-odds-singleevent-input", { id: `odds-singlevent-input-${selection.id}`, "product-id": productId, view: mode === "single" ? "top" : "default", "default-outcomes": JSON.stringify(defaultOutcomes), data: JSON.stringify({
                                    market: {
                                        id: market.marketId,
                                        name: market.name,
                                        statusId: market.statusId,
                                        type: null
                                    },
                                    match: {
                                    // TODO: Is it necessary for sports?
                                    // id: match.abId
                                    },
                                    series: {
                                        id: seriesId,
                                        name: market === null || market === void 0 ? void 0 : market.name,
                                        startDate: market === null || market === void 0 ? void 0 : market.startDate,
                                        leagueName: market === null || market === void 0 ? void 0 : market.leagueName,
                                        leagueId: data === null || data === void 0 ? void 0 : data.leagueId,
                                        sportName: market === null || market === void 0 ? void 0 : market.sportName
                                    },
                                    selection: {
                                        id: selection.id,
                                        label: (selection === null || selection === void 0 ? void 0 : selection.key) ? selection.key : selection.name,
                                        value: selection.odds,
                                        isOpen: selection.isOpen,
                                        statusId: selection.statusId,
                                        isBetBuilderEnabled: selection === null || selection === void 0 ? void 0 : selection.isBetBuilderEnabled
                                    }
                                }), sport: JSON.stringify(sport) }) }), `outcome-${selection.id}`));
                    }) }), `outcomes-row-${index}`));
            }), data.length > DEFAULT_VISIBLE_MARKET_ROWS && (_jsx(ShowMoreLess, { onClick: onShowMoreLessClick, fullOutcomes: fullOutcomes }))] }));
};
const DefaultMarket = ({ productId, mode, asItem, seriesId, defaultOutcomes, data, sport }) => {
    var _a, _b;
    const [refData, setRefData] = React.useState(data);
    // Keep actual data from props
    React.useEffect(() => {
        if ((data === null || data === void 0 ? void 0 : data.length) > 0) {
            setRefData(data);
        }
    }, [data]);
    // subscribes to multiple event ids
    const valueHandlerCallback = (data, prevData) => {
        var _a;
        const _data = data.sport_domain_market[0];
        const _prevData = (_a = prevData === null || prevData === void 0 ? void 0 : prevData.sport_domain_market) === null || _a === void 0 ? void 0 : _a[0];
        if ((_prevData === null || _prevData === void 0 ? void 0 : _prevData.market_status_id) !== (_data === null || _data === void 0 ? void 0 : _data.market_status_id)) {
            setRefData((oldArray) => oldArray.map((item) => {
                // updates the market statusId
                if (item.marketId === _data.id)
                    item.statusId = _data.market_status_id;
                return item;
            }));
        }
    };
    useSubscribeByQuery({
        query: SUBSCRIBE_EVENT_MARKETS,
        variables: {
            eventId: productId === 1 && seriesId,
            marketIds: productId === 1 && ((_a = refData === null || refData === void 0 ? void 0 : refData.filter((item) => item === null || item === void 0 ? void 0 : item.id)) === null || _a === void 0 ? void 0 : _a.map((item) => item === null || item === void 0 ? void 0 : item.marketId))
        },
        valueHandlerCallback
    });
    const isArray = refData instanceof Array;
    const onClick = (event) => {
        const content = event.target.nextElementSibling;
        const isActive = event.target.classList.value.includes(styles.active);
        if (isActive === true) {
            event.target.classList.remove(styles.active);
            content.style.maxHeight = 0;
            content.style.padding = 0;
        }
        else {
            event.target.classList.add(styles.active);
            content.style.transition = "max-height 0.2s ease-out";
            content.style.maxHeight = String(Number(content.scrollHeight) + 8) + "px";
            content.style.padding = "4px";
        }
    };
    return ((seriesId && refData && !asItem) ?
        (_jsxs("div", Object.assign({ className: styles.marketGroup }, { children: [_jsx("button", Object.assign({ className: clsx(styles.collapsible, styles.active), onClick: (e) => onClick(e) }, { children: isArray ? (_b = refData[0]) === null || _b === void 0 ? void 0 : _b.name : data === null || data === void 0 ? void 0 : data.name })), _jsx("div", Object.assign({ className: styles.content }, { children: isArray ?
                        _jsx(OutcomesSingleArray, { data: refData, productId: productId, mode: mode, defaultOutcomes: defaultOutcomes, seriesId: seriesId, sport: sport }) :
                        _jsx(OutcomesSingleObject, { data: refData, productId: productId, mode: mode, defaultOutcomes: defaultOutcomes, seriesId: seriesId, sport: sport }) }))] }), `market-${refData === null || refData === void 0 ? void 0 : refData.marketTypeConfigId}`)) :
        (seriesId && refData && asItem) ?
            _jsx("div", Object.assign({ className: styles.content }, { children: isArray ?
                    _jsx(OutcomesSingleArray, { data: refData, productId: productId, mode: mode, defaultOutcomes: defaultOutcomes, seriesId: seriesId, sport: sport }) :
                    _jsx(OutcomesSingleObject, { data: refData, productId: productId, mode: mode, defaultOutcomes: defaultOutcomes, seriesId: seriesId, sport: sport }) })) :
            _jsx(_Fragment, { children: "Loading..." }) // TODO: Implement skeleton
    );
};
class Element extends HTMLElement {
    constructor() {
        super();
        this.root = createRoot(this);
        this.mode = "single";
        this["series-id"] = 0;
        this["default-outcomes"] = [];
        this.data = undefined;
    }
    static get observedAttributes() {
        return ["mode", "as-item", "series-id", "default-outcomes", "data", "product-id", "sport"];
    }
    connectedCallback() {
        var _a;
        (_a = this.root) === null || _a === void 0 ? void 0 : _a.render(_jsx(DefaultMarket, { productId: this["product-id"], mode: this.mode, asItem: this["as-item"], seriesId: this["series-id"], defaultOutcomes: this["default-outcomes"], data: this.data, sport: this.sport }));
    }
    disconnectedCallback() {
        setTimeout(() => { var _a; return (_a = this.root) === null || _a === void 0 ? void 0 : _a.unmount(); }, 0);
    }
    attributeChangedCallback(attrName, _oldValue, newValue) {
        var _a;
        switch (attrName) {
            case "series-id":
            case "product-id":
                this[attrName] = Number(newValue);
                break;
            case "mode":
                this[attrName] = String(newValue);
                break;
            case "as-item":
            case "default-outcomes":
            case "data":
            case "sport":
                this[attrName] = JSON.parse(newValue);
                break;
            default:
                break;
        }
        (_a = this.root) === null || _a === void 0 ? void 0 : _a.render(_jsx(DefaultMarket, { productId: this["product-id"], mode: this.mode, asItem: this["as-item"], seriesId: this["series-id"], defaultOutcomes: this["default-outcomes"], data: this.data, sport: this.sport }));
    }
}
(_a = customElements.get("leetent-market-default")) !== null && _a !== void 0 ? _a : customElements.define("leetent-market-default", Element);
