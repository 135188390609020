import { jsx as _jsx } from "react/jsx-runtime";
// components
import MountDocumentComponent from "components/documentation/MountDocumentComponent";
// document details of inner components
import { tagsListDocumentDetails, dropdownGenericDocumentDetails, datePickerDocumentDetails } from "pages/documentation/documentDetails";
import { documentDetails as datePickerRangeDocumentDetails } from "pages/documentation/components/DatePickerRange";
import { documentDetails as iconsTabHeaderDocumentDetails } from "pages/documentation/components/IconsTabHeader";
export const documentDetails = {
    title: "Generic navigation",
    component: "leetent-navigation-generic",
    description: "This is a base component, that is used as part of many composed components.",
    attributes: [
        {
            key: "1",
            attr: "product-id",
            type: "number",
            notes: [
                "1 - sports",
                "2 - eSports"
            ]
        },
        {
            key: "2",
            attr: "default-event-state",
            type: "string (optional)",
            notes: [
                "It signals to the tags within the navigation, in order to highlight a default option",
                "One of the following options is expected: all, live, next24h, next48h or next72h"
            ]
        },
        {
            key: "3",
            attr: "without-tags",
            type: "boolean (optional)",
            notes: [
                "It doesn't render the tags section within the navigation",
                "The event listener related to the tag is not created"
            ]
        },
        {
            key: "4",
            attr: "exact",
            type: "boolean (optional)",
            notes: [
                "When exact is expressed, it signals to the internal components that we should be seeing exact date times",
                `For instance, today wouldn't return as DD/MM/YYYY 00:00:00 to DD/MM/YYYY 23:59:59.
        Instead, it would return the current time to the end of the day or even tomorrow at the same time.`
            ]
        }
    ],
    events: [
        {
            event: "navigation-details",
            type: "emit",
            data: [
                "The component details object is emitted when .",
                `detail: {
          lastUpdated: string;
          productId: number;
          game: number;
          date: {
            date: string;
            year: number;
            month: number;
            day: number;
            dayOfWeek: {
              index: number;
              name: string;
            };
            weekOfYear: number;
            iso: {
              start: string;
              end: string;
            };
            timestamps: {
              start: string;
              end: string;
            };
          };
          country: {
            id: number, 
            name: string
          };
          league: {
            id: number, 
            name: string
            country: {
              id: number, 
              name: string
            }
          };
          serie: string;
          tag: {
            id: number, 
            name: string
          };
        }`
            ]
        },
        {
            event: "selected-icon-tab-details",
            type: "listener",
            data: [
                `Each time selected tab changed, event gets emitted with details:`,
                `{
          id: <value>,
          label: <value>
        }`
            ]
        },
        {
            event: "selected-date",
            type: "listener",
            data: [
                `Each time selected date changed, event gets emitted with details:`,
                `{
          date: string;
          year: number;
          month: number;
          day: number;
          dayOfWeek: {
            index: number;
            name: string;
          };
          weekOfYear: number;
          iso: {
            start: string;
            end: string;
          };
          timestamps: {
            start: number;
            end: number;
          };
        }`
            ]
        },
        {
            event: "selected-date-range",
            type: "listener",
            data: [
                "The component details object is emitted on date range change.",
                `detail: {
          period: <1day | 2days | 7days | 30days | 60days | 90days | byDate>,
          date: {
            from: string;
            to: string;
          };
          year: {
            from: number;
            to: number;
          };
          month: {
            from: number;
            to: number;
          };
          day: {
            from: number;
            to: number;
          };
          dayOfWeek: {
            from: {
              index: number;
              name: string;
            };
            to: {
              index: number;
              name: string;
            };
          };
          weekOfYear: {
            from: number;
            to: number;
          };
          iso: {
            from: {
              index: number;
              name: string;
            };
            to: {
              index: number;
              name: string;
            };
          };
          timestamps: {
            from: {
              index: number;
              name: string;
            };
            to: {
              index: number;
              name: string;
            };
          };
        }`
            ]
        },
        {
            event: "countries-selected-option",
            type: "listener",
            data: [
                `Sports only`,
                `Each time selected county option changed, event gets emitted with details:`,
                `{
          id: number,
          name: string
        }`
            ]
        },
        {
            event: "leagues-selected-option",
            type: "listener",
            data: [
                `Sports only`,
                `Each time selected league option changed, event gets emitted with details:`,
                `{
          id: number,
          name: string
        }`
            ]
        },
        {
            event: "series-selected-option",
            type: "listener",
            data: [
                "When the market/serie is selected"
            ]
        },
        {
            event: "selected-tag-details",
            type: "listener",
            data: [
                "When the state tag is selected"
            ]
        }
    ],
    innerComponents: [
        {
            name: "leetent-date-picker-range",
            documentDetails: datePickerRangeDocumentDetails
        },
        {
            name: "leetent-tags-list",
            documentDetails: tagsListDocumentDetails
        },
        {
            name: "leetent-dropdown-generic",
            documentDetails: dropdownGenericDocumentDetails
        },
        {
            name: "leetent-icons-tab-header",
            documentDetails: iconsTabHeaderDocumentDetails
        },
        {
            name: "leetent-date-picker",
            documentDetails: datePickerDocumentDetails
        }
    ]
};
const NavigationGeneric = () => {
    documentDetails.example = `<leetent-navigation-generic product-id={1} />`;
    documentDetails.tabs = [
        {
            key: "1",
            label: "Sports",
            children: _jsx("leetent-navigation-generic", { "product-id": 1 })
        },
        {
            key: "2",
            label: "eSports",
            children: _jsx("leetent-navigation-generic", { "product-id": 2 })
        }
    ];
    // render the page based on the provided object
    return _jsx(MountDocumentComponent, { data: documentDetails });
};
export default NavigationGeneric;
