import { jsx as _jsx } from "react/jsx-runtime";
// components
import MountDocumentComponent from "components/documentation/MountDocumentComponent";
const NavigationFeatured = () => {
    const documentDetails = {
        title: "Featured navigation",
        component: "leetent-navigation-featured",
        description: "This is a base component, that is used as part of many composed components.",
        attributes: [
            {
                key: "1",
                attr: "product-id",
                type: "number",
                notes: [
                    "1 - sports",
                    "2 - eSports"
                ]
            },
            {
                key: "2",
                attr: "default-event-state",
                type: "string (optional)",
                notes: [
                    "It signals to the tags within the navigation, in order to highlight a default option",
                    "One of the following options is expected: all, live, next24h, next48h or next72h"
                ]
            },
            {
                key: "3",
                attr: "without-tags",
                type: "boolean (optional)",
                notes: [
                    "It doesn't render the tags section within the navigation",
                    "The event listener related to the tag is not created"
                ]
            },
            {
                key: "4",
                attr: "exact",
                type: "boolean (optional)",
                notes: [
                    "When exact is expressed, it signals to the internal components that we should be seeing exact date times",
                    `For instance, today wouldn't return as DD/MM/YYYY 00:00:00 to DD/MM/YYYY 23:59:59.
          Instead, it would return the current time to the end of the day or even tomorrow at the same time.`
                ]
            }
        ],
        events: [
            {
                event: "navigation-featured-details",
                type: "emit",
                data: [
                    "The component details object is emitted when .",
                    `detail: {
            lastUpdated: string;
            productId: number;
            game: number;
            country: {
              id: number,
              name: string
            };
            league: {
              id: number,
              name: string
              country: {
                id: number,
                name: string
              }
            };
            serie: string;
            tag: {
              id: number,
              name: string
            };
          }`
                ]
            }
        ],
        example: `<leetent-navigation-featured product-id={1} />`,
        tabs: [
            {
                key: "1",
                label: "Sports",
                children: _jsx("leetent-navigation-featured", { "product-id": 1 })
            }
        ]
    };
    // render the page based on the provided object
    return _jsx(MountDocumentComponent, { data: documentDetails });
};
export default NavigationFeatured;
