import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { Row, Col, Button, Input, Space } from "antd";
// components
import MountDocumentComponent from "components/documentation/MountDocumentComponent";
const SingleEventPageWrapper = () => {
    const [eventId, setEventId] = React.useState(25226);
    const [documentDetails, setDocumentDetails] = React.useState({});
    const updateComponent = () => {
        const value = document.getElementById("eventIdInput").value;
        setEventId(value);
    };
    const InteractiveInputs = () => {
        return (_jsx(Row, Object.assign({ justify: "start", align: "top", gutter: [30, 10] }, { children: _jsxs(Col, Object.assign({ xs: 20, sm: 20, md: 10, lg: 6 }, { children: [_jsx("label", { children: "Event id for sports" }), _jsxs(Space.Compact, Object.assign({ style: { width: "100%" } }, { children: [_jsx(Input, { id: "eventIdInput", defaultValue: eventId, size: "large" }), _jsx(Button, Object.assign({ type: "primary", size: "large", onClick: () => updateComponent() }, { children: "Submit" }))] }))] })) })));
    };
    React.useEffect(() => {
        setDocumentDetails({
            title: "Single event page (integrated components)",
            component: "leetent-single-event-page",
            description: `The single event page webcomponent is a fully-functional component,
        ready to be integrated into your application.
      `,
            withSessionComponent: false,
            dependencies: [],
            attributes: [
                {
                    key: "1",
                    attr: "relative (optional)",
                    type: "boolean",
                    notes: [
                        "No need to pass this prop, unless you want to apply relative to the leetent-footer-navigation component.",
                        `The way to use it is to suppress the word relative or simply type it.
              e.g. <leetent-sports-wrapper-featured relative />`
                    ]
                },
                {
                    key: "2",
                    attr: "menu-options",
                    type: "object[] (optional)",
                    notes: [
                        "Custom menu options.",
                        `{
              id?: number
              label: string
              url: string
              Icon?: React.FC<React.SVGProps<SVGSVGElement>>
              isAuthenticationRequired?: boolean
            }`,
                        `Bear in mind options ids are from 1 to 4 (sequentially) and
              that you can override only a specific attribute for a specific option if you wish.
              e.g. menu-options={JSON.stringify([{ id: 2, label: "Live!" }, { id: 3, url: "/my-bets" }])}`,
                        "When a route is overriden, it gets automatically redirected. Unless, it requires authentication.",
                        "By default My Bets requires authentication (isAuthenticationRequired: true)"
                    ]
                },
                {
                    key: "3",
                    attr: "footer-navigation-max-width",
                    type: "string (optional)",
                    notes: [
                        "By default the max-width is defined as inherit",
                        `If necessary, you can define a max-width. e.g. max-width="600px"`,
                        "Note: The minimum value acceptable would be 380px"
                    ]
                },
                {
                    key: "4",
                    attr: "unauthenticated-modal-title",
                    type: "string (optional)",
                    notes: [
                        "Custom unauthenticated modal title.",
                        `Default value is: Please login or register to proceed`
                    ]
                },
                {
                    key: "5",
                    attr: "unauthenticated-modal-options",
                    type: "object[] (optional)",
                    notes: [
                        "Custom unauthenticated modal options.",
                        `{
              id: number
              label: string
              url: string
            }`,
                        `Default value is: 
            [
              {
                id: 1,
                label: "Login",
                url: "/sign-in"
              },
            
              {
                id: 2,
                label: "Register",
                url: "/sign-up"
              }
            ]`
                    ]
                },
                {
                    key: "6",
                    attr: "betslip-max-width",
                    type: "string (optional)",
                    notes: [
                        "By default the max-width is defined as inherit",
                        `If necessary, you can define a max-width. e.g. max-width="600px"`,
                        "Note: The minimum value acceptable would be 380px"
                    ]
                }
            ],
            events: [
                {
                    event: "odds-outcomes-details",
                    type: "emit",
                    data: [
                        `This emit, sends the following information back to its parent.`,
                        `detail: {
              product: <value>,
              seriesId: <value>,
              matchId: <value>,
              marketId: <value>,
              selectionId: <value>,
              label: <value>,
              value: <value>
          }`
                    ]
                },
                {
                    event: "odds-singleevent-input-details",
                    type: "listener",
                    data: [
                        `Each time an input is clicked, its event gets emitted to this component,
              to be used if necessary by the odds-outcomes-details.`,
                        `{
              productId: <value>,
              seriesId: <value>,
              matchId: <value>,
              marketId: <value>,
              selectionId: <value>,
              label: <value>,
              value: <value>
          }`
                    ]
                },
                {
                    event: "nodata-cta-event-details",
                    type: "listener",
                    data: [
                        "When a user clicks on the button, this event gets emitted.",
                        `detail : {
              url: <value>
            }`
                    ]
                }
            ],
            example: `<leetent-sports-single-event-page series-id={25226} />`,
            code: _jsx(_Fragment, { children: _jsx("leetent-sports-single-event-page", { relative: true, "series-id": eventId }) }),
            interactiveInputs: _jsx(InteractiveInputs, {})
        });
        Object.assign(documentDetails);
    }, [eventId]);
    // render the page based on the provided object
    const SingleEventPageComponent = React.useCallback(() => {
        if (Object.keys(documentDetails).length)
            return _jsx(MountDocumentComponent, { data: documentDetails });
        return _jsx("div", { children: "Loading page..." });
    }, [documentDetails]);
    return _jsx(SingleEventPageComponent, {});
};
export default SingleEventPageWrapper;
