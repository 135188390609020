var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createRoot } from "react-dom/client";
import { Row, Col } from "antd";
// styles
import styles from "./NoDataForMarket.module.scss";
const NoDataForMarket = ({ message, src, cta }) => {
    const noDataApplyAction = () => {
        const triggerNoDataAction = new CustomEvent(`nodata-cta-event-details`, {
            detail: {
                url: cta === null || cta === void 0 ? void 0 : cta.url
            },
            bubbles: true
        });
        window.dispatchEvent(triggerNoDataAction);
    };
    return (_jsx("div", Object.assign({ className: styles.noDataMarket }, { children: _jsxs(Row, Object.assign({ justify: "center", align: "middle" }, { children: [src && (_jsx(Col, Object.assign({ span: 24, className: styles.noOddsImage }, { children: _jsx("img", { src: src, alt: "img", width: "100" }) }), "no_odds_image")), _jsx(Col, Object.assign({ span: 24, className: styles.noOddsMessage }, { children: message }), "no_odds_message"), (cta === null || cta === void 0 ? void 0 : cta.label) && (_jsx(Col, Object.assign({ span: 24, className: styles.noDataMarket }, { children: _jsx("button", Object.assign({ type: 'button', className: "button_social_widget info", onClick: noDataApplyAction }, { children: cta === null || cta === void 0 ? void 0 : cta.label })) }), "no_data_message"))] })) })));
};
class Element extends HTMLElement {
    constructor() {
        super();
        this.message = "No Events Available at the moment";
        this.src = "https://d1bu7ybn6mtlcz.cloudfront.net/data_alert.svg";
    }
    static get observedAttributes() {
        return ["message", "src", "cta"];
    }
    connectedCallback() {
        createRoot(this).render(_jsx(NoDataForMarket, { message: this.message, src: this.src, cta: this.cta }));
    }
    attributeChangedCallback(attrName, oldValue, newValue) {
        switch (attrName) {
            case "message":
            case "src":
                this[attrName] = String(newValue);
                break;
            case "cta":
                this[attrName] = JSON.parse(newValue);
                break;
            default:
                break;
        }
    }
}
(_a = customElements.get("leetent-nodata-for-market")) !== null && _a !== void 0 ? _a : customElements.define("leetent-nodata-for-market", Element);
