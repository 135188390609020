import { jsx as _jsx } from "react/jsx-runtime";
import { defaultMarketData as data } from "./assets/data";
// components
import MountDocumentComponent from "components/documentation/MountDocumentComponent";
// document details of inner components
import { documentDetails as singleEventInputDocumentDetails } from "pages/documentation/components/OddsSingleEventInput";
export const documentDetails = {
    title: "Default (Display Rule)",
    component: "leetent-market-default",
    description: `This is a default market component,
    which is used as a fallback (when a display rule is not provided).`,
    version: "1.1.0",
    withDarkMode: false,
    dependencies: [
        "leetent-odds-singleevent-input"
    ],
    attributes: [],
    innerComponents: [
        {
            name: "leetent-odds-singleevent-input",
            documentDetails: singleEventInputDocumentDetails
        }
    ]
};
const { singleEventDataObject, singleEventDataArray } = data;
const DefaultMarket = () => {
    let extendedData = singleEventDataArray.slice();
    // Extend the original array by adding the copy to it
    extendedData = extendedData.concat(extendedData);
    documentDetails.example = `<leetent-market-default />`;
    documentDetails.tabs = [
        {
            key: "1",
            label: "Single event version (Object)",
            children: _jsx("div", Object.assign({ style: { padding: 5 } }, { children: _jsx("leetent-market-default", { mode: "single", "series-id": 123, data: JSON.stringify(singleEventDataObject) }) }))
        },
        {
            key: "2",
            label: "Single event version (Array)",
            children: _jsx("div", Object.assign({ style: { padding: 5 } }, { children: _jsx("leetent-market-default", { mode: "single", "series-id": 123, data: JSON.stringify(singleEventDataArray) }) }))
        },
        {
            key: "3",
            label: "Single event version (Array) more outcomes",
            children: _jsx("div", Object.assign({ style: { padding: 5 } }, { children: _jsx("leetent-market-default", { mode: "single", "series-id": 123, data: JSON.stringify(extendedData) }) }))
        }
    ];
    // render the page based on the provided object
    return _jsx(MountDocumentComponent, { data: documentDetails });
};
export default DefaultMarket;
