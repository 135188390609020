export const correctScoreData = [{
        id: 1494554,
        marketTypeConfigId: 61,
        name: "Correct Score - 1st Half",
        outcomes: [
            {
                id: 3645588,
                name: "0-0",
                key: "0-0",
                odds: 3.2,
                isOpen: false,
                statusId: 10,
                isBetBuilderEnabled: false
            },
            {
                id: 3645585,
                name: "0-1",
                key: "0-1",
                odds: 3.9,
                isOpen: false,
                statusId: 20,
                isBetBuilderEnabled: false
            },
            {
                id: 3645586,
                name: "0-2",
                key: "0-2",
                odds: 9,
                isOpen: false,
                statusId: 20,
                isBetBuilderEnabled: false
            },
            {
                id: 3645604,
                name: "0-3",
                key: "0-3",
                odds: 31,
                isOpen: false,
                statusId: 10,
                isBetBuilderEnabled: false
            },
            {
                id: 3645598,
                name: "0-4",
                key: "0-4",
                odds: 161,
                isOpen: false,
                statusId: 10,
                isBetBuilderEnabled: false
            },
            {
                id: 3645589,
                name: "1-0",
                key: "1-0",
                odds: 6.75,
                isOpen: false,
                statusId: 10,
                isBetBuilderEnabled: false
            },
            {
                id: 3645595,
                name: "1-1",
                key: "1-1",
                odds: 8,
                isOpen: false,
                statusId: 10,
                isBetBuilderEnabled: false
            },
            {
                id: 3645594,
                name: "1-2",
                key: "1-2",
                odds: 20,
                isOpen: false,
                statusId: 10,
                isBetBuilderEnabled: false
            },
            {
                id: 3645605,
                name: "1-3",
                key: "1-3",
                odds: 76,
                isOpen: false,
                statusId: 10,
                isBetBuilderEnabled: false
            },
            {
                id: 3645592,
                name: "2-0",
                key: "2-0",
                odds: 25,
                isOpen: false,
                statusId: 10,
                isBetBuilderEnabled: false
            },
            {
                id: 3645591,
                name: "2-1",
                key: "2-1",
                odds: 31,
                isOpen: false,
                statusId: 10,
                isBetBuilderEnabled: false
            },
            {
                id: 3645609,
                name: "2-2",
                key: "2-2",
                odds: 91,
                isOpen: false,
                statusId: 10,
                isBetBuilderEnabled: false
            },
            {
                id: 3645596,
                name: "3-0",
                key: "3-0",
                odds: 161,
                isOpen: false,
                statusId: 10,
                isBetBuilderEnabled: false
            },
            {
                id: 3645608,
                name: "3-1",
                key: "3-1",
                odds: 201,
                isOpen: false,
                statusId: 20,
                isBetBuilderEnabled: false
            }
        ],
        statusId: 10,
        isBetBuilderEnabled: false
    }];
export const correctScoreNoDrawData = [{
        id: 57547,
        marketTypeConfigId: 51,
        name: "Correct Score No Draw",
        outcomes: [
            {
                id: 139583,
                name: "0-1",
                key: "0-1",
                odds: 1.1,
                isOpen: true
            },
            {
                id: 139584,
                name: "0-2",
                key: "0-2",
                odds: 1.1,
                isOpen: true
            },
            {
                id: 139585,
                name: "0-3",
                key: "0-3",
                odds: 1.1,
                isOpen: true
            },
            {
                id: 139586,
                name: "0-4",
                key: "0-4",
                odds: 1.1,
                isOpen: true
            },
            {
                id: 139587,
                name: "0-5",
                key: "0-5",
                odds: 1.1,
                isOpen: true
            },
            {
                id: 139588,
                name: "0-6",
                key: "0-6",
                odds: 1.1,
                isOpen: true
            },
            {
                id: 139589,
                name: "1-0",
                key: "1-0",
                odds: 1.1,
                isOpen: true
            },
            {
                id: 139590,
                name: "2-0",
                key: "2-0",
                odds: 1.1,
                isOpen: true
            },
            {
                id: 139591,
                name: "3-0",
                key: "3-0",
                odds: 1.1,
                isOpen: true
            },
            {
                id: 139592,
                name: "4-0",
                key: "4-0",
                odds: 1.1,
                isOpen: true
            },
            {
                id: 139593,
                name: "5-0",
                key: "5-0",
                odds: 1.1,
                isOpen: true
            },
            {
                id: 139594,
                name: "6-0",
                key: "6-0",
                odds: 1.1,
                isOpen: true
            }
        ]
    }];
export const Nx1Data = [{
        id: 57547,
        marketTypeConfigId: 51,
        name: "Nx1",
        outcomes: [
            {
                id: 139582,
                name: "0-0",
                key: "0-0",
                odds: 1.1,
                isOpen: true
            },
            {
                id: 139583,
                name: "0-1",
                key: "0-1",
                odds: 1.1,
                isOpen: true
            },
            {
                id: 139584,
                name: "0-2",
                key: "0-2",
                odds: 1.1,
                isOpen: true
            },
            {
                id: 139585,
                name: "0-3",
                key: "0-3",
                odds: 1.1,
                isOpen: true
            },
            {
                id: 139586,
                name: "1-0",
                key: "1-0",
                odds: 1.1,
                isOpen: true
            },
            {
                id: 139587,
                name: "2-0",
                key: "2-0",
                odds: 1.1,
                isOpen: true
            },
            {
                id: 139588,
                name: "3-0",
                key: "3-0",
                odds: 1.1,
                isOpen: true
            }
        ]
    }];
export const Nx2Data = [
    {
        displayRule: "PLAYER_OCCURRENCE_LINE_OVER_UNDER",
        id: 33477,
        marketId: 479299,
        marketTypeConfig: 116,
        name: "3-point field goals made",
        line: 4.5,
        outcomes: [
            {
                id: 1184738,
                name: "Over 4.5",
                key: "over",
                odds: 2.25,
                isOpen: true,
                statusId: 10
            },
            {
                id: 1184739,
                name: "Under 4.5",
                key: "under",
                odds: 1.6,
                isOpen: true,
                statusId: 10
            }
        ],
        eventName: "New Orleans Pelicans vs Los Angeles Lakers",
        startDate: "2024-04-16T23:30:00+00:00",
        playerName: "McCollum, CJ"
    },
    {
        displayRule: "PLAYER_OCCURRENCE_LINE_OVER_UNDER",
        id: 33477,
        marketId: 478913,
        marketTypeConfig: 116,
        name: "3-point field goals made",
        line: 3.5,
        outcomes: [
            {
                id: 1183395,
                name: "Over 3.5",
                key: "over",
                odds: 1.54,
                isOpen: true,
                statusId: 10
            },
            {
                id: 1183398,
                name: "Under 3.5",
                key: "under",
                odds: 2.38,
                isOpen: true,
                statusId: 10
            }
        ],
        eventName: "New Orleans Pelicans vs Los Angeles Lakers",
        startDate: "2024-04-16T23:30:00+00:00",
        playerName: "McCollum, CJ"
    },
    {
        displayRule: "PLAYER_OCCURRENCE_LINE_OVER_UNDER",
        id: 33477,
        marketId: 479073,
        marketTypeConfig: 116,
        name: "3-point field goals made",
        line: 3.5,
        outcomes: [
            {
                id: 1183930,
                name: "Over 3.5",
                key: "over",
                odds: 2.2,
                isOpen: true,
                statusId: 10
            },
            {
                id: 1183935,
                name: "Under 3.5",
                key: "under",
                odds: 1.63,
                isOpen: true,
                statusId: 10
            }
        ],
        eventName: "New Orleans Pelicans vs Los Angeles Lakers",
        startDate: "2024-04-16T23:30:00+00:00",
        playerName: "Russell, D'Angelo"
    },
    {
        displayRule: "PLAYER_OCCURRENCE_LINE_OVER_UNDER",
        id: 33477,
        marketId: 478886,
        marketTypeConfig: 116,
        name: "3-point field goals made",
        line: 2.5,
        outcomes: [
            {
                id: 1183339,
                name: "Over 2.5",
                key: "over",
                odds: 1.45,
                isOpen: true,
                statusId: 10
            },
            {
                id: 1183340,
                name: "Under 2.5",
                key: "under",
                odds: 2.65,
                isOpen: true,
                statusId: 10
            }
        ],
        eventName: "New Orleans Pelicans vs Los Angeles Lakers",
        startDate: "2024-04-16T23:30:00+00:00",
        playerName: "Russell, D'Angelo"
    },
    {
        displayRule: "PLAYER_OCCURRENCE_LINE_OVER_UNDER",
        id: 33477,
        marketId: 479410,
        marketTypeConfig: 116,
        name: "3-point field goals made",
        line: 2.5,
        outcomes: [
            {
                id: 1185121,
                name: "Over 2.5",
                key: "over",
                odds: 2.65,
                isOpen: true,
                statusId: 10
            },
            {
                id: 1185122,
                name: "Under 2.5",
                key: "under",
                odds: 1.44,
                isOpen: true,
                statusId: 10
            }
        ],
        eventName: "New Orleans Pelicans vs Los Angeles Lakers",
        startDate: "2024-04-16T23:30:00+00:00",
        playerName: "James, LeBron"
    },
    {
        displayRule: "PLAYER_OCCURRENCE_LINE_OVER_UNDER",
        id: 33477,
        marketId: 478885,
        marketTypeConfig: 116,
        name: "3-point field goals made",
        line: 1.5,
        outcomes: [
            {
                id: 1183341,
                name: "Over 1.5",
                key: "over",
                odds: 2.04,
                isOpen: true,
                statusId: 10
            },
            {
                id: 1183342,
                name: "Under 1.5",
                key: "under",
                odds: 1.72,
                isOpen: true,
                statusId: 10
            }
        ],
        eventName: "New Orleans Pelicans vs Los Angeles Lakers",
        startDate: "2024-04-16T23:30:00+00:00",
        playerName: "Hachimura, Rui"
    },
    {
        displayRule: "PLAYER_OCCURRENCE_LINE_OVER_UNDER",
        id: 33477,
        marketId: 478891,
        marketTypeConfig: 116,
        name: "3-point field goals made",
        line: 1.5,
        outcomes: [
            {
                id: 1183351,
                name: "Over 1.5",
                key: "over",
                odds: 1.52,
                isOpen: true,
                statusId: 10
            },
            {
                id: 1183352,
                name: "Under 1.5",
                key: "under",
                odds: 2.45,
                isOpen: true,
                statusId: 10
            }
        ],
        eventName: "New Orleans Pelicans vs Los Angeles Lakers",
        startDate: "2024-04-16T23:30:00+00:00",
        playerName: "James, LeBron"
    },
    {
        displayRule: "PLAYER_OCCURRENCE_LINE_OVER_UNDER",
        id: 33477,
        marketId: 478910,
        marketTypeConfig: 116,
        name: "3-point field goals made",
        line: 1.5,
        outcomes: [
            {
                id: 1183389,
                name: "Over 1.5",
                key: "over",
                odds: 2.02,
                isOpen: true,
                statusId: 10
            },
            {
                id: 1183390,
                name: "Under 1.5",
                key: "under",
                odds: 1.74,
                isOpen: true,
                statusId: 10
            }
        ],
        eventName: "New Orleans Pelicans vs Los Angeles Lakers",
        startDate: "2024-04-16T23:30:00+00:00",
        playerName: "Jones, Herbert"
    }
];
export const Nx2YesNo = [
    {
        id: 896924,
        marketTypeConfigId: 650,
        name: "To give an assist",
        outcomes: [
            {
                id: 2199647,
                name: "Yes",
                key: "yes",
                odds: 6,
                isOpen: true,
                statusId: 10
            },
            {
                id: 21996471,
                name: "No",
                key: "no",
                odds: 1.6,
                isOpen: true,
                statusId: 10
            }
        ],
        statusId: 10,
        playerName: "Kane, Harry"
    },
    {
        id: 896925,
        marketTypeConfigId: 650,
        name: "To give an assist",
        outcomes: [
            {
                id: 2199648,
                name: "Yes",
                key: "yes",
                odds: 6,
                isOpen: true,
                statusId: 10
            },
            {
                id: 21996472,
                name: "No",
                key: "no",
                odds: 1.6,
                isOpen: true,
                statusId: 10
            }
        ],
        statusId: 10,
        playerName: "Musiala, Jamal"
    },
    {
        id: 896926,
        marketTypeConfigId: 650,
        name: "To give an assist",
        outcomes: [
            {
                id: 2199649,
                name: "Yes",
                key: "yes",
                odds: 7,
                isOpen: true,
                statusId: 10
            },
            {
                id: 21996473,
                name: "No",
                key: "no",
                odds: 1.6,
                isOpen: true,
                statusId: 10
            }
        ],
        statusId: 10,
        playerName: "Goretzka, Leon"
    },
    {
        id: 896927,
        marketTypeConfigId: 650,
        name: "To give an assist",
        outcomes: [
            {
                id: 2199650,
                name: "Yes",
                key: "yes",
                odds: 6.5,
                isOpen: true,
                statusId: 10
            },
            {
                id: 21996474,
                name: "No",
                key: "no",
                odds: 1.6,
                isOpen: true,
                statusId: 10
            }
        ],
        statusId: 10,
        playerName: "Kimmich, Joshua"
    }
];
export const Nx2Yes = [
    {
        id: 896924,
        marketTypeConfigId: 650,
        name: "To give an assist",
        outcomes: [
            {
                id: 2199647,
                name: "Yes",
                key: "yes",
                odds: 6,
                isOpen: true,
                statusId: 10
            }
        ],
        statusId: 10,
        playerName: "Kane, Harry"
    },
    {
        id: 896925,
        marketTypeConfigId: 650,
        name: "To give an assist",
        outcomes: [
            {
                id: 2199648,
                name: "Yes",
                key: "yes",
                odds: 6,
                isOpen: true,
                statusId: 10
            }
        ],
        statusId: 10,
        playerName: "Musiala, Jamal"
    },
    {
        id: 896926,
        marketTypeConfigId: 650,
        name: "To give an assist",
        outcomes: [
            {
                id: 2199649,
                name: "Yes",
                key: "yes",
                odds: 7,
                isOpen: true,
                statusId: 10
            }
        ],
        statusId: 10,
        playerName: "Goretzka, Leon"
    },
    {
        id: 896927,
        marketTypeConfigId: 650,
        name: "To give an assist",
        outcomes: [
            {
                id: 2199650,
                name: "Yes",
                key: "yes",
                odds: 6.5,
                isOpen: true,
                statusId: 10
            }
        ],
        statusId: 10,
        playerName: "Kimmich, Joshua"
    }
];
export const HandicapData = [
    {
        id: 57547,
        marketTypeConfigId: 51,
        name: "Set Handicap",
        line: "1.5",
        outcomes: [
            {
                id: 139582,
                name: "home",
                key: "home",
                odds: 1.1,
                isOpen: true
            },
            {
                id: 139583,
                name: "away",
                key: "away",
                odds: 1.1,
                isOpen: true
            }
        ]
    },
    {
        id: 57547,
        marketTypeConfigId: 51,
        name: "Set Handicap",
        line: "2.5",
        outcomes: [
            {
                id: 139582,
                name: "home",
                key: "home",
                odds: 1.3,
                isOpen: true
            },
            {
                id: 139583,
                name: "away",
                key: "away",
                odds: 1.46,
                isOpen: true
            }
        ]
    },
    {
        id: 57547,
        marketTypeConfigId: 51,
        name: "Set Handicap",
        line: "-1.5",
        outcomes: [
            {
                id: 139582,
                name: "home",
                key: "home",
                odds: 1.8,
                isOpen: true
            },
            {
                id: 139583,
                name: "away",
                key: "away",
                odds: 1.48,
                isOpen: true
            }
        ]
    },
    {
        id: 57547,
        marketTypeConfigId: 51,
        name: "Set Handicap",
        line: "-3.5",
        outcomes: [
            {
                id: 139582,
                name: "home",
                key: "home",
                odds: 2.5,
                isOpen: true
            },
            {
                id: 139583,
                name: "away",
                key: "away",
                odds: 1.2,
                isOpen: true
            }
        ]
    },
    {
        id: 57547,
        marketTypeConfigId: 51,
        name: "Set Handicap",
        line: "-4.5",
        outcomes: [
            {
                id: 139582,
                name: "home",
                key: "home",
                odds: 5.5,
                isOpen: true
            },
            {
                id: 139583,
                name: "away",
                key: "away",
                odds: 1.1,
                isOpen: true
            }
        ]
    }
];
export const ThreeWayHandicapData = [
    {
        id: 57547,
        marketTypeConfigId: 51,
        name: "Set Handicap",
        line: "1.5",
        outcomes: [
            {
                id: 139582,
                name: "home",
                key: "home",
                odds: 1.1,
                isOpen: true
            },
            {
                id: 139583,
                name: "draw",
                key: "draw",
                odds: 1.1,
                isOpen: true
            },
            {
                id: 139583,
                name: "away",
                key: "away",
                odds: 1.1,
                isOpen: true
            }
        ]
    },
    {
        id: 57547,
        marketTypeConfigId: 51,
        name: "Set Handicap",
        line: "2.5",
        outcomes: [
            {
                id: 139582,
                name: "home",
                key: "home",
                odds: 1.3,
                isOpen: true
            },
            {
                id: 139582,
                name: "draw",
                key: "draw",
                odds: 1.3,
                isOpen: true
            },
            {
                id: 139583,
                name: "away",
                key: "away",
                odds: 1.46,
                isOpen: true
            }
        ]
    },
    {
        id: 57547,
        marketTypeConfigId: 51,
        name: "Set Handicap",
        line: "-1.5",
        outcomes: [
            {
                id: 139582,
                name: "home",
                key: "home",
                odds: 1.8,
                isOpen: true
            },
            {
                id: 139582,
                name: "draw",
                key: "draw",
                odds: 1.8,
                isOpen: true
            },
            {
                id: 139583,
                name: "away",
                key: "away",
                odds: 1.48,
                isOpen: true
            }
        ]
    },
    {
        id: 57547,
        marketTypeConfigId: 51,
        name: "Set Handicap",
        line: "-3.5",
        outcomes: [
            {
                id: 139582,
                name: "home",
                key: "home",
                odds: 2.5,
                isOpen: true
            },
            {
                id: 139582,
                name: "draw",
                key: "draw",
                odds: 2.5,
                isOpen: true
            },
            {
                id: 139583,
                name: "away",
                key: "away",
                odds: 1.2,
                isOpen: true
            }
        ]
    },
    {
        id: 57547,
        marketTypeConfigId: 51,
        name: "Set Handicap",
        line: "-4.5",
        outcomes: [
            {
                id: 139582,
                name: "home",
                key: "home",
                odds: 5.5,
                isOpen: true
            },
            {
                id: 139582,
                name: "draw",
                key: "draw",
                odds: 5.5,
                isOpen: true
            },
            {
                id: 139583,
                name: "away",
                key: "away",
                odds: 1.1,
                isOpen: true
            }
        ]
    }
];
export const bothTeamsToScoreData = [{
        id: 57545,
        marketTypeConfigId: 47,
        name: "Both Teams to Score",
        outcomes: [
            {
                id: 139577,
                name: "Yes",
                key: "yes",
                odds: 2.4,
                isOpen: true
            },
            {
                id: 139578,
                name: "No",
                key: "no",
                odds: 1.4,
                isOpen: true
            }
        ]
    }, {
        id: 57545,
        marketTypeConfigId: 47,
        name: "Both Teams to Score",
        outcomes: [
            {
                id: 139577,
                name: "Yes",
                key: "yes",
                odds: 2.4,
                isOpen: true
            },
            {
                id: 139578,
                name: "No",
                key: "no",
                odds: 1.4,
                isOpen: true
            }
        ]
    }, {
        id: 57545,
        marketTypeConfigId: 47,
        name: "Both Teams to Score",
        outcomes: [
            {
                id: 139577,
                name: "Yes",
                key: "yes",
                odds: 2.4,
                isOpen: true
            },
            {
                id: 139578,
                name: "No",
                key: "no",
                odds: 1.4,
                isOpen: true
            }
        ]
    }, {
        id: 57545,
        marketTypeConfigId: 47,
        name: "Both Teams to Score",
        outcomes: [
            {
                id: 139577,
                name: "Yes",
                key: "yes",
                odds: 2.4,
                isOpen: true
            },
            {
                id: 139578,
                name: "No",
                key: "no",
                odds: 1.4,
                isOpen: true
            }
        ]
    }, {
        id: 57545,
        marketTypeConfigId: 47,
        name: "Both Teams to Score",
        outcomes: [
            {
                id: 139577,
                name: "Yes",
                key: "yes",
                odds: 2.4,
                isOpen: true
            },
            {
                id: 139578,
                name: "No",
                key: "no",
                odds: 1.4,
                isOpen: true
            }
        ]
    }, {
        id: 57545,
        marketTypeConfigId: 47,
        name: "Both Teams to Score",
        outcomes: [
            {
                id: 139577,
                name: "Yes",
                key: "yes",
                odds: 2.4,
                isOpen: true
            },
            {
                id: 139578,
                name: "No",
                key: "no",
                odds: 1.4,
                isOpen: true
            }
        ]
    }, {
        id: 57545,
        marketTypeConfigId: 47,
        name: "Both Teams to Score",
        outcomes: [
            {
                id: 139577,
                name: "Yes",
                key: "yes",
                odds: 2.4,
                isOpen: true
            },
            {
                id: 139578,
                name: "No",
                key: "no",
                odds: 1.4,
                isOpen: true
            }
        ]
    }, {
        id: 57545,
        marketTypeConfigId: 47,
        name: "Both Teams to Score",
        outcomes: [
            {
                id: 139577,
                name: "Yes",
                key: "yes",
                odds: 2.4,
                isOpen: true
            },
            {
                id: 139578,
                name: "No",
                key: "no",
                odds: 1.4,
                isOpen: true
            }
        ]
    }, {
        id: 57545,
        marketTypeConfigId: 47,
        name: "Both Teams to Score",
        outcomes: [
            {
                id: 139577,
                name: "Yes",
                key: "yes",
                odds: 2.4,
                isOpen: true
            },
            {
                id: 139578,
                name: "No",
                key: "no",
                odds: 1.4,
                isOpen: true
            }
        ]
    }];
export const defaultMarketData = {
    singleEventDataObject: {
        id: 52826,
        marketTypeConfigId: 46,
        name: "Double Chance",
        outcomes: [
            {
                id: 127386,
                name: "Argentina or Draw",
                odds: 1.1527,
                isOpen: true
            },
            {
                id: 127387,
                name: "Argentina or Italy",
                odds: 1.2521,
                isOpen: true
            },
            {
                id: 127388,
                name: "Draw or Italy",
                odds: 2.3811,
                isOpen: true
            }
        ],
        displayRule: "DOUBLE_CHANCE"
    },
    singleEventDataArray: [
        {
            id: 55841,
            marketTypeConfigId: 45,
            name: "Over/Under - Total Goals",
            line: 2.5,
            outcomes: [
                {
                    id: 135103,
                    name: "Over 2.5",
                    odds: 2.0168,
                    isOpen: true
                },
                {
                    id: 135104,
                    name: "Under 2.5",
                    odds: 1.8273,
                    isOpen: true
                }
            ]
        },
        {
            id: 55842,
            marketTypeConfigId: 45,
            name: "Over/Under - Total Goals",
            line: 5.5,
            outcomes: [
                {
                    id: 135109,
                    name: "Over 5.5",
                    odds: 18.3814,
                    isOpen: true
                },
                {
                    id: 135110,
                    name: "Under 5.5",
                    odds: 1.029,
                    isOpen: true
                }
            ]
        },
        {
            id: 55843,
            marketTypeConfigId: 45,
            name: "Over/Under - Total Goals",
            line: 1.5,
            outcomes: [
                {
                    id: 135105,
                    name: "Over 1.5",
                    odds: 1.3134,
                    isOpen: true
                },
                {
                    id: 135106,
                    name: "Under 1.5",
                    odds: 3.4475,
                    isOpen: true
                }
            ]
        },
        {
            id: 55844,
            marketTypeConfigId: 45,
            name: "Over/Under - Total Goals",
            line: 3.5,
            outcomes: [
                {
                    id: 135107,
                    name: "Over 3.5",
                    odds: 3.5973,
                    isOpen: true
                },
                {
                    id: 135108,
                    name: "Under 3.5",
                    odds: 1.2924,
                    isOpen: true
                }
            ]
        }
    ]
};
export const doubleChanceData = {
    listEventData: [
        {
            id: 20088,
            name: "West Ham vs Manchester United",
            homeTeam: "West Ham",
            awayTeam: "Manchester United",
            startDate: "20231223",
            time: "12:30",
            matchStatus: "Game Over",
            marketId: 46,
            marketnAME: "Double Chance",
            marketCount: 26,
            league: {
                id: 1,
                name: "Premier League"
            },
            country: {
                id: 1,
                name: "England"
            },
            outcomes: [
                {
                    id: 113077,
                    name: "West Ham or Draw",
                    key: "home/draw",
                    odds: 1,
                    isOpen: false
                },
                {
                    id: 113078,
                    name: "West Ham or Manchester United",
                    key: "home/away",
                    odds: 1,
                    isOpen: false
                },
                {
                    id: 113079,
                    name: "Draw or Manchester United",
                    key: "draw/away",
                    odds: 1,
                    isOpen: false
                }
            ]
        },
        {
            id: 20087,
            name: "Liverpool vs Arsenal",
            homeTeam: "Liverpool",
            awayTeam: "Arsenal",
            startDate: "20231223",
            time: "17:30",
            matchStatus: "Game Over",
            marketId: 46,
            marketName: "Double Chance",
            marketCount: 26,
            league: {
                id: 1,
                name: "Premier League"
            },
            country: {
                id: 1,
                name: "England"
            },
            outcomes: [
                {
                    id: 112979,
                    name: "Liverpool or Draw",
                    key: "home/draw",
                    odds: 1.48,
                    isOpen: false
                },
                {
                    id: 112980,
                    name: "Liverpool or Arsenal",
                    key: "home/away",
                    odds: 1.31,
                    isOpen: false
                },
                {
                    id: 112981,
                    name: "Draw or Arsenal",
                    key: "draw/away",
                    odds: 1.64,
                    isOpen: false
                }
            ]
        }
    ],
    singleEventData: {
        id: 52826,
        marketTypeConfigId: 46,
        name: "Double Chance",
        outcomes: [
            {
                id: 127386,
                name: "Argentina or Draw",
                odds: 1.1527,
                isOpen: true
            },
            {
                id: 127387,
                name: "Argentina or Italy",
                odds: 1.2521,
                isOpen: true
            },
            {
                id: 127388,
                name: "Draw or Italy",
                odds: 2.3811,
                isOpen: true
            }
        ],
        displayRule: "DOUBLE_CHANCE"
    }
};
export const overUnderData = {
    listEventData: [
        {
            id: 20073,
            name: "Clermont vs Lens",
            homeTeam: "Clermont",
            awayTeam: "Lens",
            startDate: "20231125",
            time: "16:00",
            matchStatus: "Game Over",
            marketId: 45,
            marketName: "Total Goals",
            marketCount: 26,
            line: 0.5,
            outcomes: [
                {
                    id: 111580,
                    name: "Over 0.5",
                    key: "over",
                    odds: 1,
                    isOpen: true
                },
                {
                    id: 111581,
                    name: "Under 0.5",
                    key: "under",
                    odds: 1,
                    isOpen: false
                }
            ]
        }
    ],
    singleEventData: [
        {
            id: 55841,
            marketTypeConfigId: 45,
            name: "Over/Under - Total Goals",
            line: 2.5,
            outcomes: [
                {
                    id: 135103,
                    name: "Over 2.5",
                    odds: 2.0168,
                    isOpen: true
                },
                {
                    id: 135104,
                    name: "Under 2.5",
                    odds: 1.8273,
                    isOpen: true
                }
            ]
        },
        {
            id: 55842,
            marketTypeConfigId: 45,
            name: "Over/Under - Total Goals",
            line: 5.5,
            outcomes: [
                {
                    id: 135109,
                    name: "Over 5.5",
                    odds: 18.3814,
                    isOpen: true
                },
                {
                    id: 135110,
                    name: "Under 5.5",
                    odds: 1.029,
                    isOpen: true
                }
            ]
        },
        {
            id: 55843,
            marketTypeConfigId: 45,
            name: "Over/Under - Total Goals",
            line: 1.5,
            outcomes: [
                {
                    id: 135105,
                    name: "Over 1.5",
                    odds: 1.3134,
                    isOpen: true
                },
                {
                    id: 135106,
                    name: "Under 1.5",
                    odds: 3.4475,
                    isOpen: true
                }
            ]
        },
        {
            id: 55844,
            marketTypeConfigId: 45,
            name: "Over/Under - Total Goals",
            line: 3.5,
            outcomes: [
                {
                    id: 135107,
                    name: "Over 3.5",
                    odds: 3.5973,
                    isOpen: true
                },
                {
                    id: 135108,
                    name: "Under 3.5",
                    odds: 1.2924,
                    isOpen: true
                }
            ]
        }
    ]
};
export const threeWayHalftimeFulltimeData = [{
        id: 52807,
        marketTypeConfigId: 52,
        name: "Halftime/Fulltime",
        outcomes: [
            {
                id: 127337,
                name: "Athletic Club Bilbao - Athletic Club Bilbao",
                odds: 2.4131,
                isOpen: true,
                key: "1/1"
            },
            { id: 127338, name: "Athletic Club Bilbao - Draw", odds: 15.8195, isOpen: true, key: "1/X" },
            {
                id: 127339,
                name: "Athletic Club Bilbao - Celta Vigo",
                odds: 55.6477,
                isOpen: true,
                key: "1/2"
            },
            { id: 127340, name: "Draw - Athletic Club Bilbao", odds: 4.6636, isOpen: true, key: "X/1" },
            { id: 127341, name: "Draw - Draw", odds: 6.2299, isOpen: true, key: "X/X" },
            { id: 127342, name: "Draw - Celta Vigo", odds: 12.0901, isOpen: true, key: "X/2" },
            {
                id: 127343,
                name: "Celta Vigo - Athletic Club Bilbao",
                odds: 24.4482,
                isOpen: true,
                key: "2/1"
            },
            { id: 127344, name: "Celta Vigo - Draw", odds: 17.1717, isOpen: true, key: "2/X" },
            {
                id: 127345,
                name: "Celta Vigo - Celta Vigo",
                odds: 9.5306,
                isOpen: true,
                key: "2/2"
            }
        ]
    }];
export const threeWayData = {
    listEventData: [
        {
            id: 20326,
            name: "1. FC Heidenheim vs VfL Bochum",
            homeTeam: "1. FC Heidenheim",
            awayTeam: "VfL Bochum",
            startDate: "20231126",
            time: "14:30",
            matchStatus: "Game Over",
            marketId: 36,
            marketName: "Full Time",
            marketCount: 43,
            league: {
                id: 42,
                name: "Bundesliga"
            },
            country: {
                id: 1,
                name: "Germany"
            },
            outcomes: [
                {
                    id: 137614,
                    name: "1. FC Heidenheim",
                    odds: 2.3123,
                    isOpen: true
                },
                {
                    id: 137615,
                    name: "Draw",
                    odds: 3.9104,
                    isOpen: true
                },
                {
                    id: 137616,
                    name: "VfL Bochum",
                    odds: 3.2017,
                    isOpen: true
                }
            ]
        },
        {
            id: 20327,
            name: "TSG Hoffenheim vs Mainz 05",
            homeTeam: "TSG Hoffenheim",
            awayTeam: "Mainz 05",
            startDate: "20231126",
            time: "16:30",
            matchStatus: "Game Over",
            marketId: 36,
            marketName: "Full Time",
            marketCount: 41,
            league: {
                id: 42,
                name: "Bundesliga"
            },
            country: {
                id: 1,
                name: "Germany"
            },
            outcomes: [
                {
                    id: 137694,
                    name: "TSG Hoffenheim",
                    odds: 2.0575,
                    isOpen: true
                },
                {
                    id: 137695,
                    name: "Draw",
                    odds: 4.051,
                    isOpen: true
                },
                {
                    id: 137696,
                    name: "Mainz 05",
                    odds: 3.7362,
                    isOpen: true
                }
            ]
        },
        {
            id: 20055,
            name: "Le Havre vs PSG",
            homeTeam: "Le Havre",
            awayTeam: "PSG",
            startDate: "20231202",
            time: "18:00",
            matchStatus: "Game Over",
            marketId: 36,
            marketName: "Full Time",
            marketCount: 1,
            league: {
                id: 42,
                name: "Bundesliga"
            },
            country: {
                id: 1,
                name: "France"
            },
            outcomes: [
                {
                    id: 111076,
                    name: "Le Havre",
                    key: "home",
                    odds: 1,
                    isOpen: false
                },
                {
                    id: 111077,
                    name: "Draw",
                    key: "draw",
                    odds: 1,
                    isOpen: false
                },
                {
                    id: 111078,
                    name: "PSG",
                    key: "away",
                    odds: 1,
                    isOpen: false
                }
            ]
        }
    ],
    singleEventData: [{
            id: 54063,
            marketTypeConfigId: 36,
            name: "Full Time",
            outcomes: [
                {
                    id: 130555,
                    name: "Borussia Mönchengladbach",
                    odds: 2.3931,
                    isOpen: true
                },
                {
                    id: 130556,
                    name: "Draw",
                    odds: 4.0282,
                    isOpen: true
                },
                {
                    id: 130557,
                    name: "VfL Wolfsburg",
                    odds: 2.9903,
                    isOpen: true
                }
            ],
            displayRule: "THREE_WAY"
        }, {
            id: 54063,
            marketTypeConfigId: 36,
            name: "Full Time",
            outcomes: [
                {
                    id: 130555,
                    name: "Borussia Mönchengladbach",
                    odds: 2.3931,
                    isOpen: true
                },
                {
                    id: 130556,
                    name: "Draw",
                    odds: 4.0282,
                    isOpen: true
                },
                {
                    id: 130557,
                    name: "VfL Wolfsburg",
                    odds: 2.9903,
                    isOpen: true
                }
            ],
            displayRule: "THREE_WAY"
        }, {
            id: 54063,
            marketTypeConfigId: 36,
            name: "Full Time",
            outcomes: [
                {
                    id: 130555,
                    name: "Borussia Mönchengladbach",
                    odds: 2.3931,
                    isOpen: true
                },
                {
                    id: 130556,
                    name: "Draw",
                    odds: 4.0282,
                    isOpen: true
                },
                {
                    id: 130557,
                    name: "VfL Wolfsburg",
                    odds: 2.9903,
                    isOpen: true
                }
            ],
            displayRule: "THREE_WAY"
        }, {
            id: 54063,
            marketTypeConfigId: 36,
            name: "Full Time",
            outcomes: [
                {
                    id: 130555,
                    name: "Borussia Mönchengladbach",
                    odds: 2.3931,
                    isOpen: true
                },
                {
                    id: 130556,
                    name: "Draw",
                    odds: 4.0282,
                    isOpen: true
                },
                {
                    id: 130557,
                    name: "VfL Wolfsburg",
                    odds: 2.9903,
                    isOpen: true
                }
            ],
            displayRule: "THREE_WAY"
        }, {
            id: 54063,
            marketTypeConfigId: 36,
            name: "Full Time",
            outcomes: [
                {
                    id: 130555,
                    name: "Borussia Mönchengladbach",
                    odds: 2.3931,
                    isOpen: true
                },
                {
                    id: 130556,
                    name: "Draw",
                    odds: 4.0282,
                    isOpen: true
                },
                {
                    id: 130557,
                    name: "VfL Wolfsburg",
                    odds: 2.9903,
                    isOpen: true
                }
            ],
            displayRule: "THREE_WAY"
        }, {
            id: 54063,
            marketTypeConfigId: 36,
            name: "Full Time",
            outcomes: [
                {
                    id: 130555,
                    name: "Borussia Mönchengladbach",
                    odds: 2.3931,
                    isOpen: true
                },
                {
                    id: 130556,
                    name: "Draw",
                    odds: 4.0282,
                    isOpen: true
                },
                {
                    id: 130557,
                    name: "VfL Wolfsburg",
                    odds: 2.9903,
                    isOpen: true
                }
            ],
            displayRule: "THREE_WAY"
        }, {
            id: 54063,
            marketTypeConfigId: 36,
            name: "Full Time",
            outcomes: [
                {
                    id: 130555,
                    name: "Borussia Mönchengladbach",
                    odds: 2.3931,
                    isOpen: true
                },
                {
                    id: 130556,
                    name: "Draw",
                    odds: 4.0282,
                    isOpen: true
                },
                {
                    id: 130557,
                    name: "VfL Wolfsburg",
                    odds: 2.9903,
                    isOpen: true
                }
            ],
            displayRule: "THREE_WAY"
        }]
};
