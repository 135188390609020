import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { Checkbox, Col, Row } from "antd";
// components
import MountDocumentComponent from "components/documentation/MountDocumentComponent";
// document details of inner components
import { documentDetails as noDataForMarketDocumentDetails } from "pages/documentation/components/NoDataForMarket";
import { documentDetails as rangeFiltersDocumentDetails } from "pages/documentation/components/RangeFilters";
export const documentDetails = {
    title: "My bets",
    component: "leetent-my-bets",
    description: "This component renders list of bets based on the product and selected filters.",
    withSessionComponent: true,
    attributes: [
        {
            key: "1",
            attr: "product-id",
            type: "number",
            notes: ["1 - sports", "2 - eSports"]
        },
        {
            key: "2",
            attr: "per-page",
            type: "number (optional)",
            notes: ["Amount of bets per page", "Default is 50"]
        },
        {
            key: "3",
            attr: "date-picker-exact",
            type: "boolean (optional)",
            notes: [
                "When this prop is informed, start and end dates should be based on the current time",
                "If not passed, then you will see dates start @ 00:00:00 and finishing at 23:59:59"
            ]
        },
        {
            key: "4",
            attr: "no-data-options",
            type: "object{} optional",
            notes: [
                "Custom no data options.",
                `{
          message:"No Events Available at the moment",
          src:"https://d1bu7ybn6mtlcz.cloudfront.net/data_alert.svg",
          cta:{JSON.stringify(label: "Go To Sportsbook",url: "/")}
        }`,
                `Default value is:           
          {
            message:"No Events Available at the moment",
            src:"https://d1bu7ybn6mtlcz.cloudfront.net/data_alert.svg",
          }`
            ]
        },
        {
            key: "5",
            attr: "cashout",
            type: "boolean (optional)",
            notes: [
                "True by default. If value is false, cashout buttons will be hidden"
            ]
        }
    ],
    events: [
        {
            event: "selected-date-range",
            type: "listener",
            data: [
                "The component details object is emitted on date range change.",
                `detail: {
          period: <1day | 2days | 7days | 30days | 60days | 90days | byDate>,
          date: {
            from: string;
            to: string;
          };
          year: {
            from: number;
            to: number;
          };
          month: {
            from: number;
            to: number;
          };
          day: {
            from: number;
            to: number;
          };
          dayOfWeek: {
            from: {
              index: number;
              name: string;
            };
            to: {
              index: number;
              name: string;
            };
          };
          weekOfYear: {
            from: number;
            to: number;
          };
          iso: {
            from: {
              index: number;
              name: string;
            };
            to: {
              index: number;
              name: string;
            };
          };
          timestamps: {
            from: {
              index: number;
              name: string;
            };
            to: {
              index: number;
              name: string;
            };
          };
        }`
            ]
        },
        {
            event: "bet-cashed-out",
            type: "listener",
            data: ["-"]
        }
    ],
    innerComponents: [
        {
            name: "leetent-range-filters",
            documentDetails: rangeFiltersDocumentDetails
        },
        {
            name: "leetent-nodata-for-market",
            documentDetails: noDataForMarketDocumentDetails
        }
    ]
};
const MyBets = () => {
    const [checkedProps, setCheckedProps] = React.useState(["cashout"]);
    const onChange = (checkedValues) => {
        setCheckedProps(checkedValues);
    };
    const InteractiveInputs = React.useCallback(() => {
        return (_jsx(_Fragment, { children: _jsx(Row, Object.assign({ style: { marginTop: 30 } }, { children: _jsxs(Col, Object.assign({ span: 24 }, { children: [_jsx("label", { children: "Props" }), _jsx(Checkbox.Group, Object.assign({ style: { width: "100%" }, onChange: onChange, value: checkedProps }, { children: _jsx(Row, Object.assign({ gutter: [30, 10] }, { children: _jsx(Col, { children: _jsx(Checkbox, Object.assign({ value: "cashout" }, { children: "cashout" })) }) })) }))] })) })) }));
    }, [checkedProps]);
    documentDetails.interactiveInputs = _jsx(InteractiveInputs, {});
    documentDetails.example = `<leetent-my-bets product-id={2} per-page={20} />`;
    documentDetails.code = (_jsx("leetent-my-bets", { "product-id": 1, "per-page": 20, "no-data-options": JSON.stringify({
            message: "No Events Available at the moment",
            src: "https://d1bu7ybn6mtlcz.cloudfront.net/data_alert.svg",
            cta: { label: "Go To Sportbook", url: "/" }
        }), cashout: checkedProps === null || checkedProps === void 0 ? void 0 : checkedProps.includes("cashout") }));
    // render the page based on the provided object
    return _jsx(MountDocumentComponent, { data: documentDetails });
};
export default MyBets;
