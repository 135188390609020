var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { createRoot } from "react-dom/client";
import { Col, Row } from "antd";
import ImageFromCDN from "components/base/imageFromCDN/ImageFromCDN";
import { BetSummary } from "./betSummary/BetSummary";
import { BetSlipFooter } from "./betSlipFooter/BetSlipFooter";
import { MultipleBetsSelection } from "./multipleBetsSelection/MultipleBetsSelection";
import { SystemBets } from "./systemBets/SystemBets";
import clsx from "clsx";
import axios from "axios";
import { LocalStorage, SessionStorage } from "helpers/Storage";
import { getBetCombinations, hasOutrightsFromSameLeague, isBetLive } from "helpers/Bets";
import { createCommaSeparatedString, toFixed } from "helpers/GeneralFunctions";
import { BETSLIP_ERRORS, BETSLIP_ODDS_SETTINGS, BETSLIP_SELECTION_STATUS, BETS_LIMIT, CURRENCY, MODAL_POSITION, STAKE_SELECTIONS_TABS, EVENT_STATUS } from "enums";
import { BetSelections } from "./betSelections/BetSelections";
import { BetslipSelection as SelectionSkeleton } from "components/base/skeleton/BetslipSelection";
import { ModalGeneric } from "components/base/modal/ModalGeneric";
import { useDisableBodyScroll } from "hooks/useDisableBodyScroll";
import { SUBSCRIBE_EVENTS } from "v2/graphql/subscriptions";
import { BBPromo } from "components/base/bbPromo/BBPromo";
import { useSubscribeByQuery } from "helpers/graphql/useSubscribeByQuery";
import styles from "./BetSlip.module.scss";
const defaultTabsOptions = [
    { name: STAKE_SELECTIONS_TABS.SINGLES, value: STAKE_SELECTIONS_TABS.SINGLES },
    { name: STAKE_SELECTIONS_TABS.MULTIPLE, value: STAKE_SELECTIONS_TABS.MULTIPLE },
    { name: STAKE_SELECTIONS_TABS.SYSTEM, value: STAKE_SELECTIONS_TABS.SYSTEM, disabled: true }
];
const getDefaultSelectedTab = () => {
    let defaultSelectedTab = STAKE_SELECTIONS_TABS.MULTIPLE;
    const hash = window.location.hash;
    const searchParams = new URLSearchParams(hash.slice(hash.indexOf("?") + 1));
    const oids = searchParams.getAll("oid");
    oids === null || oids === void 0 ? void 0 : oids.forEach((oid) => {
        const oddsDetails = oid.split("_");
        if (oddsDetails[2]) {
            defaultSelectedTab = STAKE_SELECTIONS_TABS.SINGLES;
            return null;
        }
    });
    return defaultSelectedTab;
};
const defaultMinStake = 100;
const insufficientFundsModalId = "insufficient-balance";
const placeBetLoaderModalId = "place-bet-loader";
export const BetSlip = ({ bets, minStake = defaultMinStake, isRelative = false, isCoupon, isOpen = false, productId, unauthenticatedModalTitle, unauthenticatedModalOptions, maxWidth = "inherit", stake }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    const defaultSelectedTab = getDefaultSelectedTab();
    const defaultBets = Number(bets === null || bets === void 0 ? void 0 : bets.length) > 0 ? bets : (_a = LocalStorage.get("betsDetails")) !== null && _a !== void 0 ? _a : [];
    const [selectedTab, setSelectedTab] = React.useState(defaultSelectedTab);
    const [selectedBets, setSelectedBets] = React.useState(defaultBets);
    const [stakedBets, setStakedBets] = React.useState([]);
    const stakedBetsRef = React.useRef([]);
    const [groupedBets, setGroupedBets] = React.useState({});
    const [showBetslip, setShowBetslip] = React.useState(false);
    const [unauthenticatedTitle, setUnauthenticatedTitle] = React.useState(unauthenticatedModalTitle);
    const [unauthenticatedModal, setUnauthenticatedModal] = React.useState(false);
    const [insufficientFundsModal, setInsufficientFundsModal] = React.useState(false);
    const [tabsOptions, setTabsOptions] = React.useState(defaultTabsOptions);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isPlaceBetLoading, setIsPlaceBetLoading] = React.useState(false);
    const [isBetsLoading, setIsBetsLoading] = React.useState(false);
    // This state keeps track of the errors at different levels
    const [error, setError] = React.useState({
        atBetslipLevel: undefined,
        atEventLevel: undefined,
        atBetLevel: undefined
    });
    const [betSlipId, setBetSlipId] = React.useState(null);
    const [totalOdds, setTotalOdds] = React.useState(0);
    const [isBB, setIsBB] = React.useState(false);
    const [isBBEnabled, setIsBBEnabled] = React.useState(false);
    const [themeVariant, setThemeVariant] = React.useState("light");
    const [freeBet, setFreeBet] = React.useState();
    useDisableBodyScroll(!isCoupon && showBetslip && !isRelative && selectedBets.length > 0);
    const totalEvents = Object.keys(groupedBets).length;
    const totalLegs = Object.values(groupedBets).reduce((acc, item) => acc + +item.betLegs.length, 0);
    const oddsChangedError = ((_b = error === null || error === void 0 ? void 0 : error.atBetslipLevel) === null || _b === void 0 ? void 0 : _b.type) === BETSLIP_ERRORS.ODDS_CHANGED_EXCEPTION;
    const outcomeClosedError = ((_c = error === null || error === void 0 ? void 0 : error.atBetslipLevel) === null || _c === void 0 ? void 0 : _c.type) === BETSLIP_ERRORS.OUTCOME_CLOSED_EXCEPTION;
    const maxStakeError = ((_d = error === null || error === void 0 ? void 0 : error.atBetslipLevel) === null || _d === void 0 ? void 0 : _d.type) === BETSLIP_ERRORS.MAX_WIN_EXCEEDED_EXCEPTION;
    React.useEffect(() => {
        const fetchClientData = () => {
            var _a;
            if (!((_a = process.env) === null || _a === void 0 ? void 0 : _a.REACT_APP_GATEWAY_API_URL))
                return;
            setIsLoading(true);
            axios
                .get(`${process.env.REACT_APP_GATEWAY_API_URL}/client/config`)
                .then((res) => {
                var _a;
                const { isBetBuilderEnabled } = (_a = res.data) !== null && _a !== void 0 ? _a : {};
                setIsBBEnabled(isBetBuilderEnabled);
            })
                .catch((e) => {
                console.log(e);
            })
                .finally(() => {
                setIsLoading(false);
            });
        };
        fetchClientData();
    }, []);
    const defaultLiveEventIds = [
        ...new Set(selectedBets
            .filter(({ startDate, time }) => isBetLive({ startDate, time }))
            .map((bet) => bet.eventId))
    ];
    const [liveEventIds, setLiveEventIds] = React.useState(defaultLiveEventIds);
    // subscribes to multiple event ids
    const valueHandlerCallback = (details) => {
        const eventsData = (details === null || details === void 0 ? void 0 : details.sport_domain_event) || [];
        setLiveEventIds((prevLiveEventIds) => {
            // Create a new set to avoid duplicates
            const liveEventIdsSet = new Set(prevLiveEventIds);
            eventsData.forEach((event) => {
                if ((event === null || event === void 0 ? void 0 : event.event_status_id) === EVENT_STATUS.LIVE) {
                    // Add the event id to the set if it is live
                    liveEventIdsSet.add(event.id);
                }
                else {
                    // Remove the event id from the set if it is not live
                    liveEventIdsSet.delete(event.id);
                }
            });
            // Convert the set back to an array
            return Array.from(liveEventIdsSet);
        });
    };
    const eventIdsWithBBEnabled = [...new Set(selectedBets.filter((bet) => bet.isBetBuilderEnabled && bet.eventId).map((bet) => bet.eventId))];
    useSubscribeByQuery({
        query: SUBSCRIBE_EVENTS,
        variables: {
            eventIds: eventIdsWithBBEnabled
        },
        valueHandlerCallback
    });
    // For FooterNavigation
    React.useEffect(() => {
        var _a;
        const totalOddsUpdate = new CustomEvent(`bet-slip-total-events-legs-update`, {
            bubbles: true,
            detail: {
                totalEvents,
                totalLegs,
                singleEventName: (_a = selectedBets[0]) === null || _a === void 0 ? void 0 : _a.event
            }
        });
        window.dispatchEvent(totalOddsUpdate);
    }, [totalEvents, totalLegs]);
    React.useEffect(() => {
        const betslipBBChange = new CustomEvent(`bet-slip-bb-change`, {
            bubbles: true,
            detail: {
                isBB,
                showBBPromo: isBBEnabled && (selectedBets.some(bet => bet.isBetBuilderEnabled) || totalLegs > totalEvents)
            }
        });
        window.dispatchEvent(betslipBBChange);
    }, [isBB, totalLegs, totalEvents, selectedBets, isBBEnabled]);
    React.useEffect(() => {
        const widgetToken = LocalStorage.get("widget-token");
        const widgetPlayerId = LocalStorage.get("widget-playerId");
        const fetchBonusList = () => {
            var _a;
            if (!((_a = process.env) === null || _a === void 0 ? void 0 : _a.REACT_APP_GATEWAY_API_URL))
                return;
            setIsLoading(true);
            axios
                .get(`${process.env.REACT_APP_GATEWAY_API_URL}/bonus/list`, {
                headers: {
                    "widget-token": widgetToken,
                    "widget-playerId": widgetPlayerId
                }
            })
                .then((res) => {
                var _a, _b, _c, _d;
                const data = ((_b = (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.freeBet) && ((_d = (_c = res === null || res === void 0 ? void 0 : res.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.freeBet[0]);
                if (data) {
                    setFreeBet({
                        amount: +data.amount,
                        bonusId: data.bonusId,
                        usageConditions: data.rules.usageConditions,
                        expireDate: data.expireDate.date
                    });
                }
            })
                .catch((e) => {
                console.log(e);
            })
                .finally(() => {
                setIsLoading(false);
            });
        };
        if (!betSlipId && widgetToken && widgetPlayerId) {
            fetchBonusList();
        }
    }, [betSlipId]);
    React.useEffect(() => {
        if (productId === 1 && totalLegs > totalEvents && selectedBets.filter(bet => bet.isBetBuilderEnabled).length >= 2) {
            isBBEnabled && setIsBB(true);
        }
        else {
            setIsBB(false);
        }
    }, [productId, groupedBets, isBBEnabled]);
    React.useEffect(() => {
        bets && setSelectedBets(bets);
    }, [bets]);
    const triggerCloseBetSlip = () => {
        const closeBetSlip = new CustomEvent(`close-bet-slip`, {
            bubbles: true
        });
        window.dispatchEvent(closeBetSlip);
    };
    const triggerUpdateBetsEvent = ({ selectedBets, isRemoveBet = false }) => {
        var _a;
        const updateSelectedBets = new CustomEvent(`update-selected-bets`, {
            detail: {
                selectedBets,
                isRemoveBet
            },
            bubbles: true
        });
        window.dispatchEvent(updateSelectedBets);
        if (!selectedBets.length && ((_a = LocalStorage.get("betsDetails")) === null || _a === void 0 ? void 0 : _a.length) === 0) {
            triggerCloseBetSlip();
        }
    };
    const triggerClearBets = () => {
        const clearBets = new CustomEvent("bet-slip-clear-bets", {
            bubbles: true
        });
        window.dispatchEvent(clearBets);
    };
    const triggerOddsUpdate = (totalOdds) => {
        const totalOddsUpdate = new CustomEvent(`bet-slip-total-odds-update`, {
            bubbles: true,
            detail: {
                totalOdds
            }
        });
        window.dispatchEvent(totalOddsUpdate);
    };
    const updateMultipleTotalOdds = () => {
        var _a;
        const totalOdds = (_a = selectedBets.reduce((acc, bet) => {
            if (bet.productId !== productId)
                return acc;
            if (acc && (!(bet === null || bet === void 0 ? void 0 : bet.statusId) || (bet === null || bet === void 0 ? void 0 : bet.statusId) === BETSLIP_SELECTION_STATUS.OPEN))
                return acc * +bet.odds;
            return +bet.odds;
        }, 0)) !== null && _a !== void 0 ? _a : 0;
        triggerOddsUpdate(totalOdds);
        setTotalOdds(totalOdds);
    };
    const updateGroupedBetsTotalOdds = (newGroupedBets) => {
        const fetchOdds = ({ eventId, outcomeIds }) => __awaiter(void 0, void 0, void 0, function* () {
            setIsBetsLoading(true);
            try {
                const response = yield axios.get(`/bet-builder-outcomes/quote?eventId=${eventId}&outcomeIds=${outcomeIds}`);
                return (response === null || response === void 0 ? void 0 : response.data) || {};
            }
            catch (e) {
                console.log("BB error", e);
                // Handle the error appropriately here
                return {};
            }
            finally {
                setIsBetsLoading(false);
            }
        });
        setGroupedBets(newGroupedBets);
        const updateTotalOdds = () => __awaiter(void 0, void 0, void 0, function* () {
            const updatedGroupedBets = Object.assign({}, newGroupedBets);
            const calculatedOdds = yield Object.entries(newGroupedBets).reduce((accPromise, [eventId, eventData]) => __awaiter(void 0, void 0, void 0, function* () {
                var _a, _b, _c;
                const acc = yield accPromise;
                const eventBet = eventData.betLegs;
                const getGroupedCalculations = () => __awaiter(void 0, void 0, void 0, function* () {
                    const outcomeIds = createCommaSeparatedString(eventBet.map((bet) => bet.outcomeId));
                    const { odds = 1 } = yield fetchOdds({ eventId: eventBet[0].eventId, outcomeIds });
                    updatedGroupedBets[eventId].totalOdds = odds;
                    return (acc || 1) * odds;
                });
                if (((_a = eventBet[0]) === null || _a === void 0 ? void 0 : _a.statusId) !== BETSLIP_SELECTION_STATUS.OPEN)
                    return acc;
                if ((eventBet === null || eventBet === void 0 ? void 0 : eventBet.length) > 1) {
                    if (JSON.stringify((_b = groupedBets[eventId]) === null || _b === void 0 ? void 0 : _b.betLegs) === JSON.stringify(eventBet) && eventData.totalOdds) {
                        return (acc || 1) * eventData.totalOdds;
                    }
                    return yield getGroupedCalculations();
                }
                else {
                    return (acc || 1) * ((_c = eventBet[0]) === null || _c === void 0 ? void 0 : _c.odds);
                }
            }), Promise.resolve(0));
            setGroupedBets(updatedGroupedBets);
            triggerOddsUpdate(calculatedOdds);
            setTotalOdds(calculatedOdds);
        });
        updateTotalOdds();
    };
    const updateGroupedBets = () => {
        const hasOutrights = hasOutrightsFromSameLeague(selectedBets);
        if (productId === 2 || !isBBEnabled || hasOutrights || (selectedBets.length < 2 && totalLegs < 2) || !isMultiple) {
            updateMultipleTotalOdds();
            return;
        }
        const newGroupedMarkets = {};
        // Use a Set to keep track of unique outcomeIds
        const uniqueOutcomeIds = new Set();
        // Group the data by eventId and filter out duplicates based on outcomeId
        selectedBets.forEach((item) => {
            const { eventId, outcomeId } = item;
            if (item.productId !== productId)
                return;
            // Check if the outcomeId is unique
            if (!uniqueOutcomeIds.has(outcomeId)) {
                // Add the outcomeId to the set to prevent duplicates
                uniqueOutcomeIds.add(outcomeId);
                if (!newGroupedMarkets[eventId]) {
                    newGroupedMarkets[eventId] = { betLegs: [] };
                }
                newGroupedMarkets[eventId].betLegs.push(item);
            }
        });
        updateGroupedBetsTotalOdds(newGroupedMarkets);
    };
    // Handle grouped bets
    React.useEffect(() => {
        updateGroupedBets();
    }, [selectedBets, isBBEnabled]);
    React.useEffect(() => {
        const observerCallback = (mutationsList) => {
            var _a;
            for (const mutation of mutationsList) {
                if (mutation.type === "attributes" && mutation.attributeName === "data-theme") {
                    const newTheme = (_a = document.documentElement.getAttribute("data-theme")) !== null && _a !== void 0 ? _a : "light";
                    setThemeVariant(newTheme);
                }
            }
        };
        const observer = new MutationObserver(observerCallback);
        observer.observe(document.documentElement, { attributes: true });
        return () => {
            observer.disconnect();
        };
    }, []);
    React.useEffect(() => {
        setSelectedBets((bets) => bets.map(bet => {
            bet.stake = undefined;
            return bet;
        }));
        stakedBetsRef.current = [];
        setStakedBets([]);
    }, [selectedTab]);
    React.useEffect(() => {
        updateGroupedBets();
        const handleTabClick = (e) => {
            setSelectedTab(e.detail.value);
        };
        const handleBetSlipConfirmationClose = (e) => {
            var _a;
            if ((_a = e.detail) === null || _a === void 0 ? void 0 : _a.reuseSelections) {
                const betsDetails = LocalStorage.get("betsDetails");
                setSelectedBets(betsDetails);
                triggerUpdateBetsEvent({ selectedBets: betsDetails });
            }
            setBetSlipId(null);
        };
        const closeUnauthenticatedModal = (e) => {
            var _a;
            e.preventDefault();
            if (((_a = e.detail) === null || _a === void 0 ? void 0 : _a.modalId) === insufficientFundsModalId) {
                setInsufficientFundsModal(false);
            }
            else {
                setUnauthenticatedModal(false);
            }
        };
        const handleModalButton = (e) => {
            var _a;
            if (((_a = e.detail) === null || _a === void 0 ? void 0 : _a.modalId) === insufficientFundsModalId) {
                window.dispatchEvent(new CustomEvent("triggerDepositFlow", {
                    detail: { redirectURL: `${window.location.pathname}${window.location.hash}` }
                }));
            }
            else {
                setUnauthenticatedModal(false);
            }
        };
        const handleCloseModal = (e) => {
            var _a;
            if (((_a = e.detail) === null || _a === void 0 ? void 0 : _a.modalId) === insufficientFundsModalId) {
                setInsufficientFundsModal(false);
            }
            else {
                setUnauthenticatedModal(false);
            }
        };
        window.addEventListener("close-auth-modal", closeUnauthenticatedModal);
        window.addEventListener("click-modal-button", handleModalButton);
        window.addEventListener("close-modal", handleCloseModal);
        window.addEventListener("bet-slip-selected-tab", handleTabClick);
        window.addEventListener("bet-slip-confirmation-close", handleBetSlipConfirmationClose);
        updateSelectedBets();
        return () => {
            window.removeEventListener("close-auth-modal", closeUnauthenticatedModal);
            window.removeEventListener("click-modal-button", handleModalButton);
            window.removeEventListener("close-modal", handleCloseModal);
            window.removeEventListener("bet-slip-selected-tab", handleTabClick);
            window.removeEventListener("bet-slip-confirmation-close", handleBetSlipConfirmationClose);
        };
    }, []);
    const isMultiple = selectedTab === STAKE_SELECTIONS_TABS.MULTIPLE;
    const isSingles = selectedTab === STAKE_SELECTIONS_TABS.SINGLES;
    const handleBetChange = (betDetails) => {
        const { outcomeId, stake, odds, productId, applyToAll, bets, bonusId, statusId } = betDetails;
        if (applyToAll) {
            stakedBetsRef.current = [
                ...bets === null || bets === void 0 ? void 0 : bets.map(({ outcomeId, productId, odds, statusId }) => ({
                    stake,
                    legs: [{ outcomeId, odds, productId, statusId }]
                }))
            ];
            setStakedBets(stakedBetsRef.current);
            setSelectedBets((bets) => bets.map(bet => {
                bet.stake = stake;
                return bet;
            }));
            return;
        }
        const updatedBetIndex = stakedBetsRef.current.findIndex((bet) => {
            const legs = bet.legs.filter((leg) => leg.outcomeId === outcomeId);
            return (legs === null || legs === void 0 ? void 0 : legs.length) > 0;
        });
        if (updatedBetIndex !== -1) {
            const updatedBets = [...stakedBetsRef.current];
            if (stake) {
                updatedBets[updatedBetIndex].stake = stake;
            }
            stakedBetsRef.current = updatedBets;
        }
        else {
            stakedBetsRef.current = [
                ...stakedBetsRef.current,
                {
                    stake,
                    legs: [{ outcomeId, odds, productId, statusId }]
                }
            ];
        }
        const [firstItem, ...restItems] = stakedBetsRef.current;
        if (!bonusId && firstItem.bonusId) {
            delete firstItem.bonusId;
        }
        stakedBetsRef.current = [
            Object.assign(Object.assign({}, firstItem), (bonusId ? { bonusId } : {})),
            ...restItems
        ];
        setStakedBets(stakedBetsRef.current);
        setSelectedBets((prevSelectedBets) => {
            const updatedBets = bets === null || bets === void 0 ? void 0 : bets.map(bet => {
                if (bet.outcomeId === outcomeId) {
                    bet.stake = stake;
                    bet.odds = odds;
                }
                return bet;
            });
            if (JSON.stringify(prevSelectedBets) !== JSON.stringify(updatedBets)) {
                return updatedBets;
            }
            return prevSelectedBets;
        });
    };
    const handleRemoveBet = (outcomeId) => {
        var _a;
        stakedBetsRef.current = (_a = stakedBetsRef.current) === null || _a === void 0 ? void 0 : _a.filter((bet) => {
            const newLegs = bet.legs.filter((leg) => leg.outcomeId !== outcomeId);
            bet.legs = newLegs;
            return (newLegs === null || newLegs === void 0 ? void 0 : newLegs.length) > 0 ? bet : false;
        });
        setStakedBets(stakedBetsRef.current);
        setSelectedBets((prevBets) => {
            const updatedBets = prevBets === null || prevBets === void 0 ? void 0 : prevBets.filter((bets) => (bets === null || bets === void 0 ? void 0 : bets.outcomeId) !== outcomeId);
            triggerUpdateBetsEvent({ selectedBets: updatedBets, isRemoveBet: true });
            if (!updatedBets.length) {
                setShowBetslip(false);
            }
            return updatedBets;
        });
        // Every time a bet is removed, we reprocess the errors state
        processBetslipErrors(groupedBets);
    };
    const hasInterrelatedSelections = () => {
        const eventIds = selectedBets.map(bet => bet.eventId);
        const uniqueEventIds = new Set(eventIds);
        return eventIds.length > uniqueEventIds.size;
    };
    const hasOutrights = hasOutrightsFromSameLeague(selectedBets);
    const isUncombinableBets = hasInterrelatedSelections() || hasOutrights;
    React.useEffect(() => {
        var _a;
        const eventIdsLiveErrors = [];
        // this applies to BB selections only
        if (isMultiple && isBB) {
            liveEventIds.forEach((eventId) => {
                eventIdsLiveErrors.push({
                    type: BETSLIP_ERRORS.INTERRELATED_SELECTIONS,
                    message: "Some of the selections can’t go together in Bet Builder.",
                    eventId
                });
            });
        }
        const newAtEventLevel = structuredClone(error);
        newAtEventLevel.atEventLevel = newAtEventLevel.atEventLevel ?
            [...newAtEventLevel.atEventLevel, ...eventIdsLiveErrors] :
            eventIdsLiveErrors;
        if (((_a = newAtEventLevel.atEventLevel) === null || _a === void 0 ? void 0 : _a.length) === 0) {
            newAtEventLevel.atEventLevel = undefined;
        }
        setError(newAtEventLevel);
    }, [liveEventIds]);
    // This function is called to process the errors at the different levels
    const processBetslipErrors = (groupedBets) => {
        var _a;
        let isUncombinable = false;
        let hasOutrights = false;
        let mixedBBNotBBOutcomes = [];
        const nonBBLegPerBetLegs = [];
        const nonBBEventIds = [];
        const GroupedBetsIntoValues = Object.values(groupedBets);
        // checking possible scenarios we want to block
        GroupedBetsIntoValues.forEach(bet => {
            // we check if there is only one non-BB leg, in order to allow the bet to go through
            let nonBBLeg = 0;
            // some of the odds cannot be combined
            if ((bet === null || bet === void 0 ? void 0 : bet.totalOdds) && bet.totalOdds <= 1) {
                isUncombinable = true;
            }
            const outcomesWhichBBEnabledIsFalse = [];
            bet === null || bet === void 0 ? void 0 : bet.betLegs.forEach((leg) => {
                var _a;
                if (((_a = bet === null || bet === void 0 ? void 0 : bet.betLegs) === null || _a === void 0 ? void 0 : _a.length) === 1)
                    nonBBEventIds.push(leg === null || leg === void 0 ? void 0 : leg.eventId);
                // check if any bet is outright
                if (leg === null || leg === void 0 ? void 0 : leg.isOutrights) {
                    hasOutrights = true;
                }
                // we check if only one is non-BB
                if (!(leg === null || leg === void 0 ? void 0 : leg.isBetBuilderEnabled))
                    nonBBLeg++;
                // if at least one outcome is returning false to isBetbuilderEnabled
                // then we should in invalid the whole betLegs
                if (!(leg === null || leg === void 0 ? void 0 : leg.isBetBuilderEnabled)) {
                    outcomesWhichBBEnabledIsFalse.push({
                        eventId: leg === null || leg === void 0 ? void 0 : leg.eventId,
                        marketId: leg === null || leg === void 0 ? void 0 : leg.marketId,
                        outcomeId: leg === null || leg === void 0 ? void 0 : leg.outcomeId
                    });
                }
            });
            nonBBLegPerBetLegs.push(nonBBLeg);
            // adds the conflicting information to the conflictingPicks array for later validation
            if ((outcomesWhichBBEnabledIsFalse === null || outcomesWhichBBEnabledIsFalse === void 0 ? void 0 : outcomesWhichBBEnabledIsFalse.length) > 0) {
                outcomesWhichBBEnabledIsFalse.forEach((outcome) => {
                    var _a;
                    // if the event and market were not added to the array
                    if (
                    // nonBBLeg !== 1 &&
                    ((_a = mixedBBNotBBOutcomes.filter((item) => {
                        return item.eventId === outcome.eventId && item.marketId === outcome.marketId;
                    })) === null || _a === void 0 ? void 0 : _a.length) === 0) {
                        mixedBBNotBBOutcomes.push({
                            type: BETSLIP_ERRORS.INTERRELATED_SELECTIONS,
                            message: "Some of these cannot be combined.",
                            eventId: outcome.eventId,
                            marketId: outcome.marketId,
                            outcomeIds: [outcome.outcomeId]
                        });
                    }
                    else {
                        mixedBBNotBBOutcomes = mixedBBNotBBOutcomes === null || mixedBBNotBBOutcomes === void 0 ? void 0 : mixedBBNotBBOutcomes.map((item2) => {
                            var _a;
                            if (item2.eventId === outcome.eventId && item2.marketId === outcome.marketId) {
                                (_a = item2.outcomesIds) === null || _a === void 0 ? void 0 : _a.push(outcome.outcomeId);
                            }
                            return item2;
                        });
                    }
                });
            }
        });
        const shouldAllowBBWithANonBB = !(nonBBLegPerBetLegs.reduce((occurence, current) => {
            if (current > 1)
                occurence++;
            return occurence;
        }, 0) === 1);
        if (shouldAllowBBWithANonBB && (nonBBEventIds === null || nonBBEventIds === void 0 ? void 0 : nonBBEventIds.length) === (mixedBBNotBBOutcomes === null || mixedBBNotBBOutcomes === void 0 ? void 0 : mixedBBNotBBOutcomes.length)) {
            mixedBBNotBBOutcomes = [];
        }
        else if (shouldAllowBBWithANonBB && (nonBBEventIds === null || nonBBEventIds === void 0 ? void 0 : nonBBEventIds.length) !== (mixedBBNotBBOutcomes === null || mixedBBNotBBOutcomes === void 0 ? void 0 : mixedBBNotBBOutcomes.length)) {
            mixedBBNotBBOutcomes = mixedBBNotBBOutcomes === null || mixedBBNotBBOutcomes === void 0 ? void 0 : mixedBBNotBBOutcomes.filter((mixedBB) => !nonBBEventIds.includes(mixedBB === null || mixedBB === void 0 ? void 0 : mixedBB.eventId));
        }
        // For cases when we can't create combined bet including case when we select all legs from same market
        if (hasOutrights || (isUncombinable && (mixedBBNotBBOutcomes === null || mixedBBNotBBOutcomes === void 0 ? void 0 : mixedBBNotBBOutcomes.length) === 0)) {
            isMultiple && setError((oldObj) => (Object.assign(Object.assign({}, oldObj), { atBetslipLevel: {
                    type: BETSLIP_ERRORS.INTERRELATED_SELECTIONS,
                    message: "Some of these selections cannot be combined. Try removing selections or place them as single bets."
                } })));
        }
        else {
            if ((mixedBBNotBBOutcomes === null || mixedBBNotBBOutcomes === void 0 ? void 0 : mixedBBNotBBOutcomes.length) > 0) {
                // provides the array of conflicting events/markets and/or mixed BB outcomes per event/markets
                setError({
                    atBetslipLevel: undefined,
                    atEventLevel: [...mixedBBNotBBOutcomes],
                    atBetLevel: undefined
                });
            }
            else if (((_a = error === null || error === void 0 ? void 0 : error.atBetslipLevel) === null || _a === void 0 ? void 0 : _a.type) === BETSLIP_ERRORS.INTERRELATED_SELECTIONS ||
                maxStakeError) {
                setError({
                    atBetslipLevel: undefined,
                    atEventLevel: undefined,
                    atBetLevel: undefined
                });
            }
            else if ((liveEventIds === null || liveEventIds === void 0 ? void 0 : liveEventIds.length) > 0) {
                setError(error => (Object.assign(Object.assign({}, error), { atBetslipLevel: undefined, atBetLevel: undefined })));
            }
            else if (!isUncombinable && (mixedBBNotBBOutcomes === null || mixedBBNotBBOutcomes === void 0 ? void 0 : mixedBBNotBBOutcomes.length) === 0) {
                setError({
                    atBetslipLevel: undefined,
                    atEventLevel: undefined,
                    atBetLevel: undefined
                });
            }
        }
        return { isUncombinable, mixedBBNotBBOutcomes };
    };
    React.useEffect(() => {
        var _a;
        setTabsOptions(tabsOptions => tabsOptions.map((tabsOption) => {
            if (tabsOption.value === STAKE_SELECTIONS_TABS.MULTIPLE) {
                if (isBB) {
                    tabsOption.name = STAKE_SELECTIONS_TABS.BET_BUILDER;
                    tabsOption.isBB = true;
                }
                else {
                    tabsOption.name = STAKE_SELECTIONS_TABS.MULTIPLE;
                    tabsOption.isBB = false;
                }
            }
            if (selectedBets.length >= 3) {
                if (tabsOption.value === STAKE_SELECTIONS_TABS.SYSTEM) {
                    if (isUncombinableBets) {
                        tabsOption.disabled = true;
                    }
                    else {
                        tabsOption.disabled = false;
                    }
                }
            }
            else {
                if (selectedTab === STAKE_SELECTIONS_TABS.SYSTEM) {
                    setSelectedTab(STAKE_SELECTIONS_TABS.MULTIPLE);
                }
                if (tabsOption.value === STAKE_SELECTIONS_TABS.SYSTEM) {
                    tabsOption.disabled = true;
                }
            }
            return tabsOption;
        }));
        // Check if there any selections from the same game
        if (isBB) {
            processBetslipErrors(groupedBets);
        }
        else {
            if (!isUncombinableBets || isSingles) {
                if (((_a = error === null || error === void 0 ? void 0 : error.atBetslipLevel) === null || _a === void 0 ? void 0 : _a.type) === BETSLIP_ERRORS.INTERRELATED_SELECTIONS) {
                    setError({
                        atBetslipLevel: undefined,
                        atEventLevel: undefined,
                        atBetLevel: undefined
                    });
                }
            }
        }
        if ((outcomeClosedError &&
            selectedBets.every((bet) => !(bet === null || bet === void 0 ? void 0 : bet.statusId) || (bet === null || bet === void 0 ? void 0 : bet.statusId) === BETSLIP_SELECTION_STATUS.OPEN)) ||
            maxStakeError) {
            setError({
                atBetslipLevel: undefined,
                atEventLevel: undefined,
                atBetLevel: undefined
            });
        }
        LocalStorage.set("betsDetails", JSON.stringify(selectedBets));
    }, [selectedBets === null || selectedBets === void 0 ? void 0 : selectedBets.length, groupedBets, isBB, selectedTab, liveEventIds]);
    React.useEffect(() => {
        if (!showBetslip) {
            triggerCloseBetSlip();
        }
        // TODO: This is causing double-rendering issues, so when the betslip opens, it clears the errors
        // TODO: I commented out this section for testing purposes and to identify why we need it
        /* else {
          setError({
            atBetslipLevel: undefined,
            atEventLevel: undefined,
            atBetLevel: undefined
          });
        } */
    }, [showBetslip]);
    React.useEffect(() => {
        setShowBetslip(isOpen);
    }, [isOpen]);
    // React.useEffect(() => {
    //   const triggerUpdateSelectedBets = new CustomEvent(`update-selected-bets`, {
    //     detail: {
    //       selectedBets
    //     },
    //     bubbles: true
    //   });
    //   window.dispatchEvent(triggerUpdateSelectedBets);
    // }, [selectedBets]);
    function fetchSelectionState() {
        return __awaiter(this, void 0, void 0, function* () {
            const params = {};
            const selectionIds = selectedBets.reduce((acc, { outcomeId }) => {
                if (acc)
                    return `${acc},${outcomeId}`;
                return String(outcomeId);
            }, "");
            if (!selectionIds) {
                setIsLoading(false);
                return yield Promise.resolve([]);
            }
            if (productId === 1) {
                params.sportSelectionIds = selectionIds;
            }
            if (productId === 2) {
                params.esportSelectionIds = selectionIds;
            }
            setIsLoading(true);
            return yield axios
                .get("/selection-states", {
                params
            })
                .then(({ data }) => {
                return data;
            })
                .catch((err) => {
                console.log(err);
            })
                .finally(() => {
                setIsLoading(false);
            });
        });
    }
    function updateSelectedBets() {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            if (((_a = process.env) === null || _a === void 0 ? void 0 : _a.REACT_APP_SPORTSBOOK_API_URL) &&
                ((_b = process.env) === null || _b === void 0 ? void 0 : _b.REACT_APP_SPORTSBOOK_API_KEY)) {
                !isLoading && setIsLoading(true);
                const data = yield fetchSelectionState();
                const prevChangeOdds = new CustomEvent("prev-change-odds", {
                    detail: {
                        prevBets: selectedBets.map(({ odds, outcomeId }) => ({ odds, outcomeId }))
                    },
                    bubbles: true
                });
                window.dispatchEvent(prevChangeOdds);
                const getDefaultStatusId = (isOpen) => isOpen ? BETSLIP_SELECTION_STATUS.OPEN : BETSLIP_SELECTION_STATUS.SUSPENDED;
                const updatedBets = selectedBets.map((selectedBet) => {
                    // abId for esports
                    const matchingBet = data === null || data === void 0 ? void 0 : data.find((apiBet) => (selectedBet.outcomeId === apiBet.abId) || (selectedBet.outcomeId === apiBet.id));
                    if (!matchingBet) {
                        return Object.assign(Object.assign({}, selectedBet), { statusId: BETSLIP_SELECTION_STATUS.SUSPENDED });
                    }
                    if (productId === 2) {
                        return Object.assign(Object.assign({}, selectedBet), { odds: matchingBet.odds, statusId: (matchingBet.isOpen === undefined || matchingBet.isOpen) ?
                                BETSLIP_SELECTION_STATUS.OPEN :
                                BETSLIP_SELECTION_STATUS.CLOSED });
                    }
                    return Object.assign(Object.assign({}, selectedBet), { odds: matchingBet.odds, statusId: (matchingBet === null || matchingBet === void 0 ? void 0 : matchingBet.statusId) || getDefaultStatusId(matchingBet === null || matchingBet === void 0 ? void 0 : matchingBet.isOpen) });
                });
                stakedBetsRef.current = stakedBetsRef.current.map((stakedBet) => {
                    stakedBet.legs = stakedBet.legs.map(leg => {
                        const matchingBet = data.find((apiBet) => leg.outcomeId === apiBet.id);
                        if (matchingBet) {
                            return Object.assign(Object.assign({}, leg), { odds: matchingBet.odds });
                        }
                        return leg;
                    });
                    return stakedBet;
                });
                setStakedBets(stakedBetsRef.current);
                triggerUpdateBetsEvent({ selectedBets: updatedBets });
                setSelectedBets(updatedBets);
            }
        });
    }
    React.useEffect(() => {
        var _a;
        const { errors } = (_a = error === null || error === void 0 ? void 0 : error.atBetslipLevel) !== null && _a !== void 0 ? _a : {};
        if (oddsChangedError || outcomeClosedError) {
            updateSelectedBets();
        }
        if (maxStakeError &&
            (errors === null || errors === void 0 ? void 0 : errors.length) &&
            selectedTab !== STAKE_SELECTIONS_TABS.SYSTEM) {
            const updatedStakeSelections = [];
            errors.forEach((error) => {
                const { MaxStake, BetIndex } = error.data;
                updatedStakeSelections.push({
                    outcomeId: stakedBetsRef.current[BetIndex].legs[0].outcomeId,
                    stake: MaxStake
                });
                stakedBetsRef.current[BetIndex].stake = MaxStake;
            });
            const triggerChangeMaxStake = () => {
                var _a;
                const isMaxWinExceeded = ((_a = error === null || error === void 0 ? void 0 : error.atBetslipLevel) === null || _a === void 0 ? void 0 : _a.type) === BETSLIP_ERRORS.MAX_WIN_EXCEEDED_EXCEPTION;
                const changeMaxStake = new CustomEvent(`change-max-stake`, {
                    detail: { selections: updatedStakeSelections, isMaxWinExceeded },
                    bubbles: true
                });
                window.dispatchEvent(changeMaxStake);
            };
            setStakedBets([...stakedBetsRef.current]);
            triggerChangeMaxStake();
            if (isSingles) {
                setError({
                    atBetslipLevel: undefined,
                    atEventLevel: undefined,
                    atBetLevel: undefined
                });
            }
        }
    }, [error]);
    // TODO: add outer click handling
    // const outerClickHandler = (): void => {
    //   if (showBetslip) {
    //     setShowBetslip(false);
    //   }
    // };
    // const rootRef = useOuterClick(outerClickHandler);
    const onMultipleStakeChange = (stake, bonusId) => {
        if (!stake) {
            stakedBetsRef.current = [];
            setStakedBets([...stakedBetsRef.current]);
            return;
        }
        if (stakedBetsRef.current.length > 0 && stakedBetsRef.current[0].stake) {
            stakedBetsRef.current[0].stake = stake;
            bonusId && (stakedBetsRef.current[0].bonusId = bonusId);
        }
        else {
            const legs = selectedBets.map(({ outcomeId, productId, odds }) => ({
                outcomeId,
                productId,
                odds
            }));
            stakedBetsRef.current = [Object.assign({ stake, legs }, (bonusId ? { bonusId } : {}))];
        }
        setStakedBets([...stakedBetsRef.current]);
        // ? When a stake is provided, this section resets the errors, which doesn't look correct
        // ? Commented out this block until we figure out it this is actually necessary
        // error && setError({
        //   atBetslipLevel: undefined,
        //   atEventLevel: undefined,
        //   atBetLevel: undefined
        // });
    };
    // TODO: set back changes after updates
    const onSystemStakeChange = (stake, type, inputId) => {
        var _a;
        const legs = selectedBets.map(({ outcomeId, productId, odds }) => ({
            outcomeId,
            productId,
            odds
        }));
        // if (applyToAll) {
        //   const newBets: any = [];
        //   selectedBets.forEach((_, index) => {
        //     const { combinedBets } = getBetCombinations(legs, index + 1);
        //     newBets.push(...combinedBets.map((bet: any) => ({ stake, legs: bet })));
        //   });
        //   stakedBetsRef.current = [...newBets];
        // setSelectedBets((bets) => bets.map(bet => {
        //   bet.stake = stake;
        //   return bet;
        // }));
        // } else {
        // }
        const combinedBets = getBetCombinations(legs, type);
        const newBets = stake ?
            combinedBets.map(({ legs, id }) => ({
                stake,
                legs,
                outcomeCombineId: id,
                inputId
            })) :
            [];
        if (((_a = stakedBetsRef.current) === null || _a === void 0 ? void 0 : _a.length) > 0 && newBets.length > 0) {
            const outcomeCombineIdMap = new Map();
            // Merge stakedBetsRef into the map
            for (const item of stakedBetsRef.current) {
                outcomeCombineIdMap.set(item === null || item === void 0 ? void 0 : item.outcomeCombineId, Object.assign(Object.assign({}, outcomeCombineIdMap.get(item === null || item === void 0 ? void 0 : item.outcomeCombineId)), item));
            }
            // Merge newBets into the map
            for (const item of newBets) {
                outcomeCombineIdMap.set(item === null || item === void 0 ? void 0 : item.outcomeCombineId, Object.assign(Object.assign({}, outcomeCombineIdMap.get(item === null || item === void 0 ? void 0 : item.outcomeCombineId)), item));
            }
            // Convert the map values back to an array
            stakedBetsRef.current = [...outcomeCombineIdMap.values()];
        }
        else {
            stakedBetsRef.current = newBets;
        }
        setStakedBets([...stakedBetsRef.current]);
    };
    const clearBetslip = () => {
        stakedBetsRef.current = [];
        setStakedBets([]);
        setSelectedBets([]);
        setShowBetslip(false);
        triggerUpdateBetsEvent({ selectedBets: [] });
        triggerClearBets();
        setError({
            atBetslipLevel: undefined,
            atEventLevel: undefined,
            atBetLevel: undefined
        });
        LocalStorage.set("betsDetails", "[]");
    };
    const triggerBalanceUpdateEvent = () => {
        window.dispatchEvent(new CustomEvent("balance-update", {}));
    };
    const placeBet = React.useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        var _p, _q, _r;
        // user is not allowed to place a bet if unauthenticated,
        // so we emit an event and stop the execution
        if (!LocalStorage.get("widget-playerId")) {
            setUnauthenticatedModal(true);
            setUnauthenticatedTitle("Please Proceed to Place a Bet");
            return;
        }
        if (((_p = process.env) === null || _p === void 0 ? void 0 : _p.REACT_APP_GATEWAY_API_URL) &&
            ((_q = process.env) === null || _q === void 0 ? void 0 : _q.REACT_APP_GATEWAY_API_KEY)) {
            const isLiveExist = selectedBets.some(({ startDate, time }) => isBetLive({ startDate, time }));
            const setLoadingState = isLiveExist ? setIsPlaceBetLoading : setIsLoading;
            setLoadingState(true);
            const config = {
                headers: {
                    "Widget-Currency": localStorage.getItem("currencyCode"),
                    "Widget-Playerid": localStorage.getItem("widget-playerId"),
                    "Widget-Token": localStorage.getItem("widget-token")
                }
            };
            const fetchBetBuilderOutcomes = ({ eventId, outcomeIds }) => __awaiter(void 0, void 0, void 0, function* () {
                try {
                    const response = yield axios.post(`/bet-builder-outcomes?eventId=${eventId}&outcomeIds=${outcomeIds}`);
                    return response.data;
                }
                catch (error) {
                    console.error("Error fetching data:", error);
                }
            });
            // TODO: ADD isSamemarket
            const updateStakedBets = () => __awaiter(void 0, void 0, void 0, function* () {
                for (const [eventId, eventData] of Object.entries(groupedBets)) {
                    const eventBets = eventData.betLegs;
                    if (eventBets.length >= 2) {
                        const outcomeIds = createCommaSeparatedString(eventBets.map((bet) => bet.outcomeId));
                        const { odds, outcomeId } = yield fetchBetBuilderOutcomes({ eventId, outcomeIds });
                        // filter bet placement data for grouped bets
                        eventBets.forEach((eventBet) => {
                            stakedBetsRef.current[0].legs = stakedBetsRef.current[0].legs.filter((bet) => bet.outcomeId !== eventBet.outcomeId);
                        });
                        stakedBetsRef.current[0].legs.push({ outcomeId, productId: productId !== null && productId !== void 0 ? productId : 1, odds });
                    }
                }
                ;
            });
            isMultiple && isBB && (yield updateStakedBets());
            yield axios
                .post(`${(_r = process.env) === null || _r === void 0 ? void 0 : _r.REACT_APP_GATEWAY_API_URL}/bets/bet-slip`, {
                bettorId: LocalStorage.get("widget-playerId"),
                // TODO: remove after BE update
                acceptOddsChanges: false,
                acceptDecreasedOddsChanges: SessionStorage.get("oddsSetting") === BETSLIP_ODDS_SETTINGS.DECREASED ||
                    SessionStorage.get("oddsSetting") === BETSLIP_ODDS_SETTINGS.ANY,
                acceptIncreasedOddsChanges: SessionStorage.get("oddsSetting") === BETSLIP_ODDS_SETTINGS.INCREASED ||
                    SessionStorage.get("oddsSetting") === BETSLIP_ODDS_SETTINGS.ANY,
                bets: stakedBetsRef.current
            }, config)
                .then((res) => {
                const betslipId = res.data[0].betSlipId;
                setBetSlipId(betslipId);
                setError({
                    atBetslipLevel: undefined,
                    atEventLevel: undefined,
                    atBetLevel: undefined
                });
                clearBetslip();
                triggerBalanceUpdateEvent();
            })
                .catch((e) => {
                var _a, _b, _c, _d, _e;
                if (((_b = (_a = e === null || e === void 0 ? void 0 : e.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message) === "integration.esports.error.in_suffice_funds") {
                    setInsufficientFundsModal(true);
                    return;
                }
                let errorMessage;
                const { type, message, data } = ((_e = (_d = (_c = e === null || e === void 0 ? void 0 : e.response) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.errors) === null || _e === void 0 ? void 0 : _e[0]) || {};
                switch (type) {
                    case BETSLIP_ERRORS.MAX_WIN_EXCEEDED_EXCEPTION:
                        errorMessage = `Maximum stake allowed on this bet is ${CURRENCY}${data === null || data === void 0 ? void 0 : data.MaxStake}`;
                        if (isMultiple && (data === null || data === void 0 ? void 0 : data.MaxStake)) {
                            stakedBetsRef.current[0].stake = data === null || data === void 0 ? void 0 : data.MaxStake;
                            setStakedBets(stakedBetsRef.current);
                        }
                        else {
                            if (selectedTab === STAKE_SELECTIONS_TABS.SYSTEM) {
                                errorMessage = "Maximum stake allowed on this bet exceeded";
                                stakedBetsRef.current = [];
                                setStakedBets(stakedBetsRef.current);
                                const maxStakeExceeded = new CustomEvent(`max-stake-exceeded`, {
                                    bubbles: true
                                });
                                window.dispatchEvent(maxStakeExceeded);
                            }
                        }
                        break;
                    case BETSLIP_ERRORS.ODDS_CHANGED_EXCEPTION:
                        break;
                    default:
                        errorMessage = message;
                }
                setError((oldObj) => {
                    var _a, _b;
                    return (Object.assign(Object.assign({}, oldObj), { atBetslipLevel: {
                            type,
                            message: errorMessage,
                            errors: (_b = (_a = e === null || e === void 0 ? void 0 : e.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.errors
                        } }));
                });
            })
                .finally(() => {
                setLoadingState(false);
            });
        }
        else {
            console.log("Environment variables are not in place");
        }
    }), [isLoading, selectedTab, groupedBets, selectedBets]);
    const handleBetslipSubmit = (e) => {
        e.preventDefault();
        if (oddsChangedError || outcomeClosedError) {
            setError({
                atBetslipLevel: undefined,
                atEventLevel: undefined,
                atBetLevel: undefined
            });
            return;
        }
        // considering there is no blocker, then let users place a bet
        placeBet();
    };
    let totalStake = (stakedBets === null || stakedBets === void 0 ? void 0 : stakedBets.length) ?
        stakedBets.reduce((acc, bet) => {
            if (bet.stake)
                return acc + +bet.stake;
            return acc;
        }, 0) :
        0;
    let totalReturn = totalOdds * totalStake;
    totalStake = Math.round(isNaN(totalStake) ? 0 : totalStake * 100) / 100;
    totalReturn =
        isMultiple ?
            Math.round(isNaN(totalReturn) ? 0 : totalReturn * 100) / 100 :
            stakedBets.reduce((acc, bet) => acc +
                Number(bet.stake) * bet.legs.reduce((total, leg) => total ? total * leg.odds : leg.odds, 0), 0);
    const headerClickHandler = () => {
        setShowBetslip(!showBetslip);
    };
    if (betSlipId) {
        return _jsx("leetent-bet-slip-confirmation", { "bet-slip-id": betSlipId, "product-id": productId, "max-width": maxWidth, "selected-tab": selectedTab });
    }
    let errorMessage;
    if (maxStakeError) {
        if (selectedTab === STAKE_SELECTIONS_TABS.SYSTEM) {
            errorMessage = (_e = error === null || error === void 0 ? void 0 : error.atBetslipLevel) === null || _e === void 0 ? void 0 : _e.message;
        }
        else {
            if (totalStake < defaultMinStake) {
                if (((_f = error === null || error === void 0 ? void 0 : error.atBetslipLevel) === null || _f === void 0 ? void 0 : _f.type) === BETSLIP_ERRORS.MAX_WIN_EXCEEDED_EXCEPTION) {
                    errorMessage = `
            Maximum stake must be higher than minimum total stake.
            Please try to place a bet with another selection
          `;
                }
                else {
                    errorMessage = `Minimum total betslip stake must be ${CURRENCY}${defaultMinStake}`;
                }
            }
        }
    }
    else {
        if (!!(error === null || error === void 0 ? void 0 : error.atBetslipLevel) && (isMultiple ||
            (isSingles && ((_g = error === null || error === void 0 ? void 0 : error.atBetslipLevel) === null || _g === void 0 ? void 0 : _g.type) !== BETSLIP_ERRORS.INTERRELATED_SELECTIONS)) &&
            !oddsChangedError && !maxStakeError) {
            errorMessage = (_h = error === null || error === void 0 ? void 0 : error.atBetslipLevel) === null || _h === void 0 ? void 0 : _h.message;
        }
    }
    let isMatchMultipleFreeBetConditions = true;
    let isMatchSinglesFreeBetConditions = true;
    (_j = freeBet === null || freeBet === void 0 ? void 0 : freeBet.usageConditions) === null || _j === void 0 ? void 0 : _j.forEach((condition) => {
        // TODO move to enums
        switch (condition.type) {
            case "checkMinBetTotalOdds":
                if (totalOdds < condition.value) {
                    isMatchMultipleFreeBetConditions = false;
                    isMatchSinglesFreeBetConditions = false;
                }
                break;
            case "checkMinLegCount":
                if ((isMultiple && selectedBets.length < condition.value) || (isSingles && condition.value > 1)) {
                    isMatchMultipleFreeBetConditions = false;
                    isMatchSinglesFreeBetConditions = false;
                }
                break;
            case "checkMinBetLegOdds": {
                const betsLowerThanMinLegOdds = selectedBets.find(bet => bet.odds < condition.odd);
                if (betsLowerThanMinLegOdds) {
                    isMatchMultipleFreeBetConditions = false;
                }
            }
        }
    });
    const urlImageToCDN = "https://d1bu7ybn6mtlcz.cloudfront.net/images/";
    return (selectedBets.length > 0 && (isCoupon !== null && isCoupon !== void 0 ? isCoupon : showBetslip)) || !isRelative ?
        (_jsxs(_Fragment, { children: [_jsx("div", { className: clsx({
                        [styles.greyedOut]: !isCoupon && showBetslip && !isRelative && selectedBets.length > 0
                    }), onClick: () => {
                        showBetslip && setShowBetslip(false);
                    } }), _jsxs("div", Object.assign({ 
                    // ref={rootRef}
                    className: clsx(styles.betSlip, {
                        [styles.fixed]: !isRelative,
                        [styles.open]: !isRelative && (isCoupon !== null && isCoupon !== void 0 ? isCoupon : showBetslip) && selectedBets.length > 0,
                        [styles.coupon]: !isRelative && showBetslip && isCoupon
                    }), style: { maxWidth }, "data-testid": "BetSlip" }, { children: [isBB && showBetslip && selectedBets.length > 0 && (_jsx(BBPromo, { className: clsx(styles.bbPromo, { [styles.relative]: isRelative }), style: { maxWidth } })), selectedBets.length >= BETS_LIMIT &&
                            _jsx("div", Object.assign({ className: styles.maxSelectionsWarning }, { children: "Max Selections reached. Please modify your choices." })), _jsx("div", Object.assign({ className: clsx(styles.header, {
                                [styles.coupon]: isCoupon && !showBetslip
                            }), onClick: headerClickHandler }, { children: _jsxs(Row, Object.assign({ justify: "space-between", align: "middle" }, { children: [_jsxs(Col, { children: [_jsx(Row, { children: _jsxs(Col, Object.assign({ className: styles.title }, { children: [isBB && isMultiple ? "Bet Builder" : "Betslip", _jsx("div", Object.assign({ className: clsx(styles.counter, { [styles.coupon]: isCoupon && !showBetslip }) }, { children: selectedBets.length })), isBB && isMultiple && (isNaN(totalOdds) ?
                                                            0 :
                                                            toFixed(totalOdds) === "1.00" ?
                                                                _jsx(ImageFromCDN, { alt: "Lock", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/lock.svg", size: 16 }) :
                                                                toFixed(totalOdds))] })) }), isBB && ((_k = selectedBets[0]) === null || _k === void 0 ? void 0 : _k.event) &&
                                                _jsx(Row, { children: _jsx("div", Object.assign({ className: styles.summary }, { children: totalEvents > 1 ? `${totalEvents} Events - ${totalLegs} Legs` : selectedBets[0].event })) })] }), _jsx(Col, { children: _jsxs("div", Object.assign({ className: styles.arrowCol }, { children: [isCoupon &&
                                                    _jsxs("div", Object.assign({ className: styles.totalOdds }, { children: ["Total Odds ", _jsx("strong", { children: totalOdds === null || totalOdds === void 0 ? void 0 : totalOdds.toFixed(2) })] })), showBetslip ?
                                                    _jsxs("div", Object.assign({ className: styles.imageDown }, { children: [_jsx(ImageFromCDN, { alt: "Down arrow", url: `${urlImageToCDN}${themeVariant === "dark" ?
                                                                    "keyboard_arrow_right.png" :
                                                                    "arrow-down-dark.png"}`, size: 20 }), " "] })) :
                                                    _jsx(ImageFromCDN, { alt: "Up arrow", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/Icon-ios-arrow-down.png", size: 20, overrideStyles: styles.downArrow })] })) })] })) })), (!isCoupon || showBetslip) && (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ style: { borderBottom: "2px solid rgba(0, 0, 0, 0.1)" } }, { children: isBetsLoading ?
                                        _jsx(SelectionSkeleton, {}) :
                                        _jsx(BetSelections, { bets: selectedBets.sort((a, b) => a.eventId - b.eventId && a.outcomeId - b.outcomeId), selectedTab: selectedTab, groupedBets: isBB ? groupedBets : undefined, onBetRemove: handleRemoveBet, onBetChange: handleBetChange, freeBet: freeBet, showFreeBet: freeBet && productId === 1 && isMatchSinglesFreeBetConditions, errors: error }) })), selectedBets.length >= 2 && _jsx("leetent-tabs-header", { id: "bet-slip-tabs", tabs: JSON.stringify(tabsOptions), selected: selectedTab, "event-name": "bet-slip-selected-tab", classes: JSON.stringify({ root: styles.tabsHeader, tab: styles.tab }) })] })), isMultiple && showBetslip && (_jsx(MultipleBetsSelection, { betslipOpen: showBetslip, totalOdds: isNaN(totalOdds) ? 0 : toFixed(totalOdds), onStakeChange: onMultipleStakeChange, selectedTab: selectedTab, freeBet: freeBet, showFreeBet: freeBet && productId === 1 && isMatchMultipleFreeBetConditions, stake: stake })), selectedTab === STAKE_SELECTIONS_TABS.SYSTEM && showBetslip && (_jsx(SystemBets, { bets: selectedBets, onStakeChange: onSystemStakeChange, selectedTab: selectedTab, inputIdWithStake: (_l = stakedBets.find(bet => bet.stake && bet.inputId)) === null || _l === void 0 ? void 0 : _l.inputId })), (!isCoupon || showBetslip) && (_jsx(BetSummary, { totalEvents: totalEvents || new Set(selectedBets.map(bet => bet.eventId)).size, totalLegs: totalLegs || selectedBets.length, totalStake: totalStake, totalReturn: totalReturn, isBB: isBB, totalBets: isSingles ?
                                selectedBets.length :
                                undefined })), errorMessage && !isCoupon && (_jsx("div", Object.assign({ className: styles.error, "data-testid": "Betslip.errorMessage" }, { children: errorMessage }))), (!isCoupon || showBetslip) && _jsx(BetSlipFooter, { themeVariant: themeVariant, isCoupon: isCoupon, buttonDisabled: (!!(error === null || error === void 0 ? void 0 : error.atBetslipLevel) && (isMultiple ||
                                (isSingles && ((_m = error === null || error === void 0 ? void 0 : error.atBetslipLevel) === null || _m === void 0 ? void 0 : _m.type) !== BETSLIP_ERRORS.INTERRELATED_SELECTIONS)) &&
                                !oddsChangedError && !maxStakeError) ||
                                !!((_o = error === null || error === void 0 ? void 0 : error.atEventLevel) === null || _o === void 0 ? void 0 : _o.length) ||
                                (!oddsChangedError && totalStake < minStake) ||
                                isLoading ||
                                isBetsLoading ||
                                isPlaceBetLoading ||
                                (!isBB && isMultiple && isUncombinableBets) ||
                                !selectedBets.some(bet => (bet === null || bet === void 0 ? void 0 : bet.statusId) === undefined || (bet === null || bet === void 0 ? void 0 : bet.statusId) === BETSLIP_SELECTION_STATUS.OPEN), isSuspended: selectedBets.some((bet) => (bet === null || bet === void 0 ? void 0 : bet.statusId) === BETSLIP_SELECTION_STATUS.SUSPENDED), clearBetslip: clearBetslip, betSubmitHandler: handleBetslipSubmit, oddsChangedError: oddsChangedError })] })), _jsx("leetent-unauthenticated-user-modal", { open: unauthenticatedModal, "modal-title": unauthenticatedTitle, options: JSON.stringify(unauthenticatedModalOptions) }), _jsx(ModalGeneric, { open: insufficientFundsModal, title: "Your account balance is insufficient to place this bet. Please make a deposit to continue.", modalId: insufficientFundsModalId, requiredOptions: [
                        {
                            id: 1,
                            label: "Deposit"
                        }
                    ] }), _jsx(ModalGeneric, Object.assign({ modalId: placeBetLoaderModalId, open: isPlaceBetLoading, disableCloseModal: true, position: MODAL_POSITION.BOTTOM }, { children: _jsxs("div", Object.assign({ className: styles.placeBetLoader }, { children: [_jsx("img", { height: 75, width: 75, src: "https://d1bu7ybn6mtlcz.cloudfront.net/images/loading-spinner.gif", alt: "Loader" }), "Placing your bet.."] })) }))] })) :
        null;
};
class Element extends HTMLElement {
    constructor() {
        super();
        this.root = createRoot(this);
    }
    static get observedAttributes() {
        return [
            "bets",
            "min-stake",
            "relative",
            "coupon",
            "open",
            "product-id",
            "unauthenticated-modal-title",
            "unauthenticated-modal-options",
            "max-width",
            "stake"
        ];
    }
    renderComponent() {
        var _a;
        (_a = this.root) === null || _a === void 0 ? void 0 : _a.render(_jsx(BetSlip, { bets: this.bets, minStake: this["min-stake"], isRelative: this.relative, isCoupon: this.coupon, isOpen: this.open, productId: this["product-id"], unauthenticatedModalTitle: this["unauthenticated-modal-title"], unauthenticatedModalOptions: this["unauthenticated-modal-options"], maxWidth: this["max-width"], stake: this.stake }));
    }
    connectedCallback() {
        this.renderComponent();
    }
    disconnectedCallback() {
        setTimeout(() => { var _a; return (_a = this.root) === null || _a === void 0 ? void 0 : _a.unmount(); }, 0);
    }
    attributeChangedCallback(attrName, _oldValue, newValue) {
        switch (attrName) {
            case "unauthenticated-modal-title":
                this[attrName] = String(newValue);
                break;
            case "min-stake":
            case "product-id":
            case "stake":
                this[attrName] = +newValue;
                break;
            case "relative":
            case "coupon":
            case "bets":
            case "open":
            case "unauthenticated-modal-options":
                this[attrName] = JSON.parse(newValue);
                break;
            case "max-width":
                this[attrName] = String(newValue);
                break;
            default:
                break;
        }
        this.renderComponent();
    }
}
(_a = customElements.get("leetent-bet-slip")) !== null && _a !== void 0 ? _a : customElements.define("leetent-bet-slip", Element);
