import { jsx as _jsx } from "react/jsx-runtime";
// components
import MountDocumentComponent from "components/documentation/MountDocumentComponent";
const componentObj = {
    productId: 1,
    category: {
        id: 1,
        name: "soccer",
        imageUrl: ""
    },
    tournament: {
        id: 1,
        name: "Brasileirão",
        isPopular: true,
        priorityOrder: 1,
        country: {
            id: 1,
            name: "Brazil",
            imageUrl: ""
        }
    },
    event: {
        id: 101549,
        name: "Fredrikstad 2 vs Hamarkameratene 2",
        homeTeam: "Fredrikstad 2",
        awayTeam: "Hamarkameratene 2",
        marketCount: 10,
        date: "2024-07-22T13:00:17.708+00:00",
        time: "13:00:00",
        statusId: 18
    },
    market: {
        id: 5491375,
        marketTypeConfigId: 36,
        name: "Full Time",
        // line: null,
        // playerName: null,
        statusId: 10
    },
    outcomes: [{
            id: 13907638,
            name: "Fredrikstad 2",
            key: "home",
            odds: 1.45,
            isOpen: true,
            statusId: 10
        },
        {
            id: 13907639,
            name: "Draw",
            key: "draw",
            odds: 4.5,
            isOpen: true,
            statusId: 10
        },
        {
            id: 13907640,
            name: "Hamarkameratene 2",
            key: "away",
            odds: 5.3,
            isOpen: true,
            statusId: 10
        }]
};
export const documentDetails = {
    title: "Market Single",
    component: "Different Market Views components",
    description: "This is a base component, that is used to compose a market and its related outcomes.",
    attributes: [],
    events: [
        {
            event: "updated-market-single-outcomes",
            type: "listener",
            data: [
                `The component details object is emitted on stake change.`,
                `detail: { 
          productId: number,
          category: Category,
          tournament: Tournament,
          event: Event,
          market: Market,
          selectedOutcomes,
          updated
        }`
            ]
        }
    ]
};
const MarketViews = () => {
    documentDetails.example = `<leetent-market-single
    data=${JSON.stringify(componentObj)}
  />`;
    documentDetails.code = _jsx("leetent-market-single", { view: "threeWay", data: JSON.stringify(componentObj) });
    // render the page based on the provided object
    return _jsx(MountDocumentComponent, { data: documentDetails });
};
export default MarketViews;
