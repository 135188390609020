// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MOSMkDKHuhZ8ycUZN51S{display:block}", "",{"version":3,"sources":["webpack://./src/components/base/imageFromCDN/ImageFromCDN.module.scss"],"names":[],"mappings":"AAAA,sBACE,aAAA","sourcesContent":[".content {\n  display: block;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "MOSMkDKHuhZ8ycUZN51S"
};
export default ___CSS_LOADER_EXPORT___;
