import { jsx as _jsx } from "react/jsx-runtime";
import { threeWayHalftimeFulltimeData as data } from "./assets/data";
// components
import MountDocumentComponent from "components/documentation/MountDocumentComponent";
// document details of inner components
import { documentDetails as singleEventInputDocumentDetails } from "pages/documentation/components/OddsSingleEventInput";
export const documentDetails = {
    title: "Three Way Halftime/Fulltime (Display Rule)",
    component: "leetent-market-half-full-time",
    description: "This is a market component, that is used as part of many composed components.",
    withDarkMode: false,
    innerComponents: [
        {
            name: "leetent-odds-singleevent-input",
            documentDetails: singleEventInputDocumentDetails
        }
    ]
};
const ThreeWayHalftimeFulltime = () => {
    documentDetails.example = `<leetent-market-half-full-time />`;
    // code: <leetent-market-half-full-time series-id={123} data={JSON.stringify(data)} />
    documentDetails.tabs = [
        {
            key: "1",
            label: "List version",
            children: (_jsx("leetent-market-half-full-time", { "series-id": 123, data: JSON.stringify(data), "home-team": "Athletic Club Bilbao", "away-team": "Celta Vigo" }))
        },
        {
            key: "2",
            label: "Single event version",
            // TODO: Pending development
            children: (_jsx("leetent-market-half-full-time", { "series-id": 123, data: JSON.stringify(data), "home-team": "Athletic Club Bilbao", "away-team": "Celta Vigo" }))
        }
    ];
    // render the page based on the provided object
    return _jsx(MountDocumentComponent, { data: documentDetails });
};
export default ThreeWayHalftimeFulltime;
