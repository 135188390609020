import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import ImageFromCDN from "components/base/imageFromCDN/ImageFromCDN";
import { isInPlayBlacklistedSport, sportsWhereCounterIsNotRequired } from "helpers/InPlayBlacklistSports";
import { KeyboardButtons } from "components/base/keyboardButtons/KeyboardButtons";
import { useKeyboardButtons } from "hooks/useKeyboardButtons";
import { StakeInput } from "components/base/stakeInput/StakeInput";
import { toFixed } from "helpers/GeneralFunctions";
import { BETSLIP_SELECTION_STATUS, CURRENCY, CLOCK_STATUS } from "enums";
import { hasOutrightsFromSameLeague, isBetLive } from "helpers/Bets";
import { periodConverter, periods as sportsPeriods } from "helpers/Sports/Periods";
import clsx from "clsx";
import Live from "components/base/live/Live";
import { FreeBet } from "components/base/freeBet/FreeBet";
import { processedTimeCounter } from "helpers/ProcessTimeCounter";
import { useSubscribeByQuery } from "helpers/graphql/useSubscribeByQuery";
// import { setsCalculation } from "helpers/Sports/Scores";
import { SUBSCRIBE_ESPORTS_MARKET_SELECTION, SUBSCRIBE_EVENTS, SUBSCRIBE_OUTCOME } from "v2/graphql/subscriptions";
import styles from "./BetSelection.module.scss";
export const BetSelection = ({ bets, bet, isSingles, isMultiple, inputId, isActive = true, betsLength, onBetRemove, onBetChange, freeBet, showFreeBet, errors }) => {
    var _a, _b, _c, _d;
    const { market, pick, event, odds, outcomeId, productId, stake, statusId, startDate, time, marketId, eventId } = bet;
    const [showKeyBoard, setShowKeyBoard] = React.useState(false);
    const [prevOdds, setPrevOdds] = React.useState(null);
    const [error, setError] = React.useState(null);
    const [isUncombinableBets, setIsUncombinableBets] = React.useState(false);
    const [applyToAll, setApplyToAll] = React.useState(false);
    const [isFreeBetUsed, setIsFreeBetUsed] = React.useState(false);
    const [themeVariant, setThemeVariant] = React.useState("light");
    const [isDeepLinkStake, setIsDeepLinkStake] = React.useState(false);
    const { inputValue, setInputValue, updateInputValue, addNumber, removeLatestDigit } = useKeyboardButtons();
    const [outcomeData, setOutcomeData] = React.useState();
    const [outcomePrevData, setOutcomePrevData] = React.useState();
    const [liveInfo, setLiveInfo] = React.useState();
    // finds the event level related information
    const errorEvent = (_a = errors === null || errors === void 0 ? void 0 : errors.atEventLevel) === null || _a === void 0 ? void 0 : _a.find((betObj) => betObj.eventId === (bet === null || bet === void 0 ? void 0 : bet.eventId));
    const outcomeValueHandlerCallback = (data, prevData) => {
        var _a, _b;
        const _data = (_a = data === null || data === void 0 ? void 0 : data.sport_domain_outcome) === null || _a === void 0 ? void 0 : _a[0];
        const _prevData = (_b = prevData === null || prevData === void 0 ? void 0 : prevData.sport_domain_outcome) === null || _b === void 0 ? void 0 : _b[0];
        if (_data) {
            setOutcomeData({
                odds: _data === null || _data === void 0 ? void 0 : _data.odds,
                statusId: _data === null || _data === void 0 ? void 0 : _data.outcome_status_id,
                isActive: _data === null || _data === void 0 ? void 0 : _data.is_active
            });
        }
        if (_prevData) {
            setOutcomePrevData({
                odds: _prevData === null || _prevData === void 0 ? void 0 : _prevData.odds,
                statusId: _prevData === null || _prevData === void 0 ? void 0 : _prevData.outcome_status_id,
                isActive: _prevData === null || _prevData === void 0 ? void 0 : _prevData.is_active
            });
        }
    };
    useSubscribeByQuery({
        query: SUBSCRIBE_OUTCOME,
        variables: {
            eventId,
            marketId,
            outcomeId
        },
        valueHandlerCallback: outcomeValueHandlerCallback,
        enabled: productId === 1
    });
    const eventsValueHandlerCallback = (data) => {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        const eventsData = data.sport_domain_event[0];
        if (eventsData) {
            setLiveInfo({
                id: eventsData === null || eventsData === void 0 ? void 0 : eventsData.id,
                startDate: eventsData === null || eventsData === void 0 ? void 0 : eventsData.start_at,
                statusId: eventsData === null || eventsData === void 0 ? void 0 : eventsData.event_status_id,
                inPlay: {
                    clock: {
                        minutes: (_a = eventsData === null || eventsData === void 0 ? void 0 : eventsData.event_clock) === null || _a === void 0 ? void 0 : _a.minutes,
                        period: (_b = eventsData === null || eventsData === void 0 ? void 0 : eventsData.event_clock) === null || _b === void 0 ? void 0 : _b.period_name,
                        seconds: (_c = eventsData === null || eventsData === void 0 ? void 0 : eventsData.event_clock) === null || _c === void 0 ? void 0 : _c.seconds,
                        remainingMinutesInPeriod: (_d = eventsData === null || eventsData === void 0 ? void 0 : eventsData.event_clock) === null || _d === void 0 ? void 0 : _d.minutes_left_in_period,
                        remainingSecondsInPeriod: (_e = eventsData === null || eventsData === void 0 ? void 0 : eventsData.event_clock) === null || _e === void 0 ? void 0 : _e.seconds_left_in_period,
                        statusId: (_f = eventsData === null || eventsData === void 0 ? void 0 : eventsData.event_clock) === null || _f === void 0 ? void 0 : _f.event_clock_status_id,
                        timestamp: (_g = eventsData === null || eventsData === void 0 ? void 0 : eventsData.event_clock) === null || _g === void 0 ? void 0 : _g.timestamp_epoch
                    },
                    score: {
                        home: eventsData === null || eventsData === void 0 ? void 0 : eventsData.home_score,
                        away: eventsData === null || eventsData === void 0 ? void 0 : eventsData.away_score,
                        periodScores: (_h = eventsData === null || eventsData === void 0 ? void 0 : eventsData.period_scores) === null || _h === void 0 ? void 0 : _h.map((scores) => {
                            return {
                                home: scores.home_score,
                                away: scores.away_score
                            };
                        })
                    }
                }
            });
        }
    };
    useSubscribeByQuery({
        query: SUBSCRIBE_EVENTS,
        variables: {
            eventIds: [eventId]
        },
        valueHandlerCallback: eventsValueHandlerCallback,
        enabled: productId === 1
    });
    const esportsSelectionValueHandlerCallback = (data, prevData) => {
        var _a, _b;
        const _data = (_a = data === null || data === void 0 ? void 0 : data.GetEsportsMarketSelections) === null || _a === void 0 ? void 0 : _a[0];
        const _prevData = (_b = prevData === null || prevData === void 0 ? void 0 : prevData.GetEsportsMarketSelections) === null || _b === void 0 ? void 0 : _b[0];
        if (_data) {
            setOutcomeData({
                odds: _data === null || _data === void 0 ? void 0 : _data.selections[0].odds,
                statusId: (_data === null || _data === void 0 ? void 0 : _data.state) === "open" ? BETSLIP_SELECTION_STATUS.OPEN : BETSLIP_SELECTION_STATUS.CLOSED
            });
        }
        if (_prevData) {
            setOutcomePrevData({
                odds: _prevData === null || _prevData === void 0 ? void 0 : _prevData.selections[0].odds,
                statusId: (_prevData === null || _prevData === void 0 ? void 0 : _prevData.state) === "open" ? BETSLIP_SELECTION_STATUS.OPEN : BETSLIP_SELECTION_STATUS.CLOSED
            });
        }
    };
    useSubscribeByQuery({
        query: SUBSCRIBE_ESPORTS_MARKET_SELECTION,
        variables: {
            marketId,
            outcomeId
        },
        valueHandlerCallback: esportsSelectionValueHandlerCallback,
        enabled: productId === 2
    });
    const currentPrevOdds = (outcomePrevData === null || outcomePrevData === void 0 ? void 0 : outcomePrevData.odds) || prevOdds;
    const currentOdds = (outcomeData === null || outcomeData === void 0 ? void 0 : outcomeData.odds) || odds;
    const removeBetHandler = () => {
        onBetRemove(outcomeId);
        const triggerBetRemove = new CustomEvent(`bet-slip-bet-remove`, {
            detail: {
                outcomeId
            },
            bubbles: true
        });
        window.dispatchEvent(triggerBetRemove);
        setPrevOdds(null);
    };
    const triggerSelectedBet = (bonusId) => {
        if (inputValue || currentOdds !== odds || (outcomeData === null || outcomeData === void 0 ? void 0 : outcomeData.statusId) !== statusId) {
            onBetChange({
                stake: inputValue,
                outcomeId,
                odds: currentOdds,
                productId,
                applyToAll,
                bets,
                bonusId,
                statusId: (outcomeData === null || outcomeData === void 0 ? void 0 : outcomeData.statusId) || statusId
            });
        }
    };
    // Update input from deeplinking
    React.useEffect(() => {
        const hash = window.location.hash;
        const searchParams = new URLSearchParams(hash.slice(hash.indexOf("?") + 1));
        const oids = searchParams.getAll("oid");
        oids === null || oids === void 0 ? void 0 : oids.forEach((oid) => {
            const oddsDetails = oid.split("_");
            const urlOutcomeId = +oddsDetails[1];
            const urlStake = oddsDetails[2];
            if ((outcomeId === urlOutcomeId) && urlStake) {
                setIsDeepLinkStake(true);
                setInputValue(urlStake);
            }
        });
    }, []);
    React.useEffect(() => {
        const getEventCounts = () => {
            const eventCounts = {};
            bets.forEach((bet) => {
                const { eventId } = bet;
                eventCounts[eventId] = (eventCounts[eventId] || 0) + 1;
            });
            return eventCounts;
        };
        const eventCounts = getEventCounts();
        const hasOutrights = hasOutrightsFromSameLeague(bets);
        if (eventCounts[bet.eventId] >= 2 || hasOutrights) {
            setIsUncombinableBets(true);
        }
        else {
            setIsUncombinableBets(false);
        }
    }, [bets]);
    React.useEffect(() => {
        const observerCallback = (mutationsList) => {
            var _a;
            for (const mutation of mutationsList) {
                if (mutation.type === "attributes" && mutation.attributeName === "data-theme") {
                    const newTheme = (_a = document.documentElement.getAttribute("data-theme")) !== null && _a !== void 0 ? _a : "light";
                    setThemeVariant(newTheme);
                }
            }
        };
        const observer = new MutationObserver(observerCallback);
        observer.observe(document.documentElement, { attributes: true });
        return () => {
            observer.disconnect();
        };
    }, []);
    React.useEffect(() => {
        if (isFreeBetUsed) {
            if (inputValue && (freeBet.amount === +inputValue)) {
                triggerSelectedBet(freeBet.bonusId);
            }
            else {
                setIsFreeBetUsed(false);
            }
        }
        triggerSelectedBet();
    }, [inputValue, applyToAll, outcomeData]);
    React.useEffect(() => {
        if (stake !== undefined && !isDeepLinkStake) {
            setInputValue(stake);
        }
        setApplyToAll(false);
    }, [bets]);
    React.useEffect(() => {
        if (inputValue && !isDeepLinkStake) {
            setInputValue("");
        }
        setShowKeyBoard(false);
    }, [isSingles, isDeepLinkStake]);
    React.useEffect(() => {
        isDeepLinkStake && setIsDeepLinkStake(false);
    }, [isSingles]);
    React.useEffect(() => {
        const changeMaxStakeHandler = (e) => {
            setError(null);
            const currentSelection = e.detail.selections.find((selection) => selection.outcomeId === outcomeId);
            if (currentSelection) {
                const { stake } = currentSelection;
                const errorMessage = `Maximum stake allowed ${CURRENCY} ${stake}`;
                setError(errorMessage);
                stake && setInputValue(stake);
                // Remove error message in 3 sec
                setTimeout(() => {
                    setError(null);
                }, 3000);
            }
        };
        const handleActiveInput = (e) => {
            setShowKeyBoard(false);
            if (e.detail.inputId === inputId) {
                setShowKeyBoard(true);
            }
        };
        const changeOddsHandler = (e) => {
            var _a, _b;
            (_b = (_a = e.detail) === null || _a === void 0 ? void 0 : _a.prevBets) === null || _b === void 0 ? void 0 : _b.forEach((prevBet) => { prevBet.outcomeId === outcomeId && setPrevOdds(prevBet.odds); });
        };
        window.addEventListener("prev-change-odds", changeOddsHandler);
        window.addEventListener("change-max-stake", changeMaxStakeHandler);
        window.addEventListener("bet-slip-active-input", handleActiveInput);
        return () => {
            window.removeEventListener("prev-change-odds", changeOddsHandler);
            window.removeEventListener("change-max-stake", changeMaxStakeHandler);
            window.removeEventListener("bet-slip-active-input", handleActiveInput);
        };
    }, []);
    const isLive = isBetLive({ startDate, time });
    const [secondsLeft, setSecondsLeft] = React.useState();
    // every time the clock changes, we reprocess the counter
    React.useEffect(() => {
        var _a, _b, _c, _d, _e, _f, _g;
        if (isMultiple && ((_a = liveInfo === null || liveInfo === void 0 ? void 0 : liveInfo.inPlay) === null || _a === void 0 ? void 0 : _a.clock)) {
            // Stop counting if the clock is not running, or if we have counted down to 0 already
            if (((_c = (_b = liveInfo === null || liveInfo === void 0 ? void 0 : liveInfo.inPlay) === null || _b === void 0 ? void 0 : _b.clock) === null || _c === void 0 ? void 0 : _c.statusId) === CLOCK_STATUS.DISABLED)
                return;
            let timeCounterAux = processedTimeCounter((_d = liveInfo === null || liveInfo === void 0 ? void 0 : liveInfo.inPlay) === null || _d === void 0 ? void 0 : _d.clock, ((_f = (_e = bet === null || bet === void 0 ? void 0 : bet.sport) === null || _e === void 0 ? void 0 : _e.name) === null || _f === void 0 ? void 0 : _f.toLowerCase()) === "basketball");
            const minutesAndSeconds = (_g = String(timeCounterAux)) === null || _g === void 0 ? void 0 : _g.split(":");
            timeCounterAux = Number(minutesAndSeconds[0]) * 60 + Number(minutesAndSeconds[1]);
            setSecondsLeft(Number(timeCounterAux));
        }
    }, [(_b = liveInfo === null || liveInfo === void 0 ? void 0 : liveInfo.inPlay) === null || _b === void 0 ? void 0 : _b.clock]);
    // section related to counting up/down
    React.useEffect(() => {
        var _a, _b, _c, _d;
        let timeout;
        if (!isInPlayBlacklistedSport((_a = bet === null || bet === void 0 ? void 0 : bet.sport) === null || _a === void 0 ? void 0 : _a.id) &&
            !sportsWhereCounterIsNotRequired((_b = bet === null || bet === void 0 ? void 0 : bet.sport) === null || _b === void 0 ? void 0 : _b.id) &&
            ((_d = (_c = liveInfo === null || liveInfo === void 0 ? void 0 : liveInfo.inPlay) === null || _c === void 0 ? void 0 : _c.clock) === null || _d === void 0 ? void 0 : _d.statusId) === CLOCK_STATUS.RUNNING &&
            secondsLeft !== undefined &&
            secondsLeft > 0) {
            timeout = setTimeout(() => {
                var _a, _b;
                const isCountdown = ((_b = (_a = bet === null || bet === void 0 ? void 0 : bet.sport) === null || _a === void 0 ? void 0 : _a.name) === null || _b === void 0 ? void 0 : _b.toLowerCase()) === "basketball";
                if (isCountdown) {
                    setSecondsLeft(Number(secondsLeft) - 1);
                }
                else {
                    setSecondsLeft(Number(secondsLeft) + 1);
                }
            }, 1000);
        }
        return () => {
            timeout && clearTimeout(timeout);
        };
    }, [liveInfo, isMultiple, secondsLeft]);
    const MatchCounterInMinutes = React.useCallback(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5;
        if (secondsLeft === undefined)
            return _jsx(_Fragment, {});
        const convertedPeriod = periodConverter((_b = (_a = liveInfo === null || liveInfo === void 0 ? void 0 : liveInfo.inPlay) === null || _a === void 0 ? void 0 : _a.clock) === null || _b === void 0 ? void 0 : _b.period, (_c = bet === null || bet === void 0 ? void 0 : bet.sport) === null || _c === void 0 ? void 0 : _c.id, (_e = (_d = liveInfo === null || liveInfo === void 0 ? void 0 : liveInfo.inPlay) === null || _d === void 0 ? void 0 : _d.clock) === null || _e === void 0 ? void 0 : _e.statusId, (_g = (_f = liveInfo === null || liveInfo === void 0 ? void 0 : liveInfo.inPlay) === null || _f === void 0 ? void 0 : _f.clock) === null || _g === void 0 ? void 0 : _g.minutes, (_j = (_h = liveInfo === null || liveInfo === void 0 ? void 0 : liveInfo.inPlay) === null || _h === void 0 ? void 0 : _h.clock) === null || _j === void 0 ? void 0 : _j.seconds, ((_l = (_k = bet === null || bet === void 0 ? void 0 : bet.sport) === null || _k === void 0 ? void 0 : _k.name) === null || _l === void 0 ? void 0 : _l.toLowerCase()) === "basketball" // if basketball, we shorten the period
        );
        if (((_o = (_m = bet === null || bet === void 0 ? void 0 : bet.sport) === null || _m === void 0 ? void 0 : _m.name) === null || _o === void 0 ? void 0 : _o.toLowerCase()) === "basketball" && isMultiple && secondsLeft) {
            return (_jsxs(_Fragment, { children: [_jsx("div", { style: { height: "100%" } }), _jsxs("div", Object.assign({ style: { fontSize: 11, fontWeight: "bold" } }, { children: [_jsx("span", Object.assign({ style: { marginRight: 5 } }, { children: convertedPeriod })), !isInPlayBlacklistedSport((_p = bet === null || bet === void 0 ? void 0 : bet.sport) === null || _p === void 0 ? void 0 : _p.id) &&
                                ((_r = (_q = liveInfo === null || liveInfo === void 0 ? void 0 : liveInfo.inPlay) === null || _q === void 0 ? void 0 : _q.score) === null || _r === void 0 ? void 0 : _r.home) && ((_t = (_s = liveInfo === null || liveInfo === void 0 ? void 0 : liveInfo.inPlay) === null || _s === void 0 ? void 0 : _s.score) === null || _t === void 0 ? void 0 : _t.away) &&
                                ` ${String(liveInfo.inPlay.score.home)}-${String(liveInfo.inPlay.score.away)}`] }))] }));
        }
        else if ((((_v = (_u = bet === null || bet === void 0 ? void 0 : bet.sport) === null || _u === void 0 ? void 0 : _u.name) === null || _v === void 0 ? void 0 : _v.toLowerCase()) === "tennis" ||
            ((_x = (_w = bet === null || bet === void 0 ? void 0 : bet.sport) === null || _w === void 0 ? void 0 : _w.name) === null || _x === void 0 ? void 0 : _x.toLowerCase()) === "volleyball" ||
            ((_z = (_y = bet === null || bet === void 0 ? void 0 : bet.sport) === null || _y === void 0 ? void 0 : _y.name) === null || _z === void 0 ? void 0 : _z.toLowerCase()) === "tabletennis") &&
            isMultiple) {
            // const sets = setsCalculation(liveInfo?.inPlay?.score?.periodScores);
            return (_jsxs(_Fragment, { children: [_jsx("div", { style: { height: "100%" } }), _jsx("div", { style: { fontSize: 11, fontWeight: "bold" } })] }));
        }
        else if (secondsLeft && isMultiple) {
            const calculated = () => {
                var _a, _b;
                if (convertedPeriod) {
                    return `${sportsPeriods[(_a = bet === null || bet === void 0 ? void 0 : bet.sport) === null || _a === void 0 ? void 0 : _a.id][convertedPeriod]}' + ${(((secondsLeft - (sportsPeriods[(_b = bet === null || bet === void 0 ? void 0 : bet.sport) === null || _b === void 0 ? void 0 : _b.id][convertedPeriod] * 60)) / 60) + 1).toFixed(0)}`;
                }
                ;
                return "";
            };
            const TimeAndExtra = () => {
                var _a, _b, _c, _d;
                if (((_a = bet === null || bet === void 0 ? void 0 : bet.sport) === null || _a === void 0 ? void 0 : _a.id) === 1 && convertedPeriod === "1ST HALF" &&
                    secondsLeft > sportsPeriods[(_b = bet === null || bet === void 0 ? void 0 : bet.sport) === null || _b === void 0 ? void 0 : _b.id][convertedPeriod] * 60) {
                    return (_jsx("span", Object.assign({ style: { marginRight: 5 } }, { children: calculated() })));
                }
                else if (((_c = bet === null || bet === void 0 ? void 0 : bet.sport) === null || _c === void 0 ? void 0 : _c.id) === 1 && convertedPeriod === "2ND HALF" &&
                    secondsLeft > sportsPeriods[(_d = bet === null || bet === void 0 ? void 0 : bet.sport) === null || _d === void 0 ? void 0 : _d.id][convertedPeriod] * 60) {
                    return (_jsx("span", Object.assign({ style: { marginRight: 3 } }, { children: calculated() })));
                }
                return _jsxs("span", Object.assign({ style: { marginRight: 3 } }, { children: [String(Math.floor(secondsLeft / 60)), "\u2018"] }));
            };
            return (_jsxs(_Fragment, { children: [_jsx("div", { style: { height: "100%" } }), _jsxs("div", Object.assign({ style: { fontSize: 11, fontWeight: "bold" } }, { children: [!isInPlayBlacklistedSport((_0 = bet === null || bet === void 0 ? void 0 : bet.sport) === null || _0 === void 0 ? void 0 : _0.id) && TimeAndExtra(), !isInPlayBlacklistedSport((_1 = bet === null || bet === void 0 ? void 0 : bet.sport) === null || _1 === void 0 ? void 0 : _1.id) &&
                                ((_3 = (_2 = liveInfo === null || liveInfo === void 0 ? void 0 : liveInfo.inPlay) === null || _2 === void 0 ? void 0 : _2.score) === null || _3 === void 0 ? void 0 : _3.home) && ((_5 = (_4 = liveInfo === null || liveInfo === void 0 ? void 0 : liveInfo.inPlay) === null || _4 === void 0 ? void 0 : _4.score) === null || _5 === void 0 ? void 0 : _5.away) &&
                                ` ${String(liveInfo.inPlay.score.home)}-${String(liveInfo.inPlay.score.away)}`] }))] }));
        }
        return _jsx(_Fragment, {});
    }, [secondsLeft]);
    const onFreeBetClick = () => {
        setInputValue(isFreeBetUsed ? "" : String(freeBet.amount));
        setIsFreeBetUsed(isFreeBetUsed => !isFreeBetUsed);
    };
    const isSuspended = ((outcomeData === null || outcomeData === void 0 ? void 0 : outcomeData.statusId) || statusId) === BETSLIP_SELECTION_STATUS.SUSPENDED;
    let isClosed = false;
    if (productId === 1) {
        if (!(outcomeData === null || outcomeData === void 0 ? void 0 : outcomeData.isActive) ||
            (((outcomeData === null || outcomeData === void 0 ? void 0 : outcomeData.statusId) || statusId) === BETSLIP_SELECTION_STATUS.CLOSED)) {
            isClosed = true;
        }
    }
    else {
        if (((outcomeData === null || outcomeData === void 0 ? void 0 : outcomeData.statusId) || statusId) === BETSLIP_SELECTION_STATUS.CLOSED) {
            isClosed = true;
        }
    }
    const urlToCDN = "https://d1bu7ybn6mtlcz.cloudfront.net/images/";
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: styles.bet }, { children: [_jsx("div", Object.assign({ className: styles.checkboxIcon }, { children: isSuspended || isClosed ?
                            (_jsx(ImageFromCDN, { alt: "Suspended icon", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/emergency_home.svg", size: 20 })) :
                            (_jsx(ImageFromCDN, { alt: "Checkbox icon", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/check_box.svg", color: isActive && !(isUncombinableBets && isMultiple) ? "#3DAF7F" : "#ffb0b0", size: 18 })) })), _jsxs("div", Object.assign({ className: styles.main }, { children: [_jsxs("div", Object.assign({ className: styles.content }, { children: [_jsxs("div", Object.assign({ className: styles.info }, { children: [_jsx("strong", Object.assign({ className: styles.pick }, { children: pick })), _jsx("div", Object.assign({ className: styles.type }, { children: market })), _jsxs("div", Object.assign({ className: styles.event }, { children: [!isInPlayBlacklistedSport((_c = bet === null || bet === void 0 ? void 0 : bet.sport) === null || _c === void 0 ? void 0 : _c.id) &&
                                                        isLive &&
                                                        !isSuspended &&
                                                        _jsx(Live, { startDate: startDate }), _jsx("div", Object.assign({ className: styles.name }, { children: event === null || event === void 0 ? void 0 : event.replace(" vs ", " v ") }))] }))] })), _jsxs("div", Object.assign({ className: styles.details }, { children: [isSuspended || isClosed ?
                                                _jsx("div", Object.assign({ className: styles.warning, "data-testid": `BetSelection.Status` }, { children: isSuspended ? "Suspended" : "Closed" })) :
                                                _jsxs("div", Object.assign({ className: styles.odds }, { children: [currentPrevOdds && currentPrevOdds !== currentOdds && (_jsx("strong", Object.assign({ className: clsx(styles.odd, styles.prevOdds, {
                                                                [styles.arrowUpIcon]: currentPrevOdds < currentOdds,
                                                                [styles.arrowDownIcon]: currentPrevOdds > currentOdds
                                                            }) }, { children: toFixed(currentPrevOdds) }))), _jsx("strong", Object.assign({ className: styles.odd }, { children: toFixed(currentOdds) }))] })), isMultiple && liveInfo && (_jsx(MatchCounterInMinutes, {})), isSingles && (_jsx(StakeInput, { applyToAll: applyToAll, inputValue: inputValue, setInputValue: setInputValue, inputId: inputId, isActive: showKeyBoard, className: styles.stakeInput, removeLatestDigit: () => {
                                                    setApplyToAll(false);
                                                    removeLatestDigit();
                                                } }))] }))] })), isUncombinableBets && isMultiple && (_jsx("div", Object.assign({ className: styles.interrelatedBet, "data-testid": "BetSelection.Error" }, { children: "Selections cannot be combined into a multiple bet. Please remove one." })))] })), _jsx("div", Object.assign({ className: styles.removeBet, onClick: removeBetHandler, "data-testid": "BetSelection.Remove" }, { children: _jsx(ImageFromCDN, { alt: "Remove bet", url: `${urlToCDN}${themeVariant === "dark" ? "close-light.png" : "close.svg"}`, size: 13 }) }))] })), errorEvent &&
                ((_d = Object.keys(errorEvent)) === null || _d === void 0 ? void 0 : _d.length) > 0 &&
                _jsx("div", Object.assign({ className: styles.error, style: { marginBottom: 10 }, "data-testid": "Betslip.BetSelectionErrorMessage" }, { children: errorEvent === null || errorEvent === void 0 ? void 0 : errorEvent.message })), showFreeBet && isSingles && (_jsx(FreeBet, { isFreeBetUsed: isFreeBetUsed, onFreeBetClick: onFreeBetClick, amount: freeBet.amount, expireDate: freeBet.expireDate })), error && (bets.length === 1 || isSingles) && _jsx("div", Object.assign({ className: styles.errorBottom }, { children: error })), showKeyBoard && isSingles && (_jsx(KeyboardButtons, { isMultiple: betsLength < 2, enableApplyToAll: () => {
                    setApplyToAll(true);
                }, updateInputValue: updateInputValue, addNumber: addNumber, removeLatestDigit: () => {
                    setApplyToAll(false);
                    removeLatestDigit();
                }, closeKeyboard: () => { setShowKeyBoard(false); } }))] }));
};
