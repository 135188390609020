// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zHsJWwB2JkYhNljZff94{width:100%;height:1px;visibility:hidden}", "",{"version":3,"sources":["webpack://./src/helpers/ImageLoader/ImageLoader.module.scss"],"names":[],"mappings":"AAAA,sBACC,UAAA,CACA,UAAA,CACA,iBAAA","sourcesContent":[".hiddenImage {\n\twidth: 100%;\n\theight: 1px;\n\tvisibility: hidden;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hiddenImage": "zHsJWwB2JkYhNljZff94"
};
export default ___CSS_LOADER_EXPORT___;
