import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Outlet } from "react-router-dom";
const DefaultLayout = () => {
    const [productId, setProductId] = React.useState(2);
    const [open, setOpen] = React.useState(false);
    const [bets, setBets] = React.useState([
        {
            market: "Series Winner",
            pick: "Aurora Gaming",
            event: "Aurora Gaming VS 500",
            odds: 3.1,
            time: "20:16",
            outcomeId: 111,
            eventId: 111,
            productId: 2
        },
        {
            market: "Match Winner",
            pick: "9Nine",
            event: "9INE VS Looking4Org",
            odds: 2.4,
            time: "18:56",
            outcomeId: 112,
            eventId: 112,
            productId: 2
        },
        {
            market: "Match Winner",
            pick: "10Nine",
            event: "10INE VS Looking4Something",
            odds: 2.53,
            time: "19:53",
            outcomeId: 112,
            eventId: 112,
            productId: 1
        }
    ].filter(bet => bet.productId === productId));
    React.useEffect(() => {
        const toggleBetSlipHandler = (e) => {
            var _a, _b;
            e.preventDefault();
            console.log((_a = e.detail) === null || _a === void 0 ? void 0 : _a.productId);
            setProductId((_b = e.detail) === null || _b === void 0 ? void 0 : _b.productId);
            setOpen(true);
        };
        const closeBetSlipHandler = (e) => {
            e.preventDefault();
            setOpen(false);
        };
        const switchFooterRouteHandler = (e) => {
            e.preventDefault();
            console.log(e.detail);
            location.href = e.detail.route;
        };
        const updatedBetDetailsHandler = (e) => {
            e.preventDefault();
            setBets(e.detail);
        };
        window.addEventListener("updated-bet-details", updatedBetDetailsHandler);
        window.addEventListener("switch-footer-route", switchFooterRouteHandler);
        window.addEventListener("open-bet-slip", toggleBetSlipHandler);
        window.addEventListener("close-bet-slip", closeBetSlipHandler);
        return () => {
            window.removeEventListener("open-bet-slip", toggleBetSlipHandler);
            window.removeEventListener("close-bet-slip", closeBetSlipHandler);
            window.removeEventListener("switch-footer-route", switchFooterRouteHandler);
            window.removeEventListener("updated-bet-details", updatedBetDetailsHandler);
        };
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(Outlet, {}), _jsx("leetent-footer-navigation", { "product-id": productId, "betslip-items": bets.length, "total-odds": bets.reduce((acc, bet) => acc ? acc * bet.odds : bet.odds, 0) }), _jsx("leetent-bet-slip", { open: open, bets: JSON.stringify(bets) })] }));
};
export default DefaultLayout;
