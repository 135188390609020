import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
// components
import MountDocumentComponent from "components/documentation/MountDocumentComponent";
import { Button, Col, Input, Row, Space } from "antd";
const InteractiveInputs = ({ updateComponent, leagueId }) => {
    return (_jsx(Row, Object.assign({ justify: "start", align: "top", gutter: [30, 10] }, { children: _jsxs(Col, Object.assign({ xs: 20, sm: 20, md: 10, lg: 6 }, { children: [_jsx("label", { children: "Event id for sports" }), _jsxs(Space.Compact, Object.assign({ style: { width: "100%" } }, { children: [_jsx(Input, { id: "leagueIdInput", defaultValue: leagueId, size: "large" }), _jsx(Button, Object.assign({ type: "primary", size: "large", onClick: updateComponent }, { children: "Submit" }))] }))] })) })));
};
const SportsWrapperFeatured = () => {
    const [clickAuthButton, setClickAuthButton] = React.useState();
    const [leagueId, setLeagueId] = React.useState(10319);
    const updateComponent = () => {
        const value = document.getElementById("leagueIdInput").value;
        setLeagueId(value);
    };
    React.useEffect(() => {
        const clickAuthButtonHandler = (e) => {
            setClickAuthButton(e.detail);
        };
        window.addEventListener("click-auth-button", clickAuthButtonHandler);
        return () => {
            window.removeEventListener("click-auth-button", clickAuthButtonHandler);
        };
    }, []);
    const documentDetails = {
        title: "Sports league (integrated components)",
        component: "leetent-league-wrapper",
        description: `The sports league webcomponent is a fully-functional component,
      ready to be integrated into your application.
    `,
        // withDarkMode: true,
        withSessionComponent: true,
        dependencies: [
            "leetent-navigation-generic",
            "leetent-footer-navigation",
            "leetent-bet-slip"
        ],
        attributes: [
            {
                key: "1",
                attr: "relative (optional)",
                type: "boolean",
                notes: [
                    "No need to pass this prop, unless you want to apply relative to the leetent-footer-navigation component.",
                    `The way to use it is to suppress the word relative or simply type it.
            e.g. <leetent-league-wrapper relative />`
                ]
            },
            {
                key: "2",
                attr: "menu-options",
                type: "object[] (optional)",
                notes: [
                    "Custom menu options.",
                    `{
            id?: number
            label: string
            url: string
            Icon?: React.FC<React.SVGProps<SVGSVGElement>>
            isAuthenticationRequired?: boolean
          }`,
                    `Bear in mind options ids are from 1 to 4 (sequentially) and
            that you can override only a specific attribute for a specific option if you wish.
            e.g. menu-options={JSON.stringify([{ id: 2, label: "Live!" }, { id: 3, url: "/my-bets" }])}`,
                    "When a route is overriden, it gets automatically redirected. Unless, it requires authentication.",
                    "By default My Bets requires authentication (isAuthenticationRequired: true)"
                ]
            },
            {
                key: "3",
                attr: "footer-navigation-max-width",
                type: "string (optional)",
                notes: [
                    "By default the max-width is defined as inherit",
                    `If necessary, you can define a max-width. e.g. max-width="600px"`,
                    "Note: The minimum value acceptable would be 380px"
                ]
            },
            {
                key: "4",
                attr: "unauthenticated-modal-title",
                type: "string (optional)",
                notes: [
                    "Custom unauthenticated modal title.",
                    `Default value is: Please login or register to proceed`
                ]
            },
            {
                key: "5",
                attr: "unauthenticated-modal-options",
                type: "object[] (optional)",
                notes: [
                    "Custom unauthenticated modal options.",
                    `{
            id: number
            label: string
            url: string
          }`,
                    `Default value is: 
          [
            {
              id: 1,
              label: "Login",
              url: "/sign-in"
            },
          
            {
              id: 2,
              label: "Register",
              url: "/sign-up"
            }
          ]`
                ]
            },
            {
                key: "6",
                attr: "no-data-options",
                type: "object{} (optional)",
                notes: [
                    "Custom no data options.",
                    `{
            message:"No Events Available at the moment",
            src:"https://d1bu7ybn6mtlcz.cloudfront.net/data_alert.svg",
            cta:{JSON.stringify(label: "Go To Sportsbook",url: "/")}
          }`,
                    `Default value is:           
            {
              message:"No Events Available at the moment",
              src:"https://d1bu7ybn6mtlcz.cloudfront.net/data_alert.svg",
            }`
                ]
            },
            {
                key: "7",
                attr: "default-event-state",
                type: "string (optional)",
                notes: [
                    `This optional prop tells the tags located under the navigation component,
            the default events state, so they are automatically filtered/highlighted by default.`,
                    "One of the following options is expected: all, live, next24h, next48h or next72h"
                ]
            },
            {
                key: "8",
                attr: "betslip-max-width",
                type: "string (optional)",
                notes: [
                    "By default the max-width is defined as inherit",
                    `If necessary, you can define a max-width. e.g. max-width="600px"`,
                    "Note: The minimum value acceptable would be 380px"
                ]
            }
        ],
        events: [
            {
                event: "navigation-details",
                type: "listener",
                data: [
                    "Each time a new tab is selected, the state gets updated.",
                    "This is transparent to users."
                ]
            },
            {
                event: "switch-footer-route",
                type: "listener",
                data: [
                    `Every time user clicks on the go back button from
            the profile page,this listener updates the selected tab.`
                ]
            },
            {
                event: "open-bet-slip",
                type: "listener",
                data: [
                    "When betslip toggle button is clicked, the component emits its data."
                ]
            },
            {
                event: "close-bet-slip",
                type: "listener",
                data: [
                    "When add to betslip is clicked, the component emits its data."
                ]
            },
            {
                event: "bet-placement-success",
                type: "listener",
                data: [
                    "This event is emitted when bets are placed, and there is a positive response from the platform."
                ]
            },
            {
                event: "update-selected-bets",
                type: "listener",
                data: [
                    "When add to betslip is clicked, the component emits its data."
                ]
            },
            {
                event: "league-event-details",
                type: "listener",
                data: [
                    "When the league event row is clicked, an event is emitted. e.g. Europe: Euro Qualification"
                ]
            },
            {
                event: "match-event-details",
                type: "listener",
                data: [
                    "When the match row is clicked, an event is emitted containing the match's information."
                ]
            },
            {
                event: "matches-bymarket-input-details",
                type: "listener",
                data: [
                    "When add to betslip is clicked, the component emits its data."
                ]
            },
            {
                event: "match-more-markets",
                type: "listener",
                data: ["When the more markets option is clicked, an event is emitted."]
            },
            {
                event: "user-unauthenticated",
                type: "listener",
                data: ["When the user authenticated event is triggered, it means there is no session available."]
            },
            {
                event: "close-auth-modal",
                type: "listener",
                data: ["The component details object is emitted on closing the unauthenticated modal."]
            },
            {
                event: "close-modal",
                type: "listener",
                data: ["The component details object is emitted on closing the generic modal."]
            },
            {
                event: "click-modal-button",
                type: "listener",
                data: [
                    "The component details object is emitted on click auth buttons in the modal.",
                    `detail: {
              id: number
              label: string
              url: string
            }`
                ]
            },
            {
                event: "click-modal-button",
                type: "listener",
                data: [
                    "When a user clicks on one of the buttons inside the Generic modal, this event gets emitted."
                ]
            },
            {
                event: "nodata-cta-event-details",
                type: "listener",
                data: [
                    "When a user clicks on the button, this event gets emitted.",
                    `detail : {
            url: <value>
          }`
                ]
            },
            {
                event: "sports-routing-details",
                type: "listener",
                data: [
                    "When a search result league is clicked"
                ]
            },
            {
                event: "selected-tag-details",
                type: "listener",
                data: [
                    "When the state tag is selected"
                ]
            },
            {
                event: "series-selected-option",
                type: "listener",
                data: [
                    "When the market/serie is selected"
                ]
            },
            {
                event: "balance-update",
                type: "listener",
                data: [
                    "This event is emitted when a bet is successfully placed"
                ]
            }
        ],
        overrideEvents: [
            {
                event: "click-auth-button",
                type: "listener",
                data: [
                    "The component details object is emitted on click auth buttons in the modal.",
                    `detail: {
            id: number
            label: string
            url: string
          }`
                ],
                detail: {
                    emitModel: clickAuthButton
                }
            }
        ],
        example: `<leetent-league-wrapper />`,
        code: _jsx(_Fragment, { children: _jsx("leetent-league-wrapper", { relative: true, "no-data-options": JSON.stringify({
                    message: "No Events Available at the moment",
                    src: "https://d1bu7ybn6mtlcz.cloudfront.net/data_alert.svg",
                    cta: { label: "Go To Sportbook", url: "/" }
                }), "league-id": leagueId }) })
    };
    documentDetails.interactiveInputs = _jsx(InteractiveInputs, { updateComponent: updateComponent, leagueId: leagueId });
    // render the page based on the provided object
    return _jsx(MountDocumentComponent, { data: documentDetails });
};
export default SportsWrapperFeatured;
