import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import clsx from "clsx";
import ImageFromCDN from "components/base/imageFromCDN/ImageFromCDN";
import { isInPlayBlacklistedSport } from "helpers/InPlayBlacklistSports";
import { BET_STATUS, CURRENCY, RESULT_STATUS, EVENT_STATUS } from "enums";
// TODO: We might want to move accordion in to the base folder
import { Accordion } from "../Accordion/Accordion";
import { BetCashOut } from "./betCashOut/BetCashOut";
import { getEventsLegs } from "helpers/Bets";
import dayjs from "dayjs";
import Live from "components/base/live/Live";
import { processedTimeCounter, showClockForSport } from "helpers/ProcessTimeCounter";
import { useSubscribeByQuery } from "helpers/graphql/useSubscribeByQuery";
import { SUBSCRIBE_EVENTS } from "v2/graphql/subscriptions";
import { Tabs } from "components/composed/myBets/MyBets";
import styles from "./BetAccordion.module.scss";
export const BetAccordion = ({ bet, HeaderStatus, playerId, selectedTab, productId, betslipConfirmation, cashout, open }) => {
    var _a, _b;
    const [copied, setCopied] = React.useState(false);
    const [eventsData, setEventsData] = React.useState({});
    const isFreeBet = bet.isFreeBet;
    const isUnsettled = selectedTab === Tabs.Unsettled;
    const isSettled = selectedTab === Tabs.Settled;
    const { betRef, betLegs, potentialPayout, stake, resultedDate, createdDate, statusId, cashoutOffer, id, 
    // payout,
    cashouts } = bet;
    const valueHandlerCallback = (details) => {
        const newEventsData = details.sport_domain_event.reduce((acc, nextData) => {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            const data = {
                id: nextData === null || nextData === void 0 ? void 0 : nextData.id,
                startDate: nextData === null || nextData === void 0 ? void 0 : nextData.start_at,
                statusId: nextData === null || nextData === void 0 ? void 0 : nextData.event_status_id,
                inPlay: {
                    clock: {
                        minutes: (_a = nextData === null || nextData === void 0 ? void 0 : nextData.event_clock) === null || _a === void 0 ? void 0 : _a.minutes,
                        period: (_b = nextData === null || nextData === void 0 ? void 0 : nextData.event_clock) === null || _b === void 0 ? void 0 : _b.period_name,
                        seconds: (_c = nextData === null || nextData === void 0 ? void 0 : nextData.event_clock) === null || _c === void 0 ? void 0 : _c.seconds,
                        remainingMinutesInPeriod: (_d = nextData === null || nextData === void 0 ? void 0 : nextData.event_clock) === null || _d === void 0 ? void 0 : _d.minutes_left_in_period,
                        remainingSecondsInPeriod: (_e = nextData === null || nextData === void 0 ? void 0 : nextData.event_clock) === null || _e === void 0 ? void 0 : _e.seconds_left_in_period,
                        statusId: (_f = nextData === null || nextData === void 0 ? void 0 : nextData.event_clock) === null || _f === void 0 ? void 0 : _f.event_clock_status_id,
                        timestamp: (_g = nextData === null || nextData === void 0 ? void 0 : nextData.event_clock) === null || _g === void 0 ? void 0 : _g.timestamp_epoch
                    },
                    score: {
                        home: nextData === null || nextData === void 0 ? void 0 : nextData.home_score,
                        away: nextData === null || nextData === void 0 ? void 0 : nextData.away_score,
                        periodScores: (_h = nextData === null || nextData === void 0 ? void 0 : nextData.period_scores) === null || _h === void 0 ? void 0 : _h.map((scores) => {
                            return {
                                home: scores.home_score,
                                away: scores.away_score
                            };
                        })
                    }
                }
            };
            acc[nextData.id] = data;
            return acc;
        }, {});
        setEventsData((prevData) => {
            const updatedData = structuredClone(prevData);
            // Update existing or add new events
            Object.keys(newEventsData).forEach((id) => {
                updatedData[id] = newEventsData[id];
            });
            return updatedData;
        });
    };
    useSubscribeByQuery({
        query: SUBSCRIBE_EVENTS,
        variables: {
            eventIds: (_a = betLegs === null || betLegs === void 0 ? void 0 : betLegs.map(betLeg => { var _a, _b, _c; return (_c = (_b = (_a = betLeg === null || betLeg === void 0 ? void 0 : betLeg.outcome) === null || _a === void 0 ? void 0 : _a.market) === null || _b === void 0 ? void 0 : _b.event) === null || _c === void 0 ? void 0 : _c.id; })) === null || _a === void 0 ? void 0 : _a.filter((item) => item)
        },
        valueHandlerCallback
    });
    const convertDate = (timestamp) => dayjs(timestamp).format("DD MMM HH:mm");
    const convertBetRef = (betRef) => betRef.slice(0, 4) + "..." + betRef.slice(-4);
    const getBetTypeName = (numberOfBets) => {
        switch (numberOfBets) {
            case 1:
                return "Single";
            case 2:
                return "Double";
            case 3:
                return "Treble";
            default:
                return `${numberOfBets} - Fold`;
        }
    };
    const getTotalOdds = () => {
        var _a;
        const totalStakeMultiplier = (_a = bet.betLegs) === null || _a === void 0 ? void 0 : _a.reduce((acc, item) => acc * Number(item.odds), 1);
        return totalStakeMultiplier.toFixed(2);
    };
    const isResulted = statusId === BET_STATUS.RESULTED;
    const isCancelled = statusId === BET_STATUS.CANCELLED;
    const isCashedOut = statusId === BET_STATUS.CASHED_OUT;
    const isBetBuilder = betLegs.find(leg => { var _a; return (_a = leg.outcome.constituentOutcomes) === null || _a === void 0 ? void 0 : _a.length; });
    const getBetStatus = () => {
        if (isCashedOut) {
            return {
                betStatus: "Cashed Out"
            };
        }
        const isLost = betLegs.some((betLeg) => betLeg.resultStatusId === RESULT_STATUS.LOST);
        if (isLost) {
            return {
                betStatus: "Lost",
                betStatusId: 2
            };
        }
        const isWon = betLegs.some((betLeg) => betLeg.resultStatusId === RESULT_STATUS.WON);
        if (isWon) {
            return {
                betStatus: "Won",
                betStatusId: 1
            };
        }
        return {
            betStatus: "Refunded",
            betStatusId: 3
        };
    };
    const { betStatus, betStatusId } = getBetStatus();
    const isLiveBetExists = Object.values(eventsData).some((data) => data.statusId === EVENT_STATUS.LIVE && !isSettled);
    const AccordionHeader = ({ open }) => {
        const totalEvents = betLegs === null || betLegs === void 0 ? void 0 : betLegs.length;
        const totalLegs = betLegs === null || betLegs === void 0 ? void 0 : betLegs.reduce((acc, item) => {
            var _a;
            const BBLength = (_a = item.outcome.constituentOutcomes) === null || _a === void 0 ? void 0 : _a.length;
            if (BBLength) {
                return acc + BBLength;
            }
            return acc + 1;
        }, 0);
        return (_jsxs("div", Object.assign({ className: styles.header }, { children: [_jsxs("div", Object.assign({ className: clsx(styles.title, { [styles.BB]: isBetBuilder }) }, { children: [isBetBuilder ?
                            `Bet Builder @ ${getTotalOdds()}` :
                            _jsxs(_Fragment, { children: [getBetTypeName(betLegs === null || betLegs === void 0 ? void 0 : betLegs.length), " @ ", getTotalOdds(), isLiveBetExists &&
                                        !open &&
                                        _jsx(Live, { style: { marginTop: 2 } })] }), isBetBuilder && _jsx("div", Object.assign({ className: styles.date }, { children: getEventsLegs(totalEvents, totalLegs) }))] })), HeaderStatus && !betslipConfirmation && (_jsx(HeaderStatus, { status: betStatus, resultStatusId: betStatusId }))] })));
    };
    const getBetLegStatus = (status) => {
        switch (status) {
            case RESULT_STATUS.WON:
                return (_jsx(ImageFromCDN, { alt: "Won", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/double-tick-green.png", size: 24 }));
            case RESULT_STATUS.LOST:
                return (_jsx(ImageFromCDN, { url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/close-red.png", alt: "Lost", width: 16, height: 18 }));
            case RESULT_STATUS.REFUNDED:
                return "Void";
            case RESULT_STATUS.CASHED_OUT:
                return (_jsx(ImageFromCDN, { url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/cashed-out.svg", alt: "Cashed out", size: 16 }));
            default:
                return "";
        }
    };
    const BetBuilder = ({ outcome, eventName, odds }) => {
        const { constituentOutcomes, id } = outcome;
        return (_jsx("div", Object.assign({ className: styles.groupedBet, "data-testid": `BetBuilderBetAccordion-${id}` }, { children: constituentOutcomes.map(({ name, market, id }, index) => {
                return (_jsxs("div", Object.assign({ className: styles.bet }, { children: [_jsxs("div", Object.assign({ className: styles.groupSelections }, { children: [_jsx("div", { className: clsx(styles.line, { [styles.hidden]: index === 0 }) }), _jsx("div", { className: styles.indicator }), _jsx("div", { className: clsx(styles.line, { [styles.hidden]: index === constituentOutcomes.length - 1 }) })] })), _jsxs("div", Object.assign({ className: styles.content }, { children: [_jsxs("div", Object.assign({ className: styles.info }, { children: [_jsx("div", Object.assign({ className: styles.pick }, { children: name })), _jsx("div", Object.assign({ className: styles.type }, { children: market.name })), _jsx("div", Object.assign({ className: styles.event }, { children: eventName }))] })), index === 0 && _jsx("strong", Object.assign({ className: styles.odds }, { children: odds }))] }))] }), `constituent-outcome-${id}`));
            }) })));
    };
    const historyBetsSum = cashouts.reduce((acc, item) => acc + +item.amountRequested, 0);
    const getSportIconUrl = (sportName) => {
        return `https://d1sg8aqjbar3ur.cloudfront.net/sports/mybets/${sportName}-icon.svg`;
    };
    const hasRefundedBetLegs = betLegs.some(({ resultStatusId }) => resultStatusId === RESULT_STATUS.REFUNDED);
    return (_jsxs(_Fragment, { children: [_jsx(Accordion, Object.assign({ Header: AccordionHeader, classes: { root: styles.rootAccordion }, open: open }, { children: _jsxs("section", Object.assign({ className: styles.content, style: { marginBottom: isFreeBet ? 6 : 0 } }, { children: [betLegs.map(({ outcome, odds, resultStatusId }, index) => {
                            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10;
                            const isRefunded = resultStatusId === RESULT_STATUS.REFUNDED;
                            const isWon = resultStatusId === RESULT_STATUS.WON;
                            if ((_a = outcome.constituentOutcomes) === null || _a === void 0 ? void 0 : _a.length) {
                                return (_jsx(BetBuilder, { outcome: outcome, eventName: (_c = (_b = outcome === null || outcome === void 0 ? void 0 : outcome.market) === null || _b === void 0 ? void 0 : _b.event) === null || _c === void 0 ? void 0 : _c.name, odds: odds === null || odds === void 0 ? void 0 : odds.toFixed(2) }, `bet-leg-${outcome.id}-${index}`));
                            }
                            const liveData = eventsData[(_e = (_d = outcome === null || outcome === void 0 ? void 0 : outcome.market) === null || _d === void 0 ? void 0 : _d.event) === null || _e === void 0 ? void 0 : _e.id] || {};
                            const sportId = (_j = (_h = (_g = (_f = outcome === null || outcome === void 0 ? void 0 : outcome.market) === null || _f === void 0 ? void 0 : _f.event) === null || _g === void 0 ? void 0 : _g.tournament) === null || _h === void 0 ? void 0 : _h.sport) === null || _j === void 0 ? void 0 : _j.id;
                            return (_jsxs("div", Object.assign({ className: styles.bet, "data-testid": `BetAccordion-${outcome.id}` }, { children: [_jsxs("div", Object.assign({ className: styles.caption }, { children: [_jsxs("strong", Object.assign({ className: styles.marketType }, { children: [((_o = (_m = (_l = (_k = outcome === null || outcome === void 0 ? void 0 : outcome.market) === null || _k === void 0 ? void 0 : _k.event) === null || _l === void 0 ? void 0 : _l.tournament) === null || _m === void 0 ? void 0 : _m.sport.name) === null || _o === void 0 ? void 0 : _o.toLowerCase()) && productId === 1 &&
                                                        _jsx("img", { src: getSportIconUrl((_s = (_r = (_q = (_p = outcome === null || outcome === void 0 ? void 0 : outcome.market) === null || _p === void 0 ? void 0 : _p.event) === null || _q === void 0 ? void 0 : _q.tournament) === null || _r === void 0 ? void 0 : _r.sport.name) === null || _s === void 0 ? void 0 : _s.toLowerCase().replaceAll(" ", "-")), alt: "sport_icon", style: { width: 12, height: 12 } }), (_t = outcome === null || outcome === void 0 ? void 0 : outcome.market) === null || _t === void 0 ? void 0 : _t.name] })), _jsx("strong", Object.assign({ className: styles.outcome }, { children: outcome === null || outcome === void 0 ? void 0 : outcome.name })), _jsx("div", Object.assign({ className: styles.details }, { children: _jsx(_Fragment, { children: sportId && !isInPlayBlacklistedSport(sportId) &&
                                                        (liveData === null || liveData === void 0 ? void 0 : liveData.statusId) === EVENT_STATUS.LIVE && !isSettled ?
                                                        ((_v = (_u = outcome === null || outcome === void 0 ? void 0 : outcome.market) === null || _u === void 0 ? void 0 : _u.event) === null || _v === void 0 ? void 0 : _v.name.replace("vs", `${String((_x = (_w = liveData === null || liveData === void 0 ? void 0 : liveData.inPlay) === null || _w === void 0 ? void 0 : _w.score) === null || _x === void 0 ? void 0 : _x.home)} - `)) + ` ${String((_z = (_y = liveData === null || liveData === void 0 ? void 0 : liveData.inPlay) === null || _y === void 0 ? void 0 : _y.score) === null || _z === void 0 ? void 0 : _z.away)}` :
                                                        (_1 = (_0 = outcome === null || outcome === void 0 ? void 0 : outcome.market) === null || _0 === void 0 ? void 0 : _0.event) === null || _1 === void 0 ? void 0 : _1.name }) })), sportId && !isInPlayBlacklistedSport(sportId) &&
                                                (liveData === null || liveData === void 0 ? void 0 : liveData.statusId) === EVENT_STATUS.LIVE && !isSettled &&
                                                _jsx(Live, { style: { marginTop: 4 }, sport: { id: sportId }, period: (_3 = (_2 = liveData === null || liveData === void 0 ? void 0 : liveData.inPlay) === null || _2 === void 0 ? void 0 : _2.clock) === null || _3 === void 0 ? void 0 : _3.period, timeCounter: showClockForSport(sportId) ?
                                                        processedTimeCounter((_4 = liveData === null || liveData === void 0 ? void 0 : liveData.inPlay) === null || _4 === void 0 ? void 0 : _4.clock, ((_8 = (_7 = (_6 = (_5 = outcome === null || outcome === void 0 ? void 0 : outcome.market) === null || _5 === void 0 ? void 0 : _5.event) === null || _6 === void 0 ? void 0 : _6.tournament) === null || _7 === void 0 ? void 0 : _7.sport.name) === null || _8 === void 0 ? void 0 : _8.toLowerCase()) === "basketball") :
                                                        "", statusId: (_10 = (_9 = liveData === null || liveData === void 0 ? void 0 : liveData.inPlay) === null || _9 === void 0 ? void 0 : _9.clock) === null || _10 === void 0 ? void 0 : _10.statusId, startDate: liveData === null || liveData === void 0 ? void 0 : liveData.startDate })] })), _jsxs("div", Object.assign({ className: styles.status }, { children: [_jsx("div", Object.assign({ className: clsx(styles.icon, {
                                                    [styles.refunded]: isRefunded || isCancelled
                                                }) }, { children: isCancelled ? "Void" : getBetLegStatus(resultStatusId) })), _jsx("strong", Object.assign({ className: clsx(styles.odds, {
                                                    [styles.refunded]: isRefunded || isCancelled,
                                                    [styles.won]: isWon,
                                                    [styles.unsettled]: isUnsettled
                                                }) }, { children: odds === null || odds === void 0 ? void 0 : odds.toFixed(2) }))] }))] }), `bet-leg-${outcome.id}-${index}`));
                        }), (cashouts === null || cashouts === void 0 ? void 0 : cashouts.length) > 0 && (_jsx("div", Object.assign({ className: styles.cashoutHistory }, { children: cashouts.map((cashout, index) => {
                                const { amountRequested, amountOffered } = cashout || {};
                                const date = dayjs(cashout === null || cashout === void 0 ? void 0 : cashout.createdAt).format("DD MMM YYYY | HH:mm");
                                return (_jsxs("div", Object.assign({ className: styles.cashoutItem }, { children: [_jsxs("div", Object.assign({ className: styles.details }, { children: [_jsx("div", Object.assign({ className: styles.date }, { children: date })), amountRequested === amountOffered ?
                                                    "Full cash out" :
                                                    "Partial cash out", isUnsettled &&
                                                    ` (original stake: ${CURRENCY}${cashouts[cashouts.length - 1].stakeBefore})`] })), _jsxs("div", Object.assign({ className: styles.amount }, { children: [CURRENCY, amountRequested] }))] }), `cashout-history-${index}`));
                            }) }))), isFreeBet && _jsxs("div", Object.assign({ className: styles.freeBet }, { children: [_jsxs("div", Object.assign({ className: styles.leftSide }, { children: [_jsx(ImageFromCDN, { url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/freeBet.svg", alt: "Free bet", size: 24 }), _jsx("span", Object.assign({ style: { marginLeft: 5 } }, { children: "Free Bet Used" }))] })), betRef && _jsx("div", Object.assign({ className: styles.betRef }, { children: _jsxs("div", Object.assign({ className: styles.details }, { children: [`Bet Ref : ${convertBetRef(betRef)}`, _jsx("button", Object.assign({ className: styles.copyBtn, onClick: () => {
                                                    setCopied(true);
                                                    setTimeout(() => {
                                                        setCopied(false);
                                                    }, 2000);
                                                    navigator.clipboard.writeText(betRef);
                                                } }, { children: _jsx(ImageFromCDN, { url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/copy.svg", size: 12, color: !copied ? "#000000" : "#3daf7f", overrideStyles: { cursor: "pointer" } }) }))] })) }))] })), playerId && productId && isUnsettled && !betslipConfirmation && cashout && (_jsx(BetCashOut, { stake: stake, payout: potentialPayout, cashoutOffer: cashoutOffer, betId: id, playerId: playerId, productId: productId, betRef: betRef, hasRefundedBetLegs: hasRefundedBetLegs })), _jsxs("div", Object.assign({ className: styles.summary }, { children: [_jsxs("div", Object.assign({ className: styles.stake }, { children: [_jsx("span", { children: "Stake" }), _jsxs("strong", { children: [CURRENCY, !isUnsettled && isCashedOut ? (_b = cashouts === null || cashouts === void 0 ? void 0 : cashouts[cashouts.length - 1]) === null || _b === void 0 ? void 0 : _b.stakeBefore : stake] })] })), _jsx("div", Object.assign({ className: styles.return }, { children: _jsxs("div", Object.assign({ className: styles.potentialPayout }, { children: [_jsx("span", { children: !isUnsettled && isCashedOut ? "Cashed Out" : "To Return" }), _jsxs("strong", { children: [CURRENCY, isSettled && !betslipConfirmation && betStatusId !== 1 ?
                                                        Number.isInteger(historyBetsSum) ? historyBetsSum : parseFloat(historyBetsSum.toFixed(2)) :
                                                        potentialPayout] })] })) }))] }))] })) })), betRef && !isFreeBet && (_jsxs("div", Object.assign({ className: styles.betRef }, { children: [_jsxs("div", Object.assign({ className: styles.details }, { children: [`Bet Ref : ${convertBetRef(betRef)}`, _jsxs("button", Object.assign({ className: styles.copyBtn, onClick: () => {
                                    setCopied(true);
                                    setTimeout(() => {
                                        setCopied(false);
                                    }, 2000);
                                    navigator.clipboard.writeText(betRef);
                                } }, { children: [_jsx(ImageFromCDN, { url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/copy.svg", size: 12, color: !copied ? "#000000" : "#3daf7f", overrideStyles: { cursor: "pointer" } }), copied && (_jsxs("div", Object.assign({ className: styles.copied }, { children: ["Copied ", _jsx(ImageFromCDN, { url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/check-green.png", alt: "Copy", size: 18 })] })))] }))] })), _jsx("div", Object.assign({ className: styles.betRefDate }, { children: convertDate(isResulted ? resultedDate : createdDate) }))] })))] }));
};
