import dayjs from "dayjs";
export const mockEventDetailData = {
    eventDetail: {
        id: 19475,
        name: "Galatasaray Spor Kulubu vs Borussia Monchengladbach",
        homeTeam: "Galatasaray Spor Kulubu",
        awayTeam: "Borussia Monchengladbach",
        leagueName: "UEFA Champions League",
        countryName: "Euro Qualifiers",
        sportId: 1,
        sportName: "Soccer",
        startDate: dayjs().add(5, "seconds").format("YYYY-MM-DDTHH:mm:ssZ"),
        statusId: 18,
        inPlay: {
            clock: {
                period: "2H",
                statusId: 30,
                minutes: 0,
                seconds: 0,
                remainingMinutesInPeriod: 1,
                remainingSecondsInPeriod: 20,
                timestamp: dayjs().add(5, "seconds").valueOf()
            },
            score: {
                home: "2",
                away: "1",
                periodScores: [
                    {
                        period: "1H",
                        home: "1",
                        away: "0"
                    },
                    {
                        period: "2H",
                        home: "2",
                        away: "1"
                    }
                ]
            },
            stats: {
                homecorners: 4,
                awaycorners: 1,
                homeyellows: 0,
                awayyellows: 0,
                homepossession: 50,
                awaypossession: 50,
                homereds: 0,
                awayreds: 0
            }
        }
    },
    otherEvents: [
        {
            name: "Galatasaray Spor Kulubu v Borussia Monchengladbach",
            id: 19475,
            homeTeam: "Galatasaray Spor Kulubu",
            awayTeam: "Borussia Monchengladbach",
            leagueName: "UEFA Champions League",
            countryName: "Euro Qualifiers",
            sportId: 1,
            sportName: "Soccer",
            startDate: "2023-10-03T18:45:00+00:00",
            statusId: 18,
            inPlay: {
                clock: {
                    period: "1H",
                    statusId: 10,
                    minutes: 1,
                    seconds: 140,
                    remainingMinutesInPeriod: 1,
                    remainingSecondsInPeriod: 20,
                    timestamp: 1708442098
                },
                score: {
                    home: "2",
                    away: "1",
                    periodScores: [
                        {
                            period: "1H",
                            home: "2",
                            away: "1"
                        }
                    ]
                },
                stats: {
                    homecorners: 4,
                    awaycorners: 1,
                    homeyellows: 0,
                    awayyellows: 0,
                    homepossession: 50,
                    awaypossession: 50,
                    homereds: 0,
                    awayreds: 0
                }
            }
        },
        {
            name: "Fulham v Chelsea",
            id: 19476,
            homeTeam: "Fulham",
            awayTeam: "Chelsea",
            leagueName: "UEFA Champions League",
            countryName: "Euro Qualifiers",
            sportId: 1,
            sportName: "Soccer",
            startDate: "2023-10-03T21:45:00+00:00",
            statusId: 18
        },
        {
            name: "Arsenal v Liverpool",
            id: 19477,
            homeTeam: "Arsenal",
            awayTeam: "Liverpool",
            leagueName: "UEFA Champions League",
            countryName: "Euro Qualifiers",
            sportId: 1,
            sportName: "Soccer",
            startDate: "2023-10-04T22:23:45+00:00",
            statusId: 18,
            inPlay: {
                clock: {
                    period: "2H",
                    statusId: 10,
                    minutes: 1,
                    seconds: 140,
                    remainingMinutesInPeriod: 1,
                    remainingSecondsInPeriod: 20,
                    timestamp: 1708442098
                },
                score: {
                    home: "0",
                    away: "5",
                    periodScores: [
                        {
                            period: "1H",
                            home: "0",
                            away: "3"
                        },
                        {
                            period: "2H",
                            home: "0",
                            away: "5"
                        }
                    ]
                },
                stats: {
                    homecorners: 4,
                    awaycorners: 1,
                    homeyellows: 0,
                    awayyellows: 0,
                    homepossession: 50,
                    awaypossession: 50,
                    homereds: 0,
                    awayreds: 0
                }
            }
        },
        {
            name: "Wolves v Brentford",
            id: 19478,
            homeTeam: "Wolves",
            awayTeam: "Brentford",
            leagueName: "UEFA Champions League",
            countryName: "Euro Qualifiers",
            sportId: 1,
            sportName: "Soccer",
            startDate: "2023-10-05T20:01:23+00:00",
            statusId: 18,
            inPlay: {
                clock: {
                    period: "1H",
                    statusId: 10,
                    minutes: 1,
                    seconds: 140,
                    remainingMinutesInPeriod: 1,
                    remainingSecondsInPeriod: 20,
                    timestamp: 1708442098
                },
                score: {
                    home: "1",
                    away: "3",
                    periodScores: [
                        {
                            period: "1H",
                            home: "1",
                            away: "3"
                        }
                    ]
                },
                stats: {
                    homecorners: 4,
                    awaycorners: 1,
                    homeyellows: 0,
                    awayyellows: 0,
                    homepossession: 50,
                    awaypossession: 50,
                    homereds: 0,
                    awayreds: 0
                }
            }
        }
    ]
};
export const mockEventDetailBasketballData = {
    eventDetail: {
        id: 19475,
        name: "Chicago Bulls vs Charlotte Hornets",
        homeTeam: "Chicago Bulls",
        awayTeam: "Charlotte Hornets",
        leagueName: "NBA",
        countryName: "United States of America",
        sportId: 2,
        sportName: "Basketball",
        startDate: "2023-09-26T18:45:00+00:00",
        statusId: 18,
        inPlay: {
            clock: {
                period: "Q4",
                statusId: 10,
                minutes: 1,
                seconds: 140,
                remainingMinutesInPeriod: 1,
                remainingSecondsInPeriod: 20,
                timestamp: 1708442098
            },
            score: {
                home: String(Math.floor(Math.random() * (70 - 0 + 1) + 10)),
                away: String(Math.floor(Math.random() * (70 - 0 + 1) + 10)),
                periodScores: []
            },
            stats: {
                homecorners: 4,
                awaycorners: 1,
                homeyellows: 0,
                awayyellows: 0,
                homepossession: 50,
                awaypossession: 50,
                homereds: 0,
                awayreds: 0
            }
        }
    },
    otherEvents: [
        {
            name: "MIA vs PHO",
            id: 19475,
            homeTeam: "Miami Florida",
            awayTeam: "Phoenix",
            leagueName: "NBA",
            countryName: "United States of America",
            sportId: 2,
            sportName: "Basketball",
            startDate: "2023-10-03T18:45:00+00:00",
            statusId: 18,
            inPlay: {
                clock: {
                    period: "Q3",
                    statusId: 10,
                    minutes: 1,
                    seconds: 140,
                    remainingMinutesInPeriod: 1,
                    remainingSecondsInPeriod: 20,
                    timestamp: 1708442098
                },
                score: {
                    home: String(Math.floor(Math.random() * (70 - 0 + 1) + 10)),
                    away: String(Math.floor(Math.random() * (70 - 0 + 1) + 10)),
                    periodScores: [
                        {
                            period: "Q1",
                            home: String(Math.floor(Math.random() * (70 - 0 + 1) + 10)),
                            away: String(Math.floor(Math.random() * (70 - 0 + 1) + 10))
                        },
                        {
                            period: "Q2",
                            home: String(Math.floor(Math.random() * (70 - 0 + 1) + 10)),
                            away: String(Math.floor(Math.random() * (70 - 0 + 1) + 10))
                        }
                    ]
                },
                stats: {
                    homecorners: 4,
                    awaycorners: 1,
                    homeyellows: 0,
                    awayyellows: 0,
                    homepossession: 50,
                    awaypossession: 50,
                    homereds: 0,
                    awayreds: 0
                }
            }
        }
    ]
};
export const mockEventDetailTennisData = {
    eventDetail: {
        id: 19475,
        name: "S. Nagal vs S.C. Hong",
        homeTeam: "S. Nagal",
        awayTeam: "S.C. Hong",
        leagueName: "India Wells Open",
        countryName: "India",
        sportId: 3,
        sportName: "Tennis",
        startDate: "2023-09-26T18:45:00+00:00",
        statusId: 18,
        inPlay: {
            clock: {
                period: "SET3",
                statusId: 10,
                minutes: 1,
                seconds: 140,
                remainingMinutesInPeriod: 1,
                remainingSecondsInPeriod: 20,
                timestamp: 1708442098
            },
            score: {
                home: "40",
                away: "40",
                periodScores: [
                    {
                        home: "4",
                        away: "6"
                    },
                    {
                        home: "7",
                        away: "6"
                    },
                    {
                        home: "7",
                        away: "6"
                    },
                    {
                        home: "7",
                        away: "6"
                    },
                    {
                        home: "6",
                        away: "6"
                    }
                ]
            },
            stats: {
                homecorners: 4,
                awaycorners: 1,
                homeyellows: 0,
                awayyellows: 0,
                homepossession: 50,
                awaypossession: 50,
                homereds: 0,
                awayreds: 0
            }
        }
    },
    otherEvents: [
        {
            name: "MIA vs PHO",
            id: 19475,
            homeTeam: "Miami Florida",
            awayTeam: "Phoenix",
            leagueName: "NBA",
            countryName: "United States of America",
            sportId: 2,
            sportName: "Basketball",
            startDate: "2023-10-03T18:45:00+00:00",
            statusId: 18,
            inPlay: {
                clock: {
                    period: "Q3",
                    statusId: 10,
                    minutes: 1,
                    seconds: 140,
                    remainingMinutesInPeriod: 1,
                    remainingSecondsInPeriod: 20,
                    timestamp: 1708442098
                },
                score: {
                    home: String(Math.floor(Math.random() * (70 - 0 + 1) + 10)),
                    away: String(Math.floor(Math.random() * (70 - 0 + 1) + 10)),
                    periodScores: [
                        {
                            period: "Q1",
                            home: String(Math.floor(Math.random() * (70 - 0 + 1) + 10)),
                            away: String(Math.floor(Math.random() * (70 - 0 + 1) + 10))
                        },
                        {
                            period: "Q2",
                            home: String(Math.floor(Math.random() * (70 - 0 + 1) + 10)),
                            away: String(Math.floor(Math.random() * (70 - 0 + 1) + 10))
                        }
                    ]
                },
                stats: {
                    homecorners: 4,
                    awaycorners: 1,
                    homeyellows: 0,
                    awayyellows: 0,
                    homepossession: 50,
                    awaypossession: 50,
                    homereds: 0,
                    awayreds: 0
                }
            }
        }
    ]
};
export const mockEventDetailVolleyballData = {
    eventDetail: {
        id: 19478,
        name: "Kazakhstan vs Australia",
        homeTeam: "Kazakhstan",
        awayTeam: "Australia",
        leagueName: "AVC Challenge Cup",
        countryName: "International Tournaments",
        sportId: 11,
        sportName: "Volleyball",
        startDate: dayjs().add(5, "seconds").format("YYYY-MM-DDTHH:mm:ssZ"),
        statusId: 18,
        inPlay: {
            clock: {
                period: "S4",
                statusId: 10,
                minutes: 1,
                seconds: 140,
                remainingMinutesInPeriod: 1,
                remainingSecondsInPeriod: 20,
                timestamp: 1708442098
            },
            score: {
                home: "17",
                away: "13",
                periodScores: [
                    {
                        period: "S1",
                        home: "25",
                        away: "19"
                    },
                    {
                        period: "S2",
                        home: "27",
                        away: "25"
                    },
                    {
                        period: "S3",
                        home: "22",
                        away: "25"
                    }
                ]
            },
            stats: {}
        }
    },
    otherEvents: [
        {
            name: "Pakistan vs Vietnam",
            id: 19482,
            homeTeam: "Vietnam",
            awayTeam: "Vietnam",
            leagueName: "AVC Challenge Cup",
            countryName: "International Tournaments",
            sportId: 11,
            sportName: "Volleyball",
            startDate: "2023-10-03T18:45:00+00:00",
            statusId: 18,
            inPlay: {
                clock: {
                    period: "S3",
                    statusId: 10,
                    minutes: 1,
                    seconds: 140,
                    remainingMinutesInPeriod: 1,
                    remainingSecondsInPeriod: 20,
                    timestamp: 1708442098
                },
                score: {
                    home: "11",
                    away: "15",
                    periodScores: [
                        {
                            period: "S1",
                            home: "21",
                            away: "19"
                        },
                        {
                            period: "S2",
                            home: "21",
                            away: "11"
                        }
                    ]
                },
                stats: {}
            }
        }
    ]
};
export const mockEventDetailTableTennisData = {
    eventDetail: {
        id: 12061,
        name: "Fabini, Marek vs Koubek, Vojtech",
        homeTeam: "Fabini, Marek",
        awayTeam: "Koubek, Vojtech",
        leagueName: "Czech Liga Pro",
        countryName: "Czech Republic",
        sportId: 11,
        sportName: "Table Tennis",
        startDate: dayjs().add(5, "seconds").format("YYYY-MM-DDTHH:mm:ssZ"),
        statusId: 18,
        inPlay: {
            clock: {
                period: "S4",
                statusId: 10,
                minutes: 1,
                seconds: 140,
                remainingMinutesInPeriod: 1,
                remainingSecondsInPeriod: 20,
                timestamp: 1708442098
            },
            score: {
                home: "10",
                away: "6",
                periodScores: [
                    {
                        period: "S1",
                        home: "13",
                        away: "11"
                    },
                    {
                        period: "S2",
                        home: "7",
                        away: "11"
                    },
                    {
                        period: "S3",
                        home: "11",
                        away: "6"
                    }
                ]
            },
            stats: {}
        }
    },
    otherEvents: [
        {
            name: "Pakistan vs Vietnam",
            id: 12062,
            homeTeam: "Vietnam",
            awayTeam: "Vietnam",
            leagueName: "AVC Challenge Cup",
            countryName: "International Tournaments",
            sportId: 11,
            sportName: "Table Tennis",
            startDate: "2023-10-03T18:45:00+00:00",
            statusId: 18,
            inPlay: {
                clock: {
                    period: "S4",
                    statusId: 10,
                    minutes: 1,
                    seconds: 140,
                    remainingMinutesInPeriod: 1,
                    remainingSecondsInPeriod: 20,
                    timestamp: 1708442098
                },
                score: {
                    home: "7",
                    away: "3",
                    periodScores: [
                        {
                            period: "S1",
                            home: "11",
                            away: "7"
                        },
                        {
                            period: "S2",
                            home: "11",
                            away: "9"
                        },
                        {
                            period: "S3",
                            home: "11",
                            away: "9"
                        }
                    ]
                },
                stats: {}
            }
        }
    ]
};
export const mockBreadcrumbData = [
    {
        title: "Soccer",
        href: "http://www.oyawin.com/soccer"
    },
    {
        title: "EURO Qualifiers",
        href: "http://www.oyawin.com/soccer/england/premier-league"
    },
    {
        title: "Galatasaray Spor Kulubu v Borussia Monchengladbach"
    }
];
export const mockBreadcrumbBasketballData = [
    {
        title: "Basketball",
        href: "http://www.oyawin.com/basketball"
    },
    {
        title: "NBA",
        href: "http://www.oyawin.com/soccer/england/premier-league"
    },
    {
        title: "Chicago Bulls vs Charlotte Hornets"
    }
];
export const mockBreadcrumbTennisData = [
    {
        title: "Tennis",
        href: "http://www.oyawin.com/tennis"
    },
    {
        title: "India Wells Open",
        href: "http://www.oyawin.com/tennis/india/india-wells-open"
    },
    {
        title: "S. Nagal vs S.C. Hong"
    }
];
export const mockBreadcrumbVolleyballData = [
    {
        title: "Volleyball",
        href: "http://www.oyawin.com/volleyball"
    },
    {
        title: "AVC Challenge Cup",
        href: "http://www.oyawin.com/volleyball/internationals/avc-challenge-cup"
    },
    {
        title: "Kazakhstan vs Australia"
    }
];
export const mockBreadcrumbTableTennisData = [
    {
        title: "Table Tennis",
        href: "http://www.oyawin.com/tabletennis"
    },
    {
        title: "Czech Liga Pro",
        href: "http://www.oyawin.com/tabletennis/czech-republic/czech-liga-pro"
    },
    {
        title: "Fabini, Marek vs Koubek, Vojtech"
    }
];
