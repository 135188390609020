var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { createRoot } from "react-dom/client";
import ImageFromCDN from "components/base/imageFromCDN/ImageFromCDN";
import { BetSummary } from "../betSummary/BetSummary";
import { Col, Row } from "antd";
import axios from "axios";
import { LocalStorage } from "helpers/Storage";
import clsx from "clsx";
import { SingleBet } from "../../myBets/SingleBet";
import SkeletonBetSlipConfirmation from "components/base/skeleton/BetSlipConfirmation";
import { useDisableBodyScroll } from "hooks/useDisableBodyScroll";
import styles from "./BetSlipConfirmation.module.scss";
export const BetSlipConfirmation = ({ betRefs, betSlipId, productId, isRelative, playerId, betSummariesOnly, maxWidth = "inherit", selectedTab }) => {
    const [showConfirmation, setShowConfirmation] = React.useState(true);
    const [bets, setBets] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    useDisableBodyScroll(!isRelative);
    const triggerBetDataLayerEvent = (action, data = []) => {
        data === null || data === void 0 ? void 0 : data.forEach(bet => {
            var _a, _b;
            const events = [];
            (_a = bet === null || bet === void 0 ? void 0 : bet.betLegs) === null || _a === void 0 ? void 0 : _a.forEach((event) => {
                events.push({
                    id: `${String(event.outcome.id)}`,
                    name: event.outcome.market.event.name,
                    product_category: productId === 1 ? "Sports" : productId === 2 ? "Esports" : null,
                    sport_category: event.outcome.market.event.tournament.sport.name,
                    market_name: event.outcome.market.name,
                    add_to_betslip_place: "",
                    league_name: event.outcome.market.event.tournament.name,
                    outcome_name: event.outcome.name,
                    odd_type: Number(bet === null || bet === void 0 ? void 0 : bet.statusId) === 1 ? "pregame" : "inplay",
                    odd: event.odds
                });
            });
            window.dispatchEvent(new CustomEvent("bet-data-layer", {
                detail: {
                    event: action,
                    coupon_id: bet === null || bet === void 0 ? void 0 : bet.betRef,
                    price: bet === null || bet === void 0 ? void 0 : bet.stake,
                    bet_type: selectedTab,
                    total_odd: Number(((bet === null || bet === void 0 ? void 0 : bet.potentialPayout) / (bet === null || bet === void 0 ? void 0 : bet.stake)).toFixed(2)),
                    stake: bet === null || bet === void 0 ? void 0 : bet.stake,
                    return: bet === null || bet === void 0 ? void 0 : bet.potentialPayout,
                    event_count: (_b = bet === null || bet === void 0 ? void 0 : bet.betLegs) === null || _b === void 0 ? void 0 : _b.length,
                    events
                },
                bubbles: true
            }));
        });
    };
    React.useEffect(() => {
        function fetchBetsData(refs) {
            var _a, _b;
            return __awaiter(this, void 0, void 0, function* () {
                const playerIdWithFallback = playerId !== null && playerId !== void 0 ? playerId : LocalStorage.get("widget-playerId");
                if (!playerIdWithFallback)
                    return [];
                const betRef = refs === null || refs === void 0 ? void 0 : refs.reduce((acc, item) => {
                    if (acc) {
                        if (item)
                            return `${acc},${item}`;
                        return acc;
                    }
                    return item;
                }, "");
                if (!((_a = process.env) === null || _a === void 0 ? void 0 : _a.REACT_APP_GATEWAY_API_URL))
                    return null;
                return yield axios
                    .get(`${(_b = process.env) === null || _b === void 0 ? void 0 : _b.REACT_APP_GATEWAY_API_URL}/bets/bettor/${playerIdWithFallback}/bets?betRef=${betRef}`)
                    .then(({ data }) => {
                    return data;
                })
                    .catch((err) => {
                    console.log(err);
                })
                    .finally(() => {
                    setIsLoading(false);
                });
            });
        }
        function setBetsData(refs) {
            var _a;
            return __awaiter(this, void 0, void 0, function* () {
                !isLoading && setIsLoading(true);
                if ((_a = process.env) === null || _a === void 0 ? void 0 : _a.REACT_APP_GATEWAY_API_URL) {
                    const data = yield fetchBetsData(refs);
                    setBets(data);
                }
                else {
                    setIsLoading(false);
                }
            });
        }
        if (betRefs === null || betRefs === void 0 ? void 0 : betRefs.length) {
            setBetsData(betRefs);
        }
    }, [betRefs]);
    React.useEffect(() => {
        function fetchBetsData(betSlipId) {
            var _a, _b;
            return __awaiter(this, void 0, void 0, function* () {
                const playerIdWithFallback = playerId !== null && playerId !== void 0 ? playerId : LocalStorage.get("widget-playerId");
                if (!playerIdWithFallback)
                    return [];
                if (!((_a = process.env) === null || _a === void 0 ? void 0 : _a.REACT_APP_GATEWAY_API_URL))
                    return null;
                return yield axios
                    .get(`${(_b = process.env) === null || _b === void 0 ? void 0 : _b.REACT_APP_GATEWAY_API_URL}/bets/bettor/${playerIdWithFallback}/bets`, {
                    params: {
                        betSlipId
                    }
                })
                    .then(({ data }) => {
                    triggerBetDataLayerEvent("bet_placement", data);
                    return data;
                })
                    .catch((err) => {
                    console.log(err);
                })
                    .finally(() => {
                    setIsLoading(false);
                });
            });
        }
        function setBetsData(betSlipId) {
            var _a;
            return __awaiter(this, void 0, void 0, function* () {
                !isLoading && setIsLoading(true);
                if ((_a = process.env) === null || _a === void 0 ? void 0 : _a.REACT_APP_GATEWAY_API_URL) {
                    const data = yield fetchBetsData(betSlipId);
                    setBets(data);
                }
                else {
                    setIsLoading(false);
                }
            });
        }
        if (betSlipId) {
            setBetsData(betSlipId);
        }
    }, [betSlipId]);
    const closeConfirmation = (reuseSelections) => new CustomEvent(`bet-slip-confirmation-close`, {
        detail: {
            reuseSelections
        },
        bubbles: true
    });
    const triggerCloseConfirmation = () => {
        setShowConfirmation(false);
        window.dispatchEvent(closeConfirmation());
    };
    // const triggerReuseSelections = (bets: string[]): void => {
    //   const reuseSelections = (bets: string[]): CustomEvent =>
    //     new CustomEvent(`bet-slip-reuse-bets`, {
    //       detail: {
    //         bets
    //       },
    //       bubbles: true
    //     });
    //   window.dispatchEvent(reuseSelections(bets));
    // };
    // const reuseSelectionsHandler = (): void => {
    //   const selectedBets: any = [];
    //
    //   bets?.forEach((bet) => {
    //     bet?.betLegs?.forEach(({ outcome, odds }) => {
    //       const { market, name, constituentOutcomes } = outcome;
    //       if (constituentOutcomes?.length) {
    //         constituentOutcomes?.forEach(outcome => {
    //           const isOutcomeIncludes = selectedBets?.some((bet: any) => bet.outcomeId === outcome.id);
    //
    //           if (!isOutcomeIncludes) {
    //             selectedBets.push({
    //               market: outcome.market.name,
    //               pick: outcome.name,
    //               event: market.event.name,
    //               odds,
    //               time: market.event.startDate,
    //               outcomeId: outcome.id,
    //               eventId: market.event.id,
    //               productId
    //             });
    //           }
    //         });
    //       } else {
    //         const isOutcomeIncludes = selectedBets?.some((bet: any) => bet.outcomeId === outcome.id);
    //
    //         if (!isOutcomeIncludes) {
    //           selectedBets.push({
    //             market: market.name,
    //             pick: name,
    //             event: market.event.name,
    //             odds,
    //             time: market.event.startDate,
    //             outcomeId: outcome.id,
    //             eventId: market.event.id,
    //             productId
    //           });
    //         }
    //       }
    //     });
    //   });
    //   LocalStorage.set("betsDetails", JSON.stringify(selectedBets));
    //   setShowConfirmation(false);
    //   window.dispatchEvent(closeConfirmation(true));
    //   triggerReuseSelections(selectedBets.map((bet: any) => bet.outcomeId));
    // };
    const totalReturn = bets === null || bets === void 0 ? void 0 : bets.reduce((acc, bet) => acc + bet.potentialPayout, 0);
    const totalStake = bets === null || bets === void 0 ? void 0 : bets.reduce((acc, bet) => acc + bet.stake, 0);
    if (!showConfirmation)
        return null;
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: clsx({
                    [styles.greyedOut]: !isRelative
                }), onClick: triggerCloseConfirmation }), _jsxs("div", Object.assign({ className: clsx(styles.root, {
                    [styles.fixed]: !isRelative
                }), style: { maxWidth }, "data-testid": "BetSlipConfirmation" }, { children: [!betSummariesOnly &&
                        _jsx("div", Object.assign({ className: styles.header, onClick: triggerCloseConfirmation }, { children: _jsxs(Row, Object.assign({ justify: "space-between", align: "middle" }, { children: [_jsx(Col, Object.assign({ className: styles.text }, { children: "Bet Placed" })), _jsx(Col, { children: _jsx(ImageFromCDN, { alt: "Close", url: "https://d1bu7ybn6mtlcz.cloudfront.net/images/close.png", size: 15 }) })] })) })), _jsx("div", Object.assign({ className: styles.bets, style: { minHeight: betSummariesOnly ? "0" : "65dvh" } }, { children: isLoading ?
                            _jsx(SkeletonBetSlipConfirmation, {}) :
                            bets === null || bets === void 0 ? void 0 : bets.map((bet, index) => {
                                return _jsx(SingleBet, { bet: bet, betslipConfirmation: true }, `bet-slip-accordion-bet-${index}`);
                            }) })), !isLoading && _jsx(BetSummary, { totalBets: bets === null || bets === void 0 ? void 0 : bets.length, totalStake: totalStake, totalReturn: totalReturn })] }))] }));
};
class Element extends HTMLElement {
    constructor() {
        super();
        this.root = createRoot(this);
        this["bet-refs"] = [];
    }
    static get observedAttributes() {
        return [
            "bet-refs",
            "bet-slip-id",
            "relative",
            "product-id",
            "bet-summaries-only",
            "player-id",
            "max-width",
            "selected-tab"
        ];
    }
    connectedCallback() {
        var _a;
        (_a = this.root) === null || _a === void 0 ? void 0 : _a.render(_jsx(BetSlipConfirmation, { betRefs: this["bet-refs"], betSlipId: this["bet-slip-id"], isRelative: this.relative, productId: this["product-id"], playerId: this["player-id"], betSummariesOnly: this["bet-summaries-only"], maxWidth: this["max-width"], selectedTab: this["selected-tab"] }));
    }
    disconnectedCallback() {
        setTimeout(() => { var _a; return (_a = this.root) === null || _a === void 0 ? void 0 : _a.unmount(); }, 0);
    }
    attributeChangedCallback(attrName, oldValue, newValue) {
        var _a;
        switch (attrName) {
            case "bet-refs":
            case "bet-summaries-only":
            case "relative":
                this[attrName] = JSON.parse(newValue);
                break;
            case "product-id":
            case "bet-slip-id":
                this[attrName] = Number(newValue);
                break;
            case "player-id":
                this[attrName] = newValue;
                break;
            case "max-width":
            case "selected-tab":
                this[attrName] = String(newValue);
                break;
            default:
                break;
        }
        (_a = this.root) === null || _a === void 0 ? void 0 : _a.render(_jsx(BetSlipConfirmation, { betRefs: this["bet-refs"], betSlipId: this["bet-slip-id"], isRelative: this.relative, productId: this["product-id"], betSummariesOnly: this["bet-summaries-only"], playerId: this["player-id"], maxWidth: this["max-width"], selectedTab: this["selected-tab"] }));
    }
}
(_a = customElements.get("leetent-bet-slip-confirmation")) !== null && _a !== void 0 ? _a : customElements.define("leetent-bet-slip-confirmation", Element);
